import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import { IBarType, IStandard } from "../../../interfaces/standard.interface";
import projectSvc from "../../../services/project.service";
import standardSvc from "../../../services/standard.service";
import companySvc from "../../../services/company.service";
import { ICompany } from "../../../interfaces/company.interface";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { IBBS } from "../../../interfaces/bbs.interface";
import bbsSvc from "../../../services/bbs.service";
import memberSvc from "../../../services/member.service";
import { IMember } from "../../../interfaces/member.interface";
import BBSTextField from "../../../component/inputs/BBSTextField";
import { triggerFetchProjects } from "../../../store/features/commonSlice";

const initialStateMember = {
  id: "",
  name: "",
  bbsId: "",
  revisionNumber: "",
  noOfMem: null,
  memberType: "",
  extra1: "",
  extra2: "",
};
interface IProps {
  memberProperties: any;
  showMemberRevisionDrawer: boolean;
  setShowMemberRevisionDrawer: any;
}

export const MemberRevision = ({
  memberProperties,
  showMemberRevisionDrawer,
  setShowMemberRevisionDrawer,
}: IProps) => {
  const [newMember, setNewMember] = useState(initialStateMember);
  const [memberToEdit, setMemberToEdit] = useState<IMember | null>(null);
  const dispatch = useAppDispatch();
  const [bbsList, setBbsList] = useState<IBBS[] | null>(null);

  useEffect(() => {
    if (showMemberRevisionDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showMemberRevisionDrawer]);

  const [validFields, setValidFields] = useState<any>({
    name: null,
    revisionNumber: null,
  });

  useEffect(() => {
    if (memberProperties.memberId) {
      memberSvc.getById(memberProperties.memberId).then((x: any) => {
        if (x.data) {
          setMemberToEdit(x.data as IMember);
        }
      });
    }
  }, [memberProperties, showMemberRevisionDrawer]);

  const saveMemberRevison = async () => {
    if (!memberToEdit) return false;

    if (memberToEdit.revisionNumber === newMember.revisionNumber) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `New revision cannot be same as current revision.`,
        })
      );
      return;
    }

    try {
      var payload = {
        ...memberToEdit,
        revisionNumber: newMember.revisionNumber,
      };

      const res: any = await memberSvc.createRevision(payload);
      if (res.data) {
        dispatch(triggerFetchProjects());
        setShowMemberRevisionDrawer(false);

        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Member revison created successfully",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `Unable to create member revision, Please try again.`,
        })
      );
    }
  };

  const validateMember = () => {
    if (!newMember) return false;
    if (!newMember.revisionNumber) return false;

    return Object.values(invalidFields).every((field) => !field);
  };

  const onMemberChange = (e: ComboBoxChangeEvent | TextBoxChangeEvent) => {
    setNewMember((prev: any) => {
      return {
        ...prev,
        [e.target.name as string]:
          e.target.name === "bbsId" ? e.value.id : e.value,
      };
    });
  };

  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev: any) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  useEffect(() => {
    if (!showMemberRevisionDrawer) setNewMember(initialStateMember);
  }, [showMemberRevisionDrawer]);
  return (
    <>
      <Drawer
        expanded={showMemberRevisionDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500 "
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() =>
              setShowMemberRevisionDrawer(!showMemberRevisionDrawer)
            }
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showMemberRevisionDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Add New Member</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() =>
                  setShowMemberRevisionDrawer(!showMemberRevisionDrawer)
                }
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <CustomLabel label="Name" required={false} />
                  <TextBox
                    value={memberToEdit?.name}
                    type="text"
                    autoComplete="new-password"
                    disabled={true}
                    readOnly={true}
                  />
                </div>

                <div>
                  <CustomLabel label="Current Revision" required={false} />
                  <TextBox
                    value={memberToEdit?.revisionNumber}
                    type="text"
                    autoComplete="new-password"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
                <div>
                  <CustomLabel label="Name" required={true} />

                  <TextBox
                    value={newMember.revisionNumber}
                    onChange={onMemberChange}
                    type="text"
                    placeholder="Enter revisionNumber"
                    name="revisionNumber"
                    valid={!validFields.revisionNumber}
                    autoComplete="new-password"
                  />
                  {validFields?.revisionNumber && (
                    <p className="text-red-600 text-[12px]">
                      {validFields?.revisionNumber}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => {
                  setNewMember(initialStateMember);
                  setShowMemberRevisionDrawer(!showMemberRevisionDrawer);
                }}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                onClick={saveMemberRevison}
                disabled={!validateMember()}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
