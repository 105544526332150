import { ShowColumnsFilter } from "../../../component/global/ColumnMenu";
import { WeightCell } from "../../../shared-components/custom-cells";
import { MIN_RESIZABLE_WIDTH_KENDO_TABLE } from "../../../utils/utils";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  onColumnsSubmit: any,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean,
  gridData?: any
): any => {
  const isVisible = (field: string) => {
    if (!columnsMapping?.length) return false;
    if (columnsMapping.includes(field)) {
      return true;
    }

    return false;
  };
  const columns = [
    {
      title: "Member Name",
      field: "name",
      show: true,
      // width: 200,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },

    {
      title: "No. of Members",
      field: "noOfMembers",
      show: changed ? isVisible("noOfMembers") : true,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["noOfMembers"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },

    {
      title: "Weight (KG)",
      field: "weight",
      show: changed ? isVisible("weight") : true,
      width: 150,
      cells: {
        data: (props: any) => <WeightCell {...props} />,
      },
      footerCell: (props: any) => {
        const total = gridData?.reduce(
          (acc: any, current: any) => acc + current[props.field],
          0
        );
        return (
          <td colSpan={props.colSpan} style={props.style}>
            Total: {total}
          </td>
        );
      },
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
  ];

  return columns;
};
