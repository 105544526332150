import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import {
  PagerTargetEvent,
  getSelectedState,
} from "@progress/kendo-react-data-tools";
import {
  DatePickerChangeEvent,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  ComboBoxChangeEvent,
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridDataStateChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  CheckboxChangeEvent,
  Input,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import { debounce } from "lodash";
import {
  ArrowUpSquare,
  CheckIcon,
  ChevronDown,
  FileUp,
  FileX,
  FileX2,
  Forward,
  GitBranchPlus,
  GitGraph,
  Plus,
  Scissors,
  Send,
  Undo2,
  X,
} from "lucide-react";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import SearchBox from "../../../component/ui/SearchBox";
import Filters from "../../../constants/filters";
import IPageState from "../../../interfaces/page.interface";
import { ISearchFilter } from "../../../interfaces/searchFilter.interface";
import {
  resetFetchProjects,
  setSelectedCallOff,
  resetSelectedCallOff,
  triggerFetchProjects,
} from "../../../store/features/commonSlice";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { getPaginationData } from "../../../utils/utils";
import { columnInterface, generateColumns } from "./columns";
import WriteDrawer from "./WriteDrawer";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CustomLabel from "../../../component/global/RequiredMark";
import { PDFViewer, PDFViewerTool } from "@progress/kendo-react-pdf-viewer";
import { setToast } from "../../../store/features/toastSlice";
import { ICallOff } from "../../../interfaces/callOff.interface";
import callOffSvc from "../../../services/callOff.service";
import { CustomDialogBox } from "../../../shared-components/dialog-box";
import { getDialogInfo } from "../../../utils/dialog.utils";
import ManageMemberDrawer from "./ManageMemberDrawer";
import ManageNoOfMemberDrawer from "./ManageNoOfMemberDrawer";
import SendToFabricator from "./SendToFabricator";
import CallOffLogsDrawer from "./CallOffLogsDrawer";
import FabricatorEmail from "./FabricatorEmail";

let mappingChanged = false;
const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const tools: PDFViewerTool[] = [
  "pager",
  "spacer",
  "zoomInOut",
  //"zoom",
  "selection",
  "spacer",
  "search",
  "print",
  "download",
];

const CallOffListing = () => {
  const [editCallOff, setEditCallOff] = useState<ICallOff | null>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSendToFabDrawer, setShowSendToFabDrawer] = useState(false);
  const [showFabEmailDrawer, setShowFabEmailDrawer] = useState(false);

  const [showMemberDrawer, setShowMemberDrawer] = useState(false);
  const [showLogsDrawer, setShowLogsDrawer] = useState(false);
  const [releaseCallOffAfterNoOfMem, setReleaseCallOffAfterNoOfMem] =
    useState(false);
  const [showNoOfMemberDrawer, setShowNoOfMemberDrawer] = useState(false);
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [fileNameVal, setFileNameVal] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [releaseModalVisible, setReleaseModalVisible] = React.useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);
  const [releaseMemberModalVisible, setReleaseMemberModalVisible] =
    React.useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState<string>("");
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [confirmationType, setConfirmationType] = useState<string>("");

  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      fetchAllCallOff();
    }
  };
  const Pagination = getPaginationData();

  const dispatch = useDispatch();

  const toggleReleaseDialog = () => {
    setReleaseModalVisible(!releaseModalVisible);
  };

  const handleReleaseDialog = (action: string) => {
    //

    if (action === "yes") {
      if (commonState?.selectedCallOff?.status === "Engineered")
        toggleReleaseMemberDialog();
      else {
        var payload = {
          ...commonState?.selectedCallOff,
          action: "UnRelease",
        };
        callOffSvc.update(payload?.id, payload).then((x) => {
          if (!x.error) {
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: "Call-Off has been Cancelled successfully",
              })
            );

            fetchCallOff(searchValue, filterQuery);
            dispatch(triggerFetchProjects());
          }
        });
      }
    }

    toggleReleaseDialog();
  };

  const toggleReleaseMemberDialog = () => {
    setReleaseMemberModalVisible(!releaseMemberModalVisible);
  };

  const handleReleaseMemberDialog = (action: string) => {
    if (action === "yes") {
      setReleaseCallOffAfterNoOfMem(true);
      setShowNoOfMemberDrawer(true);
    } else {
      releaseCallOff();
    }

    toggleReleaseMemberDialog();
  };

  const releaseCallOff = () => {
    var payload = {
      ...commonState?.selectedCallOff,
      action: "Released",
    };
    if (payload?.members?.length == 0) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: "Call-Off does not have any members",
        })
      );
    } else {
      callOffSvc.update(payload?.id, payload).then((x) => {
        if (!x.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Call-Off has been Released successfully",
            })
          );
          fetchCallOff(searchValue, filterQuery);
          dispatch(triggerFetchProjects());
        }
      });
    }
  };

  const commonState = useAppSelector((state: RootState) => state.common);

  const debouncedSearch = useRef(
    debounce((value: string) => fetchCallOff(value), 500)
  ).current;

  const callOffTemp = useRef<ICallOff[]>([]);

  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >();
  const [callOff, setCallOff] = useState<ICallOff[]>([]);
  const [allCallOff, setAllCallOff] = useState<ICallOff[]>([]);
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);

  const [releasedDisabled, setReleasedDisabled] = useState(true);
  const [sendToFabDisabled, setSendToFabDisabled] = useState(true);
  const [recallFromFabDisabled, setRecallFromFabDisabled] = useState(true);

  const handleExport = (e: any) => {
    if (e?.id) {
      dispatch(setSelectedCallOff(e));
      callOffSvc.exportExcel(e?.id, e?.name);
      return;
    }

    if (commonState?.selectedCallOff?.id || e?.id) {
      if (commonState?.selectedCallOff?.members.length == 0) {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: "Call-Off does not have any members",
          })
        );
        return;
      }

      callOffSvc.exportExcel(
        commonState?.selectedCallOff?.id,
        commonState?.selectedCallOff?.name
      );
    } else {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: "Please select a Call-Off to export",
        })
      );
    }

    setShow(false);
  };

  const fetchAllCallOff = () => {
    let filterQueryTemp = { ...filterQuery };
    if (filterQueryTemp?.filters?.length) {
      const index = filterQueryTemp.filters[0].filters.findIndex(
        (filter: any) => filter.field === "status"
      );
      if (index === -1 && statusFilterValue !== "All") {
        filterQueryTemp = filterQueryTemp.filters[0].filters.push({
          field: "status",
          operator: "eq",
          value: statusFilterValue,
        });
      }
    }
    callOffSvc.getAll([], filterQueryTemp).then((res) => {
      setAllCallOff(res.data);
    });
  };

  const handleCancelCallOffClick = () => {
    setConfirmationType("cancel");
    setConfirmationTitle("Cancel Call-Off");
    setConfirmationMessage("Are you sure you want to cancel this Call-Off?");
    toggleConfirmationDialog();
  };

  const handleDelete = () => {
    editFilterQuery();
    dispatch(triggerFetchProjects());
  };

  const handleCallOffLogsClick = (data: ICallOff & { id: string }) => {
    setEditCallOff(data);
    setShowLogsDrawer(true);
  };

  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      handleEditClick,
      handleManageMemberClick,
      handleManageNoOfMemberClick,
      handleCancelCallOffClick,
      handleCallOffLogsClick,
      handleCallOffFabEmailClick,
      handleExport,
      onColumnsSubmit,
      setShowDrawer,
      handleDelete,
      null,
      columnsMapping,
      mappingChanged
    )
  );
  const [searchValue, setSearchValue] = useState("");
  const [filterQuery, setFilterQuery] = useState<any>({
    filters: [
      {
        logic: "and",
        filters: [
          {
            field: "isActive",
            operator: "eq",
            value: true,
          },
        ],
      },
    ],
  });

  const [statusFilterValue, setStatusFilterValue] = useState("");

  const [dataState, setDataState] = useState<State>({
    skip: 0,
    take: 10,
  });
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>("");

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === "Escape") {
        setShowPdfPreview(false);
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [showPdfPreview]);

  const saveCallOffListing = (action?: string) => {
    var payload;
    if (!editCallOff?.id) {
      payload = {
        ...editCallOff,
        prefix: commonState.selectedProject.event.item.projectAbbreviation,
        status: "Engineered",
        projectId: commonState.selectedProject.event.item.projectId,
        projectName: commonState?.selectedProject?.event?.item?.projectName,
        levelCode: commonState.selectedProject.event.item.levelCode,
        levelName: commonState.selectedProject.event.item.levelName,
        subLevelCode: commonState.selectedProject.event.item.subLevelCode,
        subLevelName: commonState.selectedProject.event.item.subLevelName,
        shopDrawingCode: commonState.selectedProject.event.item.shopDrawingCode,
        shopDrawingName: commonState.selectedProject.event.item.shopDrawingName,
      };

      if (editCallOff?.deliveryDate) {
        const formattedDate = editCallOff.deliveryDate?.toLocaleString(
          "en-US",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        );
        payload.deliveryDate = formattedDate;
      }

      callOffSvc.create(payload).then((res: any) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Call-Off created successfully",
            })
          );
          fetchCallOff(searchValue, filterQuery);
          setEditCallOff({} as ICallOff);
          setShowDrawer(false);
        } else {
          dispatch(
            setToast({
              toastType: "error",
              toastMsg: res.error,
            })
          );
        }
      });
    } else {
      payload = {
        ...editCallOff,
        action: action,
      };

      callOffSvc.update(payload?.id!, payload).then((res: any) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg:
                action && action === "SendToFab"
                  ? "Call-Off has been sent to fabrictor successfully"
                  : "Call-Off updated successfully",
            })
          );

          fetchCallOff(searchValue, filterQuery);
          dispatch(resetSelectedCallOff());
          if (action && action === "SendToFab") {
            setShowSendToFabDrawer(false);
            setShowFabEmailDrawer(true);
          } else {
            setShowDrawer(false);
            setEditCallOff(null);
          }
        } else {
          dispatch(
            setToast({
              toastType: "error",
              toastMsg: res.error,
            })
          );
        }
      });
    }
  };

  function handleEditClick(data: ICallOff & { id: string }) {
    setEditCallOff(data);
    setShowDrawer(true);
  }

  function handleManageMemberClick(data: ICallOff & { id: string }) {
    setEditCallOff(data);
    setShowMemberDrawer(true);
  }

  function handleManageNoOfMemberClick(data: ICallOff & { id: string }) {
    setEditCallOff(data);
    setShowNoOfMemberDrawer(true);
  }

  function handleCallOffFabEmailClick(data: ICallOff & { id: string }) {
    setEditCallOff(data);
    setShowFabEmailDrawer(!showFabEmailDrawer);
  }

  const handleDateChange = (date: any) => {
    try {
      if (!date) {
        setEditCallOff((prev: any) => ({
          ...prev,
          deliveryDate: null,
        }));
        return;
      }

      // Instead of using toLocaleString, store the Date object directly
      setEditCallOff((prev: any) => ({
        ...prev,
        deliveryDate: date,
      }));
    } catch (error) {
      console.error("ERROR: ", error);
    }
  };

  const handleChange = (e: any, isDropdown?: boolean) => {
    if (e.target.name === "subcontractorId") {
      var prefix = commonState.selectedProject.event.item.callOffPrefix;
      setEditCallOff((prev: any) => {
        return {
          ...prev,
          subcontractorId: e.target.value.id,
          subcontractorName: e.target.value.name,
          prefix:
            prefix === "Project-Subcontractor Abbreviation"
              ? `${prev.prefix}-${e.target.value.abbreviation}`
              : prefix === "Subcontractor Abbreviation"
              ? e.target.value.abbreviation
              : prev.prefix,
        };
      });
      return;
    }

    if (e.target.name === "deliveryDate" || e?.value) {
      setEditCallOff((prev: any) => {
        return {
          ...prev,
          [e.target.name as any]: e?.value?.toLocaleString(), // dd-MM-yyyy HH:mm AM/PM
        };
      });
      return;
    }

    setEditCallOff((prev: any) => {
      return {
        ...prev,
        [e.target.name as any]: isDropdown ? e.target.value.id : e.target.value,
      };
    });
  };

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      handleEditClick,
      handleManageMemberClick,
      handleManageNoOfMemberClick,
      handleCancelCallOffClick,
      handleCallOffLogsClick,
      handleCallOffFabEmailClick,
      handleExport,
      onColumnsSubmit,
      setShowDrawer,
      handleDelete,
      columnsState,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      debouncedSearch(value);
    }
  };

  const projectSelected = () => {
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.checked) {
      return false;
    }
    return true;
  };

  const fetchCallOff = (
    searchQuery?: string,
    filters?: any,
    sort?: any,
    pageNumber = 0,
    pageSize = Pagination.initialDataState.take
  ) => {
    if (!projectSelected) return;

    if (!searchQuery && searchValue) {
      searchQuery = searchValue;
    }

    if (!filters && filterQuery) {
      filters = filterQuery;
    }

    const { item } = commonState?.selectedProject?.event || {};

    // Store current selected call off ID before fetching
    const currentSelectedId = Object.keys(selectedState)[0];

    let searchFilters: ISearchFilter[] = [];
    if (searchQuery) {
      searchFilters = [
        { field: "Name", operator: "contains", value: searchQuery },
        { field: "CallOffNumber", operator: "contains", value: searchQuery },
      ];
    }

    if (statusFilterValue == "") {
      setStatusFilterValue("All");
    }
    if (filters?.filters?.length) {
      const index = filters.filters[0].filters.findIndex(
        (filter: any) => filter.field === "status"
      );
      const projIdx = filters.filters[0].filters.findIndex(
        (filter: any) => filter.field === "projectId"
      );
      if (projIdx === -1) return;
      if (
        index === -1 &&
        statusFilterValue !== "" &&
        statusFilterValue !== "All"
      ) {
        filters.filters[0].filters.push({
          field: "status",
          operator: "eq",
          value: statusFilterValue,
        });
      }
    } else {
      if (item) {
        const { projectId } = item;

        filters = {
          filters: [
            {
              logic: "and",
              filters: [
                {
                  field: "projectId",
                  operator: "eq",
                  value: projectId,
                },
              ],
            },
          ],
        };
      }
    }

    callOffSvc
      .getWithUrl(
        "get-callOffs",
        searchFilters,
        filters,
        sort,
        pageNumber,
        pageSize
      )
      .then((res) => {
        callOffTemp.current = JSON.parse(JSON.stringify(res.data));
        setCallOff(res.data);
        setTotal(res.data.total);

        // If there was a previously selected call off, find it in the new data
        if (currentSelectedId) {
          const selectedCallOff = res.data.find(
            (item: any) => item.id === currentSelectedId
          );

          if (selectedCallOff) {
            // Update selection state while maintaining the same selected item
            setSelectedState({ [currentSelectedId]: true });
            dispatch(setSelectedCallOff(selectedCallOff));

            // Update UI states based on the selected call off
            if (selectedCallOff?.sendToFab) {
              setReleasedDisabled(true);
              setRecallFromFabDisabled(false);
            } else {
              setReleasedDisabled(false);
              setRecallFromFabDisabled(true);
            }

            if (
              selectedCallOff?.status === "Released" &&
              !selectedCallOff?.sendToFab
            ) {
              setSendToFabDisabled(false);
            } else {
              setSendToFabDisabled(true);
            }
          } else {
            // If the selected item is no longer in the results, clear selection
            setSelectedState({});
            dispatch(resetSelectedCallOff());
            setReleasedDisabled(true);
            setSendToFabDisabled(true);
            setRecallFromFabDisabled(true);
          }
        }
      });
  };

  useEffect(() => {
    if (commonState?.fetchProjects) {
      editFilterQuery();
      dispatch(resetFetchProjects());
    }
  }, [commonState?.fetchProjects]);

  const editFilterQuery = (showAll?: boolean) => {
    if (!projectSelected()) return;
    const { item } = commonState?.selectedProject?.event || {};
    const filterQueryTemp = {
      filters: [
        {
          logic: "and",
          filters:
            statusFilterValue !== "" && statusFilterValue !== "All"
              ? [
                  {
                    field: "status",
                    operator: "eq",
                    value: statusFilterValue,
                  },
                ]
              : [],
        },
      ],
    };

    if (item) {
      const { level, projectId, levelCode, subLevelCode, shopDrawingCode } =
        item;

      const filterExists = (field: any) => {
        return filterQueryTemp.filters[0].filters.some(
          (filter: any) => filter.field === field
        );
      };
      const removeFilter = (field: any) => {
        filterQueryTemp.filters[0].filters =
          filterQueryTemp.filters[0].filters.filter(
            (filter: any) => filter.field !== field
          );
      };

      if (level === 0) {
        if (filterExists("projectId")) {
          removeFilter("projectId");
        }
        filterQueryTemp.filters[0].filters.push({
          field: "projectId",
          operator: "eq",
          value: projectId,
        });
      } else if (level === 1) {
        if (filterExists("projectId")) {
          removeFilter("projectId");
        }
        if (filterExists("levelCode")) {
          removeFilter("levelCode");
        }
        filterQueryTemp.filters[0].filters.push({
          field: "projectId",
          operator: "eq",
          value: projectId,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "levelCode",
          operator: "eq",
          value: levelCode,
        });
      } else if (level === 2) {
        if (filterExists("projectId")) {
          removeFilter("projectId");
        }
        if (filterExists("levelCode")) {
          removeFilter("levelCode");
        }
        if (filterExists("subLevelCode")) {
          removeFilter("subLevelCode");
        }
        filterQueryTemp.filters[0].filters.push({
          field: "projectId",
          operator: "eq",
          value: projectId,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "levelCode",
          operator: "eq",
          value: levelCode,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "subLevelCode",
          operator: "eq",
          value: subLevelCode,
        });
      } else if (level === 3 || level == 4) {
        if (filterExists("projectId")) {
          removeFilter("projectId");
        }
        if (filterExists("levelCode")) {
          removeFilter("levelCode");
        }
        if (filterExists("subLevelCode")) {
          removeFilter("subLevelCode");
        }
        if (filterExists("shopDrawingCode")) {
          removeFilter("shopDrawingCode");
        }
        filterQueryTemp.filters[0].filters.push({
          field: "projectId",
          operator: "eq",
          value: projectId,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "levelCode",
          operator: "eq",
          value: levelCode,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "subLevelCode",
          operator: "eq",
          value: subLevelCode,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "shopDrawingCode",
          operator: "eq",
          value: shopDrawingCode,
        });
      }
    }
    setFilterQuery(filterQueryTemp);
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    fetchCallOff(
      "",
      event.dataState.filter,
      event.dataState.sort,
      page.skip,
      page.take
    );
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = targetEvent.value === "All" ? callOff.length : event.page.take;

    fetchCallOff(searchValue, null, event.page.skip, event.page.take);
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  useEffect(() => {
    if (commonState?.selectedProject?.event?.item) {
      editFilterQuery();
    }
  }, [commonState?.selectedProject]);

  useEffect(() => {
    const selectedStateTemp = { ...selectedState };
    setSelectedState({});
    setSelectedState(selectedStateTemp);
    fetchCallOff(searchValue, filterQuery);
  }, [filterQuery, searchValue]);

  const onStatusFilterChange = (e: DropDownListChangeEvent) => {
    setStatusFilterValue(e.value);
    const prevFilter = { ...filterQuery };
    const index = prevFilter.filters[0].filters.findIndex(
      (filter: any) => filter.field === "status"
    );
    if (index === -1) {
      if (e.value !== "All") {
        prevFilter.filters[0].filters.push({
          field: "status",
          operator: "eq",
          value: e.value,
        });
      }
    } else {
      prevFilter.filters[0].filters.splice(index, 1);
    }
    setFilterQuery(prevFilter);
  };

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const target = event.nativeEvent?.target as HTMLElement;

      // Check if click is on the more options icon or its SVG elements
      if (
        target?.tagName?.toLowerCase() === "svg" ||
        target?.tagName?.toLowerCase() === "circle" ||
        target?.closest(".cta_dropdown") ||
        target?.closest(".bbsEditPopup")
      ) {
        return;
      }

      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      if (Object.keys(newSelectedState)[0] === Object.keys(selectedState)[0]) {
        dispatch(resetSelectedCallOff());
        setSelectedState({});
        setReleasedDisabled(true);
        setSendToFabDisabled(true);
        setRecallFromFabDisabled(true);
        return;
      }

      if (newSelectedState) {
        const key = Object.keys(newSelectedState)[0];
        const row =
          callOffTemp?.current?.find((item) => key === item.id) || null;
        dispatch(setSelectedCallOff(row));
        if (row?.sendToFab) setReleasedDisabled(true);
        else setReleasedDisabled(false);

        if (row?.status === "Released" && !row?.sendToFab)
          setSendToFabDisabled(false);
        else setSendToFabDisabled(true);

        if (row?.sendToFab) setRecallFromFabDisabled(false);
        else setRecallFromFabDisabled(true);
      }
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  useEffect(() => {
    if (selectedState && Object.values(selectedState).length == 0) {
      dispatch(resetSelectedCallOff());
    }
  }, [selectedState]);

  const handleDownload = (e: any) => {
    handlePDFDialog(fileType);
    setShowPdfPreview(false);
    return false;
  };

  const handlePDFDialog = (type: string) => {
    setFileNameVal(
      `${commonState?.selectedCallOff?.name} - ${commonState?.selectedCallOff?.revisionNumber}`
    );
    setFileType(type);
    setShowDialog(true);
  };

  const handlePdfDownload = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `${fileNameVal} - Call Off ${fileType}.pdf`);
    document.body.appendChild(link);
    link.click();
    setShowDialog(false);
    // document.removeChild(link);
  };

  const handlePDFExport = async (type: string) => {
    if (commonState?.selectedCallOff?.id) {
      if (commonState?.selectedCallOff?.members.length == 0) {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: "Call-Off does not have any members",
          })
        );
        return;
      }

      const res = await callOffSvc.exportPDF(
        commonState?.selectedCallOff?.id,
        commonState?.selectedCallOff?.no,
        type
      );
      if (res.data) {
        if (res.data) {
          setFileUrl(res.data);
          setShowPdfPreview(true);
          setFileType(type);
        }
      }
    }
    setShow(false);
  };

  const handleReleaseClick = () => {
    if (commonState?.selectedCallOff?.id) {
      toggleReleaseDialog();
    } else {
      dispatch(
        setToast({
          toastMsg: "Select a Call-Off to Release",
          toastType: "error",
        })
      );
    }
  };

  const handleSendToFabClick = () => {
    if (commonState?.selectedCallOff) {
      setEditCallOff(commonState?.selectedCallOff);
      setShowSendToFabDrawer(true);
    }
  };

  const handleRecallFromFabClick = () => {
    setConfirmationType("recall");
    setConfirmationTitle("Recall from Fabricator");
    setConfirmationMessage(
      "Are you sure you want to recall this Call-Off from fabricator?"
    );
    toggleConfirmationDialog();
  };

  useEffect(() => {
    if (!allCallOff?.length) return;
    _exporter.current?.save();
    setAllCallOff([]);
  }, [allCallOff]);

  const fabPopupRef = React.useRef<HTMLDivElement>(null);
  const fabAnchor = useRef<HTMLButtonElement | null>(null);
  const [fabShowPopup, setFabShowPopup] = useState(false);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const anchor = useRef<HTMLButtonElement | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  const onClick = () => {
    setShowPopup(!showPopup);
  };

  const onFabClick = () => {
    setFabShowPopup(!fabShowPopup);
  };

  const handleClickOutside = (event: any) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      anchor.current &&
      !anchor.current.contains(event.target)
    ) {
      setShowPopup(false);
    }

    if (
      fabPopupRef.current &&
      !fabPopupRef.current.contains(event.target) &&
      fabAnchor.current &&
      !fabAnchor.current.contains(event.target)
    ) {
      setFabShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (showDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showDrawer]);

  const handleConfirmationDialogClick = (action: string) => {
    if (action === "yes") {
      if (confirmationType == "recall") {
        var payload = {
          ...commonState?.selectedCallOff,
          action: "RecallFromFab",
        };

        callOffSvc.update(payload?.id, payload).then((x) => {
          if (!x.error) {
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: "Call-Off has been Recalled from Fabricator.",
              })
            );
            fetchCallOff(searchValue, filterQuery);
          }
        });
      } else {
        var payload = {
          ...commonState?.selectedCallOff,
          action: "Cancelled",
        };

        callOffSvc.update(payload?.id, payload).then((x) => {
          if (!x.error) {
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: "Call-Off has been cancelled successfully.",
              })
            );
            fetchCallOff(searchValue, filterQuery);
          }
        });
      }
    }
    toggleConfirmationDialog();
  };

  const toggleConfirmationDialog = () => {
    setShowConfirmationDialog(!showConfirmationDialog);
  };

  return (
    <>
      {releaseModalVisible && (
        <Dialog
          title={"Call-Off"}
          width={"345px"}
          onClose={() => toggleReleaseDialog()}
        >
          <p className="mb-2">
            {commonState?.selectedCallOff?.status == "Engineered" &&
              "Are you sure you want to release this Call-Off and all its members?"}
            {commonState?.selectedCallOff?.status == "Released" &&
              "Are you sure you want to cancel this Call-Off? Note that cancelling a Call-Off will unrelease its releated members?"}
          </p>
          <DialogActionsBar>
            <div className="flex gap-x-2 justify-end">
              <Button
                themeColor={"base"}
                onClick={() => handleReleaseDialog("no")}
                className="iconcta"
              >
                <span className="inline-flex items-center gap-1">
                  <X className="h-5 w-5" strokeWidth={1} />
                  No
                </span>
              </Button>
              <Button
                className="iconcta"
                themeColor={"primary"}
                onClick={() => handleReleaseDialog("yes")}
              >
                <span className="inline-flex items-center gap-1">
                  <CheckIcon className="h-5 w-5" strokeWidth={1} />
                  Yes
                </span>
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}

      {releaseMemberModalVisible && (
        <Dialog
          title={"Call-Off"}
          width={"345px"}
          onClose={() => toggleReleaseMemberDialog()}
        >
          <p className="mb-2">
            Do you want to change the number of members in Call Off?
          </p>
          <DialogActionsBar>
            <div className="flex gap-x-2 justify-end">
              <Button
                themeColor={"base"}
                onClick={() => handleReleaseMemberDialog("no")}
                className="iconcta"
              >
                <span className="inline-flex items-center gap-1">
                  <X className="h-5 w-5" strokeWidth={1} />
                  No
                </span>
              </Button>
              <Button
                className="iconcta"
                themeColor={"primary"}
                onClick={() => handleReleaseMemberDialog("yes")}
              >
                <span className="inline-flex items-center gap-1">
                  <CheckIcon className="h-5 w-5" strokeWidth={1} />
                  Yes
                </span>
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}

      {showConfirmationDialog && (
        <Dialog
          width={350}
          title={confirmationTitle}
          onClose={() => setShowDialog(false)}
        >
          <p className="mb-2">{confirmationMessage}</p>
          <DialogActionsBar>
            <div className="flex gap-x-2 justify-end">
              <Button
                themeColor={"base"}
                onClick={() => handleConfirmationDialogClick("no")}
                className="iconcta"
              >
                <span className="inline-flex items-center gap-1">
                  <X className="h-5 w-5" strokeWidth={1} />
                  No
                </span>
              </Button>
              <Button
                className="iconcta"
                themeColor={"primary"}
                onClick={() => handleConfirmationDialogClick("yes")}
              >
                <span className="inline-flex items-center gap-1">
                  <CheckIcon className="h-5 w-5" strokeWidth={1} />
                  Yes
                </span>
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}

      <div style={{ height: "100%" }}>
        <Grid
          size="small"
          style={{ height: "650px" }}
          data={callOff?.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          {...dataState}
          onDataStateChange={dataStateChange}
          skip={page.skip}
          take={page.take}
          total={total}
          selectedField={SELECTED_FIELD}
          pageable={{
            ...Pagination.pageSizeValue,
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          className={`customHeight callOffGrid`}
          resizable={Filters.resizeable}
          selectable={{
            enabled: true,
            drag: true,
            mode: "single",
          }}
          scrollable="scrollable"
          fixedScroll={true}
          onSelectionChange={onSelectionChange}
        >
          <GridToolbar>
            <div className="flex gap-3 w-full items-center justify-between">
              <div className="flex gap-x-3 w-full">
                <div className="w-[250px]">
                  <SearchBox
                    value={searchValue}
                    onChange={handleSearchChange}
                    placeholder="Search"
                  />
                </div>
                {/* <div
                className="cursor-pointer sm:ml-0 ml-2 sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
                <ExcelExport
                  data={allBbs}
                  collapsible={true}
                  fileName="Subcontractors.xlsx"
                  ref={_exporter}
                >
                  {stateColumns &&
                    stateColumns.length > 0 &&
                    stateColumns.map((column: any, idx: number) => {
                      if (column.show) {
                        return (
                          <ExcelExportColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                          />
                        );
                      }
                    })}
                </ExcelExport>
              </div> */}
                <DropDownList
                  className="px-2 font-small"
                  data={["All", "Engineered", "Released", "Cancelled"]}
                  value={statusFilterValue}
                  onChange={onStatusFilterChange}
                />
                <Button
                  className="border border-[#E2E8F0] k-button-solid-primary"
                  onClick={() => {
                    setEditCallOff({} as ICallOff);
                    setShowDrawer(!showDrawer);
                  }}
                >
                  <div className="flex gap-x-2">
                    <Plus className="text-[#484F61] self-center p-0 w-4 h-4 text-white" />
                    <p className="self-center pr-2">Add New</p>
                  </div>
                </Button>
                <Button
                  className="border border-[#E2E8F0] k-button-solid-info"
                  onClick={() => handleReleaseClick()}
                  disabled={releasedDisabled}
                >
                  <div className="flex gap-x-2">
                    {(!commonState?.selectedCallOff ||
                      commonState?.selectedCallOff?.status == "Engineered") && (
                      <GitBranchPlus className="text-[#484F61] self-center p-0 w-4 h-4 text-white" />
                    )}
                    {commonState?.selectedCallOff?.status == "Released" && (
                      <GitGraph className="text-[#484F61] self-center p-0 w-4 h-4 text-white" />
                    )}
                    <p className="self-center pr-2">
                      {commonState?.selectedCallOff?.status == "Released"
                        ? "Cancel Release"
                        : "Release"}
                    </p>
                  </div>
                </Button>

                <button
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md px-2 font-small"
                  onClick={onFabClick}
                  ref={fabAnchor}
                >
                  <span className="inline-flex items-center gap-2">
                    <Scissors className="w-4 h-4" />
                    <span className="border-r border-neutral-110 pr-2">
                      Fabrication
                    </span>

                    <ChevronDown className="w-4 h-4" />
                  </span>
                </button>

                <Popup
                  anchor={fabAnchor.current}
                  show={fabShowPopup}
                  popupClass={"popup-content w-[174px]"}
                  anchorAlign={{ horizontal: "right", vertical: "bottom" }}
                  popupAlign={{ horizontal: "right", vertical: "top" }}
                >
                  <div ref={fabPopupRef}>
                    <Button
                      fillMode="flat"
                      disabled={sendToFabDisabled}
                      onClick={() => {
                        handleSendToFabClick();
                        setFabShowPopup(false);
                      }}
                      className="w-full iconBtn px-1"
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <Forward className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Send to Fabricator
                        </span>
                      </span>
                    </Button>
                    <Button
                      fillMode="flat"
                      disabled={recallFromFabDisabled}
                      onClick={() => {
                        handleRecallFromFabClick();
                        setFabShowPopup(false);
                      }}
                      className="w-full iconBtn px-1"
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <Undo2 className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Recall from Fabricator
                        </span>
                      </span>
                    </Button>
                  </div>
                </Popup>
                <Button onClick={handleExport} className="px-2 font-small">
                  <span className="inline-flex items-center gap-2 w-full">
                    <FileX2 className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">
                      Export Call-Off
                    </span>
                  </span>
                </Button>

                <button
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md px-2 font-small"
                  onClick={onClick}
                  ref={anchor}
                >
                  <span className="inline-flex items-center gap-2">
                    <FileUp className="w-4 h-4" />

                    <span className="border-r border-neutral-110 pr-2">
                      Call-Off Reports
                    </span>

                    <ChevronDown className="w-4 h-4" />
                  </span>
                </button>
                <Popup
                  anchor={anchor.current}
                  show={showPopup}
                  popupClass={"popup-content w-[174px]"}
                  anchorAlign={{ horizontal: "right", vertical: "bottom" }}
                  popupAlign={{ horizontal: "right", vertical: "top" }}
                >
                  <div ref={popupRef}>
                    <Button
                      fillMode="flat"
                      disabled={!commonState?.selectedCallOff}
                      onClick={() => {
                        handlePDFExport("Cover Sheet");
                        setShowPopup(false);
                      }}
                      className="w-full iconBtn px-1"
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <FileX className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Cover Sheet
                        </span>
                      </span>
                    </Button>
                    <Button
                      fillMode="flat"
                      disabled={!commonState?.selectedCallOff}
                      onClick={() => {
                        handlePDFExport("Units");
                        setShowPopup(false);
                      }}
                      className="w-full iconBtn px-1"
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <FileX className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Units
                        </span>
                      </span>
                    </Button>
                    <Button
                      fillMode="flat"
                      disabled={!commonState?.selectedCallOff}
                      onClick={() => {
                        handlePDFExport("Total Weight");
                        setShowPopup(false);
                      }}
                      className="w-full iconBtn px-1"
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <FileX className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Total Weight
                        </span>
                      </span>
                    </Button>
                    <Button
                      fillMode="flat"
                      disabled={!commonState?.selectedCallOff}
                      onClick={() => {
                        handlePDFExport("Members");
                        setShowPopup(false);
                      }}
                      className="w-full iconBtn px-1"
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <FileX className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Members
                        </span>
                      </span>
                    </Button>
                  </div>
                </Popup>
              </div>
            </div>
          </GridToolbar>
          {stateColumns &&
            stateColumns.length > 0 &&
            stateColumns.map((column: any, idx: number) => {
              if (column.show) {
                if (column.cells) {
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      columnMenu={column.columnMenu}
                      minResizableWidth={200}
                      width={column.width}
                      cells={column.cells}
                      filter={column.filter ? column.filter : "text"}
                    />
                  );
                }
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    width={column.width}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
            })}
        </Grid>
        <WriteDrawer
          showCreateDrawer={showDrawer}
          setShowCreateDrawer={setShowDrawer}
          editCallOff={editCallOff}
          setEditCallOff={setEditCallOff}
          handleChange={handleChange}
          onSave={saveCallOffListing}
        />

        <CallOffLogsDrawer
          showLogsDrawer={showLogsDrawer}
          setShowLogsDrawer={setShowLogsDrawer}
          callOff={editCallOff}
          fetchCallOff={fetchCallOff}
        />

        <ManageMemberDrawer
          showManageMemberDrawer={showMemberDrawer}
          setShowManageMemberDrawer={setShowMemberDrawer}
          callOff={editCallOff}
          fetchCallOff={fetchCallOff}
        />

        <ManageNoOfMemberDrawer
          releaseCallOffAfterNoOfMem={releaseCallOffAfterNoOfMem}
          setReleaseCallOffAfterNoOfMem={setReleaseCallOffAfterNoOfMem}
          releaseCallOff={releaseCallOff}
          showNoOfMemberDrawer={showNoOfMemberDrawer}
          setShowNoOfMemberDrawer={setShowNoOfMemberDrawer}
          callOff={editCallOff}
          setEditCallOff={setEditCallOff}
          fetchCallOff={fetchCallOff}
        />

        <SendToFabricator
          showSendToFabDrawer={showSendToFabDrawer}
          setShowSendToFabDrawer={setShowSendToFabDrawer}
          editCallOff={editCallOff}
          setEditCallOff={setEditCallOff}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          onSave={saveCallOffListing}
        />

        <FabricatorEmail
          showFabEmailDrawer={showFabEmailDrawer}
          setShowFabEmailDrawer={setShowFabEmailDrawer}
          editCallOff={editCallOff}
          setEditCallOff={setEditCallOff}
        />

        {showDialog && (
          <Dialog
            width={450}
            title={"Export Call Off " + fileType}
            onClose={() => setShowDialog(false)}
            className="custom-dialog"
          >
            <div className="my-4">
              <CustomLabel label={"File Name"} required={true} />
              <Input
                type="text"
                placeholder={"Enter Name"}
                value={fileNameVal}
                onChange={(e: any) => {
                  setFileNameVal(e.target.value);
                }}
              />
            </div>
            <DialogActionsBar>
              <div className="flex justify-end gap-3">
                <Button
                  className="border border-[#E2E8F0] xpx-2 py-2 font-small"
                  themeColor={"primary"}
                  disabled={!fileNameVal}
                  onClick={handlePdfDownload}
                >
                  Save
                </Button>
                <Button
                  className="border border-[#E2E8F0]"
                  onClick={() => setShowDialog(false)}
                  themeColor={"base"}
                  fillMode="outline"
                >
                  Cancel
                </Button>
              </div>
            </DialogActionsBar>
          </Dialog>
        )}
        {showPdfPreview && (
          <div className={`fixed h-screen w-screen z-[999999] top-0 left-0`}>
            <div
              className="overlay"
              onClick={() => setShowPdfPreview(false)}
            ></div>
            <div className="flex justify-center items-center w-full h-full">
              {
                <PDFViewer
                  url={fileUrl}
                  style={{ height: "100vh", width: "90vw" }}
                  saveFileName={fileNameVal}
                  tools={tools}
                  defaultZoom={1.5}
                  onDownload={(e) => handleDownload(e)}
                />
              }
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CallOffListing;
