import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Pencil, Trash2 } from "lucide-react";
import * as React from "react";

export const CommandCell = (props: any) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.ProductID === undefined;
  const [visible, setVisible] = React.useState(false);
  const onDeleteData = () => {
    props.remove(props.dataItem);
    setVisible(!visible);
  };
  const toggleDialog = () => {
    setVisible(!visible);
  };

  return (
    <div
      className="k-command-cell inline-edit-cell pb-0 pl-0"
      style={{
        alignContent: "center",
        justifyContent: "end",
        alignItems: "end",
        textAlign: "end",
      }}
    >
      <td>
        <Trash2
          // className="ml-auto"
          size={20}
          onClick={() =>
            inEdit
              ? isNewItem
                ? props.discard(dataItem)
                : props.cancel(dataItem)
              : toggleDialog()
          }
        />

        {visible && (
          <Dialog
            title={"Delete Data"}
            onClose={toggleDialog}
            width={350}
            style={{ zIndex: 100000 }}
          >
            <div>Are you sure you want to delete this item?</div>
            <DialogActionsBar>
              <Button onClick={onDeleteData}>Delete</Button>
              <Button onClick={toggleDialog}>Cancel</Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </td>
      {props.showEdit && (
        <td>
          <Pencil
            className="ml-auto mr-10"
            size={20}
            onClick={() => props?.edit(dataItem)}
          />

          {visible && (
            <Dialog
              title={"Delete Data"}
              onClose={toggleDialog}
              width={350}
              style={{ zIndex: 100000 }}
            >
              <div>Are you sure you want to delete this item?</div>
              <DialogActionsBar>
                <Button onClick={onDeleteData}>Delete</Button>
                <Button onClick={toggleDialog}>Cancel</Button>
              </DialogActionsBar>
            </Dialog>
          )}
        </td>
      )}
    </div>
  );
};
