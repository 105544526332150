import * as React from "react";
import {
  ComboBox,
  ComboBoxChangeEvent,
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { ISubContractor } from "../../../interfaces/subcontractor.interface";
import { IBarSize } from "../../../interfaces/standard.interface";
import { useState } from "react";

export const BarsizeDropdownCell = (
  props: GridCellProps & {
    barSizes: IBarSize[];
  }
) => {
  const handleChange = (e: ComboBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: props.dataItem.idx,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value?.size,
      });
    }
  };
  const [filteredData, setFilteredData] = useState<IBarSize[]>([]);

  const onFilterChange = (event: any) => {
    setFilteredData(filterData(event.filter.value));
  };

  const filterData = (filter: string) => {
    if (!filter) {
      return props.barSizes;
    }

    return props.barSizes?.filter(
      (item) => item.size.toString() == filter.toLowerCase()
    );
  };

  const { dataItem } = props;
  const field = "size";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  return (
    <td className={`${dataItem.pasted ? "pasted-row" : ""} `}>
      {dataItem.inEdit ? (
        <ComboBox
          style={{ width: 75, height: 30 }}
          onChange={handleChange}
          onFilterChange={onFilterChange}
          value={props.barSizes?.find((c) => c.size == dataValue)}
          data={filteredData.length > 0 ? filteredData : props.barSizes}
          dataItemKey="size"
          clearButton={false}
          filterable={true}
          textField="size"
        />
      ) : dataValue == null ? (
        ""
      ) : (
        dataValue
      )}
    </td>
  );
};
