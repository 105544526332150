import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { debounce } from "lodash";
import { Plus, X } from "lucide-react";
import { createRef, useEffect, useRef, useState } from "react";
import { ShowColumnsFilter } from "../../../component/global/ColumnMenu";
import Export from "../../../component/global/Export";
import BBSTextField from "../../../component/inputs/BBSTextField";
import PageTitle from "../../../component/ui/PageTitle";
import SearchBox from "../../../component/ui/SearchBox";
import Filters from "../../../constants/filters";
import IPageState from "../../../interfaces/page.interface";
import { IStandard } from "../../../interfaces/standard.interface";
import standardSvc from "../../../services/standard.service";
import {
  ActionCellPopupSettings,
  ActionCellPopupStandards,
} from "../../../shared-components/custom-cells";
import {
  MIN_RESIZABLE_WIDTH_KENDO_TABLE,
  getPaginationData,
} from "../../../utils/utils";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch } from "../../../store/hooks/hooks";

const Pagination = getPaginationData();
let mappingChanged = false;

interface IProps {
  standards: IStandard[];
  total: number;
  handleValidation: (name: string, isValid: boolean) => void;
  fetchStandards: (
    searchValue?: string,
    filter?: CompositeFilterDescriptor | null,
    sort?: any,
    pageNumber?: number,
    pageSize?: number
  ) => Promise<void>;
  handleStandardAction: (action: string, standardData?: IStandard) => void;
}

function Standards({
  standards,
  total,
  handleValidation,
  fetchStandards,
  handleStandardAction,
}: IProps) {
  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      fetchAllStandards();
    }
  };
  const dispatch = useAppDispatch();
  const [dataState, setDataState] = useState<State>(
    Pagination.initialDataState
  );
  const [allStandards, setAllStandards] = useState<IStandard[]>([]);
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >();
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);
  const [searchValue, setSearchValue] = useState("");
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [filterValues, setAllFilterValues] = useState<any>(null);
  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      handleStandardAction,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    )
  );
  const [addNewStandard, setAddNewStandard] = useState<{
    name: string;
    abbreviation: string;
  }>();
  const [editStandardValues, setEditStandardValues] = useState<IStandard>();

  const handleValueChange = (e: any) => {
    setAddNewStandard((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEditChange = (e: any) => {
    setEditStandardValues((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const debouncedSearch = useRef(debounce((value: string) => {})).current;

  function handleEditClick(e: any) {
    // setShowEditDrawer(true);
    // setEditStandardValues(e);
    // setAction("edit");
  }

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      handleStandardAction,
      onColumnsSubmit,
      columnsState,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    fetchStandards(
      searchValue,
      event.dataState.filter,
      event.dataState.sort,
      page.skip,
      page.take
    );
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = targetEvent.value === "All" ? total : event.page.take;

    fetchStandards(searchValue, null, event.page.skip, event.page.take);
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      debouncedSearch(value);
    }
  };

  useEffect(() => {
    fetchStandards(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (!standards?.length || !columnsMapping) return;
    const cols = generateColumns(
      handleStandardAction,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }, [filterValues, standards, columnsMapping]);

  const createStandard = () => {
    standardSvc.create(addNewStandard).then(() => {
      fetchStandards(searchValue);
      setShowCreateDrawer(false);
    });
  };

  const editStandard = () => {
    if (editStandardValues) {
      standardSvc
        .update(editStandardValues?.id, editStandardValues)
        .then(() => {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "standard updated successfully",
            })
          );
          fetchStandards(searchValue);
          setShowEditDrawer(false);
        });
    }
  };

  function handleDeleteClick(e: any) {
    if (e?.id) {
      standardSvc.delete(e.id).then(() => {
        fetchStandards(searchValue);
      });
    }
  }

  const fetchAllStandards = () => {
    standardSvc.getAll().then((res) => {
      setAllStandards(res.data);
    });
  };
  useEffect(() => {
    if (!allStandards?.length) return;
    _exporter.current?.save();
    setAllStandards([]);
  }, [allStandards]);

  return (
    <>
      <div className="setting-page">
        <Grid
          style={{ maxHeight: "" }}
          data={standards}
          {...dataState}
          onDataStateChange={dataStateChange}
          skip={page.skip}
          take={page.take}
          total={total}
          pageable={{
            ...Pagination.pageSizeValue,
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          className={`customHeight employee`}
          resizable={Filters.resizeable}
          selectable={{
            enabled: true,
            drag: true,
            mode: "multiple",
          }}
          // selectedField={SELECTED_FIELD}
          // onSelectionChange={onSelectionChange}
        >
          <GridToolbar>
            <div className="flex gap-3 w-full items-center">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
              <Button
                themeColor={"primary"}
                className="border border-[#E2E8F0]"
                onClick={() => handleStandardAction("create")}
              >
                <span className="inline-flex items-center gap-2">
                  <Plus className="w-4 h-4" /> Add New
                </span>
              </Button>
              <div
                className="cursor-pointer  sm:ml-0 ml-auto sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
                <ExcelExport
                  data={allStandards}
                  collapsible={true}
                  fileName="Standards.xlsx"
                  ref={_exporter}
                >
                  {stateColumns &&
                    stateColumns.length > 0 &&
                    stateColumns.map((column: any, idx: number) => {
                      if (column.show) {
                        return (
                          <ExcelExportColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                          />
                        );
                      }
                    })}
                </ExcelExport>
              </div>
              {/* {isAuthorize([PRO_ADMIN, SUPER_USER]) && (
                <div className="inline-flex items-center gap-2">
                  <Label className="font-medium text-sm font-cabin">
                    Show Inactive
                  </Label>
                  <Switch
                    defaultChecked={false}
                    onChange={(e) => {
                      setShowArchived(e.value);
                    }}
                  />
                </div>
              )} */}
              {/* <div
                className="cursor-pointer  sm:ml-0 ml-auto sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
                <ExcelExport
                  data={allSubContractors}
                  collapsible={true}
                  fileName="Employees.xlsx"
                  ref={_exporter}
                >
                  {stateColumns &&
                    stateColumns.length > 0 &&
                    stateColumns.map((column: any, idx: number) => {
                      if (column.show) {
                        return (
                          <ExcelExportColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                          />
                        );
                      }
                    })}
                </ExcelExport>
              </div> */}
            </div>
          </GridToolbar>
          {stateColumns &&
            stateColumns.length > 0 &&
            stateColumns.map((column: any, idx: number) => {
              if (column.show) {
                if (column.cells) {
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      columnMenu={column.columnMenu}
                      minResizableWidth={200}
                      // width={200}
                      cells={column.cells}
                      filter={column.filter ? column.filter : "text"}
                    />
                  );
                }
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    // width={200}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
            })}
        </Grid>
      </div>
      {/*Create Drawer*/}
      <Drawer
        expanded={showCreateDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer transition-all duration-500"
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowCreateDrawer(false)}
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showCreateDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Add New Standard</h2>
              <Button
                fillMode="flat"
                className="border border-[#E2E8F0]"
                onClick={() => setShowCreateDrawer(false)}
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <BBSTextField
                    name="name"
                    value={addNewStandard?.name}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="Name"
                    required={true}
                    placeholder="Enter Name"
                    disabled={false}
                    regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                    error="Invalid Name"
                  />
                </div>
                <div>
                  <BBSTextField
                    name="abbreviation"
                    value={addNewStandard?.abbreviation}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="Abbreviation"
                    required={false}
                    placeholder="Enter Abbreviation"
                    disabled={false}
                    error="Invalid Code"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                onClick={() => setShowCreateDrawer(false)}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                themeColor={"primary"}
                onClick={createStandard}
                disabled={!addNewStandard?.name}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
      {/*Edit Drawer*/}
      <Drawer
        expanded={showEditDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer transition-all duration-500"
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowEditDrawer(false)}
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showEditDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Edit Standard</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() => setShowEditDrawer(false)}
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <BBSTextField
                    name="name"
                    value={editStandardValues?.name}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="Name"
                    required={true}
                    placeholder="Enter Name"
                    disabled={false}
                    regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                    error="Invalid Name"
                  />
                </div>
                <div>
                  <BBSTextField
                    name="abbreviation"
                    value={editStandardValues?.abbreviation}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="Abbreviation"
                    required={false}
                    placeholder="Enter Abbreviation"
                    disabled={false}
                    error="Invalid Code"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                onClick={() => setShowEditDrawer(false)}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                themeColor={"primary"}
                onClick={editStandard}
                disabled={!editStandardValues?.name}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Standards;

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  handleStandardAction: any,
  onColumnsSubmit: any,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  const isVisible = (field: string) => {
    if (!columnsMapping?.length) return false;
    if (columnsMapping.includes(field)) {
      return true;
    }

    return false;
  };
  const columns = [
    {
      title: "Name",
      field: "name",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      cells: {
        data: (props: any) => (
          <ActionCellPopupStandards
            rowData={props.dataItem}
            handleStandardAction={handleStandardAction}
            dataKey="name"
          />
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Abbreviation",
      field: "abbreviation",
      show: changed ? isVisible("abbreviation") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
  ];

  return columns;
};
