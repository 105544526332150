import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { Button } from "@progress/kendo-react-buttons";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { X } from "lucide-react";
import CustomLabel from "../../../component/global/RequiredMark";
import { useEffect, useRef, useState } from "react";
import projectSvc from "../../../services/project.service";
import {
  Grid,
  GridColumn as Column,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { IDetailingOffice } from "../../../interfaces/project.interface";
import { CommandCell } from "../ProjectComponents/CommandCell";
import { setToast } from "../../../store/features/toastSlice";
import { triggerFetchProjects } from "../../../store/features/commonSlice";

const initialDetailingOffice = {
  name: "",
};

const editField = "inEdit";

export const DetailingOffices = ({
  showAddDetailingOfficeDrawer,
  setShowAddDetailingOfficeDrawer,
  projectToEdit,
  setProjectToEdit,
  fetchProjects,
}: any) => {
  const dispatch = useAppDispatch();

  const commonState = useAppSelector((state: RootState) => state.common);
  const [newDetailingOffice, setDetailingOffice] = useState(
    initialDetailingOffice
  );
  const [projectDetailingOffices, setProjectDetailingOffices] = useState<
    IDetailingOffice[]
  >([]);

  const [validFields, setValidFields] = useState<any>({
    name: null,
  });

  const ActionCell = (props: any) => {
    const { edit, remove, add, discard, update, cancel, editField } = props;
    return (
      <CommandCell
        {...props}
        edit={edit}
        remove={remove}
        add={add}
        discard={discard}
        update={update}
        cancel={cancel}
        editField={editField}
      />
    );
  };

  const remove = (dataItem: any) => {
    const newData = [...projectDetailingOffices];
    const index = newData.findIndex((x) => {
      return x.name === dataItem.name;
    });

    newData.splice(index, 1);
    setProjectDetailingOffices(newData);
  };

  const add = (dataItem: any) => {
    const newData = [...projectDetailingOffices];
    dataItem.inEdit = true;

    newData.push(dataItem);
    setProjectDetailingOffices(newData);
  };

  const update = (dataItem: any) => {
    dataItem.inEdit = false;
    // const newData = updateItem(dataItem);
    // setDetailingOffice(newData);
  };

  const validateDetailingOffice = () => {
    if (!newDetailingOffice) return false;
    if (!newDetailingOffice.name) return false;
    return true;
  };

  const discard = () => {
    const newData = [...projectDetailingOffices];
    newData.splice(0, 1);
    setProjectDetailingOffices(newData);
  };

  const cancel = (dataItem: any) => {
    const originalItem = projectDetailingOffices.find(
      (p) => p.name === dataItem.name
    );
    const newData = projectDetailingOffices.map((item) =>
      item.name === originalItem?.name ? originalItem : item
    );
    if (newData) {
      setProjectDetailingOffices(newData!);
    }
  };

  const enterEdit = (dataItem: any) => {
    // setDetailingOffice(
    //   data.map((item) =>
    //     item.ProductID === dataItem.ProductID
    //       ? {
    //           ...item,
    //           inEdit: true,
    //         }
    //       : item
    //   )
    // );
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const newData: any = projectDetailingOffices.map((item) =>
      item.id === event.dataItem.id
        ? {
            ...item,
            [event.field || ""]: event.value,
          }
        : item
    );
    setProjectDetailingOffices(newData);
  };

  const addNew = () => {
    var exists = projectDetailingOffices.some(
      (element) => element.name === null || element.name === ""
    );

    if (!exists) {
      const newDataItem: any = {
        inEdit: true,
        name: "",
        id: projectDetailingOffices.length,
      };
      setProjectDetailingOffices([newDataItem, ...projectDetailingOffices]);
    }
  };
  const initialRender = useRef(false);

  useEffect(() => {
    if (initialRender.current === true || !showAddDetailingOfficeDrawer) return;
    let temp = [...projectDetailingOffices];
    temp = temp.map((item: any, index: number) => {
      return {
        ...item,
        id: index,
      };
    });
    setProjectDetailingOffices(temp);
    if (temp.length) {
      initialRender.current = true;
    }
  }, [projectDetailingOffices, showAddDetailingOfficeDrawer]);

  const commandCellProps = {
    edit: enterEdit,
    remove: remove,
    add: add,
    discard: discard,
    update: update,
    cancel: cancel,
    editField: editField,
  };

  const saveDetailingOffice = async () => {
    var hasBlank = projectDetailingOffices.some(
      (element) => element.name === null || element.name === ""
    );

    if (hasBlank) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: "Detailing Office name cannot be blank",
        })
      );
      return;
    }

    const hasDups = hasDuplicates(projectDetailingOffices, "name");

    if (hasDups) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: "Detailing Offices cannot have duplicate names",
        })
      );
      return;
    }

    try {
      const proj = projectToEdit;
      if (proj?.detailingOffices) {
        proj.detailingOffices = projectDetailingOffices;
      }
      setProjectToEdit(proj);

      setProjectDetailingOffices(projectToEdit?.detailingOffices);
      await projectSvc.update(projectToEdit?.id!, projectToEdit).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Detailing Office updated successfully",
            })
          );
        }
        dispatch(triggerFetchProjects());
      });

      setShowAddDetailingOfficeDrawer(!showAddDetailingOfficeDrawer);
    } catch (error) {
      console.error(error);
    }
  };

  function hasDuplicates<T>(arr: T[], key: keyof T): boolean {
    const seen = new Set();
    return arr.some((item) => {
      const value = String(item[key]).toLowerCase();
      if (seen.has(value)) {
        return true; // Duplicate found
      }
      seen.add(value);
      return false; // No duplicate so far
    });
  }

  useEffect(() => {
    if (projectToEdit?.detailingOffices) {
      setProjectDetailingOffices(projectToEdit?.detailingOffices);
    }
  }, [projectToEdit]);

  return (
    <>
      <Drawer
        expanded={showAddDetailingOfficeDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer transition-all duration-500 "
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() =>
              setShowAddDetailingOfficeDrawer(!showAddDetailingOfficeDrawer)
            }
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showAddDetailingOfficeDrawer
                ? "translate-x-0"
                : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Detailing Offices</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() =>
                  setShowAddDetailingOfficeDrawer(!showAddDetailingOfficeDrawer)
                }
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div className="detailingGrid">
                  <Grid
                    pageable={false}
                    sortable={false}
                    filterable={false}
                    size={"small"}
                    groupable={false}
                    scrollable="none"
                    data={projectDetailingOffices}
                    onItemChange={itemChange}
                    editField={editField}
                  >
                    <GridToolbar>
                      <Button
                        title="Add new"
                        themeColor={"primary"}
                        onClick={addNew}
                      >
                        Add new
                      </Button>
                    </GridToolbar>
                    <Column field="name" title="Name" editor="text" />
                    <Column
                      cell={(props) => (
                        <ActionCell {...props} {...commandCellProps} />
                      )}
                      width="100px"
                    />
                  </Grid>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() =>
                  setShowAddDetailingOfficeDrawer(!showAddDetailingOfficeDrawer)
                }
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                onClick={saveDetailingOffice}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
