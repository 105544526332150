import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools/pager/Pager";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { debounce } from "lodash";
import { Plus } from "lucide-react";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import Export from "../../component/global/Export";
import SearchBox from "../../component/ui/SearchBox";
import Filters from "../../constants/filters";
import IPageState from "../../interfaces/page.interface";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import { getPaginationData } from "../../utils/utils";
import WriteDrawer from "./WriteDrawer";
import { columnInterface, generateColumns } from "./columns";
import { IShapeCodes } from "../../interfaces/shapes.interface";
import shapesSvc from "../../services/shapes.service";
import { IStandard } from "../../interfaces/standard.interface";
import standardSvc from "../../services/standard.service";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useDispatch } from "react-redux";
import { setToast } from "../../store/features/toastSlice";

let mappingChanged = false;

export const Listing = () => {
  const dispatch = useDispatch();

  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    let filterQueryTemp = { ...filterQuery };
    console.log("filterQueryTemp", filterQueryTemp);
    if (filterQueryTemp?.filters?.length) {
      const index = filterQueryTemp.filters[0].filters.findIndex(
        (filter: any) => filter.field === "standardId"
      );
      if (index === -1 && standardFilterValue?.id) {
        filterQueryTemp = filterQueryTemp.filters[0].filters.push({
          field: "standardId",
          operator: "eq",
          value: standardFilterValue?.id,
        });
      }
    }

    console.log("filterQueryTemp", filterQueryTemp);
    shapesSvc.exportShapes([], filterQueryTemp).then((res) => {
      if(!res.data) {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: "File export failed",
          })
        );
      }
    });
  };

  const Pagination = getPaginationData();

  const debouncedSearch = useRef(
    debounce((value: string) => fetchShapes(value), 500)
  ).current;

  const [addNewShape, setAddNewShape] = useState<
    (IShapeCodes & { id: string }) | null
  >(null);
  const [allShapes, setAllShapes] = useState([]);
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [dataState, setDataState] = useState<State>(
    Pagination.initialDataState
  );
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >();
  const [searchValue, setSearchValue] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const [shapes, setShapes] = useState<any>([]);
  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      handleDeleteClick,
      handleDownload,
      handlePreview,
      fetchShapesWrapper,
      handleEditClick,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    )
  );
  const [total, setTotal] = useState(0);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [standards, setStandards] = useState<IStandard[]>([]);
  const [standardFilterValue, setStandardFilterValue] =
    useState<IStandard | null>(null);
  const [filterQuery, setFilterQuery] = useState<any>({
    filters: [
      {
        logic: "and",
        filters: [],
      },
    ],
  });

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      handleDeleteClick,
      handleDownload,
      handlePreview,
      fetchShapesWrapper,
      handleEditClick,
      onColumnsSubmit,
      columnsState,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = targetEvent.value === "All" ? shapes.length : event.page.take;

    fetchShapes(searchValue, null, null, event.page.skip, event.page.take);
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleSearchChange = (value: string) => {
    const newValue = value.trim();
    setSearchValue(newValue);

    if (newValue === "") {
      // Just call fetchShapes with empty search
      fetchShapes("");
      return;
    }

    if (newValue.length !== 1) {
      debouncedSearch(newValue);
    }
  };

  const handleStandardChange = (e: ComboBoxChangeEvent) => {
    setStandardFilterValue(e.value);
    const prevFilter = { ...filterQuery };
    const index = prevFilter.filters[0].filters.findIndex(
      (filter: any) => filter.field === "standardId"
    );
    if (index === -1) {
      if (e.value) {
        prevFilter.filters[0].filters.push({
          field: "standardId",
          operator: "eq",
          value: e.value?.id,
        });
        console.log("Setting Filter: ",e.value)
      }
    } else {
      if (e.value) {
        prevFilter.filters[0].filters[index].value = e.value?.id;
      } else {
        prevFilter.filters[0].filters.splice(index, 1);
      }
    }
    console.log("Prev Filter: ",prevFilter)
    setFilterQuery(prevFilter);
    fetchShapes(searchValue, prevFilter);
  };

  useEffect(() => {
    console.log("Filter Query: ",filterQuery)
  },[filterQuery])

  const handleChange = (e: any, isDropdown?: boolean) => {
    if (isDropdown && e?.value) {
      setAddNewShape((prev: any) => {
        return { ...prev, standardId: e.value.id };
      });
      return;
    }
    setAddNewShape((prev: any) => {
      return { ...prev, [e.target.name as any]: e.target.value };
    });
  };

  useEffect(() => {}, [addNewShape]);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    fetchShapes(
      searchValue,
      event.dataState.filter,
      event.dataState.sort,
      page.skip,
      page.take
    );
  };

  function fileToBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const createShapeCode = async () => {
    if (logoFile) {
      const base64: any = await fileToBase64(logoFile);
      if (base64) {
        addNewShape!.imagePath = base64;
      }
    }

    shapesSvc.create(addNewShape).then((res) => {
      if (res?.error == "Code already exists") {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: "Code must be unique",
          })
        );
      } else {
        fetchShapes(searchValue);
        setAddNewShape(null);
        setLogoFile(null);
        setShowDrawer(false);
      }
    });
  };

  const editShape = async () => {
    if (!addNewShape?.id) return;
    if (logoFile) {
      const base64: any = await fileToBase64(logoFile);
      if (base64) {
        addNewShape!.imagePath = base64;
      }
    }
    shapesSvc.update(addNewShape.id, addNewShape).then((res) => {
      if (!res.error) {
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Shape updated successfully",
          })
        );
      }
      fetchShapes(searchValue);
      setAddNewShape(null);
      setLogoFile(null);
      setShowDrawer(false);
    });
  };

  function handleCancel() {
    setAddNewShape(null);
    setLogoFile(null);
    setShowDrawer(false);
  }

  function handleEditClick(data: IShapeCodes) {
    setAddNewShape(data);
    setLogoFile(null);
    setShowDrawer(true);
  }

  const fetchShapes = useCallback(
    async (
      searchQuery = "",
      filter?: any,
      sort?: any,
      pageNumber = 0,
      pageSize = Pagination.initialDataState.take
    ) => {
      // Create base filter structure
      let currentFilter = filter || { ...filterQuery };

      // Handle search filters
      if (searchQuery) {
        // Create a new filter object instead of mutating the existing one
        currentFilter = {
          filters: [
            // Preserve the "and" filters (like standardId)
            ...(currentFilter.filters || []).filter(
              (f: any) => f.logic === "and"
            ),
            // Add the new search filter
            {
              logic: "or",
              filters: [
                { field: "Code", operator: "contains", value: searchQuery }
              ]
            }
          ]
        };
      } else {
        // If no search query, only keep the "and" filters
        currentFilter = {
          filters: [
            {
              logic: "and",
              filters: currentFilter.filters?.[0]?.filters || []
            }
          ]
        };
      }

      // Update the filter state
      setFilterQuery(currentFilter);

      const res = await shapesSvc.get(
        [], // Remove searchFilters since we're handling it in the filter object
        currentFilter,
        [{ field: "Code", dir: "asc" }],
        pageNumber,
        pageSize
      );
      if (res.data) {
        setShapes(res.data);
        setTotal(res.total);
      }
    },
    [filterQuery] // Add filterQuery to dependencies
  );
  useEffect(() => {
    if (shapes.length) return;
    fetchShapes(searchValue);
  }, []);

  useEffect(() => {
    if (!shapes?.length || !columnsMapping) return;
    const cols = generateColumns(
      handleDeleteClick,
      handleDownload,
      handlePreview,
      fetchShapesWrapper,
      handleEditClick,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }, [shapes, columnsMapping]);

  useEffect(() => {
    if (!allShapes?.length) return;
    _exporter.current?.save();
    setAllShapes([]);
  }, [allShapes]);

  function fetchShapesWrapper() {
    fetchShapes(searchValue);
  }

  function handleDownload(base64: string, name: string) {
    const link = document.createElement("a");
    link.href = base64;

    link.download = name + ".png";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  function handlePreview(base64: string, name: string) {
    if (base64) setShowDialog(!showDialog);
    setImageSrc(base64);
  }

  async function handleFileUpload(e: any) {
    const files = e.affectedFiles;
    if (files.length > 0) {
      setLogoFile(files[0].getRawFile());

      const base64: any = await fileToBase64(files[0].getRawFile());
      if (base64) {
        setAddNewShape((prev: any) => {
          return { ...prev, imagePath: base64 };
        });
      }
    }
  }

  const fetchStandards = () => {
    standardSvc.getAll().then((res) => {
      setStandards(res.data);
    });
  };

  useEffect(() => {
    fetchStandards();
  }, []);

  useEffect(() => {
    if (!showDrawer) {
      setAddNewShape(null);
      setLogoFile(null);
    }
  }, [showDrawer]);

  function handleDeleteClick(data: IShapeCodes) {
    shapesSvc.delete(data.id).then((res) => {
      fetchShapes(searchValue);
    });
  }

  function getStandardValue(): any {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <div className="setting-page">
        <Grid
          style={{ maxHeight: "" }}
          data={shapes}
          {...dataState}
          onDataStateChange={dataStateChange}
          skip={page.skip}
          take={page.take}
          total={total}
          pageable={{
            ...Pagination.pageSizeValue,
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          className={`customHeight employee`}
          resizable={Filters.resizeable}
          selectable={{
            enabled: true,
            drag: true,
            mode: "multiple",
          }}
        >
          <GridToolbar>
            <div className="flex gap-3 w-full items-center">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
              <div className="w-[300px]">
                <ComboBox
                  style={{ width: "300px" }}
                  placeholder="Select Standard"
                  data={standards}
                  dataItemKey="id"
                  textField="name"
                  value={standardFilterValue}
                  onChange={(e) => handleStandardChange(e)}
                />
              </div>
              <Button
                themeColor={"primary"}
                className="border border-[#E2E8F0]"
                onClick={() => {
                  setLogoFile(null);
                  setShowDrawer(true);
                }}
              >
                <span className="inline-flex items-center gap-2">
                  <Plus className="w-4 h-4" /> Add New
                </span>
              </Button>
              <div
                className="cursor-pointer  sm:ml-0 ml-auto sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
              </div>
            </div>
          </GridToolbar>
          {stateColumns &&
            stateColumns.length > 0 &&
            stateColumns.map((column: any, idx: number) => {
              if (column.show) {
                if (column.cells) {
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      columnMenu={column.columnMenu}
                      minResizableWidth={200}
                      // width={200}
                      cells={column.cells}
                      filter={column.filter ? column.filter : "text"}
                    />
                  );
                }
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    // width={200}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
            })}
        </Grid>

        {showDialog && (
          <Dialog
            width={350}
            title={"Shape Image"}
            onClose={() => setShowDialog(false)}
            className="custom-dialog"
          >
            <div>
              <img src={imageSrc} className="w-full"></img>
            </div>
          </Dialog>
        )}
      </div>
      <WriteDrawer
        handleFileUpload={handleFileUpload}
        showCreateDrawer={showDrawer}
        setShowCreateDrawer={setShowDrawer}
        addNewShape={addNewShape}
        handleChange={handleChange}
        onSave={addNewShape?.id ? editShape : createShapeCode}
        onCancel={handleCancel}
        setLogoFile={setLogoFile}
        logoFile={logoFile}
        standards={standards}
      />
    </>
  );
};
