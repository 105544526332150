import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store/store';

interface CommonState {
  requestsInProgress: number;
  selectedProject: any;
  selectedBBS: any;
  manageCallOff: boolean;
  selectedCallOff: any;
  selected: boolean;
  fetchProjects: boolean;
  fetchMemberLines: boolean;
  fetchMembersGridData: boolean;
}

const initialState: CommonState = {
  requestsInProgress: 0,
  selectedProject: null,
  selectedBBS: null,
  manageCallOff: false,
  selectedCallOff: null,
  selected: false,
  fetchProjects: false,
  fetchMemberLines: false,
  fetchMembersGridData: false
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    show: (state) => {
      state.requestsInProgress = state.requestsInProgress + 1;
    },
    hide: (state) => {
      const val = state.requestsInProgress - 1;
      if (val < 0) {
        state.requestsInProgress = 0;
      } else {
        state.requestsInProgress = val;
      }
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    resetSelectedProject: (state) => {
      state.selectedProject = null;
    },
    setSelectedBBS: (state, action) => {
      state.selectedBBS = action.payload;
    },
    resetSelectedBBS: (state) => {
      state.selectedBBS = null;
    },
    setSelectedCallOff: (state, action) => {
      state.selectedCallOff = action.payload;
    },
    resetSelectedCallOff: (state) => {
      state.selectedCallOff = null;
    },
    triggerFetchProjects: (state) => {
      state.fetchProjects = true;
    },
    resetFetchProjects: (state) => {
      state.fetchProjects = false;
    },
    setSelectedMenu: (state, action) => {
      state.selected = !action.payload;
    },
    setMemberLines: (state) => {
      state.fetchMemberLines = true;
    },
    resetMemberLines: (state) => {
      state.fetchMemberLines = false;
    },
    setManageCallOff: (state, action) => {
      state.manageCallOff = !action.payload;
    },
    setMemberGridData: (state) => {
      state.fetchMembersGridData = true;
    },
    resetMemberGridData: (state) => {
      state.fetchMembersGridData = false;
    }
  },
});

export const {
  show,
  hide,
  setSelectedProject,
  resetSelectedProject,
  setSelectedBBS,
  resetSelectedBBS,
  setSelectedCallOff,
  resetSelectedCallOff,
  setSelectedMenu,
  setManageCallOff,
  triggerFetchProjects,
  resetFetchProjects,
  resetMemberLines,
  setMemberLines,
  setMemberGridData,
  resetMemberGridData
} = commonSlice.actions;
export const selectCommon = (state: RootState) => state.common;
export default commonSlice.reducer;
