import { Button } from "@progress/kendo-react-buttons";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { DownloadCloudIcon, EyeIcon, Trash2, X } from "lucide-react";
import { useEffect, useState } from "react";
import BBSTextField from "../../component/inputs/BBSTextField";
import { ICompany } from "../../interfaces/company.interface";
import { Upload } from "@progress/kendo-react-upload";
interface IProps {
  handleFileUpload: any;
  handlePreview: any;
  handleDownload: any;
  showCreateDrawer: boolean;
  setShowCreateDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  addNewCompany: (ICompany & { id: string }) | null;
  setAddNewCompany: React.Dispatch<
    React.SetStateAction<
      | (ICompany & {
          id: string;
        })
      | null
    >
  >;
  handleChange: (e: TextBoxChangeEvent) => void;
  onSave: () => void;
  setLogoFile: React.Dispatch<any>;
  logoFile: any;
  onCancel: () => void;
}

function WriteDrawer({
  handleFileUpload,
  handlePreview,
  handleDownload,
  showCreateDrawer,
  setShowCreateDrawer,
  addNewCompany,
  setAddNewCompany,
  handleChange,
  onSave,
  setLogoFile,
  logoFile,
  onCancel,
}: IProps) {
  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev: any) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const validateSave = () => {
    if (!(logoFile || addNewCompany?.logoPath) || !addNewCompany?.name) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!logoFile) {
      setInvalidFields({});
    }
  }, [logoFile]);

  return (
    <Drawer
      expanded={showCreateDrawer}
      position={"start"}
      mode={"overlay"}
      animation={{ duration: 400 }}
      className="customDrawer transition-all duration-500"
    >
      <DrawerContent>
        <div
          className="overlay"
          onClick={() => setShowCreateDrawer(false)}
        ></div>
        <div
          className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
            showCreateDrawer ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
            <h2 className="font-medium text-lg">
              {addNewCompany?.id ? "Edit Company" : "Add Company"}
            </h2>
            <Button
              fillMode="flat"
              className="p-2 bg-none outline-none"
              onClick={() => setShowCreateDrawer(false)}
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
          <div className="flex-1 py-4">
            <div className="grid gap-4">
              <div>
                <BBSTextField
                  name="name"
                  value={addNewCompany?.name}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Name"
                  required={true}
                  placeholder="Enter Name"
                  disabled={false}
                  regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                  // error="Invalid Name"
                />
              </div>
              <div>
                <BBSTextField
                  name="address"
                  value={addNewCompany?.address}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Address"
                  required={false}
                  placeholder="Enter Address"
                  disabled={false}
                  // regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                  // error="Invalid Name"
                />
              </div>

              <div>
                <div className="flex">
                  <Label
                    className={`text-sm font-cabin block font-medium mb-1 text-txtPrimary`}
                  >
                    Logo
                  </Label>
                  {<div className="text-red-600 ml-1">*</div>}
                </div>

                <Upload
                  batch={false}
                  multiple={false}
                  withCredentials={false}
                  files={logoFile ? [logoFile] : []}
                  onAdd={handleFileUpload}
                  onRemove={() => {
                    if (addNewCompany) addNewCompany.logoPath = "";
                    setLogoFile(null);
                  }}
                />
              </div>

              {addNewCompany?.logoPath && (
                <div className={`${!addNewCompany?.logoPath ? "hidden" : ""}`}>
                  <div
                    className={`relative group transition-all duration-500 flex items-center justify-center flex-col border-neutral-30 bg-neutral-10 cursor-pointer h-[150px]  overflow-hidden 'pointer-events-none'`}
                  >
                    <img
                      src={addNewCompany?.logoPath}
                      style={{ width: "100%" }}
                    />
                    <div className="absolute inset-0 flex items-center justify-center gap-2  bg-black/70 transition-all duration-500 opacity-0 invisible group-hover:opacity-100 group-hover:visible">
                      <Button
                        className="rounded p-2 text-white border border-white"
                        fillMode={"outline"}
                        onClick={() => handlePreview(addNewCompany?.logoPath)}
                      >
                        <EyeIcon size={16} className="w-4 h-4" />
                      </Button>
                      <Button
                        fillMode={"outline"}
                        className="rounded p-2 text-white border border-white"
                        onClick={() =>
                          handleDownload(
                            addNewCompany?.logoPath,
                            addNewCompany?.name
                          )
                        }
                      >
                        <DownloadCloudIcon size={16} className="w-4 h-4" />
                      </Button>
                      <Button
                        className="rounded p-2 text-white border border-white"
                        fillMode={"outline"}
                        onClick={() => {
                          setAddNewCompany((prev: any) => {
                            return { ...prev, logoPath: null };
                          });

                          setLogoFile(null);
                        }}
                      >
                        <Trash2 className="w-4 h-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-2 pb-4">
            <Button
              className="border border-[#E2E8F0]"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <Button
              className="border border-[#E2E8F0]"
              themeColor={"primary"}
              onClick={onSave}
              disabled={!validateSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default WriteDrawer;
