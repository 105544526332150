import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { api } from '../lib';
import { setToast } from '../store/features/toastSlice';
import { store } from '../store/store/store';
import LoaderService from './loader.service';
import { ISearchFilter } from '../interfaces/searchFilter.interface';

export class BaseService {
  constructor(private controllerName: string) {}

  async getById(id: string) {
    try {
      LoaderService.showLoader();
      const res = await api.get(
        `${process.env.REACT_APP_BASE_URL}/api/${this.controllerName}/${id}`
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async get(
    searchFilters: ISearchFilter[] = [],
    filter?: CompositeFilterDescriptor | null,
    sort?: any,
    pageNumber = 0,
    pageSize = 20
  ) {
    try {
      LoaderService.showLoader();

      let paramsObj: any = {
        skip: pageNumber.toString(),
        take: pageSize.toString(),
      };

      // Check if filter is provided, if not, initialize it
      if (!filter) {
        filter = {
          filters: [],
          logic: 'and',
        };
      }

      // Add search query to filter
      if (searchFilters?.length) {
        filter.filters.push({
          logic: 'or',
          filters: searchFilters,
        });
      }

      paramsObj = {
        ...paramsObj,
        filter: JSON.stringify(filter),
      };

      if (sort) {
        paramsObj = {
          ...paramsObj,
          sort: JSON.stringify(sort),
        };
      }

      const params = new URLSearchParams(paramsObj);

      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/api/${
          this.controllerName
        }?${params.toString()}`
      );
      if (res.ok) {
        return {
          data: res.data.items,
          total: res.data.totalCount,
          error: null,
        };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getWithUrl(
    url: string,
    searchFilters: ISearchFilter[] = [],
    filter?: CompositeFilterDescriptor | null,
    sort?: any,
    pageNumber = 0,
    pageSize = 20
  ) {
    try {
      LoaderService.showLoader();

      let paramsObj: any = {
        skip: pageNumber.toString(),
        take: pageSize.toString(),
      };

      // Check if filter is provided, if not, initialize it
      if (!filter) {
        filter = {
          filters: [],
          logic: 'and',
        };
      }

      // Add search query to filter
      if (searchFilters?.length) {
        filter.filters.push({
          logic: 'or',
          filters: searchFilters,
        });
      }

      paramsObj = {
        ...paramsObj,
        filter: JSON.stringify(filter),
      };

      if (sort) {
        paramsObj = {
          ...paramsObj,
          sort: JSON.stringify(sort),
        };
      }

      const params = new URLSearchParams(paramsObj);

      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/api/${
          this.controllerName
        }/${url}?${params.toString()}`
      );
      if (res.ok) {
        return {
          data: res.data.items,
          total: res.data.totalCount,
          error: null,
        };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getAll(searchFilters: ISearchFilter[] = [], filter?: CompositeFilterDescriptor | null, sort?: any) {
    try {
      LoaderService.showLoader();

      let paramsObj: any = {};

      // Check if filter is provided, if not, initialize it
      if (!filter) {
        filter = {
          filters: [],
          logic: "and"
        };
      }

      // Add search query to filter
      if (searchFilters?.length) {
        filter.filters.push({
          logic: "or",
          filters: searchFilters
        });
      }

      paramsObj = {
        ...paramsObj,
        filter: JSON.stringify(filter)
      };

      if (sort) {
        paramsObj = {
          ...paramsObj,
          sort: JSON.stringify(sort)
        };
      }

      const params = new URLSearchParams(paramsObj);

      const res: any = await api.get(`${process.env.REACT_APP_BASE_URL}/api/${this.controllerName}/all?${params.toString()}`);

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }


  async create(payload: any) {
    try {
      LoaderService.showLoader();
      const res = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/${this.controllerName}`,
        payload
      );
      if (res.ok) {
        return { data: res.data, error: null };
      }
      if (typeof res.data === 'string') {
        store.dispatch(
          setToast({
            toastType: 'error',
            toastMsg: res.data,
          })
        );
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async update(id: string, payload: any) {
    try {
      LoaderService.showLoader();
      const res = await api.put(
        `${process.env.REACT_APP_BASE_URL}/api/${this.controllerName}/${id}`,
        payload
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }
      if (typeof res.data === 'string') {
        store.dispatch(
          setToast({
            toastType: 'error',
            toastMsg: res.data,
          })
        );
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async delete(id: string) {
    try {
      LoaderService.showLoader();
      const res = await api.delete(
        `${process.env.REACT_APP_BASE_URL}/api/${this.controllerName}/${id}`
      );

      if (res.ok) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}
