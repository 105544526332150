import { State } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import {
  getSelectedState,
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import SearchBox from "../../../component/ui/SearchBox";
import Filters from "../../../constants/filters";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import {
  IProject,
  IShopDrawings,
  ISubLevel,
} from "../../../interfaces/project.interface";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CustomLabel from "../../../component/global/RequiredMark";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import projectSvc from "../../../services/project.service";
import { CustomDialogBox } from "../../../shared-components/dialog-box";
import { getDialogInfo } from "../../../utils/dialog.utils";
import { Plus, SquarePen, Trash2 } from "lucide-react";
import BtnAddLevel from "../components/BtnAddLevel";
import BtnRename from "../components/BtnRename";
import BtnDelete from "../components/BtnDelete";
import { CreatedOnDateCell } from "../../../shared-components/custom-cells";
import {
  setSelectedProject,
  triggerFetchProjects,
} from "../../../store/features/commonSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToast } from "../../../store/features/toastSlice";
import { TreeViewDataItem } from "..";

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function ThirdLevelListing({
  treeView,
  setTreeView,
  projects,
  fetchProjects,
}: {
  treeView: TreeViewDataItem[];
  setTreeView: React.Dispatch<React.SetStateAction<TreeViewDataItem[]>>;
  projects: IProject[];
  fetchProjects: any;
}) {
  const commonState = useAppSelector((state: RootState) => state.common);
  const oldData = useRef<any>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [action, setAction] = useState("Create");
  const [inputVal, setInputVal] = useState<string>("");
  const [showDialog, setShowDialog] = useState(false);
  const [gridData, setGridData] = useState<any>([]);
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});
  const [modalVisible, setModalVisible] = useState(false);
  const [dataState, setDataState] = useState<State>({
    skip: 0,
    take: 10,
  });

  const setSelectedLevel = () => {
    if (
      projects?.length &&
      treeView?.length &&
      commonState?.selectedProject?.event?.item
    ) {
      const item = treeView.find(
        (p: any) =>
          p.projectId === commonState?.selectedProject?.event?.item?.projectId
      );
      if (item) {
        let newItem = JSON.parse(JSON.stringify(commonState.selectedProject));
        newItem.event.item = item;
        dispatch(setSelectedProject(newItem));
      }
    }
  };

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      if (
        newSelectedState &&
        selectedState &&
        JSON.stringify(newSelectedState) === JSON.stringify(selectedState)
      ) {
        setSelectedState({});
        return;
      }
      if (newSelectedState) {
        const key = Object.keys(newSelectedState)[0];
        // const row = gridData?.current?.find((item) => key === item.id) || null;
        // dispatch(setSelectedBBS(row));
        // setSelectedRow(row);
      }
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: HTMLInputElement = event.syntheticEvent
        .target as HTMLInputElement;
      const checked = checkboxElement.checked;
      const newSelectedState: { [id: string]: boolean | number[] } = {};
      gridData?.forEach((item: any) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      const filteredData = oldData.current.filter((data: any) =>
        data.text.toLowerCase().includes(value.toLowerCase())
      );
      setGridData(filteredData);
    }

    if (value === "") {
      setGridData(oldData.current);
    }
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  useEffect(() => {
    loadGridData();
  }, [commonState.selectedProject]);

  const handleSave = () => {
    if (action === "Create") {
      handleAddShopDrawing();
    } else {
      handleEditShopDrawing();
    }
  };

  const loadGridData = () => {
    if (commonState?.selectedProject?.event?.item) {
      const currLevelItems =
        commonState?.selectedProject?.event?.item?.items ?? [];
      let numOfMembers = 0;
      const listData = currLevelItems?.map((x: any, index: number) => {
        if (x.items?.length) {
          numOfMembers = x.items.length;
        } else {
          numOfMembers = 0;
        }
        return {
          id: index,
          text: x.text?.split("-Code-")[0],
          numOfMembers: numOfMembers,
          createdOn: x.createdOn,
        };
      });
      oldData.current = JSON.parse(JSON.stringify(listData));
      setGridData(listData);
    }
  };

  const handleEditShopDrawing = async () => {
    const proj = projects.find(
      (p) => p.id === commonState?.selectedProject?.event?.item?.projectId
    );
    if (proj) {
      proj.levels[commonState?.selectedProject?.event?.item?.idx].name =
        inputVal;

      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Drawing updated successfully",
            })
          );
        }
        dispatch(triggerFetchProjects());
      });
    }
    setInputVal("");
    setShowDialog(false);
    loadGridData();
  };

  const handleAddShopDrawing = async () => {
    if (commonState.selectedProject?.event?.item) {
      const proj = projects.find(
        (x) => x.id === commonState.selectedProject?.event?.item?.projectId
      );

      if (proj) {
        const newLevel: IShopDrawings = {
          name: inputVal,
          members: [],
          memberParts: [],
          code: crypto.randomUUID(),
          isActive: true,
        };
        proj?.levels[
          commonState.selectedProject?.event?.item?.parentId
        ].subLevels[
          commonState.selectedProject?.event?.item?.idx
        ].shopDrawings.push(newLevel);
        await projectSvc
          .update(commonState.selectedProject?.event?.item?.projectId, proj)
          .then((res) => {
            if (!res.error) {
              dispatch(
                setToast({
                  toastType: "success",
                  toastMsg: "Drawing added successfully",
                })
              );
            }
            dispatch(triggerFetchProjects());
          });
      }
      setInputVal("");
      setShowDialog(false);
      loadGridData();
    }
  };

  const handleDelete = () => {
    if (commonState.selectedProject?.event?.item) {
      const proj = projects.find(
        (x) => x.id === commonState.selectedProject?.event?.item?.projectId
      );

      if (proj) {
        proj.levels.splice(commonState.selectedProject?.event?.item?.idx, 1);
        proj.isSubLevelDeleted = true;
        proj.levelCode = commonState.selectedProject?.event?.item?.levelCode;
        proj.subLevelCode =
          commonState.selectedProject?.event?.item?.subLevelCode;
        projectSvc
          .update(commonState.selectedProject?.event?.item?.projectId, proj)
          .then((y) => {
            //navigate("/home");
            dispatch(triggerFetchProjects());
            setSelectedLevel();
            loadGridData();
          });
      }
    }
  };

  const handleDeleteDialog = (action: string) => {
    if (action === "yes") {
      handleDelete();
    }
    toggleDeleteDialog();
  };

  const toggleDeleteDialog = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDeleteDialog}
        />
      )}
      <Grid
        style={{ maxWidth: "100vw", width: "100%" }}
        data={gridData?.map((item: any) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        }))}
        // {...dataState}
        // onDataStateChange={dataStateChange}
        // skip={page.skip}
        // take={page.take}
        // total={total}
        selectedField={SELECTED_FIELD}
        // pageable={{
        //   ...Pagination.pageSizeValue,
        //   pageSizeValue: pageSizeValue,
        // }}
        // onPageChange={pageChange}
        className={`customHeight employee`}
        resizable={Filters.resizeable}
        selectable={{
          enabled: true,
          drag: true,
          mode: "single",
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
      >
        <GridToolbar>
          <div className="flex gap-3 w-full items-center justify-between">
            <div className="flex gap-x-4 w-full">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
              {/* <BtnAddLevel
                level={commonState?.selectedProject?.event?.item?.level}
                onClickHandler={() => setShowDialog(true)}
              /> */}
              {/* <BtnRename
                onClickHandler={() => {
                  setAction("Edit");
                  setShowDialog(true);
                  setInputVal(
                    commonState?.selectedProject?.event?.item?.text?.split(
                      "-Code-"
                    )[0]
                  );
                }}
              /> */}
              <BtnDelete onClickHandler={toggleDeleteDialog} />
            </div>
          </div>
        </GridToolbar>
        {/* <GridColumn
          field={SELECTED_FIELD}
          width="50px"
          headerSelectionValue={
            gridData?.findIndex(
              (item: any) => !selectedState[idGetter(item)]
            ) === -1
          }
        /> */}
        <GridColumn field={"text"} title="Levels" minResizableWidth={350} />
        <GridColumn
          field={"createdOn"}
          title="Created On"
          minResizableWidth={350}
          cell={CreatedOnDateCell}
          width={350}
        />
        <GridColumn
          field={"numOfMembers"}
          title="Total No. Of Members"
          minResizableWidth={350}
          width={350}
        />
      </Grid>
      {showDialog && (
        <Dialog
          width={350}
          title={action === "Create" ? "Add Drawing" : "Edit Drawing"}
          onClose={() => setShowDialog(false)}
          className="custom-dialog"
        >
          <div className="my-4">
            <CustomLabel label="Drawing" required={true} />
            <Input
              type="text"
              placeholder={"Enter Name"}
              value={inputVal}
              onChange={(e: any) => {
                setInputVal(e.target.value);
              }}
              autoComplete="new-password"
              list="autoCompleteOff"
            />
          </div>
          <DialogActionsBar>
            <div className="flex justify-end gap-3">
              <Button
                themeColor={"primary"}
                disabled={!inputVal}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                onClick={() => setShowDialog(false)}
                themeColor={"base"}
                fillMode="outline"
              >
                Cancel
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}

export default ThirdLevelListing;
