import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import {
  BringToFront,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsDown,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUp,
  ChevronUp,
  Database,
  FileX2,
  Layers,
  PanelsLeftBottom,
  Plus,
  X,
} from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useResizable } from "react-resizable-layout";
import DashboardMain from "../../component/layout/main/DashboardMain";
import { IBBS } from "../../interfaces/bbs.interface";
import { IProject } from "../../interfaces/project.interface";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import { IShapeCodes } from "../../interfaces/shapes.interface";
import bbsSvc from "../../services/bbs.service";
import LoaderService from "../../services/loader.service";
import projectSvc from "../../services/project.service";
import shapesSvc from "../../services/shapes.service";
import {
  setManageCallOff,
  setSelectedBBS,
  setSelectedCallOff,
  setSelectedMenu,
  setSelectedProject,
} from "../../store/features/commonSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";
import { AddProject } from "./ProjectComponents/AddProject";
import { DetailingOffices } from "./ProjectComponents/DetailingOffices";
import { AddSubcontractors } from "./ProjectComponents/Subcontractors";
import BBSListing from "./Listing/BBSListing";
import FirstLevelListing from "./Listing/FirstLevelListing";
import SecondLevelListing from "./Listing/SecondLevelListing";
import ThirdLevelListing from "./Listing/ThirdLevelListing";
import MemberDetails from "./MemberComponents/MemberDetails";
import SampleSplitter from "./SampleSplitter";
import TreeViewMain from "./TreeViewMain";
import Stats from "./DashboardComponents/Stats";
import dashboardService from "../../services/dashboard.service";
import { IHomeDashboardStats } from "../../interfaces/dashboard.interface";
import _ from "lodash";
import Members from "./Members";
import { setToast } from "../../store/features/toastSlice";
import BBSStats from "./DashboardComponents/BBSStats";
import standardSvc from "../../services/standard.service";
import { IStandard } from "../../interfaces/standard.interface";
import WeightStats from "./DashboardComponents/WeightStats";
import ProjectStats from "./DashboardComponents/ProjectStats";
import LengthStats from "./DashboardComponents/LengthStats";
import FourthLevelListing from "./Listing/FourthLevelListing";
import { MemberProperties } from "./MemberComponents/MemberProperties";
import { MemberAssignments } from "./MemberComponents/MemberAssignments";
import { MemberImport } from "./MemberComponents/ImportMember";
import { MemberParts } from "./MemberComponents/MemberParts";
import {
  IMember,
  IMemberDetail,
  IMemberLine,
} from "../../interfaces/member.interface";
import CallOffListing from "./Call-Off/CallOffListing";
import {
  processTreeViewItems,
  TreeViewCheckDescriptor,
  TreeViewOperationDescriptor,
} from "@progress/kendo-react-treeview";
import memberSvc from "../../services/member.service";
import CallOffMembers from "./CallOffMembers";
import { MemberRevision } from "./MemberComponents/MemberRevision";
export interface RolesStatsTask {
  id: string;
  title: string;
  count: number;
  Icon: React.ElementType;
  iconColor: string;
}

export type TreeViewDataItem = {
  text: string;
  expanded?: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
  level?: number;
  levelCode?: string;
  subLevelCode?: string;
};

const Home = () => {
  const [dashboardStats, setDashboardStats] = useState<IHomeDashboardStats>();
  const [projectDashboardStats, setProjectDashboardStats] =
    useState<IHomeDashboardStats>();

  const [check, setCheck] = useState<string[] | TreeViewCheckDescriptor>([]);
  const [select, setSelect] = useState<string[]>([""]);
  const [expand, setExpand] = useState<TreeViewOperationDescriptor>({
    ids: ["Item2"],
    idField: "text",
  });
  // Tree View Handlers

  const dispatch = useDispatch();
  const oldProjectId = useRef<string | null>(null);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const anchor = useRef<Button | null>(null);

  const [copiedItems, setCopiedItems] = React.useState<any[]>([]);
  const [pastedItems, setPastedItems] = React.useState<any[]>([]);

  const commonState = useAppSelector((state: RootState) => state.common);
  const prevMemberDetail = useRef<string>("");

  const [distinctMembers, setDistinctMembers] = useState<IMemberLine[]>([]);

  const [selectedMembers, setSelectedMembers] = useState<IMember[]>([]);

  const [selectedLevelBBS, setSelectedLevelBBS] = useState<IBBS | null>(null);
  const [treeView, setTreeView] = useState<TreeViewDataItem[]>([]);
  const [selectedRow, setSelectedRow] = useState<IBBS | null>(null);
  const [show, setShow] = useState(false);
  const [shapeCodes, setShapeCodes] = useState<IShapeCodes[]>([]);
  const [standard, setStandard] = useState<IStandard | null>(null);

  const [showMembersView, setShowMembersView] = useState(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [memberDetail, setMemberDetail] = useState<IMemberDetail>();
  const [memberProperties, setMemberProperties] = useState<any[]>([]);
  const [memberImportType, setMemberImportType] = useState<any[]>([]);

  const [showManageBBS, setShowManageBBS] = useState(false);
  const [showManageCallOff, setShowManageCallOff] = useState(false);
  const [showEditProjectDrawer, setShowEditProjectDrawer] =
    useState<boolean>(false);

  const [showMemberRevisionDrawer, setShowMemberRevisionDrawer] =
    useState<boolean>(false);

  const [showAddDetailingOfficeDrawer, setShowAddDetailingOfficeDrawer] =
    useState<boolean>(false);

  const [showAddSubcontractorDrawer, setShowAddSubcontractorDrawer] =
    useState<boolean>(false);
  const [members, setMembers] = useState<IMember[]>([]);

  const [projectToEdit, setProjectToEdit] = useState<
    (IProject & { id: string }) | null
  >(null);

  const [prevProject, setPrevProject] = useState<
    (IProject & { id: string }) | null
  >(null);

  const showStats = () => {
    if (commonState?.selectedProject?.event?.item === undefined) {
      return true;
    }
    return false;
  };

  const showBBSGrid = () => {
    if (showMembersView) return false;

    if (showManageBBS) {
      if (showMembersView) setShowMembersView(false);
      return true;
    }

    if (showManageCallOff) {
      if (showMembersView) setShowMembersView(false);
      return false;
    }

    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (
      commonState?.selectedProject?.event?.item?.level === 4 ||
      commonState?.selectedProject?.event?.item?.level === 5
    ) {
      return true;
    }
    return false;
  };
  const showCallOffGrid = () => {
    if (showMembersView) return false;

    if (showManageBBS) {
      if (showMembersView) setShowMembersView(false);
      return false;
    }

    if (showManageCallOff) {
      if (showMembersView) setShowMembersView(false);
      return true;
    }

    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (
      commonState?.selectedProject?.event?.item?.level === 4 ||
      commonState?.selectedProject?.event?.item?.level === 5
    ) {
      return true;
    }
    return false;
  };

  const showGridFirstLevel = () => {
    if (showMembersView) return false;
    if (showManageBBS) return false;
    if (showManageCallOff) return false;
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.level === 0) {
      return true;
    }
    return false;
  };

  const showGridSecondLevel = () => {
    if (showMembersView) return false;
    if (showManageBBS) return false;
    if (showManageCallOff) return false;
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.level === 1) {
      return true;
    }
    return false;
  };

  const showGridThirdLevel = () => {
    if (showMembersView) return false;
    if (showManageBBS) return false;
    if (showManageCallOff) return false;
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.level === 2) {
      return true;
    }
    return false;
  };

  const showGridFourthLevel = () => {
    if (showMembersView) return false;
    if (showManageBBS) return false;
    if (showManageCallOff) return false;
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.level === 3) {
      return true;
    }
    return false;
  };

  const fetchShapeCodes = (standardId: string) => {
    shapesSvc.getByStandardId(standardId).then((res) => {
      setShapeCodes(res.data as IShapeCodes[]);
    });
  };

  const fetchStandard = (standardId: string) => {
    standardSvc.getById(standardId).then((res) => {
      setStandard(res.data as IStandard);
    });
  };

  useEffect(() => {
    if (
      commonState?.selectedProject?.event?.item?.level === 4 ||
      commonState?.selectedProject?.event?.item?.level === 5
    ) {
      const treeViewProj = treeView.find(
        (x: any) =>
          x.projectId === commonState?.selectedProject?.event?.item?.projectId
      );
      if (treeViewProj) {
      }

      setShowMembersView(true);
    } else {
      setShowMembersView(false);
    }
  }, [commonState?.selectedProject?.event?.item, treeView]);

  useEffect(() => {
    if (projects?.length && commonState?.selectedProject?.event?.item?.level) {
      if (
        commonState?.selectedProject?.event?.item?.projectId ===
        oldProjectId.current
      )
        return;

      const project = projects.find(
        (x) => x.id === commonState.selectedProject.event.item.projectId
      );
      if (
        project &&
        (commonState?.selectedProject?.event?.item?.level === 4 ||
          commonState?.selectedProject?.event?.item?.level === 5)
      ) {
        fetchStandard(project.standardId);
        fetchShapeCodes(project.standardId);
        oldProjectId.current = project.id;
      }
    }
  }, [projects, commonState?.selectedProject?.event?.item]);

  useEffect(() => {
    setShow(false);
  }, []);

  // useEffect(() => {
  //   projectSvc.getTree().then((x) => {
  //     if (x.data) {
  //       console.log(x.data);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    dashboardService
      .getStats()
      .then((response: any) => {
        if (response.data) {
          setDashboardStats(response.data);
        }
      })
      .catch((error) => {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: `Unable to get stats, Please try again.`,
          })
        );
      });
  }, []);

  useEffect(() => {
    dashboardService
      .projectStats()
      .then((response: any) => {
        if (response.data) {
          setProjectDashboardStats(response.data);
        }
      })
      .catch((error) => {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: `Unable to get stats, Please try again.`,
          })
        );
      });
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const refreshProjects = () => {
    fetchProjects();
  };

  const fetchProjects = useCallback(
    (searchValue?: string) => {
      let searchFilters: ISearchFilter[] = [];
      if (searchValue) {
        searchFilters = [
          { field: "name", operator: "contains", value: searchValue },
        ];
      }
      projectSvc.get(searchFilters).then((res) => {
        if (res.data) {
          setProjects(res.data);
        }
      });
    },
    [setProjects]
  );

  const getSelectedItems = (items: any[]) => {
    let selectedItems: any[] = [];

    items.forEach((item) => {
      if (Array.isArray(check) && check.some((x) => x === item.index)) {
        selectedItems.push({
          id: item.id,
          name: item.text,
          level: item.level,
          noOfMem: item.noOfMem ?? 0,
        });
      }

      if (item.items && item.items.length > 0) {
        // Recursively find selected nodes in child items
        selectedItems = selectedItems.concat(getSelectedItems(item.items));
      }
    });

    return selectedItems;
  };

  const getHeader = () => {
    const item = commonState?.selectedProject?.event?.item;

    const navigateTo = (level: number) => {
      const idx = treeView.findIndex(
        (x: any) =>
          x.projectId === commonState?.selectedProject?.event?.item?.projectId
      );
      if (idx === -1) return;

      let treeViewItem = treeView[idx];
      switch (level) {
        case 0:
          dispatch(
            setSelectedProject({
              event: {
                item: { ...treeViewItem, cloned: true, expanded: true },
                itemHierarchicalIndex: idx.toString(),
              },
              check: [],
              treeView,
              settings: commonState?.selectedProject?.settings,
            })
          );
          break;
        case 1:
          treeViewItem = treeViewItem!.items![item.parentId];
          dispatch(
            setSelectedProject({
              event: {
                item: { ...treeViewItem, cloned: true, expanded: true },
                itemHierarchicalIndex: idx.toString() + "_" + item.idx,
              },
              check: [],
              treeView,
              settings: commonState?.selectedProject?.settings,
            })
          );
          break;
        case 2:
          treeViewItem =
            treeViewItem?.items![item.parentId]?.items![item.subLevelId];
          dispatch(
            setSelectedProject({
              event: {
                item: { ...treeViewItem, cloned: true, expanded: true },
                itemHierarchicalIndex:
                  idx.toString() +
                  "_" +
                  item.parentId.toString() +
                  "_" +
                  item.idx.toString(),
              },
              check: [],
              treeView,
              settings: commonState?.selectedProject?.settings,
            })
          );
          break;
        case 3:
          treeViewItem =
            treeViewItem?.items![item.parentId]?.items![item.subLevelId].items![
              item.shopDwgId
            ];
          dispatch(
            setSelectedProject({
              event: {
                item: { ...treeViewItem, cloned: true, expanded: true },
                itemHierarchicalIndex:
                  idx.toString() +
                  "_" +
                  item.parentId.toString() +
                  "_" +
                  item.idx.toString(),
              },
              check: [],
              treeView,
              settings: commonState?.selectedProject?.settings,
            })
          );
          break;
      }
    };
    if (item.level === 0) {
      return (
        <>
          {showManageCallOff && (
            <div>
              <h2 className="xl:text-base text-sm flex items-center gap-1 text-neutral-130 group-hover:text-primaryMain">
                Manage Call-Off
              </h2>
              <div className="flex items-center gap-3 justify-between">
                <div className="inline-flex items-center">
                  <h1 className="xl:text-[22px] text-[22px] font-light font-kanit leading-[25px]">
                    {item.text?.split("-Code-")[0]}
                  </h1>
                </div>
              </div>
            </div>
          )}

          {!showManageCallOff && (
            <div className="inline-flex items-center gap-1">
              <h1 className="text-[18px] text-txtPrimary font-kanit font-light flex items-center">
                {item.text?.split("-Code-")[0]}
              </h1>
            </div>
          )}
        </>
      );
    }

    if (item.level === 1) {
      return (
        <div className="inline-flex items-center">
          <h1
            className="text-[18px] text-[#aea69d]  font-kanit font-light flex items-center mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(0)}
          >
            {item.projectName}
          </h1>
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex items-center mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1 className="text-[18px] text-txtPrimary  font-kanit font-light flex items-center mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black">
            {item.text?.split("-Code-")[0]}
          </h1>
        </div>
      );
    }

    if (item.level === 2) {
      return (
        <>
          {showManageBBS && (
            <div>
              <h2 className="xl:text-base text-sm flex items-center gap-1 text-neutral-130 group-hover:text-primaryMain">
                Manage BBS
              </h2>
              <div className="flex items-center gap-3 justify-between">
                <div className="inline-flex items-center">
                  <h1 className="text-[15px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 hover:text-black">
                    {item.projectName}
                  </h1>
                  <h1 className="text-[15px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
                    <ChevronRight strokeWidth={1} size={16} />
                  </h1>
                  <h1 className="text-[15px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 hover:text-black">
                    {item.levelName}
                  </h1>
                  <h1 className="text-[15px]text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
                    <ChevronRight strokeWidth={1} size={16} />
                  </h1>
                  <h1 className="text-[15px] text-txtPrimary  font-kanit font-light flex mb-1 mt-1 hover:text-black">
                    {item.text?.split("-Code-")[0]}
                  </h1>
                </div>
              </div>
            </div>
          )}

          {!showManageBBS && (
            <div className="inline-flex items-center">
              <h1
                className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
                onClick={() => navigateTo(0)}
              >
                {item.projectName}
              </h1>
              <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
                <ChevronRight strokeWidth={1} />
              </h1>
              <h1
                className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
                onClick={() => navigateTo(1)}
              >
                {item.levelName}
              </h1>
              <h1 className="text-[18px]text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
                <ChevronRight strokeWidth={1} />
              </h1>
              <h1 className="text-[18px] font-kanit font-light flex mb-1 mt-1">
                {item.text?.split("-Code-")[0]}
              </h1>
            </div>
          )}

          {/* {showManageBBS && (
            <div className="flex">
              <h1 className="text-[18px] text-txtPrimary font-kanit font-medium flex items-center">
                Manage BBS {">"}
              </h1>
            </div>
          )} */}
        </>
      );
    }

    if (item.level === 3) {
      return (
        <div className="inline-flex items-center">
          <h1
            className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(0)}
          >
            {item.projectName}
          </h1>
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(1)}
          >
            {item.levelName}
          </h1>
          <h1 className="text-[18px]text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(2)}
          >
            {item.subLevelName}
          </h1>
          <h1 className="text-[18px]text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1 className="text-[18px] text-txtPrimary  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black">
            {item.text?.split("-Code-")[0]}
          </h1>
        </div>
      );
    }

    if (item.level === 4) {
      return (
        <div className="flex text-lg">
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer">
            {item.projectName}
          </h1>
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(1)}
          >
            {item.levelName}
          </h1>
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(2)}
          >
            {item.subLevelName}
          </h1>
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1 className="text-[18px] text-txtPrimary  font-kanit font-light flex mb-1 mt-1">
            {item.text}
          </h1>
        </div>
      );
    }
    if (item.level === 5) {
      return (
        <div className="flex text-lg">
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer">
            {item.projectName}
          </h1>
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1  hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(1)}
          >
            {item.levelName}
          </h1>
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(2)}
          >
            {item.subLevelName}
          </h1>
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1
            className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 hover:bg-[var(--kendo-neutral-30)] px-1  hover:text-black cursor-pointer"
            onClick={() => navigateTo(3)}
          >
            {item.memberName}
          </h1>
          <h1 className="text-[18px] text-[#aea69d]  font-kanit font-light flex mb-1 mt-1 headingIcon">
            <ChevronRight strokeWidth={1} />
          </h1>
          <h1 className="text-[18px] text-txtPrimary  font-kanit font-light flex mb-1 mt-1">
            {item.text}
          </h1>
        </div>
      );
    }
  };
  const [expanded, setExpanded] = useState(true);
  const [showAddProjectDrawer, setShowAddProjectDrawer] =
    useState<boolean>(false);

  const [showMemberPropertiesDrawer, setShowMemberPropertiesDrawer] =
    useState<boolean>(false);
  const [showEditMemberPropertiesDrawer, setShowEditMemberPropertiesDrawer] =
    useState<boolean>(false);

  const [showMemberAssignmentsDrawer, setShowMemberAssignmentsDrawer] =
    useState<boolean>(false);

  const [showMemberPartsDrawer, setShowMemberPartsDrawer] =
    useState<boolean>(false);

  const [showMemberImportDrawer, setShowMemberImportDrawer] =
    useState<boolean>(false);

  const [selected, setSelected] = useState<boolean>(false);
  const toggleSelected = () => {
    setSelected(!selected);
  };

  const closeManageBBS = () => {
    setShowManageBBS(false);
    setSelect([]);
    setCheck([]);
    if (
      commonState?.selectedProject?.event?.item?.level === 4 ||
      commonState?.selectedProject?.event?.item?.level === 5
    ) {
      const treeViewProj = treeView.find(
        (x: any) =>
          x.projectId === commonState?.selectedProject?.event?.item?.projectId
      );
      if (treeViewProj) {
      }

      setShowMembersView(true);
    } else {
      setShowMembersView(false);
    }
  };

  useEffect(() => {
    setShowManageCallOff(commonState?.manageCallOff);
  }, [commonState?.manageCallOff]);

  const closeManageCallOff = () => {
    setSelect([]);
    setCheck([]);

    // dispatch(setSelectedMenu(commonState?.selected));
    // dispatch(setManageCallOff(commonState?.manageCallOff));
    setShowManageCallOff(false);

    if (
      commonState?.selectedProject?.event?.item?.level === 4 ||
      commonState?.selectedProject?.event?.item?.level === 5
    ) {
      const treeViewProj = treeView.find(
        (x: any) =>
          x.projectId === commonState?.selectedProject?.event?.item?.projectId
      );
      if (treeViewProj) {
      }

      setShowMembersView(true);
    } else {
      setShowMembersView(false);
    }
  };

  // const {
  //   isDragging: isFileDragging,
  //   position: fileW,
  //   splitterProps: fileDragBarProps,
  // } = useResizable({
  //   axis: "x",
  //   initial: 500,
  //   min: 300,
  //   max: 500,
  // });

  const canManageCallOff = () => {
    if (commonState?.selectedProject?.event?.item?.level === 0) {
      return true;
    }
    return false;
  };

  const canManageBBS = () => {
    if (commonState?.selectedProject?.event?.item?.level === 2) {
      return true;
    }
    return false;
  };

  const moveTo = (mode: string) => {
    if (mode !== "all" && Array.isArray(check) && check.length === 0) {
      dispatch(
        setToast({
          toastMsg: "Select Members to move",
          toastType: "error",
        })
      );
      return;
    }

    var data = getSelectedItems(treeView);
    console.log(data);

    if (showManageBBS && !commonState?.selectedBBS) {
      dispatch(
        setToast({
          toastMsg: "Select a BBS to Move Members",
          toastType: "error",
        })
      );
      return;
    }

    if (showManageCallOff && !commonState?.selectedCallOff) {
      dispatch(
        setToast({
          toastMsg: "Select a Call Off to Move Members",
          toastType: "error",
        })
      );
      return;
    }

    var payload = {
      members: data,
      type: "move",
      mode: mode,
      module: showManageBBS ? "bbs" : "calloff",
      id: showManageBBS
        ? commonState?.selectedBBS?.id
        : commonState?.selectedCallOff?.id,
    };

    memberSvc.moveMembers(payload).then((res) => {
      if (!res.error) {
        dispatch(
          setToast({
            toastMsg: "Members moved successfully",
            toastType: "success",
          })
        );
        if (showManageBBS) {
          dispatch(setSelectedBBS(commonState?.selectedBBS));
        }
        if (showManageCallOff) {
          dispatch(
            setSelectedCallOff({
              ...commonState?.selectedCallOff,
              members: data,
            })
          );
        }

        setSelect([]);
        setCheck([]);
      }
    });
  };

  const removeFrom = (mode: string) => {
    if (showManageBBS && !commonState?.selectedBBS) {
      dispatch(
        setToast({
          toastMsg: "Select a BBS to Remove Members",
          toastType: "error",
        })
      );
      return;
    }

    if (showManageCallOff && !commonState?.selectedCallOff) {
      dispatch(
        setToast({
          toastMsg: "Select a Call Off to Remove Members",
          toastType: "error",
        })
      );
      return;
    }

    if (mode !== "all" && selectedMembers.length === 0) {
      dispatch(
        setToast({
          toastMsg: "Select Members to remove",
          toastType: "error",
        })
      );
      return;
    }

    var payload = {
      members: selectedMembers,
      type: "remove",
      mode: mode,
      module: showManageBBS ? "bbs" : "calloff",
      id: showManageBBS
        ? commonState?.selectedBBS?.id
        : commonState?.selectedCallOff?.id,
    };

    memberSvc.moveMembers(payload).then((res) => {
      if (!res.error) {
        if (showManageBBS) {
          dispatch(setSelectedBBS(commonState?.selectedBBS));
        }
        if (showManageCallOff) {
          dispatch(setSelectedCallOff(commonState?.selectedCallOff));
          fetchCallOffMembers();
        }
        dispatch(
          setToast({
            toastMsg: "Members removed successfully",
            toastType: "success",
          })
        );
      }
    });
  };

  const isMovementToDisabled = () => {
    if (
      (showManageBBS && commonState?.selectedBBS) ||
      (showManageCallOff && commonState?.selectedCallOff)
    ) {
      if (showManageBBS && commonState?.selectedBBS) {
        return !commonState.selectedBBS.isActive;
      }

      if (showManageCallOff && commonState?.selectedCallOff) {
        return commonState.selectedCallOff.status === "Released";
      }

      return false;
    }

    return true;
  };

  const isMovementFromDisabled = () => {
    if (
      (showManageBBS && commonState?.selectedBBS) ||
      (showManageCallOff && commonState?.selectedCallOff)
    ) {
      if (showManageBBS && commonState?.selectedBBS) {
        return members.length === 0 || !commonState.selectedBBS.isActive;
      }

      if (showManageCallOff && commonState?.selectedCallOff) {
        return (
          members.length === 0 ||
          commonState.selectedCallOff.status === "Released"
        );
      }

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (commonState?.selectedCallOff) {
      fetchCallOffMembers();
    }
  }, [commonState?.selectedCallOff]);

  const fetchCallOffMembers = () => {
    memberSvc.getByCallOff(commonState?.selectedCallOff?.id).then((x: any) => {
      if (x.data) {
        setMembers(x.data);
        setSelectedMembers([]);
      } else {
        setMembers([]);
        setSelectedMembers([]);
      }
    });
  };

  return (
    <>
      <MemberRevision
        memberProperties={memberProperties}
        showMemberRevisionDrawer={showMemberRevisionDrawer}
        setShowMemberRevisionDrawer={setShowMemberRevisionDrawer}
      />
      <MemberParts
        memberProperties={memberProperties}
        showMemberPartsDrawer={showMemberPartsDrawer}
        setShowMemberPartsDrawer={setShowMemberPartsDrawer}
        fetchProjects={fetchProjects}
      />
      <MemberProperties
        memberProperties={memberProperties}
        showMemberPropertiesDrawer={showMemberPropertiesDrawer}
        setShowMemberPropertiesDrawer={setShowMemberPropertiesDrawer}
        showEditMemberPropertiesDrawer={showEditMemberPropertiesDrawer}
        setShowEditMemberPropertiesDrawer={setShowEditMemberPropertiesDrawer}
        fetchProjects={fetchProjects}
      />
      <MemberAssignments
        memberProperties={memberProperties}
        showMemberAssignmentsDrawer={showMemberAssignmentsDrawer}
        setShowMemberAssignmentsDrawer={setShowMemberAssignmentsDrawer}
        fetchProjects={fetchProjects}
      />
      <MemberImport
        memberProperties={memberProperties}
        memberImportType={memberImportType}
        showMemberImportDrawer={showMemberImportDrawer}
        setShowMemberImportDrawer={setShowMemberImportDrawer}
        fetchProjects={fetchProjects}
      />
      <AddProject
        showAddProjectDrawer={showAddProjectDrawer}
        setShowAddProjectDrawer={setShowAddProjectDrawer}
        showEditProjectDrawer={showEditProjectDrawer}
        setShowEditProjectDrawer={setShowEditProjectDrawer}
        prevProject={prevProject}
        setPrevProject={setPrevProject}
        projectToEdit={projectToEdit}
        setProjectToEdit={setProjectToEdit}
        fetchProjects={fetchProjects}
      />
      <DetailingOffices
        showAddDetailingOfficeDrawer={showAddDetailingOfficeDrawer}
        setShowAddDetailingOfficeDrawer={setShowAddDetailingOfficeDrawer}
        projectToEdit={projectToEdit}
        setProjectToEdit={setProjectToEdit}
        fetchProjects={fetchProjects}
      ></DetailingOffices>
      <AddSubcontractors
        projectToEdit={projectToEdit}
        setProjectToEdit={setProjectToEdit}
        showAddSubcontractorDrawer={showAddSubcontractorDrawer}
        setShowAddSubcontractorDrawer={setShowAddSubcontractorDrawer}
        fetchProjects={fetchProjects}
      />

      <div
        className="flex flex-1 transition-all duration-500 wrapper"

        // style={{ height: "93vh" }}
      >
        <div
          className={`flex-col flex left-block  ${
            commonState?.selected ? "lg:w-[0px!important]" : ""
          }`}
        >
          {/*  */}

          <div
            className={`fixed top-0 bottom-0 ${
              commonState?.selected ? "lg:w-[0px!important]" : ""
            } z-[999] h-full flex flex-col bg-white w-[400px!important]`}
          >
            <div className={`flex-1 `}>
              <div className={`bg-white bbs-treeView z-10`}>
                <div className={` z-[999] pb-2`}>
                  <TreeViewMain
                    treeView={treeView}
                    setTreeView={setTreeView}
                    projects={projects}
                    setProjects={setProjects}
                    memberDetail={memberDetail}
                    setMemberDetail={setMemberDetail}
                    prevMemberDetail={prevMemberDetail}
                    setSelectedLevelBBS={setSelectedLevelBBS}
                    setDistinctMembers={setDistinctMembers}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    showAddProjectDrawer={showAddProjectDrawer}
                    setShowAddProjectDrawer={setShowAddProjectDrawer}
                    showMemberPropertiesDrawer={showMemberPropertiesDrawer}
                    setShowMemberPropertiesDrawer={
                      setShowMemberPropertiesDrawer
                    }
                    showEditMemberPropertiesDrawer={
                      showEditMemberPropertiesDrawer
                    }
                    setShowEditMemberPropertiesDrawer={
                      setShowEditMemberPropertiesDrawer
                    }
                    showMemberRevisionDrawer={showMemberRevisionDrawer}
                    setShowMemberRevisionDrawer={setShowMemberRevisionDrawer}
                    showMemberPartsDrawer={showMemberPartsDrawer}
                    setShowMemberPartsDrawer={setShowMemberPartsDrawer}
                    selected={commonState?.selected}
                    toggleSelected={toggleSelected}
                    showEditProjectDrawer={showEditProjectDrawer}
                    setShowEditProjectDrawer={setShowEditProjectDrawer}
                    showAddDetailingOfficeDrawer={showAddDetailingOfficeDrawer}
                    setShowAddDetailingOfficeDrawer={
                      setShowAddDetailingOfficeDrawer
                    }
                    showAddSubcontractorDrawer={showAddSubcontractorDrawer}
                    setShowAddSubcontractorDrawer={
                      setShowAddSubcontractorDrawer
                    }
                    projectToEdit={projectToEdit}
                    setProjectToEdit={setProjectToEdit}
                    prevProject={prevProject}
                    setPrevProject={setPrevProject}
                    showManageBBS={showManageBBS}
                    setShowManageBBS={setShowManageBBS}
                    showManageCallOff={showManageCallOff}
                    setShowManageCallOff={setShowManageCallOff}
                    memberProperties={memberProperties}
                    setMemberProperties={setMemberProperties}
                    showMemberAssignmentsDrawer={showMemberAssignmentsDrawer}
                    setShowMemberAssignmentsDrawer={
                      setShowMemberAssignmentsDrawer
                    }
                    setShowMemberImportDrawer={setShowMemberImportDrawer}
                    showMemberImportDrawer={showMemberImportDrawer}
                    memberImportType={memberImportType}
                    setMemberImportType={setMemberImportType}
                    showMembersView={showMembersView}
                    setShowMembersView={setShowMembersView}
                    copiedItems={copiedItems}
                    pastedItems={pastedItems}
                    setCopiedItems={setCopiedItems}
                    setPastedItems={setPastedItems}
                    check={check}
                    setCheck={setCheck}
                    select={select}
                    setSelect={setSelect}
                    expand={expand}
                    setExpand={setExpand}
                    processTreeViewItems={processTreeViewItems}
                  />
                </div>
              </div>
            </div>
            {!commonState?.selected && (
              <div className="flex grid grid-cols-2 gap-2 justify-center align-center p-2 border-tb">
                <div className="flex gap-2 justify-center">
                  <Button
                    size={"small"}
                    themeColor={"tertiary"}
                    disabled={isMovementToDisabled()}
                    title="Move selected member"
                    onClick={() => moveTo("selected")}
                    className="px-3"
                  >
                    <ChevronDown />
                  </Button>
                  <Button
                    size={"small"}
                    title="Move all members"
                    disabled={isMovementToDisabled()}
                    themeColor={"tertiary"}
                    onClick={() => moveTo("all")}
                    className="px-3"
                  >
                    <ChevronsDown />
                  </Button>
                </div>
                <div className="flex gap-2 justify-center">
                  <Button
                    size={"small"}
                    title="Remove selected member"
                    disabled={isMovementFromDisabled()}
                    themeColor={"error"}
                    onClick={() => removeFrom("selected")}
                    className="px-3"
                  >
                    <ChevronUp />
                  </Button>
                  <Button
                    size={"small"}
                    title="Remove all members"
                    disabled={isMovementFromDisabled()}
                    themeColor={"error"}
                    onClick={() => removeFrom("all")}
                    className="px-3"
                  >
                    <ChevronsUp />
                  </Button>
                </div>
              </div>
            )}
            {!commonState?.selected && (
              <div
                className={`flex-1 ${
                  showManageBBS || showManageCallOff
                    ? "h-[55vh!important] overflow-y-scroll"
                    : "h-full overflow-y-auto"
                }`}
              >
                {!showMembersView &&
                  showManageBBS &&
                  commonState?.selectedBBS && (
                    <Members
                      selectedRow={commonState?.selectedBBS}
                      setSelectedMembers={setSelectedMembers}
                      members={members}
                      setMembers={setMembers}
                    />
                  )}

                {!showMembersView &&
                  showManageCallOff &&
                  commonState?.selectedCallOff && (
                    <CallOffMembers
                      setSelectedMembers={setSelectedMembers}
                      members={members}
                      setMembers={setMembers}
                    />
                  )}
              </div>
            )}
            {!commonState?.selected && !showManageBBS && !showManageCallOff && (
              <div
                className={`fixed bottom-0 ${
                  commonState?.selected ? "lg:w-[0px!important]" : ""
                } z-[999999] h-[60px] flex flex-col border-t bg-white w-[400px!important]`}
              >
                <div className="p-2 pl-1">
                  <h4 className="text-[12px] text-txtPrimary tracking-[-0.08px] font-kanit font-medium">
                    Tree Legend
                  </h4>
                </div>
                <div className="grid grid-cols-4 justify-center align-center">
                  <h5 className="text-[10px] p-1 border text-txtPrimary tracking-[-0.10px] font-light ">
                    Engineered
                  </h5>
                  <h5 className="text-[10px] p-1 border tracking-[-0.10px] font-light partially-released">
                    Partially Released
                  </h5>
                  <h5 className="text-[10px] p-1 border tracking-[-0.10px] font-light released ">
                    Released
                  </h5>
                  <h5 className="text-[10px] p-1 border  tracking-[-0.10px] font-light inactive">
                    Deleted
                  </h5>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* {!commonState?.selected && (
          <SampleSplitter isDragging={isFileDragging} {...fileDragBarProps} />
        )} */}
        <div
          className={`w-full h-full flex justify-center items-center flex-col right-block
          ${!commonState?.selected ? "lg:pl-[400px!important]" : ""}
          `}
        >
          <DashboardMain>
            {dashboardStats &&
              projectDashboardStats &&
              projects?.length != 0 &&
              showStats() && (
                <>
                  <div className="px-5 py-4">
                    <div className="card p-2">
                      <div className="grid xl:grid-cols-5 gap-3 items-start">
                        <div className="inline-flex items-center gap-2">
                          <PanelsLeftBottom strokeWidth={1} size="20" />
                          <h2 className="font-medium font-kanit text-[20px] -tracking-[0.04px]">
                            Stats
                          </h2>
                        </div>

                        <div className="col-span-4">
                          <Stats statsData={dashboardStats} />
                        </div>
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-2 gap-3 mt-3">
                      <div className="card p-3">
                        <h2 className="font-medium font-kanit text-lg -tracking-[0.04px]">
                          Members by Project
                        </h2>
                        <ProjectStats statsData={projectDashboardStats} />
                      </div>
                      <div className="card p-3">
                        <h2 className="font-medium font-kanit text-lg -tracking-[0.04px]">
                          BBS by Project
                        </h2>
                        <BBSStats statsData={projectDashboardStats} />
                      </div>
                      <div className="card p-3">
                        <h2 className="font-medium font-kanit text-lg -tracking-[0.04px]">
                          Total Weight (Ton) by Project
                        </h2>
                        <WeightStats statsData={projectDashboardStats} />
                      </div>
                      <div className="card p-3">
                        <h2 className="font-medium font-kanit text-lg -tracking-[0.04px]">
                          Total Length (m) by Project
                        </h2>
                        <LengthStats statsData={projectDashboardStats} />
                      </div>
                    </div>
                  </div>
                </>
              )}

            {!projects.length && (
              <div className="px-5 py-4 justify-center items-center flex flex-col h-full">
                <FileX2
                  size={72}
                  strokeWidth={2}
                  color="rgba(184, 187, 190, 1)"
                />
                <p
                  className="mt-4 text-2xl"
                  style={{ color: "rgba(0, 0, 0, 0.32)" }}
                >
                  Create new project to continue...
                </p>
                <Button
                  className="border border-[#E2E8F0] px-2 py-[6px] font-medium mt-4"
                  onClick={() => setShowAddProjectDrawer(true)}
                >
                  <div className="flex gap-x-2">
                    <Plus className="text-[#484F61] self-center p-0" />
                    <p className="self-center pr-2">New Project</p>
                  </div>
                </Button>
              </div>
            )}

            {!showStats() && projects?.length ? (
              <div className="px-5 py-4 ">
                {!showMembersView && (showManageBBS || showManageCallOff) && (
                  <div className="card p-3 mb-5">
                    <div className="grid sm:grid-cols-[auto_300px] gap-3 items-center ">
                      {getHeader()}
                      <div className="text-end">
                        <div className="sm:inline-block text-left">
                          <Button
                            type="button"
                            themeColor={"light"}
                            size={"medium"}
                            className="bg-[#F5F5F5] border border-[#E2E8F0] px-2 font-small w-24"
                            onClick={() => {
                              if (showManageBBS) closeManageBBS();
                              if (showManageCallOff) closeManageCallOff();
                            }}
                          >
                            <ChevronLeft strokeWidth={0.8} />
                            <span className="inline-flex items-center gap-2">
                              Close
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!(showManageBBS || showManageCallOff) &&
                commonState?.selectedProject ? (
                  <div className="card p-3 mb-5">
                    <div className="grid sm:grid-cols-[auto_350px] gap-3 items-center ">
                      {getHeader()}
                      {
                        <>
                          <div className="text-end">
                            <div className="sm:inline-block text-left">
                              {!showMembersView && (
                                <>
                                  {canManageCallOff() && (
                                    <Button
                                      type="button"
                                      className="k-button k-button-solid k-button-solid-base k-rounded-md bg-[#0078d4] border border-[#E2E8F0] k-button-solid-primary mr-2"
                                      onClick={() => {
                                        setShowManageCallOff(true);
                                        // dispatch(
                                        //   setSelectedMenu(commonState?.selected)
                                        // );
                                        // dispatch(
                                        //   setManageCallOff(
                                        //     commonState.manageCallOff
                                        //   )
                                        // );
                                      }}
                                      // ref={anchor}
                                    >
                                      <span className="inline-flex items-center gap-2">
                                        <i>
                                          <BringToFront className="w-5 h-5" />
                                        </i>
                                        Manage Call-Off
                                      </span>
                                    </Button>
                                  )}
                                  {canManageBBS() && (
                                    <Button
                                      type="button"
                                      className="k-button bg-[#0078d4] k-button-solid k-button-solid-base k-rounded-md border border-[#E2E8F0] k-button-solid-primary"
                                      onClick={() => setShowManageBBS(true)}
                                      // ref={anchor}
                                    >
                                      <span className="inline-flex items-center gap-2">
                                        <i>
                                          <Layers className="w-5 h-5" />
                                        </i>
                                        Manage BBS
                                      </span>
                                    </Button>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="flex items-center gap-3 mb-3">
                  <div className="flex-1 flex items-center justify-end gap-2">
                    <Popup
                      anchor={anchor.current && anchor.current.element}
                      show={show}
                      popupClass={"popup-content w-[174px]"}
                      anchorAlign={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                      popupAlign={{ horizontal: "right", vertical: "top" }}
                    >
                      <div ref={popupRef}></div>
                    </Popup>
                  </div>
                </div>
                <div className="customTabs">
                  {showBBSGrid() && <BBSListing />}
                  {showCallOffGrid() && <CallOffListing />}
                </div>
                <div className="flex LookUpTabs">
                  {showGridFirstLevel() && (
                    <FirstLevelListing
                      treeView={treeView}
                      setTreeView={setTreeView}
                      projects={projects}
                      fetchProjects={fetchProjects}
                    />
                  )}
                  {showGridSecondLevel() && (
                    <SecondLevelListing
                      treeView={treeView}
                      setTreeView={setTreeView}
                      projects={projects}
                      fetchProjects={fetchProjects}
                    />
                  )}
                  {showGridThirdLevel() && (
                    <ThirdLevelListing
                      treeView={treeView}
                      setTreeView={setTreeView}
                      projects={projects}
                      fetchProjects={fetchProjects}
                    />
                  )}
                  {showGridFourthLevel() && (
                    <FourthLevelListing
                      treeView={treeView}
                      setTreeView={setTreeView}
                      distinctMembers={distinctMembers}
                      projects={projects}
                      fetchProjects={fetchProjects}
                    />
                  )}
                </div>

                <div>
                  {showMembersView && (
                    <MemberDetails
                      shapeCodes={shapeCodes}
                      standard={standard}
                      copiedItems={copiedItems}
                      pastedItems={pastedItems}
                      setCopiedItems={setCopiedItems}
                      setPastedItems={setPastedItems}
                      memberDetail={memberDetail}
                      setMemberDetail={setMemberDetail}
                      prevMemberDetail={prevMemberDetail}
                      memberProperties={memberProperties}
                      setMemberProperties={setMemberProperties}
                      setShowMemberPartsDrawer={setShowMemberPartsDrawer}
                      showMemberPartsDrawer={showMemberPartsDrawer}
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </DashboardMain>
        </div>
      </div>
    </>
  );
};

export default Home;
