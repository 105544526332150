import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import { useEffect, useState } from "react";
import dashboardService from "../../../services/dashboard.service";
import { chartColors } from "../../../utils/application.utils";

const ProjectStats = ({ statsData }: any) => {
  const [projects, setProjects] = useState<any[]>();
  const [memberCounts, setMemberCounts] = useState<number[]>([
    2, 4, 10, 5, 5, 2, 3, 4, 0, 1,
  ]);

  useEffect(() => {
    const data = Array.from(new Set(statsData.map((x: any) => x.name))).sort();

    const projects = Array.from(new Set(data.map((x: any) => x)));
    setProjects(projects);

    const membersList = statsData
      .map((proj: any) => {
        return {
          project: proj.name,
          count: proj.members,
        };
      })
      .map((x: any) => x.count);

    setMemberCounts(membersList);
  }, [statsData]);

  return (
    <>
      <div className="sm:px-4 px-3  flex-1">
        <Chart style={{ height: "300px" }}>
          <ChartLegend
            visible={false}
            position="bottom"
            orientation="horizontal"
            labels={{ font: "10px Cabin, sans-serif" }}
          />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={projects}
              majorGridLines={{ visible: false }}
              minorGridLines={{ visible: false }}
            ></ChartCategoryAxisItem>
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="column"
              data={memberCounts}
              name="Members"
              color={"#2D8BBA"}
              tooltip={{ visible: true }}
            />
          </ChartSeries>
        </Chart>
      </div>
    </>
  );
};

export default ProjectStats;
