import { IMemberDetail, IMemberLine } from '../interfaces/member.interface';
import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class MemberService extends BaseService {
  constructor() {
    super('Member');
  }

  async importMembers(data: any) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/Member/import-members`,
        data
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async createRevision(data: any) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/Member/create-revision`,
        data
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getByBbs(bbsId: string) {
    try {
      const res = await api.get(`/Member/by-bbsId/${bbsId}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      // LoaderService.hideLoader();
    }
  }

  async getByBbsCallOff(callOffId: string, bbsId?: string) {
    try {
      const res = await api.get(
        `/Member/by-bbsCalloffId/${callOffId}/${bbsId}`
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      // LoaderService.hideLoader();
    }
  }

  async getByCallOff(callOffId?: string) {
    try {
      const res = await api.get(`/Member/by-callOffId/${callOffId}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      // LoaderService.hideLoader();
    }
  }

  async getByCallOffNoOfMem(callOffId?: string) {
    try {
      const res = await api.get(`/Member/by-callOff-noOfMem/${callOffId}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      // LoaderService.hideLoader();
    }
  }

  // Move Member Calls

  async moveMembers(data: any) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/Home/move-members`,
        data
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async deleteMemberLines(id: string, payload: IMemberLine[]) {
    try {
      LoaderService.showLoader();
      const res = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/Member/delete-memberLines/${id}`,
        payload
      );

      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async updateMemberDetail(id: string, payload: IMemberDetail) {
    try {
      LoaderService.showLoader();
      const res = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/Member/update-memberDetail/${id}`,
        payload
      );

      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async unDelete(id: string) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/Member/undelete/${id}`
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getByMemberId(id: string, showLoader?: boolean) {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/Member/by-memberId/${id}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getByShopDwg(
    projectId: string,
    levelCode: string,
    subLevelCode: string,
    shopDwg: string
  ) {
    try {
      LoaderService.showLoader();
      const res = await api.get(
        `/Member/by-shopDwg/${projectId}/${levelCode}/${subLevelCode}/${shopDwg}`
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const memberSvc = new MemberService();

export default memberSvc;
