import { IMember } from "../../interfaces/member.interface";
import { useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";
import { Checkbox } from "@progress/kendo-react-inputs";

function CallOffMembers({ setSelectedMembers, members, setMembers }: any) {
  const commonState = useAppSelector((state: RootState) => state.common);

  const handleCheckboxSelectionChange = (event: any) => {
    if (event.target.value) {
      setSelectedMembers((prev: any) => {
        var member = members.find(
          (x: IMember) => x.id === event.target.element.value
        );

        return [...prev, member];
      });
    } else {
      setSelectedMembers((prev: any) => {
        return prev.filter(
          (member: any) => member.id !== event.target.element.value
        );
      });
    }
  };

  return (
    <>
      <div className="overflow-y-auto">
        <div className="card shadow-elevation8 py-1 relative z-10 w-full">
          <div className="p-2">
            <h3 className="text-[16px] text-txtPrimary tracking-[-0.08px] font-kanit font-medium">
              Call-Off Members
            </h3>
          </div>
          <div className="pl-2">
            {members &&
              members.length > 0 &&
              members.map((x: any, index: any) => {
                return (
                  <>
                    {commonState?.selectedCallOff.status === "Released" && (
                      <h4
                        key={index}
                        className="text-[11px] py-1 border-t border-neutral-30 text-txtPrimary tracking-[-0.10px] font-light"
                      >
                        {x.name}
                      </h4>
                    )}
                    {commonState?.selectedCallOff.status !== "Released" && (
                      <h4
                        key={index}
                        className="text-[11px] py-1 border-t border-neutral-30 text-txtPrimary tracking-[-0.10px] font-light"
                      >
                        <Checkbox
                          value={x.id}
                          label={x.name}
                          onChange={handleCheckboxSelectionChange}
                        />
                      </h4>
                    )}
                  </>
                );
              })}
            {members && members.length == 0 && (
              <h4 className="text-[14px] py-2 text-txtPrimary tracking-[-0.10px] font-light">
                No Member Exists
              </h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CallOffMembers;
