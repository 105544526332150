import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { Plus, X } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import BBSDatePicker from "../../../component/inputs/BBSDatePicker";
import BBSTextField from "../../../component/inputs/BBSTextField";
import { IBBS } from "../../../interfaces/bbs.interface";
import { IObjectType } from "../../../interfaces/objectType.interface";
import { IDetailingOffice } from "../../../interfaces/project.interface";
import objectTypeSvc from "../../../services/objectType.service";
import projectSvc from "../../../services/project.service";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import {
  ICallOff,
  ICallOffMembers,
} from "../../../interfaces/callOff.interface";
import {
  getSelectedState,
  Grid,
  GridColumn,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import SearchBox from "../../../component/ui/SearchBox";
import { getter } from "@progress/kendo-react-common";
import { State } from "@progress/kendo-data-query";
import { debounce } from "lodash";
import bbsSvc from "../../../services/bbs.service";
import { ISearchFilter } from "../../../interfaces/searchFilter.interface";
import { IMember } from "../../../interfaces/member.interface";
import memberSvc from "../../../services/member.service";
import { elementOffset } from "@progress/kendo-drawing/dist/npm/util";
import callOffSvc from "../../../services/callOff.service";
import { useDispatch } from "react-redux";
import { setToast } from "../../../store/features/toastSlice";
import { prevView } from "@progress/kendo-react-dateinputs/messages";
import { resetSelectedCallOff } from "../../../store/features/commonSlice";

const DATA_ITEM_KEY: string = "id";

interface IProps {
  showNoOfMemberDrawer: boolean;
  setShowNoOfMemberDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  callOff: (ICallOff & { id: string }) | null;
  fetchCallOff: any;
  releaseCallOffAfterNoOfMem: boolean;
  setReleaseCallOffAfterNoOfMem: React.Dispatch<React.SetStateAction<boolean>>;
  releaseCallOff: any;
  setEditCallOff: any;
  // handleChange: (
  //   e: TextBoxChangeEvent | ComboBoxChangeEvent | DatePickerChangeEvent,
  //   isDropdown?: boolean
  // ) => void;
  // onSave: () => void;
}

function ManageNoOfMemberDrawer({
  showNoOfMemberDrawer,
  setShowNoOfMemberDrawer,
  releaseCallOffAfterNoOfMem,
  setReleaseCallOffAfterNoOfMem,
  callOff,
  setEditCallOff,
  releaseCallOff,
  fetchCallOff,
}: //handleChange,
//onSave,
IProps) {
  const commonState = useAppSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const validateSave = () => {
    return true;
  };

  const onSave = async () => {
    if (callOffMembers.length > 0) {
      var error = callOffMembers.filter((x) => {
        return x.noOfMem > x.memberNoOfMember - x.membersReleased;
      });

      if (error && error.length > 0) {
        dispatch(
          setToast({
            toastMsg: "Members in batch cannot be more than no of members",
            toastType: "error",
          })
        );
        return;
      }

      error = callOffMembers.filter((x) => {
        return !x.noOfMem || x.noOfMem === 0;
      });

      if (error && error.length > 0) {
        dispatch(
          setToast({
            toastMsg: "Members in batch cannot be 0",
            toastType: "error",
          })
        );
        return;
      }

      var payload = {
        ...callOff,
        members: callOffMembers.map((x) => {
          return {
            id: x.id,
            noOfMem: x.noOfMem,
          };
        }),
      };

      var res = await callOffSvc.update(payload.id as string, payload);

      if (res) {
        fetchCallOff();
        dispatch(resetSelectedCallOff());

        // setEditCallOff((prev: any) => {
        //   return {
        //     ...prev,
        //     members: callOffMembers.map((x) => {
        //       return {
        //         ...x,
        //         noOfMem: x.noOfMem,
        //       };
        //     }),
        //   };
        // });

        if (releaseCallOffAfterNoOfMem) {
          releaseCallOff();
          setReleaseCallOffAfterNoOfMem(false);
        }

        setCallOffMembers([]);
        setShowNoOfMemberDrawer(false);
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Members updated successfully",
          })
        );
      } else {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: "Unable to update members",
          })
        );
      }
    }
  };

  const [callOffMembers, setCallOffMembers] = useState<any[]>([]);

  useEffect(() => {
    if (callOff && showNoOfMemberDrawer) {
      memberSvc.getByCallOffNoOfMem(callOff?.id).then((x: any) => {
        if (x.data) {
          setCallOffMembers(x.data);
        }
      });
    }
  }, [callOff, showNoOfMemberDrawer]);

  useEffect(() => {
    if (commonState?.selectedProject?.event?.item) {
    }
  }, [commonState?.selectedProject]);

  useEffect(() => {
    if (showNoOfMemberDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showNoOfMemberDrawer]);

  const itemChange = (e: GridItemChangeEvent) => {
    let newData = callOffMembers.map((item: any) => {
      if (item.id === e.dataItem.id) {
        item[e.field || ""] = e.value;
      }
      return item;
    });
    setCallOffMembers(newData);
  };

  return (
    <Drawer
      expanded={showNoOfMemberDrawer}
      position={"start"}
      mode={"overlay"}
      animation={{ duration: 400 }}
      className="customDrawer2 transition-all duration-500"
    >
      <DrawerContent>
        <div
          className="overlay"
          onClick={() => setShowNoOfMemberDrawer(false)}
        ></div>
        <div
          className={`max-w-[550px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-screen w-full transform transition-all duration-500 overflow-y-scroll ${
            showNoOfMemberDrawer ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
            <h2 className="font-medium text-lg">Update No of Members</h2>
            <Button
              fillMode="flat"
              className="p-2 bg-none outline-none"
              onClick={() => setShowNoOfMemberDrawer(false)}
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
          <div className="flex-1 py-4">
            <div className="grid gap-4">
              <div className="callOffMembers-Grid">
                <Grid
                  editField="inEdit"
                  onItemChange={itemChange}
                  style={{ width: "100%", maxWidth: "100vw", height: "500px" }}
                  size="small"
                  // dataItemKey={DATA_ITEM_KEY}
                  //onDataStateChange={dataStateChange}
                  className={`customHeight employee`}
                  scrollable="scrollable"
                  data={callOffMembers?.map((item) =>
                    Object.assign({ inEdit: true }, item)
                  )}
                >
                  {/* <GridColumn field="id" title="Id" editable={false} /> */}
                  <GridColumn field="name" title="Name" editable={false} />
                  <GridColumn
                    field="memberNoOfMember"
                    width={125}
                    title="Members"
                    editable={false}
                  />
                  <GridColumn
                    field="membersReleased"
                    title="Released"
                    editable={false}
                    width={125}
                  />
                  <GridColumn
                    field="noOfMem"
                    title="Batch"
                    width={125}
                    editor="numeric"
                  />
                </Grid>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 pb-4 sticky-buttons">
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              onClick={() => {
                setShowNoOfMemberDrawer(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              themeColor={"primary"}
              onClick={onSave}
              disabled={!validateSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default ManageNoOfMemberDrawer;
