import { SortDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import {
  ClipboardActionType,
  ClipboardDataEvent,
  ColumnBaseProps,
  populateClipboardData,
} from "@progress/kendo-react-data-tools";
import {
  GridColumn as Column,
  Grid,
  GridCustomCellProps,
  GridHandle,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridKeyDownEvent,
  GridRowDoubleClickEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
  getSelectedState,
  getSelectedStateFromKeyDown,
} from "@progress/kendo-react-grid";
import {
  BadgePlus,
  ClipboardPaste,
  Copy,
  SeparatorHorizontal,
  SeparatorVertical,
  SeparatorVerticalIcon,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IMemberDetail,
  IMemberLine,
} from "../../../interfaces/member.interface";
import { IShapeCodes } from "../../../interfaces/shapes.interface";
import {
  IBar,
  IBarSize,
  IStandard,
} from "../../../interfaces/standard.interface";
import { CustomDialogBox } from "../../../shared-components/dialog-box";
import { setToast } from "../../../store/features/toastSlice";
import {
  clearCopiedUnits,
  clearSelectedUnits,
  setCopiedUnits,
  setSelectedUnits,
} from "../../../store/features/unitsSlice";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { getDialogInfo } from "../../../utils/dialog.utils";
import { BarsizeDropdownCell } from "./BarsizeDropdownCell";
import { columnInterface, generateColumns } from "./MembersListingColumns";
import { ShapeCodeDropdownCell } from "./ShapeCodeDropdownCell";
import shapesSvc from "../../../services/shapes.service";

interface IProps {
  gridData: IMemberLine[];
  onChange: (gridLines: IMemberLine[]) => void;
  shapeCodes: IShapeCodes[];
  selectedShape: IShapeCodes | undefined;
  setSelectedShape: React.Dispatch<
    React.SetStateAction<IShapeCodes | undefined>
  >;
  standard: IStandard | null;
  editID: string | null;
  setEditID: (id: string | null) => void;
  handleBtnDisable: () => boolean;
  handleCancelBtnDisable: () => boolean;
  updateMember: () => void;
  deleteMemberLines: (lines: IMemberLine[]) => void;
  cancelUpdate: () => void;
  errMsg: string;
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
  memberIsActive: boolean;
  memberIsPart: boolean;
  memberIsReleased: boolean;
  copiedItems: any;
  setCopiedItems: any;
  pastedItems: any;
  setPastedItems: any;
  selectedState: any;
  setSelectedState: any;
  memberDetail?: IMemberDetail;
}

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
let mappingChanged = false;
const GROUP_SUBITEMS_COLLECTION = "items";
const idGetter = getter(DATA_ITEM_KEY);

const dimensions = [
  "dimA",
  "dimB",
  "dimC",
  "dimD",
  "dimE",
  "dimF",
  "dimG",
  "dimH",
  "dimK",
  "dimJ",
  "dimR",
  "dimI",
  "dimH2",
  "dimO",
];

const initialSort: Array<SortDescriptor> = [{ field: "mark", dir: "asc" }];

function MembersListing({
  gridData,
  onChange,
  shapeCodes,
  setSelectedShape,
  standard,
  editID,
  setEditID,
  updateMember,
  deleteMemberLines,
  cancelUpdate,
  errMsg,
  setErrMsg,
  memberIsActive,
  memberIsPart,
  memberIsReleased,
  memberDetail,
  copiedItems,
  setCopiedItems,
  selectedState,
  setSelectedState,
}: IProps) {
  const dispatch = useDispatch();
  const [editableDimensions, setEditableDimensions] = useState<string[]>([]);
  const commonState = useAppSelector((state: RootState) => state.common);
  const [sort, setSort] = useState(initialSort);
  const gridDataTemp = useRef<IMemberLine[] | null>(null);
  const [lines, setLines] = useState<IMemberLine[]>([]);

  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const gridRef = React.useRef<GridHandle>(null);

  const handleCopyBtnDisable = () => {
    if (selectedState) {
      return false;
    }

    return true;
  };

  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      onColumnsSubmit,
      standard,
      null,
      columnsMapping,
      mappingChanged
    )
  );

  const onSelectionChange = (event: GridSelectionChangeEvent) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    dispatch(setSelectedUnits(newSelectedState));
    setSelectedState(newSelectedState);
  };

  const onHeaderSelectionChange = React.useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState: any = {};

      event.dataItems.forEach((item: any) => {
        newSelectedState[idGetter(item)] = checked;
      });
      dispatch(setSelectedUnits(newSelectedState));
      setSelectedState(newSelectedState);
    },
    []
  );

  const onKeyDown = (event: GridKeyDownEvent) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    dispatch(setSelectedUnits(newSelectedState));
    setSelectedState(newSelectedState);
  };

  const rowClick = (event: GridRowDoubleClickEvent) => {
    if (memberIsActive && !memberIsPart) {
      event.dataItem.inEdit = true;
      setEditID(event.dataItem.id);

      var shapeCode = shapeCodes.find(
        (item) => item.code === event.dataItem.shapeCode
      );

      if (shapeCode) {
        const allowedDimensions: string[] = [];
        dimensions.forEach((x) => {
          if (shapeCode![x as keyof IShapeCodes]) {
            allowedDimensions.push(x);
          }
        });
        setEditableDimensions(allowedDimensions);

        fetchShapeImg(event.dataItem.shapeCode);
      } else {
        setEditableDimensions([]);
      }
    }
  };

  const fetchShapeImg = async (shapeCode: string) => {
    if (!shapeCode) return;
    const res: any = await shapesSvc.getImgByStandardId(shapeCode);
    if (res?.data) {
      setSelectedShape(res.data);
    }
  };

  const calculateValue = (line: IMemberLine, prevLines: IMemberLine[]) => {
    const barsSizes: IBarSize[] = standard?.barSizes!;
    const bars: IBar[] = standard?.bars!;
    const shapeCodeObj = shapeCodes?.find(
      (shape: IShapeCodes) => shape.code === line.shapeCode
    );

    if (line.id?.includes("new") && !shapeCodeObj) {
      return;
    }

    if (!shapeCodeObj) {
      setErrMsg(`ShapeCode ${line.shapeCode} not found`);
      // dispatch(
      //   setToast({
      //     toastType: "error",
      //     toastMsg: `ShapeCode ${line.shapeCode} not found`,
      //   })
      // );
      setLines(prevLines);
      //setEditID(null);
      //return;
    }

    const dimensions = {
      A: line.dimA,
      B: line.dimB,
      C: line.dimC,
      D: line.dimD,
      E: line.dimE,
      F: line.dimF,
      G: line.dimG,
      H: line.dimH,
      J: line.dimJ,
      K: line.dimK,
      O: line.dimO,
      R: line.dimR,
      I: line.dimI,
      H2: line.dimH2,
    };

    const barSizeObj = barsSizes.find((x) => x.size === Number(line.size));
    const bar = bars.find((x) => x.typeCode === line.type);

    if (barSizeObj && bar && shapeCodeObj) {
      const { radius, size, unitWeight } = barSizeObj;
      const { n, h } = bar;
      const { formula, condition } = shapeCodeObj;

      if (condition) {
        let conditionToEvaluate = condition;

        for (const [key, value] of Object.entries(dimensions)) {
          const regex = new RegExp(`\\b${key}\\b`, "g");
          conditionToEvaluate = conditionToEvaluate.replace(
            regex,
            String(value)
          );
        }

        conditionToEvaluate = conditionToEvaluate
          .replace(/r/g, String(radius))
          .replace(/d/g, String(size))
          .replace(/n/g, String(n))
          .replace(/h/g, String(h));

        try {
          if (conditionToEvaluate && !eval(conditionToEvaluate)) {
            return { ...line, isInvalidBarLength: true };
          }
        } catch (error) {
          setErrMsg(
            `Error evaluating condition for line with shape code ${line.shapeCode}: ${error}`
          );
          // dispatch(
          //   setToast({
          //     toastType: "error",
          //     toastMsg: `Error evaluating condition for line with shape code ${line.shapeCode}: ${error}`,
          //   })
          // );
          setLines(prevLines);
          //setEditID(null);
          //return;
        }
      }

      let length: string | number = formula;
      for (const [key, value] of Object.entries(dimensions)) {
        const regex = new RegExp(`\\b${key}\\b`, "g");
        length = length.replace(regex, String(value));
      }

      length = length
        .replace(/r/g, String(radius))
        .replace(/d/g, String(size))
        .replace(/n/g, String(n))
        .replace(/h/g, String(h));

      try {
        length = eval(length);
      } catch (error) {
        setErrMsg(
          `Error evaluating condition for line with shape code ${line.shapeCode}: ${error}`
        );
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Error evaluating formula for line with shape code ${line.shapeCode}: ${error}`,
        //   })
        // );
        setLines(prevLines);
        //setEditID(null);
        //return;
      }

      let totalNumbers = 0;

      if (!unitWeight) {
        setErrMsg(`Unit weight for bar size ${line.size} not found`);
      }

      if (totalNumbers == 0) {
        totalNumbers = line.noInEach;
      }

      var roundedLength = Math.ceil(Number(length) / 25) * 25;

      const weight = (unitWeight * totalNumbers * roundedLength) / 1000;
      if (length > commonState.selectedProject.event.item.barLength) {
        setErrMsg(
          `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`
        );
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`,
        //   })
        // );
        setLines(prevLines);
        //setEditID(null);
        //return;
      }
      return {
        ...line,
        length: roundedLength,
        weight: weight,
        totalNo: line.noInEach * Number(line.noOfMem),
      };
    }
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const inEditID = event.dataItem.id;
    const field = event.field || "";
    const id = lines.findIndex((item) => item.id === inEditID);
    const otherLines = lines.filter((item) => item.id !== inEditID);

    if (dimensions.includes(field)) {
      if (event.dataItem.shapeCode) {
        const shapeCode = shapeCodes.find(
          (item) => item.code === event.dataItem.shapeCode
        );
        if (!shapeCode) {
          setErrMsg(
            `Shape code ${event.dataItem.shapeCode} is not available in the selected standard`
          );
          setEditableDimensions([]);
          return;
        }

        if (shapeCode[field as keyof IShapeCodes] && !Number(event.value)) {
          setErrMsg(`Please enter a valid value for ${field}`);
          return;
        }
      }
      event.value = Number(event.value);
    }

    if (field === "mark") {
      var hasSameMark = otherLines.find((item) => item.mark == event.value);
      if (hasSameMark) {
        setErrMsg(`Mark ${event.value} already exists`);
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Mark ${event.dataItem.mark} already exists`,
        //   })
        // );
        event.value = event.dataItem.mark;
        // setLines(lines);
        return;
      }
    }

    if (field === "length") {
      if (event.value > commonState.selectedProject.event.item.barLength) {
        setErrMsg(
          `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`
        );
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`,
        //   })
        // );
        //setEditID(null);
        //return;
      }
    }

    if (field === "noOfMem") {
      if (event.value !== 1) {
        setErrMsg(`No. of members should be 1`);
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `No. of members should be 1`,
        //   })
        // );
        //setEditID(null);
        //return;
      }
    }

    if (field === "shapeCode") {
      var shapeCode = shapeCodes.find((item) => item.code === event.value);
      if (event.value > 1 && !shapeCode) {
        setErrMsg(
          `Shape code ${event.value} is not available in the selected standard`
        );
        setEditableDimensions([]);
      }
      fetchShapeImg(event.value);
      const allowedDimensions: string[] = [];
      dimensions.forEach((x) => {
        if (shapeCode![x as keyof IShapeCodes]) {
          allowedDimensions.push(x);
        }
      });
      setEditableDimensions(allowedDimensions);
    }

    if (id !== -1) {
      const prevLines = JSON.parse(JSON.stringify(lines));
      const prevLine = { ...lines[id] };

      (lines[id] as any)[field] = event.value;

      if (field === "shapeCode") {
        dimensions.forEach((x) => {
          if (!shapeCode![x as keyof IShapeCodes]) {
            (lines[id] as any)[x as keyof IShapeCodes] = 0;
          }
        });
      }

      if (lines[id]?.id?.includes("new")) {
        const shapeCodeObj = shapeCodes?.find(
          (shape: IShapeCodes) => shape.code === lines[id].shapeCode
        );
        if (!shapeCodeObj) {
          onChange(lines);
          return;
        }
      }

      const afterCalculation = calculateValue(lines[id], prevLines);

      if (afterCalculation) {
        lines[id] = afterCalculation;
        onChange(lines);
      } else {
        if (!lines[id]?.id?.includes("new")) {
          lines[id] = prevLine;
        }
        onChange(lines);
      }
    }
  };

  useEffect(() => {
    if (!gridData || gridData.length === 0) {
      setLines([]);
      return;
    }

    const cols = generateColumns(
      onColumnsSubmit,
      standard,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
    setLines(gridData);
  }, [gridData, standard]);

  useEffect(() => {
    if (gridData?.length) {
      gridDataTemp.current = JSON.parse(JSON.stringify(gridData));
    }
  }, [gridData]);
  const [modalVisible, setModalVisible] = React.useState(false);

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      onColumnsSubmit,
      standard,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  useEffect(() => {
    if (errMsg) {
      const timer = setTimeout(() => {
        setErrMsg("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errMsg]);

  const handleCopyClick = () => {
    const selectedItems = lines.filter((line) => selectedState[line?.id!]);
    const itemsToCopy = selectedItems.map((item) => ({
      ...item,
      id: `Copy-${item.id}`,
      mark: `Copy-${item.mark}`,
      selected: false,
      pasted: true,
    }));

    dispatch(setCopiedUnits(itemsToCopy));

    handleClipboard({
      type: ClipboardActionType.copy,
      columns: gridRef.current!.columns as ColumnBaseProps[],
      dataItemKey: DATA_ITEM_KEY,
    });
  };

  const onDeleteClick = () => {
    if (selectedState) {
      setModalVisible(!modalVisible);
    }
  };

  const handleDeleteClick = async (action: string) => {
    if (action === "yes") {
      let updatedLines: IMemberLine[] = [];
      const localLinesToBeRemoved = lines.filter(
        (line) =>
          selectedState[line.id!] && (line.id!.includes("new") || line.isNew)
      );

      if (localLinesToBeRemoved.length > 0) {
        const newLines = lines.filter(
          (line) =>
            !selectedState[line.id!] ||
            (!line.id!.includes("new") && !line.isNew)
        );
        // onChange(newLines);
        updatedLines = [...newLines];
      }

      const linesToRemove = lines.filter(
        (line) =>
          selectedState[line.id!] && !line.id!.includes("new") && !line.isNew
      );

      if (linesToRemove.length) {
        deleteMemberLines(linesToRemove);
        const newLines = lines.filter((line) => !selectedState[line.id!]);
        updatedLines = newLines;
      }

      onChange(updatedLines);
    }

    setModalVisible(!modalVisible);
    dispatch(clearSelectedUnits());
  };

  const handlePasteClick = () => {
    if (copiedItems) {
      // setLines((prev: any) => {
      //   return [
      //     ...prev,
      //     ...copiedItems.map((x: any) => {
      //       return {
      //         ...x.dataItem,
      //         id: `Copy - ${x.dataItem.id}`,
      //         mark: `Copy - ${x.dataItem.mark}`,
      //         selected: false,
      //       };
      //     }),
      //   ];
      // });
      const newItems = copiedItems
        .filter((x: any) => x.dataItem.selected)
        .map((x: any) => {
          const exists = lines.some(
            (existing) => existing.id === `Copy - ${x.dataItem.id}`
          );
          return exists
            ? null
            : {
                ...x.dataItem,
                isNew: true,
                id: `Copy - ${x.dataItem.id}`,
                mark: `Copy - ${x.dataItem.mark}`,
                selected: false,
                pasted: true,
              };
        })
        .filter(Boolean); // Remove null values

      onChange([...lines, ...newItems]);
      setCopiedItems(null);
      dispatch(clearCopiedUnits());
    }
  };

  const handleClipboard = async (event: ClipboardDataEvent) => {
    let pageData = lines.map((x) => {
      if (
        selectedState.hasOwnProperty(x.id!) &&
        selectedState[x.id!] === true
      ) {
        return { ...x, selected: true };
      }
      return x;
    });

    const gridClipboardData = await populateClipboardData({
      event,
      data: pageData,
      selectedState,
    });

    setCopiedItems(gridClipboardData.copiedItems);

    dispatch(
      setToast({
        toastMsg: "Copied to clipboard.",
        toastType: "success",
      })
    );
  };

  const CustomCell = (props: GridCustomCellProps) => {
    const [bgColor, setBgColor] = React.useState("");

    React.useEffect(() => {
      const isFieldAffected = props.dataItem.pasted;

      if (isFieldAffected) {
        setBgColor("#edebe9");
      }
    }, [props.dataItem.clipboardAction]);

    return (
      <td
        {...props.tdProps}
        style={{
          backgroundColor: bgColor,
        }}
      >
        {props.children}
      </td>
    );
  };

  const addNewRow = () => {
    var id = crypto.randomUUID().toString() + "-new";
    const newLine: IMemberLine = {
      idx: lines.length + 1,
      name: "",
      detail: "",
      type: commonState?.selectedProject?.event?.item?.barType,
      qty: 0,
      barRev: "",
      callOffId: "",
      status: "",
      remarks: "",
      isActive: true,
      logs: [],
      id: id,
      memberId: memberDetail!.member!.id,
      mark: "1",
      size: "",
      shapeCode: "",
      dimA: 0,
      dimB: 0,
      dimC: 0,
      dimD: 0,
      dimE: 0,
      dimF: 0,
      dimG: 0,
      dimH: 0,
      dimJ: 0,
      dimK: 0,
      dimO: 0,
      dimR: 0,
      dimI: 0,
      dimH2: 0,
      noOfMem: 1,
      noInEach: 1,
      totalNo: 0,
      length: 0,
      weight: 0,
      selected: false,
    };
    onChange([...lines, newLine]);
    setEditID(id);
  };

  return (
    <>
      <div className="mb-5 w-full col-span-2">
        <div className="setting-page">
          <Grid
            ref={gridRef}
            style={{ minHeight: "200px", height: "650px" }}
            pageable={false}
            total={lines?.length || 0}
            sortable={false}
            // sort={sort}
            className="customHeight members-editor"
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            // selectable={{
            //   enabled: true,
            //   drag: true,
            //   cell: true,
            //   mode: "multiple",
            // }}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
            navigatable={true}
            editField="inEdit"
            onRowClick={rowClick}
            onItemChange={itemChange}
            clipboard={true}
            onClipboard={handleClipboard}
            onSelectionChange={onSelectionChange}
            onKeyDown={onKeyDown}
            onHeaderSelectionChange={onHeaderSelectionChange}
            // onHeaderSelectionChange={onHeaderSelectionChange}
            resizable={true}
            data={lines?.map((item: IMemberLine) => ({
              ...item,
              inEdit: item.id === editID,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}
            scrollable="scrollable"
            cells={{
              data: CustomCell,
            }}
          >
            {!memberIsPart && !memberIsReleased && memberIsActive && (
              <Column
                field={SELECTED_FIELD}
                width="50px"
                headerSelectionValue={
                  lines?.findIndex((item) => !selectedState[idGetter(item)]) ===
                  -1
                }
              />
            )}
            {stateColumns &&
              stateColumns.length > 0 &&
              stateColumns.map((column: any, id: number) => {
                var dimensionColumn = dimensions.some(
                  (x) => x === column.field
                );

                if (column.show) {
                  if (column.field == "size") {
                    return (
                      <Column
                        key={id}
                        field="size"
                        title={"Bar \r\n Size"}
                        width={100}
                        cell={(props) => (
                          <BarsizeDropdownCell
                            {...props}
                            barSizes={standard?.barSizes || []}
                            cells={{
                              data: CustomCell,
                            }}
                          />
                        )}
                      />
                    );
                  }

                  if (column.field == "shapeCode") {
                    return (
                      <Column
                        key={id}
                        field="shapeCode"
                        title={"Shape Code"}
                        width={125}
                        cell={(props) => (
                          <ShapeCodeDropdownCell
                            {...props}
                            shapeCodes={shapeCodes}
                          />
                        )}
                      />
                    );
                  }

                  if (dimensionColumn) {
                    return (
                      <Column
                        key={id}
                        field={column.field}
                        title={column.title}
                        width={85}
                        editable={editableDimensions.includes(column.field)}
                        // cell={(props) => (
                        //   <DImensionTextboxCell
                        //     {...props}
                        //     shapeCodes={shapeCodes}
                        //   />
                        // )}
                      />
                    );
                  }

                  if (column.cells) {
                    return (
                      <Column
                        key={id}
                        field={column.field}
                        title={column.title}
                        // columnMenu={column.columnMenu}
                        // minResizableWidth={200}
                        width={column.width}
                        cells={column.cells}
                        filter={column.filter ? column.filter : "text"}
                        editor={column.editor}
                        editable={column.editable}
                      />
                    );
                  }
                  return (
                    <Column
                      key={id}
                      field={column.field}
                      title={column.title}
                      // columnMenu={column.columnMenu}
                      // minResizableWidth={200}
                      width={column.width}
                      filter={column.filter ? column.filter : "text"}
                      editor={column.editor}
                      editable={column.editable}
                    />
                  );
                }
              })}
            <GridToolbar>
              <div className="flex gap-3 w-full items-center justify-between">
                {memberIsActive && !memberIsPart && !memberIsReleased && (
                  <div className="flex gap-x-3 w-full">
                    <Button
                      themeColor={"success"}
                      size={"small"}
                      onClick={addNewRow}
                    >
                      <span className="inline-flex items-center gap-2">
                        Add
                      </span>
                    </Button>

                    <Button themeColor={"primary"} onClick={updateMember}>
                      <span className="inline-flex items-center gap-2">
                        Save
                      </span>
                    </Button>
                    <Button themeColor={"dark"} onClick={() => onDeleteClick()}>
                      <span className="inline-flex items-center gap-2">
                        Delete
                      </span>
                    </Button>
                    <Button
                      onClick={cancelUpdate}
                      className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                    >
                      <span className="inline-flex items-center gap-2">
                        Cancel
                      </span>
                    </Button>

                    <div className="px-[0.5px] bg-gray-400 opacity-50"></div>

                    <Button
                      themeColor={"warning"}
                      size={"small"}
                      disabled={handleCopyBtnDisable()}
                      onClick={handleCopyClick}
                      // className="mb-3"
                    >
                      <span className="inline-flex items-center gap-2">
                        <Copy className="w-4 h-4" />
                      </span>
                    </Button>
                    <Button
                      themeColor={"warning"}
                      size={"small"}
                      disabled={handleCopyBtnDisable()}
                      onClick={handlePasteClick}
                      // className="mb-3"
                    >
                      <span className="inline-flex items-center gap-2">
                        <ClipboardPaste className="w-4 h-4" />
                      </span>
                    </Button>
                    {errMsg && (
                      <p className="text-lg text-red-400 text-left w-full font-bold">
                        {errMsg}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </GridToolbar>
          </Grid>

          {modalVisible && (
            <CustomDialogBox
              dialogInfo={getDialogInfo("delete")}
              onToggleDeleteDialog={handleDeleteClick}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MembersListing;
