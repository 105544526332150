import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  ComboBox,
  ComboBoxChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import {
  TextArea,
  TextAreaChangeEvent,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { ColumnMenu } from "../../../component/global/ColumnMenu";
import CustomLabel from "../../../component/global/RequiredMark";
import BBSTextField from "../../../component/inputs/BBSTextField";
import Reports from "../../../constants/reports";
import { ISubContractor } from "../../../interfaces/subcontractor.interface";
import projectSvc from "../../../services/project.service";
import subContractorSvc from "../../../services/subContractor.service";
import {
  setSelectedProject,
  triggerFetchProjects,
} from "../../../store/features/commonSlice";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { CommandCell } from "./CommandCell";
const initialSubcontractor = {
  id: "",
  to: "",
  cc: "",
  reports: [],
  address: "",
  location: "",
  deliveryComments: "",
};

const editField = "inEdit";

export const AddSubcontractors = ({
  showAddSubcontractorDrawer,
  setShowAddSubcontractorDrawer,
  projectToEdit,
  setProjectToEdit,
  fetchProjects,
}: any) => {
  const dispatch = useAppDispatch();
  const commonState = useAppSelector((state: RootState) => state.common);
  const [newSubcontractor, setSubcontractor] = useState(initialSubcontractor);
  const [isEditMode, setIsEditMode] = useState(false);
  const prevProjectSubcons = useRef<string>("");

  const [subcontractors, setSubcontractors] = useState<ISubContractor[]>([]);

  const [projectSubcontractors, setProjectSubcontractors] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<ISubContractor[]>([]);

  const fetchSubcontractors = () => {
    subContractorSvc.getAll().then((res) => {
      setSubcontractors(res.data);
    });
  };

  const handleValidation = (name: string, isValid: boolean) => {
    // setInvalidFields((prev: any) => ({
    //   ...prev,
    //   [name]: !isValid,
    // }));
  };

  const validateSubcontractor = () => {
    if (prevProjectSubcons.current !== JSON.stringify(projectSubcontractors))
      return true;

    if (!newSubcontractor) return false;
    if (!newSubcontractor.id || !newSubcontractor.to) return false;

    return true;
  };

  useEffect(() => {
    setSubcontractor(initialSubcontractor);
    setIsEditMode(false);
  }, [showAddSubcontractorDrawer]);

  const saveSubcontractor = async () => {
    try {
      let proj = JSON.parse(JSON.stringify(projectToEdit));

      if (isEditMode) {
        // Handle edit mode
        const updatedSubcontractors = projectSubcontractors.map((sub: any) =>
          sub.id === newSubcontractor.id ? newSubcontractor : sub
        );

        proj.subcontractors = updatedSubcontractors;
        setProjectSubcontractors(updatedSubcontractors);
      } else {
        // Handle add mode
        var exists = projectSubcontractors.some((element: any) =>
          Object.values(element).some((val) => val === newSubcontractor.id)
        );

        if (!exists) {
          proj.subcontractors = [newSubcontractor, ...projectSubcontractors];
          setProjectSubcontractors([
            newSubcontractor,
            ...projectSubcontractors,
          ]);
        } else {
          dispatch(
            setToast({
              toastType: "error",
              toastMsg: "Subcontractor already exists",
            })
          );
          return;
        }
      }

      await projectSvc.update(proj?.id!, proj).then((x) => {
        setSubcontractor(initialSubcontractor);
        setIsEditMode(false);

        let temp = JSON.parse(JSON.stringify(commonState.selectedProject));
        temp.event.item.subcontractors = proj.subcontractors;
        dispatch(setSelectedProject(temp));
        dispatch(triggerFetchProjects());
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (dataItem: any) => {
    setIsEditMode(true);
    setSubcontractor({
      ...dataItem,
      subcontractor: dataItem.subcontractor,
    });
  };

  useEffect(() => {
    if (showAddSubcontractorDrawer) fetchSubcontractors();
  }, [showAddSubcontractorDrawer]);

  useEffect(() => {
    if (projectToEdit?.subcontractors) {
      prevProjectSubcons.current = JSON.stringify(
        projectToEdit?.subcontractors
      );

      setProjectSubcontractors(projectToEdit?.subcontractors);
    }
  }, [subcontractors, projectToEdit]);

  const handleChange = (
    e:
      | TextBoxChangeEvent
      | ComboBoxChangeEvent
      | DatePickerChangeEvent
      | TextAreaChangeEvent
      | MultiSelectChangeEvent,
    isDropdown?: boolean
  ) => {
    if (e.target.name === "id") {
      setSubcontractor((prev: any) => {
        return {
          ...prev,
          [e.target.name as any]: e.target.value.id,
          subcontractor: e.target.value,
        };
      });
      return;
    }
    if (e.target.name === "reports") {
      setSubcontractor((prev: any) => {
        return {
          ...prev,
          reports: e.value,
        };
      });
      return;
    }
    setSubcontractor((prev: any) => {
      return {
        ...prev,
        [e.target.name as any]: isDropdown ? e.target.value.id : e.target.value,
      };
    });
  };

  const getSubcontractorValue = () => {
    const subcontractor = subcontractors?.find(
      (std) => std.id === newSubcontractor?.id
    );
    if (!subcontractor) return "";
    return subcontractor;
  };
  // const initialRender = useRef(false);

  // useEffect(() => {
  //   if (initialRender.current === true || !showAddSubcontractorDrawer) return;
  //   let temp = [...projectSubcontractors];
  //   temp = temp.map((item: any, index: number) => {
  //     return {
  //       ...item,
  //       id: index,
  //     };
  //   });
  //   setProjectSubcontractors(temp);
  //   if (temp.length) {
  //     initialRender.current = true;
  //   }
  // }, [projectSubcontractors, showAddSubcontractorDrawer]);

  const remove = (dataItem: any) => {
    const newData = structuredClone(projectSubcontractors);
    const index = newData.findIndex((x: any) => {
      return x.id === dataItem.id;
    });
    newData.splice(index, 1);
    setProjectSubcontractors(newData);
  };

  const commandCellProps = {
    remove: remove,
    edit: handleEdit,
  };

  const ActionCell = (props: any) => {
    const { edit, remove, add, discard, update, cancel, editField } = props;
    return <CommandCell {...props} remove={remove} showEdit />;
  };

  const filterData = (filter: string) => {
    if (!filter) {
      return subcontractors;
    }

    return subcontractors.filter(
      (item) =>
        item.name.toLowerCase().includes(filter.toLowerCase()) ||
        (item.name && item.name.toLowerCase().includes(filter.toLowerCase()))
    );
  };

  const onFilterChange = (event: any) => {
    setFilteredData(filterData(event.filter.value));
  };

  return (
    <>
      <Drawer
        expanded={showAddSubcontractorDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500"
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() =>
              setShowAddSubcontractorDrawer(!showAddSubcontractorDrawer)
            }
          ></div>
          <div
            className={`max-w-[1024px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showAddSubcontractorDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Subcontractors</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() =>
                  setShowAddSubcontractorDrawer(!showAddSubcontractorDrawer)
                }
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div>
                    <CustomLabel label="Subcontractor" required={true} />
                    <ComboBox
                      filterable={true}
                      data={
                        filteredData.length > 0 ? filteredData : subcontractors
                      }
                      placeholder="Select Subcontractor"
                      name="id"
                      value={getSubcontractorValue()}
                      onFilterChange={onFilterChange}
                      onChange={(e) => handleChange(e, true)}
                      disabled={false}
                      clearButton={false}
                      dataItemKey="id"
                      textField="name"
                      suggest={true}
                    />
                  </div>
                  <div className="mt-2">
                    <BBSTextField
                      name="location"
                      value={newSubcontractor?.location}
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Location"
                      required={false}
                      placeholder="Enter Location"
                      disabled={false}
                      error="Invalid Location"
                    />
                  </div>
                  <div className="mt-2">
                    <CustomLabel label="Address" required={false} />
                    <TextArea
                      name="address"
                      value={newSubcontractor?.address}
                      onChange={handleChange}
                      required={false}
                      placeholder="Enter Address"
                      disabled={false}
                      rows={3}
                    />
                  </div>
                  <div className="mt-2">
                    <CustomLabel label="Reports" required={false} />
                    <MultiSelect
                      autoClose={false}
                      data={Reports}
                      name="reports"
                      onChange={handleChange}
                      value={newSubcontractor?.reports}
                      placeholder="Please select ..."
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <BBSTextField
                      name="to"
                      value={newSubcontractor?.to}
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="To"
                      required={true}
                      placeholder="Enter To"
                      disabled={false}
                      error="Invalid To"
                    />
                  </div>
                  <div className="mt-2">
                    <BBSTextField
                      name="cc"
                      value={newSubcontractor?.cc}
                      onChange={handleChange}
                      onValidate={handleValidation}
                      label="Cc"
                      required={false}
                      placeholder="Enter Cc"
                      disabled={false}
                      error="Invalid Cc"
                    />
                  </div>
                  <div className="mt-2">
                    <CustomLabel label="Delivery Comments" required={false} />
                    <TextArea
                      name="deliveryComments"
                      value={newSubcontractor?.deliveryComments}
                      onChange={handleChange}
                      required={false}
                      placeholder="Enter Delivery Comments"
                      disabled={false}
                      rows={3}
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-4 mt-5">
                <div>
                  <Grid
                    pageable={false}
                    sortable={false}
                    filterable={false}
                    style={{ height: "400px" }}
                    size={"small"}
                    groupable={false}
                    scrollable="none"
                    data={projectSubcontractors}
                  >
                    <Column
                      field="subcontractor.name"
                      title="Name"
                      columnMenu={ColumnMenu}
                      width={200}
                    />
                    <Column
                      field="address"
                      title="Address"
                      columnMenu={ColumnMenu}
                      width={300}
                    />
                    <Column field="to" title="To" columnMenu={ColumnMenu} />
                    <Column field="cc" title="Cc" columnMenu={ColumnMenu} />
                    <Column
                      cell={(props) => (
                        <ActionCell {...props} {...commandCellProps} />
                      )}
                      width="100px"
                    />
                  </Grid>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => {
                  setSubcontractor(initialSubcontractor);
                  setShowAddSubcontractorDrawer(!showAddSubcontractorDrawer);
                }}
              >
                Close
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                disabled={!validateSubcontractor()}
                onClick={saveSubcontractor}
              >
                {isEditMode ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
