import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import {
  getSelectedState,
  Grid,
  GridColumn,
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { debounce } from "lodash";
import { Plus, X } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SearchBox from "../../../component/ui/SearchBox";
import { IBBS } from "../../../interfaces/bbs.interface";
import { ICallOff } from "../../../interfaces/callOff.interface";
import { IMember } from "../../../interfaces/member.interface";
import { ISearchFilter } from "../../../interfaces/searchFilter.interface";
import bbsSvc from "../../../services/bbs.service";
import callOffSvc from "../../../services/callOff.service";
import memberSvc from "../../../services/member.service";
import {
  resetSelectedCallOff,
  setSelectedCallOff,
} from "../../../store/features/commonSlice";
import { setToast } from "../../../store/features/toastSlice";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

interface IProps {
  showManageMemberDrawer: boolean;
  setShowManageMemberDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  callOff: (ICallOff & { id: string }) | null;
  fetchCallOff: any;
  // handleChange: (
  //   e: TextBoxChangeEvent | ComboBoxChangeEvent | DatePickerChangeEvent,
  //   isDropdown?: boolean
  // ) => void;
  // onSave: () => void;
}

function ManageMemberDrawer({
  showManageMemberDrawer,
  setShowManageMemberDrawer,
  callOff,
  fetchCallOff,
}: //handleChange,
//onSave,
IProps) {
  const commonState = useAppSelector((state: RootState) => state.common);
  const dispatch = useDispatch();
  const removeAllClicked = useRef(false);

  const [filterQuery, setFilterQuery] = useState<any>({
    filters: [
      {
        logic: "and",
        filters: [
          {
            field: "isActive",
            operator: "eq",
            value: true,
          },
        ],
      },
    ],
  });

  const editFilterQuery = (showAll?: boolean) => {
    const { item } = commonState?.selectedProject?.event || {};
    const filterQueryTemp = {
      filters: [
        {
          logic: "and",
          filters: [
            {
              field: "isActive",
              operator: "eq",
              value: true,
            },
          ],
        },
      ],
    };

    if (item) {
      const { projectId } = item;

      const filterExists = (field: any) => {
        return filterQueryTemp.filters[0].filters.some(
          (filter: any) => filter.field === field
        );
      };
      const removeFilter = (field: any) => {
        filterQueryTemp.filters[0].filters =
          filterQueryTemp.filters[0].filters.filter(
            (filter: any) => filter.field !== field
          );
      };

      if (filterExists("projectId")) {
        removeFilter("projectId");
      }
      filterQueryTemp.filters[0].filters.push({
        field: "projectId",
        operator: "eq",
        value: projectId,
      });
    }
    setFilterQuery(filterQueryTemp);
  };

  const [selectedBbsState, setSelectedBbsState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  const [selectedMemberState, setSelectedMemberState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  const [selectedCallOffMemberState, setSelectedCallOffMemberState] = useState<{
    [id: string]: boolean | number[];
  }>({});
  //

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      debouncedSearch(value);
    }
  };

  const fetchBBS = (searchQuery?: string, filters?: any) => {
    let searchFilters: ISearchFilter[] = [];
    const { item } = commonState?.selectedProject?.event || {};
    if (searchQuery) {
      searchFilters = [
        { field: "Name", operator: "contains", value: searchQuery },
        { field: "No", operator: "contains", value: searchQuery },
      ];
    }
    if (filters?.filters?.length) {
      const index = filters.filters[0].filters.findIndex(
        (filter: any) => filter.field === "isActive"
      );
      const projIdx = filters.filters[0].filters.findIndex(
        (filter: any) => filter.field === "projectId"
      );
      if (projIdx === -1) return;
      if (index === -1) {
        filters.filters[0].filters.push({
          field: "isActive",
          operator: "eq",
          value: true,
        });
      }

      if (filters.filters.length > 1) {
        const search = filters.filters[1].filters.findIndex(
          (filter: any) => filter.field === "Name" || filter.field === "No"
        );

        if (search != -1) {
          filters.filters[0].filters.splice(search, 1);
        }
      }
    } else {
      if (item) {
        const { projectId } = item;

        filters = {
          filters: [
            {
              logic: "and",
              filters: [
                {
                  field: "projectId",
                  operator: "eq",
                  value: projectId,
                },
              ],
            },
          ],
        };

        filters.filters[0].filters.push({
          field: "isActive",
          operator: "eq",
          value: true,
        });
      }
    }

    bbsSvc.get(searchFilters, filters).then((res) => {
      setBbsList(res.data);
    });
  };

  const debouncedSearch = useRef(
    debounce((value: string) => fetchBBS(value), 500)
  ).current;

  const validateSave = () => {
    return true;
  };

  const onSave = async () => {
    var payload = {
      ...callOff,
      members: callOffMembers.map((x) => {
        return {
          id: x.id,
          noOfMem: x.noOfMem,
        };
      }),
    };

    var res = await callOffSvc.update(payload.id as string, payload);

    if (res) {
      setBbs(null);
      setBbsList([]);
      setBbsMembers([]);
      // if (callOff) {
      //   callOff.members = callOffMembers.map((x) => {
      //     return {
      //       id: x.id,
      //       noOfMem: x.noOfMem,
      //     };
      //   });

      //   dispatch(setSelectedCallOff(callOff));
      // }
      dispatch(
        setToast({
          toastType: "success",
          toastMsg: "Members updated successfully",
        })
      );
      fetchCallOff();
      dispatch(resetSelectedCallOff());
      setShowManageMemberDrawer(false);
    } else {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: "Unable to update members",
        })
      );
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const [bbs, setBbs] = useState<IBBS | null>();
  const [bbsList, setBbsList] = useState<IBBS[]>([]);
  const [bbsMembers, setBbsMembers] = useState<IMember[]>([]);
  const [callOffMembers, setCallOffMembers] = useState<IMember[]>([]);

  useEffect(() => {
    if (showManageMemberDrawer) {
      setSelectedBbsState({});
      setSelectedMemberState({});
      setBbsMembers([]);
      fetchBBS(searchValue, filterQuery);
    }
  }, [filterQuery, searchValue, showManageMemberDrawer]);

  useEffect(() => {
    if (commonState?.selectedProject?.event?.item) {
      editFilterQuery();
    }
  }, [commonState?.selectedProject]);

  useEffect(() => {
    if (bbs && callOff) {
      memberSvc.getByBbsCallOff(callOff!.id, bbs.id).then((x: any) => {
        if (x.data) setBbsMembers(x.data);
      });
    } else {
      setBbsMembers([]);
    }
  }, [bbs, callOff]);

  useEffect(() => {
    if (callOff) {
      memberSvc.getByCallOff(callOff.id).then((x: any) => {
        setCallOffMembers(x.data);
      });
    }
  }, [callOff]);

  const addCallOffMembers = useCallback(
    (type: string) => {
      if (type === "all") {
        setCallOffMembers((prev) => {
          return Array.from(
            new Set([
              ...prev,
              ...bbsMembers.filter(
                (item) => !prev.some((x) => x.name === item.name)
              ),
            ])
          );
        });
      } else {
        const selectedMembers = bbsMembers.filter(
          (x) => selectedMemberState[x.id]
        );
        setCallOffMembers((prev) => {
          return Array.from(new Set([...prev, ...selectedMembers]));
        });
      }
      setSelectedMemberState({});
    },
    [bbsMembers, selectedMemberState]
  );

  const removeCallOffMembers = (type: string) => {
    if (type === "all") {
      if (callOffMembers.length) {
        removeAllClicked.current = true;
        setCallOffMembers([]);
      }
    } else {
      var members = callOffMembers.filter((x) => {
        return !Object.keys(selectedCallOffMemberState).includes(x.id);
      });

      setCallOffMembers(members);
    }
    setSelectedCallOffMemberState({});
  };

  const onBbsSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedBbsState,
        dataItemKey: DATA_ITEM_KEY,
      });

      if (
        Object.keys(newSelectedState)[0] === Object.keys(selectedBbsState)[0]
      ) {
        setSelectedBbsState({});
        setBbs(null);
        setSelectedMemberState({});
        return;
      }

      if (newSelectedState) {
        const key = Object.keys(newSelectedState)[0];
        const row = bbsList?.find((item) => key === item.id) || null;
        setBbs(row);
      }
      setSelectedBbsState(newSelectedState);
    },
    [selectedBbsState, bbsList]
  );

  const onMemberSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedMemberState,
        dataItemKey: DATA_ITEM_KEY,
      });

      if (newSelectedState) {
        const key = Object.keys(newSelectedState)[0];
        const row = bbsMembers?.find((item) => key === item.id) || null;
      }
      setSelectedMemberState(newSelectedState);
    },
    [selectedMemberState]
  );

  const onMemberHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: HTMLInputElement = event.syntheticEvent
        .target as HTMLInputElement;
      const checked = checkboxElement.checked;
      const newSelectedState: { [id: string]: boolean | number[] } = {};
      bbsMembers?.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedMemberState(newSelectedState);
    },
    [bbsMembers]
  );

  const onCallOffMemberSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedCallOffMemberState,
        dataItemKey: DATA_ITEM_KEY,
      });

      if (newSelectedState) {
        const key = Object.keys(newSelectedState)[0];
        const row = callOffMembers?.find((item) => key === item.id) || null;
      }
      setSelectedCallOffMemberState(newSelectedState);
    },
    [selectedCallOffMemberState]
  );

  const onCallOffMemberHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: HTMLInputElement = event.syntheticEvent
        .target as HTMLInputElement;
      const checked = checkboxElement.checked;
      const newSelectedState: { [id: string]: boolean | number[] } = {};
      callOffMembers?.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedCallOffMemberState(newSelectedState);
    },
    [callOffMembers]
  );

  useEffect(() => {
    if (showManageMemberDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showManageMemberDrawer]);

  const getBBSMembers = () => {
    return bbsMembers
      ?.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedMemberState[idGetter(item)],
      }))
      .filter(
        (item) => callOffMembers.findIndex((x) => x.name === item.name) === -1
      );
  };

  return (
    <Drawer
      expanded={showManageMemberDrawer}
      position={"start"}
      mode={"overlay"}
      animation={{ duration: 400 }}
      className="customDrawer2 transition-all duration-500"
      style={{ zIndex: 9995 }}
    >
      <DrawerContent>
        <div
          className="overlay"
          onClick={() => setShowManageMemberDrawer(false)}
        ></div>
        <div
          className={`max-w-[1024px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-screen w-full transform transition-all duration-500 overflow-y-scroll ${
            showManageMemberDrawer ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
            <h2 className="font-medium text-lg">Select Members</h2>
            <Button
              fillMode="flat"
              className="p-2 bg-none outline-none"
              onClick={() => setShowManageMemberDrawer(false)}
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
          <div className="flex-1 py-4">
            <div className="grid gap-4">
              <div className="bbs-Grid">
                <Grid
                  style={{ width: "100%", maxWidth: "100vw", height: "300px" }}
                  size="small"
                  pageable={false}
                  sortable={false}
                  filterable={false}
                  groupable={false}
                  dataItemKey={DATA_ITEM_KEY}
                  //onDataStateChange={dataStateChange}
                  selectedField={SELECTED_FIELD}
                  className={`customHeight employee`}
                  selectable={{
                    enabled: true,
                    drag: true,
                    mode: "single",
                  }}
                  scrollable="scrollable"
                  onSelectionChange={onBbsSelectionChange}
                  data={bbsList?.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedBbsState[idGetter(item)],
                  }))}
                >
                  <GridToolbar>
                    <div className="flex gap-3 w-full items-center justify-between">
                      <div className="flex gap-x-3 w-full">
                        <div className="w-[250px]">
                          <SearchBox
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder="Search"
                          />
                        </div>
                      </div>
                    </div>
                  </GridToolbar>
                  <GridColumn field="no" title="Number" />
                  <GridColumn field="name" title="Name" />
                  <GridColumn field="revNo" title="Revision" />
                </Grid>
              </div>
              <div className="bbsMembers-Grid">
                <Grid
                  style={{ width: "100%", maxWidth: "100vw", height: "300px" }}
                  size="small"
                  pageable={false}
                  sortable={false}
                  filterable={false}
                  groupable={false}
                  dataItemKey={DATA_ITEM_KEY}
                  //onDataStateChange={dataStateChange}
                  selectedField={SELECTED_FIELD}
                  className={`customHeight employee`}
                  selectable={{
                    enabled: true,
                    drag: true,
                    mode: "multiple",
                  }}
                  scrollable="scrollable"
                  onSelectionChange={onMemberSelectionChange}
                  onHeaderSelectionChange={onMemberHeaderSelectionChange}
                  data={getBBSMembers()}
                >
                  <GridToolbar>
                    <div className="flex gap-3 w-full items-center justify-between">
                      <div className="flex gap-x-3 w-full">
                        <Button
                          className="border border-[#E2E8F0] k-button-solid-primary"
                          onClick={() => addCallOffMembers("selected")}
                        >
                          <div className="flex gap-x-2">
                            <Plus className="text-[#484F61] self-center p-0 w-4 h-4 text-white" />
                            <p className="self-center pr-2">Add Selected</p>
                          </div>
                        </Button>
                        <Button
                          className="border border-[#E2E8F0] k-button-solid-primary"
                          onClick={() => addCallOffMembers("all")}
                        >
                          <div className="flex gap-x-2">
                            <Plus className="text-[#484F61] self-center p-0 w-4 h-4 text-white" />
                            <p className="self-center pr-2">Add All</p>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </GridToolbar>
                  <GridColumn
                    field={SELECTED_FIELD}
                    width="50px"
                    headerSelectionValue={
                      bbsMembers?.findIndex(
                        (item) => !selectedMemberState[idGetter(item)]
                      ) === -1
                    }
                  />
                  <GridColumn field="name" title="Name" width={350} />
                  <GridColumn field="revisionNumber" title="Revision" />
                  <GridColumn
                    field="memberStatus"
                    title="Status"
                    cells={{
                      data: (props: any) => (
                        <td>
                          {props.dataItem.membersReleased === 0
                            ? "Engineered"
                            : props.dataItem.membersReleased ===
                              props.dataItem.noOfMem
                            ? "Released"
                            : "Engineered"}
                        </td>
                      ), //<DateCell {...props} />
                    }}
                  />
                  <GridColumn field="noOfMem" title="Engineered" />
                  <GridColumn field="membersReleased" title="Released" />
                </Grid>
              </div>
              <div className="callOffMembers-Grid">
                <Grid
                  style={{ width: "100%", maxWidth: "100vw", height: "300px" }}
                  size="small"
                  pageable={false}
                  sortable={false}
                  filterable={false}
                  groupable={false}
                  dataItemKey={DATA_ITEM_KEY}
                  //onDataStateChange={dataStateChange}
                  selectedField={SELECTED_FIELD}
                  className={`customHeight employee`}
                  selectable={{
                    enabled: true,
                    drag: true,
                    mode: "multiple",
                  }}
                  scrollable="scrollable"
                  onSelectionChange={onCallOffMemberSelectionChange}
                  onHeaderSelectionChange={onCallOffMemberHeaderSelectionChange}
                  data={callOffMembers?.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]:
                      selectedCallOffMemberState[idGetter(item)],
                  }))}
                >
                  <GridToolbar>
                    <div className="flex gap-3 w-full items-center justify-between">
                      <div className="flex gap-x-3 w-full">
                        <Button
                          className="border border-[#E2E8F0] k-button-solid-primary"
                          onClick={() => removeCallOffMembers("selected")}
                        >
                          <div className="flex gap-x-2">
                            <X className="text-[#484F61] self-center p-0 w-4 h-4 text-white" />
                            <p className="self-center pr-2">Remove Selected</p>
                          </div>
                        </Button>
                        <Button
                          className="border border-[#E2E8F0] k-button-solid-primary"
                          onClick={() => removeCallOffMembers("all")}
                        >
                          <div className="flex gap-x-2">
                            <X className="text-[#484F61] self-center p-0 w-4 h-4 text-white" />
                            <p className="self-center pr-2">Remove All</p>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </GridToolbar>
                  <GridColumn
                    field={SELECTED_FIELD}
                    width="50px"
                    headerSelectionValue={
                      callOffMembers?.findIndex(
                        (item) => !selectedCallOffMemberState[idGetter(item)]
                      ) === -1
                    }
                  />
                  <GridColumn field="name" title="Name" />
                </Grid>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 pb-4 sticky-buttons">
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              onClick={() => {
                setBbs(null);
                setBbsList([]);
                setBbsMembers([]);
                setShowManageMemberDrawer(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              themeColor={"primary"}
              onClick={onSave}
              disabled={!validateSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default ManageMemberDrawer;
