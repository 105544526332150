import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { IBBS } from "../../../interfaces/bbs.interface";
import bbsSvc from "../../../services/bbs.service";
import {
  Grid,
  GridColumn as Column,
  GridSelectionChangeEvent,
  getSelectedState,
  GridKeyDownEvent,
  getSelectedStateFromKeyDown,
} from "@progress/kendo-react-grid";
import {
  IMemberPartUI,
  IMemberPartUnits,
} from "../../../interfaces/member.interface";
import memberLineSvc from "../../../services/memberLine.service";
import { getter } from "@progress/kendo-react-common";
import React from "react";
import memberPartSvc from "../../../services/memberPart.service";
import { triggerFetchProjects } from "../../../store/features/commonSlice";

const DATA_ITEM_KEY: string = "name";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}
{
  /* <Column field="mark" title="Bar No" />
                        <Column field="barRev" title="Bar Rev" />
                        <Column field="type" title="Bar Type" />
                        <Column field="size" title="Bar Size" />
                        <Column field="shapeCode" title="Shape Code" />
                        <Column
                          field="noInEach"
                          title={`Total Number Of Units (each * ${memberProperties?.noOfMem})`}
                        /> */
}
const columns = [
  {
    title: "Bar No",
    field: "mark",
    show: true,
  },
  {
    title: "Bar Rev",
    field: "barRev",
    show: true,
  },
  {
    title: "Bar Type",
    field: "type",
    show: true,
  },
  {
    title: "Bar Size",
    field: "size",
    show: true,
  },
  {
    title: "Shape Code",
    field: "shapeCode",
    show: true,
  },
  {
    title: `Total Number Of Units (each * 1)`,
    field: "noInEach",
    show: true,
  },
];

const nonEditableColumns = [
  "mark",
  "type",
  "size",
  "barRev",
  "shapeCode",
  "noInEach",
];

const initialMemberPart = {
  name: "",
  noOfMem: null,
  option: "Set all units number to remaining",
  status: "Engineered",
  membersEngineered: 0,
  membersReleased: 0,
  membersFabricated: 0,
  membersErected: 0,
};

export const MemberParts = ({
  memberProperties,
  showMemberPartsDrawer,
  setShowMemberPartsDrawer,
}: any) => {
  const dispatch = useAppDispatch();

  const [bbs, setBBS] = useState<IBBS[]>([]);
  const [newPart, setNewPart] = useState(initialMemberPart);

  const [parts, setParts] = useState<IMemberPartUI[] | null>([]);
  const [part, setSelectedPart] = useState<IMemberPartUI | null>();
  const [partUnits, setPartUnits] = useState<IMemberPartUnits[]>([]);

  const [prevParts, setPrevParts] = useState<IMemberPartUnits[]>([]);
  const [prevPartUnits, setPrevPartUnits] = useState<IMemberPartUnits[]>([]);

  const [selectedPartState, setSelectedPartState] = React.useState<{
    [id: string]: boolean | number[];
  }>({});

  const [state, setState] = useState({
    columns: [...columns],
    editID: null,
  });

  useEffect(() => {
    if (showMemberPartsDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      setState({
        columns: [...columns],
        editID: null,
      });
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showMemberPartsDrawer]);

  const onSelectionChange = (event: GridSelectionChangeEvent) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedPartState,
      dataItemKey: DATA_ITEM_KEY,
    });

    if (newSelectedState) {
      var key = Object.keys(newSelectedState)[0];
      const row = parts?.find((x) => key === x.name);
      setSelectedPart(row);
    }

    setSelectedPartState(newSelectedState);
  };

  const onKeyDown = (event: GridKeyDownEvent) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedPartState,
      dataItemKey: DATA_ITEM_KEY,
    });

    if (newSelectedState) {
      var key = Object.keys(newSelectedState)[0];
      const row = parts?.find((x) => key === x.name);
      setSelectedPart(row);
    }

    setSelectedPartState(newSelectedState);
  };

  const removeSelectedPart = () => {
    setParts((prev: any) =>
      prev?.filter((prt: any) => part?.name !== prt.name)
    );
    setPartUnits((prev: IMemberPartUnits[]) => {
      return prev.map((item: any) => {
        var key = part?.name.replace(/\s/g, "").toLowerCase();
        if (key) {
          delete item[key];
        }

        return item;
      });
    });

    setState((prev: any) => {
      return {
        columns: prev.columns.filter(
          (x: any) => x.field !== part?.name.replace(/\s/g, "").toLowerCase()
        ),
        editID: null,
      };
    });

    setSelectedPart(null);
    setSelectedPartState({});
  };

  const removeAllParts = () => {
    setParts([]);
    setSelectedPart(null);
    setSelectedPartState({});

    setState((prev: any) => ({
      columns: [...columns],
      editID: null,
    }));
  };

  const addPart = async () => {
    if (!newPart.name) return false;

    if (newPart.name === memberProperties?.text) {
      dispatch(
        setToast({
          toastMsg: "Member Part name must not be the same as the Member Name",
          toastType: "error",
        })
      );
      return false;
    }

    var exists = parts?.find((x) => x.name == newPart.name);

    if (exists) {
      dispatch(
        setToast({
          toastMsg: "Member Part name already exists",
          toastType: "error",
        })
      );
      return false;
    }

    var part = {
      ...newPart,
      membersEngineered: newPart.noOfMem,
    };

    setParts((prev: any) => {
      return [...prev, part];
    });

    setPartUnits((prev: any) => {
      var keys = parts
        ?.filter((x) => x.name !== newPart.name)
        .map((x: any) => {
          return x.name.replace(/\s/g, "").toLowerCase();
        });

      return prev.map((item: any) => {
        var totalValue = 0;

        keys?.forEach((element: any) => {
          totalValue += parseInt(item[element]);
        });

        if (newPart.option === "Set all units number to remaining")
          item[newPart.name.replace(/\s/g, "").toLowerCase()] =
            item.noInEach - totalValue;
        if (newPart.option === "Set all units number to total")
          item[newPart.name.replace(/\s/g, "").toLowerCase()] = item.noInEach;
        if (newPart.option === "Set all units number to zero")
          item[newPart.name.replace(/\s/g, "").toLowerCase()] = 0;

        return item;
      });
    });

    setState((prev: any) => ({
      columns: [
        ...prev.columns,
        {
          title: `${newPart.name} (each * ${newPart.noOfMem})`,
          field: newPart.name.replace(/\s/g, "").toLowerCase(),
        },
      ],
      editID: null,
    }));

    setNewPart((prev: any) => {
      return {
        ...prev,
        name: memberProperties?.text,
        noOfMem: memberProperties?.noOfMem,
      };
    });
  };

  const validateAddPart = () => {
    if (!newPart) return false;

    if (!newPart.name) return false;

    if (!newPart.noOfMem) return false;

    if (!newPart.option) return false;

    return true;
  };

  const newPartChange = (e: ComboBoxChangeEvent | TextBoxChangeEvent) => {
    setNewPart((prev: any) => {
      return {
        ...prev,
        [e.target.name as string]: e.value,
      };
    });
  };

  const fetchMemberLines = async () => {
    if (memberProperties?.memberId) {
      const res = await memberLineSvc.getMemberLines(
        memberProperties?.memberId
      );
      if (res.data) {
        return res.data;
      }
      return [];
    }
  };

  useEffect(() => {
    if (memberProperties && showMemberPartsDrawer) {
      fetchMemberParts();
    }
  }, [memberProperties, showMemberPartsDrawer]);

  const fetchMemberParts = async () => {
    let partUnitsRes = await fetchMemberLines();
    if (memberProperties?.items?.length > 0) {
      memberPartSvc
        .getMemberParts(
          memberProperties?.items.map((x: any) => x.memberPartId).join(",")
        )
        .then((res: any) => {
          if (res.data) {
            setParts(res.data.parts);
            setPrevParts(res.data.parts);

            res.data.parts.forEach((part: any) => {
              var key = part.name.replace(/\s/g, "").toLowerCase();

              setState((prev: any) => ({
                columns: [
                  ...prev.columns,
                  {
                    title: `${part.name} (each * ${part.noOfMem})`,
                    field: key,
                  },
                ],
                editID: null,
              }));
              setPrevPartUnits(partUnitsRes);
              partUnitsRes = partUnitsRes.map((item: any) => {
                var value = res.data.partUnits.find(
                  (x: any) => x.id == item.id && x.name == part.name
                );

                if (value) {
                  item[key] = value.noInEach;
                }
                return item;
              });

              setPartUnits(partUnitsRes);
            });
          }
        });
    } else {
      setPartUnits(partUnitsRes);
    }

    setNewPart((prev: any) => {
      return {
        ...prev,
        name: memberProperties?.text,
        noOfMem: memberProperties?.noOfMem,
      };
    });
  };

  const rowClick = (event: any) => {
    setState((prev) => ({ ...prev, editID: event.dataItem.id }));
  };

  const itemChange = (event: any) => {
    const inEditID = event.dataItem.id;
    const updatedData = partUnits.map((item) =>
      item.id === inEditID ? { ...item, [event.field]: event.value } : item
    );

    setState((prev) => ({ ...prev }));
    setPartUnits(updatedData);
  };

  const saveMemberParts = async () => {
    var keys = parts
      ?.filter((x) => x.name !== newPart.name)
      .map((x: any) => {
        return x.name.replace(/\s/g, "").toLowerCase();
      });

    var units = partUnits
      ?.map((x: any) => {
        return keys?.map((k: string) => {
          return {
            id: x.id,
            name: k,
            noInEach: parseInt(x[k] ?? 0),
          };
        });
      })
      .flat();

    if (units.length > 0 && !units.some((x: any) => x.noInEach > 0)) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: "No in Each cannot be 0 for All Member Units",
        })
      );
      return;
    }

    const groupedSum = Object.values(
      units.reduce((acc, item: any) => {
        // Check if the category already exists in the accumulator
        if (!acc[item.id]) {
          acc[item.id] = { ...item }; // Initialize with the first item
        } else {
          // Sum the values and counts for the existing category
          acc[item.id].noInEach += item.noInEach;
        }
        return acc;
      }, {} as Record<string, any>)
    );

    // Create a lookup map from arrayB
    const lookupMap = new Map(
      partUnits.map((item) => [item.id, item.noInEach])
    );

    // Loop through arrayA and check if any `value` is greater than the corresponding `maxAllowedValue` in arrayB
    const isWithinLimits = groupedSum.every((itemA) => {
      const maxAllowedValue = lookupMap.get(itemA.id);
      return maxAllowedValue !== undefined && itemA.noInEach <= maxAllowedValue;
    });

    if (!isWithinLimits) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg:
            "Total No in Each for all parts cannot be greater than total no in each of parent member",
        })
      );
      return;
    }

    var payload = {
      memberId: memberProperties?.memberId,
      parts: parts,
      partUnits: units,
    };

    if (prevParts.length > 0) {
      const res = await memberPartSvc.updateMemberParts(payload);
      if (res) {
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Member Parts have been updated successfully",
          })
        );
      }
    } else {
      const res = await memberPartSvc.createMemberParts(payload);
      if (res) {
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Member Parts have been added successfully",
          })
        );
      }
    }

    dispatch(triggerFetchProjects());
    resetValues();
    setShowMemberPartsDrawer(!showMemberPartsDrawer);
  };

  const validateSaveButton = () => {
    return true;
  };

  const resetValues = () => {
    setPartUnits([]);
    setPrevParts([]);
    setPrevPartUnits([]);
    setParts([]);
    setSelectedPart(null);
    setSelectedPartState({});
    setState({
      columns: [...columns],
      editID: null,
    });
  };

  return (
    <>
      <Drawer
        expanded={showMemberPartsDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500 "
        // onOverlayClick={() => handleEditClick}
        // onSelect={() => handleEditClick}
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowMemberPartsDrawer(!showMemberPartsDrawer)}
          ></div>
          <div
            className={`max-w-[1024px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showMemberPartsDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Manange Member Parts</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() => setShowMemberPartsDrawer(!showMemberPartsDrawer)}
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <CustomLabel label="Member part name" required={true} />
                  <TextBox
                    value={newPart?.name}
                    onChange={newPartChange}
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    autoComplete="new-password"
                  />
                </div>
                <div>
                  <CustomLabel label="No of members" required={true} />
                  <TextBox
                    value={newPart?.noOfMem || ""}
                    onChange={newPartChange}
                    type="number"
                    placeholder="Enter No of Members"
                    name="noOfMem"
                    autoComplete="new-password"
                  />
                </div>
                <div>
                  <CustomLabel label="Options" required={true} />
                  <ComboBox
                    data={[
                      "Set all units number to remaining",
                      "Set all units number to total",
                      "Set all units number to zero",
                    ]}
                    // onChange={onNewProjectChange}
                    value={newPart?.option}
                    // value={newProject.callOffPrefix}
                    onChange={newPartChange}
                    name="option"
                    clearButton={false}
                    placeholder="Select Option"
                    defaultValue={"Set all units number to remaining"}
                  />
                </div>
              </div>
              <div className="flex gap-4 justify-end mt-5">
                {/* <Button
                  className="border border-[#E2E8F0]"
                  themeColor={"primary"}
                  // onClick={handleEditProject}
                  disabled={true}
                  // disabled={!validateEditProject()}
                >
                  Apply option to selected member part
                </Button> */}
                <Button
                  className="border border-[#E2E8F0]"
                  themeColor={"primary"}
                  onClick={addPart}
                  disabled={!validateAddPart()}
                >
                  Add Part
                </Button>
              </div>

              <hr className="my-4" />
              <div className="grid">
                <div className="py-2 flex-1 items-center justify-between gap-2 border-b border-neutral-30">
                  <div className="card mb-5 p-3">
                    <div className="justify-between w-full">
                      <div className="flex flex-wrap rounded  border-neutral-30">
                        <div className="self-center text-base py-1 rounded-sm">
                          Member Parts:
                        </div>
                      </div>
                    </div>
                    <div>
                      <Grid
                        style={{ height: 220 }}
                        pageable={false}
                        sortable={false}
                        filterable={false}
                        selectable={{
                          enabled: true,
                          mode: "single",
                          drag: false,
                        }}
                        size={"small"}
                        groupable={false}
                        scrollable="none"
                        data={parts?.map((item) => ({
                          ...item,
                          [SELECTED_FIELD]: selectedPartState[idGetter(item)],
                        }))}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        onSelectionChange={onSelectionChange}
                        navigatable={true}
                        onKeyDown={onKeyDown}
                      >
                        <Column field="name" title="Name" width={300} />
                        <Column field="status" title="Status" />
                        <Column field="noOfMem" title="No of Members" />
                        <Column field="membersEngineered" title="Engineered" />
                        <Column field="membersReleased" title="Released" />
                      </Grid>
                    </div>
                    <div className="flex gap-4 justify-end mt-3">
                      <Button
                        className="border border-[#E2E8F0]"
                        themeColor={"primary"}
                        onClick={removeSelectedPart}
                        disabled={!part}
                        // disabled={!validateEditProject()}
                      >
                        Remove Selected
                      </Button>
                      <Button
                        className="border border-[#E2E8F0]"
                        themeColor={"primary"}
                        onClick={removeAllParts}
                        disabled={parts?.length === 0}
                        // disabled={!validateEditProject()}
                      >
                        Remove All
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid gap-4">
                <div className="card mb-5 p-3">
                  <div className="justify-between w-full">
                    <div className="flex flex-wrap rounded  border-neutral-30">
                      <div className="self-center text-base py-1 rounded-sm">
                        Member Part Units:
                      </div>
                    </div>
                    <div>
                      <Grid
                        style={{ height: 200 }}
                        pageable={false}
                        sortable={false}
                        filterable={false}
                        size={"small"}
                        groupable={false}
                        scrollable="scrollable"
                        data={partUnits?.map((item) => {
                          return {
                            ...item,
                            inEdit: item.id === state.editID,
                          };
                        })}
                        editField="inEdit"
                        onRowClick={rowClick}
                        onItemChange={itemChange}
                      >
                        {state.columns.map((c: any, idx: number) => {
                          let width = "wrap";
                          let editable = true;

                          if (nonEditableColumns.includes(c.field)) {
                            editable = false;
                          }

                          return (
                            <Column
                              key={idx}
                              field={c.field}
                              title={c.title}
                              editable={editable}
                            />
                          );
                        })}
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => {
                  resetValues();
                  setShowMemberPartsDrawer(!showMemberPartsDrawer);
                }}
              >
                Close
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                onClick={saveMemberParts}
                disabled={!validateSaveButton()}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
