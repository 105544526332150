import { DebouncedFunc } from "lodash";
import { ShowColumnsFilter } from "../../../component/global/ColumnMenu";
import { IBBS } from "../../../interfaces/bbs.interface";
import {
  BBSActionCellPopup,
  CallOffActionCellPopup,
  DateCell,
  TotalWeightCell,
  WeightCell,
} from "../../../shared-components/custom-cells";
import {
  DATE_TIME_FORMAT,
  MIN_RESIZABLE_WIDTH_KENDO_TABLE,
} from "../../../utils/utils";
import { Switch } from "@progress/kendo-react-inputs";
import moment from "moment";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  handleEditClick: any,
  handleManageMemberClick: any,
  handleManageNoOfMemberClick: any,
  handleCancelCallOffClick: any,
  handleCallOffLogsClick: any,
  handleCallOffFabEmailClick: any,
  handleExport: any,
  onColumnsSubmit: any,
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>,
  handleDelete: () => void,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  const isVisible = (field: string) => {
    if (!columnsMapping?.length) return false;
    if (columnsMapping.includes(field)) {
      return true;
    }

    return false;
  };
  const columns = [
    {
      title: "Name",
      field: "name",
      show: true,
      width: 300,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      cells: {
        data: (props: any) => (
          <CallOffActionCellPopup
            {...props}
            handleEditClick={handleEditClick}
            handleManageMemberClick={handleManageMemberClick}
            handleManageNoOfMemberClick={handleManageNoOfMemberClick}
            handleCancelCallOffClick={handleCancelCallOffClick}
            handleCallOffLogsClick={handleCallOffLogsClick}
            handleCallOffFabEmailClick={handleCallOffFabEmailClick}
            handleExport={handleExport}
            handleDelete={handleDelete}
          />
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },

    {
      title: "Rev.",
      field: "revisionNumber",
      show: changed ? isVisible("revisionNumber") : true,
      width: 75,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Status",
      field: "status",
      show: changed ? isVisible("status") : true,
      width: 90,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Created On",
      field: "createdOn",
      show: changed ? isVisible("createdOn") : true,
      width: 160,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["no"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => <DateCell {...props} />,
      },
    },

    {
      title: "Released On",
      field: "releaseDate",
      show: changed ? isVisible("releaseDate") : true,
      width: 160,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["releaseDate"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => <DateCell {...props} />,
      },
    },
    {
      title: "Work Order ID",
      field: "workOrderNumber",
      show: changed ? isVisible("workOrderNumber") : true,
      width: 200,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["workOrderNumber"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Call-Off No",
      field: "callOffNumber",
      show: changed ? isVisible("callOffNumber") : true,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["callOffNumber"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Fabrication",
      field: "sendToFab",
      show: changed ? isVisible("sendToFab") : true,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["sendToFab"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => {
          return (
            <td {...props.tdProps}>
              {props.dataItem.sendToFab && <span>Sent</span>}

              {props.dataItem.recalledFromFab && <span>Recalled</span>}
            </td>
          );
        },
      },
    },
    {
      title: "Fabrication Date",
      field: "sendtoFabDate",
      show: changed ? isVisible("sendtoFabDate") : true,
      width: 175,

      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["sendtoFabDate"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => {
          const { dataItem } = props;

          let field = dataItem.sendToFab
            ? "sendtoFabDate"
            : "recalledFromFabDate";

          const formatDate = (dateString: string) => {
            if (!dateString) return "";
            const formattedDate = moment(dateString).format(DATE_TIME_FORMAT);
            return formattedDate;
          };

          return (
            <td {...props.tdProps}>
              <span className={`px-2 py-1 rounded`}>
                {formatDate(dataItem[field || ""])}
              </span>
            </td>
          );
        },
      },
    },
    {
      title: "Weight (Ton)",
      field: "weight",
      show: changed ? isVisible("weight") : true,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["weight"]}
        />
      ),
      cells: {
        data: (props: any) => <WeightCell {...props} />,
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Cancelled Before",
      field: "unReleased",
      show: changed ? isVisible("unReleased") : true,
      width: 100,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["unReleased"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => (
          <td {...props.tdProps}>{props.dataItem.unReleased ? "Yes" : ""}</td>
        ),
      },
    },
    {
      title: "Cancelled Before On",
      field: "unReleaseDate",
      show: changed ? isVisible("unReleaseDate") : true,
      width: 175,

      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["unReleaseDate"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => <DateCell {...props} />,
      },
    },
    {
      title: "Modified By",
      field: "modifiedBy",
      show: changed ? isVisible("modifiedBy") : false,
      //width: 200,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["modifiedBy"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Modified On",
      field: "modifiedOn",
      show: changed ? isVisible("modifiedOn") : false,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["modifiedOn"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
      cells: {
        data: (props: any) => <DateCell {...props} />,
      },
    },
    {
      title: "Subcontractor",
      field: "subcontractorName",
      show: changed ? isVisible("subcontractorName") : true,
      width: 150,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["subcontractorName"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
  ];

  return columns;
};
