import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMemberLine } from '../../interfaces/member.interface';

interface UnitsState {
  copiedUnits: IMemberLine[];
  selectedUnits: { [id: string]: boolean | number[] };
}

const initialState: UnitsState = {
  copiedUnits: [],
  selectedUnits: {},
};

export const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setCopiedUnits: (state, action: PayloadAction<IMemberLine[]>) => {
      state.copiedUnits = action.payload;
    },
    setSelectedUnits: (state, action: PayloadAction<{ [id: string]: boolean | number[] }>) => {
      state.selectedUnits = action.payload;
    },
    clearCopiedUnits: (state) => {
      state.copiedUnits = [];
    },
    clearSelectedUnits: (state) => {
      state.selectedUnits = {};
    }
  },
});

export const { setCopiedUnits, setSelectedUnits, clearCopiedUnits, clearSelectedUnits } = unitsSlice.actions;

export default unitsSlice.reducer;
