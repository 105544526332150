import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { PDFViewer, PDFViewerTool } from "@progress/kendo-react-pdf-viewer";
import {
  ItemRenderProps,
  TreeView,
  TreeViewCheckChangeEvent,
  TreeViewCheckChangeSettings,
  TreeViewCheckDescriptor,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
  TreeViewOperationDescriptor,
  handleTreeViewCheckChange,
} from "@progress/kendo-react-treeview";
import { debounce } from "lodash";
import {
  BriefcaseIcon,
  BringToFront,
  CheckIcon,
  ClipboardPaste,
  Component,
  Copy,
  CopyMinus,
  CopyPlus,
  Download,
  FileText,
  Folder,
  FolderGit,
  FolderGit2,
  FolderKanban,
  Import,
  Layers,
  List,
  MoreHorizontal,
  Paperclip,
  Plus,
  SquarePen,
  SquarePlus,
  TableProperties,
  Trash2Icon,
  Ungroup,
  User,
  X,
} from "lucide-react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomLabel from "../../component/global/RequiredMark";
import SearchBox from "../../component/ui/SearchBox";
import DeletionReasons from "../../constants/deletionReasons";
import { IBBS } from "../../interfaces/bbs.interface";
import {
  IMember,
  IMemberDetail,
  IMemberLine,
} from "../../interfaces/member.interface";
import {
  IProject,
  IShopDrawings,
  Level,
} from "../../interfaces/project.interface";
import { ISearchFilter } from "../../interfaces/searchFilter.interface";
import bbsSvc from "../../services/bbs.service";
import memberSvc from "../../services/member.service";
import projectSvc from "../../services/project.service";
import { CustomDialogBox } from "../../shared-components/dialog-box";
import {
  resetFetchProjects,
  resetMemberLines,
  setSelectedProject,
  triggerFetchProjects,
} from "../../store/features/commonSlice";
import { setToast } from "../../store/features/toastSlice";
import {
  clearCopiedUnits,
  clearSelectedUnits,
  setCopiedUnits,
} from "../../store/features/unitsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";
import { getDialogInfo } from "../../utils/dialog.utils";

const tools: PDFViewerTool[] = [
  "pager",
  "spacer",
  "zoomInOut",
  //"zoom",
  "selection",
  "spacer",
  "search",
  "print",
  "download",
];

type TreeViewDataItem = {
  text: string;
  expanded?: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
  level?: number;
  levelCode?: string;
  subLevelCode?: string;
  projectId?: string;
};

const AddLevelContext = createContext({
  // Project Right Click Fuctions //

  handleManageProject: (data: any, action: string) => {},
  handleSummary: (data: any, action: string) => {},

  handleManageDetailingOffices: (data: any, action: string) => {},
  handleManageSubcontractors: (data: any, action: string) => {},

  // Levels Right Click Fuctions //

  // Member Right Click Fuctions //
  handleManageMemberRevision: (data: any, action: string) => {},
  handleManageMemberProperties: (data: any, action: string) => {},
  handleManageMemberImport: (data: any, action: string) => {},
  handleMemberPDFExport: (data: any, action: string) => {},
  handleMemberExcelExport: (data: any, action: string) => {},
  handleMemberAssignment: (data: any, action: string) => {},
  handleMemberParts: (data: any, action: string) => {},
  // Common Right Click Functions //
  addLevel: (data: any, action: string) => {},
  handleManageBBS: (data: any, action: string) => {},
  handleManageCallOff: (data: any, action: string) => {},

  handleCopyUnitsClick: (data: any, action: string) => {},
  handlePasteUnitsClick: (data: any, action: string) => {},
  handleDeleteUnitsClick: (data: any, action: string) => {},

  toggleMemberDeleteDialog: () => {},
  toggleUnDeleteDialog: () => {},
  setSelectedItem: (data: any) => {},
  handleTreeSelection: () => {},
  showManageBBS: false,
  showManageCallOff: false,
});

interface IProps {
  projects: IProject[];
  setProjects: React.Dispatch<React.SetStateAction<IProject[]>>;
  setSelectedLevelBBS: React.Dispatch<React.SetStateAction<IBBS | null>>;
  memberDetail: IMemberDetail | undefined;
  setMemberDetail: React.Dispatch<
    React.SetStateAction<IMemberDetail | undefined>
  >;
  prevMemberDetail: any;
  treeView: TreeViewDataItem[];
  setTreeView: React.Dispatch<React.SetStateAction<TreeViewDataItem[]>>;
  setDistinctMembers: React.Dispatch<React.SetStateAction<IMemberLine[]>>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  showAddProjectDrawer: boolean;
  setShowAddProjectDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showMemberPropertiesDrawer: boolean;
  setShowMemberPropertiesDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showEditMemberPropertiesDrawer: boolean;
  setShowEditMemberPropertiesDrawer: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  showMemberPartsDrawer: boolean;
  setShowMemberPartsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showMemberImportDrawer: boolean;
  setShowMemberImportDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showMemberRevisionDrawer: boolean;
  setShowMemberRevisionDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  memberImportType: any;
  setMemberImportType: React.Dispatch<React.SetStateAction<any | null>>;
  selected: boolean;
  toggleSelected: () => void;
  showEditProjectDrawer: boolean;
  setShowEditProjectDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showAddDetailingOfficeDrawer: boolean;
  setShowAddDetailingOfficeDrawer: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  showAddSubcontractorDrawer: boolean;
  setShowAddSubcontractorDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  projectToEdit: (IProject & { id: string }) | null;
  setProjectToEdit: React.Dispatch<
    React.SetStateAction<(IProject & { id: string }) | null>
  >;
  prevProject: (IProject & { id: string }) | null;
  setPrevProject: React.Dispatch<
    React.SetStateAction<(IProject & { id: string }) | null>
  >;
  showManageBBS: boolean;
  setShowManageBBS: React.Dispatch<React.SetStateAction<boolean>>;
  showManageCallOff: boolean;
  setShowManageCallOff: React.Dispatch<React.SetStateAction<boolean>>;

  memberProperties: any;
  setMemberProperties: React.Dispatch<React.SetStateAction<any | null>>;
  showMemberAssignmentsDrawer: boolean;
  setShowMemberAssignmentsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showMembersView: boolean;
  setShowMembersView: React.Dispatch<React.SetStateAction<boolean>>;
  copiedItems: any;
  setCopiedItems: any;
  pastedItems: any;
  setPastedItems: any;
  check: string[] | TreeViewCheckDescriptor;
  setCheck: React.Dispatch<
    React.SetStateAction<string[] | TreeViewCheckDescriptor>
  >;
  select: string[];
  setSelect: React.Dispatch<React.SetStateAction<string[]>>;
  expand: TreeViewOperationDescriptor;
  setExpand: React.Dispatch<React.SetStateAction<TreeViewOperationDescriptor>>;
  processTreeViewItems: any;
}

const TreeViewMain = ({
  projects,
  setProjects,
  memberDetail,
  setMemberDetail,
  prevMemberDetail,
  setSelectedLevelBBS,
  treeView,
  setTreeView,
  setDistinctMembers,
  expanded,
  setExpanded,
  showAddProjectDrawer,
  setShowAddProjectDrawer,
  showMemberPropertiesDrawer,
  setShowMemberPropertiesDrawer,
  showEditMemberPropertiesDrawer,
  setShowEditMemberPropertiesDrawer,
  showMemberPartsDrawer,
  setShowMemberPartsDrawer,
  showMemberImportDrawer,
  setShowMemberImportDrawer,
  showMemberRevisionDrawer,
  setShowMemberRevisionDrawer,
  memberImportType,
  setMemberImportType,
  selected,
  toggleSelected,
  showEditProjectDrawer,
  setShowEditProjectDrawer,
  showAddDetailingOfficeDrawer,
  setShowAddDetailingOfficeDrawer,
  showAddSubcontractorDrawer,
  setShowAddSubcontractorDrawer,
  projectToEdit,
  setProjectToEdit,
  prevProject,
  setPrevProject,
  showManageBBS,
  setShowManageBBS,
  showManageCallOff,
  setShowManageCallOff,
  memberProperties,
  setMemberProperties,
  showMemberAssignmentsDrawer,
  setShowMemberAssignmentsDrawer,
  showMembersView,
  setShowMembersView,
  copiedItems,
  setCopiedItems,
  pastedItems,
  setPastedItems,
  check,
  setCheck,
  select,
  setSelect,
  expand,
  setExpand,
  processTreeViewItems,
}: IProps) => {
  const debouncedSearch = useRef(
    debounce((value: string) => fetchProjects(value), 500)
  ).current;

  const commonState = useAppSelector((state: RootState) => state.common);
  const selectedUnits = useAppSelector(
    (state: RootState) => state.units.selectedUnits
  );
  const copiedUnits = useAppSelector(
    (state: RootState) => state.units.copiedUnits
  );

  const dispatch = useAppDispatch();

  const selectedLevel = useRef<any>(null);
  const selectedAction = useRef<any>("");
  const [showDialog, setShowDialog] = useState(false);
  const [inputVal, setInputVal] = useState<string>("");

  const [searchValue, setSearchValue] = useState("");

  const [modalVisible, setModalVisible] = useState(false);
  const [unDeleteModalVisible, setUnDeleteModalVisible] = useState(false);
  const [memberDeleteModalVisible, setMemberDeleteModalVisible] =
    useState(false);

  const [deletionReason, setDeletionReason] = useState("");
  //
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    // if (value?.length !== 1) {
    //   debouncedSearch(value);
    // }
  };

  const [fileUrl, setFileUrl] = useState<string>("");
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [fileNameVal, setFileNameVal] = useState<string>("");

  // const generateMemberReport(id: any) {
  //   const res = await bbsSvc.exportMemberPDF(id);
  //   if (res.data) {
  //     if (res.data) {
  //       setFileUrl(res.data);
  //       setShowPdfPreview(true);
  //     }
  //   }
  // }

  const showCheckboxes = () => {
    if (
      commonState?.selectedCallOff &&
      commonState.selectedCallOff?.status === "Released"
    ) {
      return false;
    }

    if (showManageBBS || showManageCallOff) {
      return true;
    }

    return false;
  };

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    const ids: string[] = expand.ids ? expand.ids.slice() : [];
    const index: number = ids.indexOf(event.item.text);

    index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
    setExpand({ ids, idField: "text" });
  };

  const handleCollapseAll = () => {
    setExpand({ ids: [], idField: "text" });
  };

  const handleExpandAll = () => {
    if (
      commonState?.selectedProject?.event?.item &&
      commonState?.selectedProject?.event?.item?.level === 0
    ) {
      const selectedItem = commonState.selectedProject.event.item;
      const textsToExpand: string[] = [];

      textsToExpand.push(selectedItem.text);

      const collectTexts = (items: TreeViewDataItem[] | undefined) => {
        if (!items) return;

        items.forEach((item) => {
          textsToExpand.push(item.text);
          if (item.items) {
            collectTexts(item.items);
          }
        });
      };

      if (selectedItem.items) {
        collectTexts(selectedItem.items);
      }

      setExpand({ ids: textsToExpand, idField: "text" });

      return;
    }
    const allTexts = treeView.flatMap((item: TreeViewDataItem) => {
      return [
        item.text,
        ...(item.items
          ? item.items.flatMap((subItem) => {
              return [
                subItem.text,
                ...(subItem.items
                  ? subItem.items.flatMap((subSubItem) => {
                      return [
                        subSubItem.text,

                        ...(subSubItem.items
                          ? subSubItem.items.flatMap((subSubSubItem) => {
                              return [
                                subSubSubItem.text,

                                ...(subSubSubItem.items
                                  ? subSubSubItem.items.flatMap(
                                      (subSubSubSubItem) => {
                                        return subSubSubSubItem.text;
                                      }
                                    )
                                  : []),
                              ];
                            })
                          : []),
                      ];
                    })
                  : []),
              ];
            })
          : []),
      ];
    });
    setExpand({ ids: allTexts, idField: "text" });
  };

  const onItemClick = (event: TreeViewItemClickEvent) => {
    if (
      event.nativeEvent.target.className ===
      "k-button k-button-md k-button-flat k-button-flat-base k-rounded-md w-full iconBtn px-1"
    ) {
      return;
    }
    if (
      event.nativeEvent.target.className ===
      "k-button k-button-md k-button-flat k-button-flat-base k-rounded-md p-2 bg-none outline-none k-ripple-target"
    ) {
      return;
    }
    if (
      event.nativeEvent.target.className !==
      "self-center cursor-pointer hover:text-primaryMain whitespace-pre-wrap line-clamp-1 flex-1 project-text"
    ) {
      return;
    }

    if (showManageBBS || showManageCallOff) return; //&& event.item.level === 4 || event.item.level === 5;
    setSelect([event.itemHierarchicalIndex]);
    selectedLevel.current = event.item;
    const settings: TreeViewCheckChangeSettings = {
      singleMode: false,
      checkChildren: false,
      checkParents: false,
    };
    const eventData = {
      item: event.item,
      itemHierarchicalIndex: event.itemHierarchicalIndex,
    };

    if (
      eventData.itemHierarchicalIndex ===
      commonState?.selectedProject?.event?.itemHierarchicalIndex
    ) {
      // dispatch(setSelectedProject(null));
    } else {
      dispatch(
        setSelectedProject({
          event: eventData,
          check,
          treeView,
          settings,
          index: eventData.itemHierarchicalIndex,
        })
      );
    }

    if (event.item.level === 4 || event.item.level === 5) {
      memberSvc.getByMemberId(event.item.id).then((res) => {
        if (res.data) {
          setMemberDetail(res.data);
          prevMemberDetail.current = JSON.stringify(res.data);
        }
      });
    }
  };

  const onCheckChange = (event: TreeViewCheckChangeEvent) => {
    if (
      event.item.level === 0 ||
      event.item.level === 1 ||
      event.item.level === 2 ||
      event.item.level === 3 ||
      !event.item.isActive ||
      (showManageBBS && event.item.level === 5) ||
      event.item.status === "Released"
    )
      return;

    if (!event.nativeEvent.target.closest(".k-treeview")) {
      if (event.nativeEvent.code === "Space") return;
    }

    const settings: TreeViewCheckChangeSettings = {
      singleMode: false,
      checkChildren: false,
      checkParents: false,
    };
    const eventData = {
      item: event.item,
      itemHierarchicalIndex: event.itemHierarchicalIndex,
    };

    setCheck(
      handleTreeViewCheckChange(eventData as any, check, treeView, settings)
    );
  };

  useEffect(() => {
    if (!commonState.selectedProject) {
      setSelect([""]);
      return;
    }
    setSelect([commonState.selectedProject.event.itemHierarchicalIndex]);
  }, [commonState.selectedProject]);

  useEffect(() => {
    if (commonState?.fetchProjects) {
      fetchProjects();
      dispatch(resetFetchProjects());
    }
  }, [commonState?.fetchProjects]);

  useEffect(() => {
    if (commonState?.fetchMemberLines) {
      fetchMemberLines();
    }
  }, [commonState?.fetchMemberLines]);

  const fetchProjects = useCallback(
    (searchValue?: string) => {
      let searchFilters: ISearchFilter[] = [];
      if (searchValue) {
        searchFilters = [
          { field: "name", operator: "contains", value: searchValue },
        ];
      }
      projectSvc.getTree().then((res) => {
        if (res.data) {
          const treeViewData = mapProjectsToTreeView(res.data);
          setTreeView(treeViewData);
          setProjects(res.data);
          setExpand((prev) => {
            return { ...prev };
          });
        }
      });
    },
    [setProjects]
  );

  const fetchMemberLines = () => {
    if (
      commonState?.selectedProject?.event?.item &&
      (commonState?.selectedProject?.event?.item?.level === 4 ||
        commonState?.selectedProject?.event?.item?.level === 5)
    ) {
      memberSvc
        .getByMemberId(commonState?.selectedProject?.event.item.id)
        .then((res) => {
          if (res.data) {
            setMemberDetail(res.data);
            prevMemberDetail.current = JSON.stringify(res.data);
            dispatch(resetMemberLines());
          }
        });
    }
  };

  //Refreshing selecting project state
  useEffect(() => {
    if (
      projects?.length &&
      treeView?.length &&
      commonState?.selectedProject?.event?.item
    ) {
      const level = commonState?.selectedProject?.event?.item?.level;
      switch (level) {
        case 0:
          const item = treeView.find(
            (p: any) =>
              p.projectId ===
              commonState?.selectedProject?.event?.item?.projectId
          );
          if (
            item &&
            item.items?.length !==
              commonState?.selectedProject?.event?.item?.items?.length
          ) {
            let newItem = JSON.parse(
              JSON.stringify(commonState.selectedProject)
            );
            newItem.event.item = item;
            dispatch(setSelectedProject(newItem));
          }
          break;
        case 1:
          const parentItem: any = treeView.find(
            (p: any) =>
              p.projectId ===
              commonState?.selectedProject?.event?.item?.projectId
          );
          if (parentItem) {
            const item = parentItem.items.find(
              (l: any) =>
                l.levelCode ===
                commonState?.selectedProject?.event?.item?.levelCode
            );
            if (
              item &&
              item.items?.length !==
                commonState?.selectedProject?.event?.item?.items?.length
            ) {
              let newItem = JSON.parse(
                JSON.stringify(commonState.selectedProject)
              );
              newItem.event.item = item;
              dispatch(setSelectedProject(newItem));
            }
          }
          break;
      }
    }
  }, [treeView, projects, commonState?.selectedProject]);

  const prevProjectsLength = useRef(0);
  useEffect(() => {
    const treeViewData = mapProjectsToTreeView(projects);
    setTreeView(treeViewData);
    if (projects.length && prevProjectsLength.current !== projects.length) {
      prevProjectsLength.current = projects.length;
    }
  }, [projects]);

  const markStatus = (
    member: IMember,
    shopDwg: IShopDrawings,
    project: any
  ) => {
    // if (project.name === "NHM2 $" && member.name === "900010 pour-15 slab 1a") {
    //   debugger;
    // }

    return member.membersReleased == 0 &&
      shopDwg.memberParts?.filter((x) => x.parentMemberPartId == member.id)
        ?.length === 0
      ? "Engineered"
      : (member.membersReleased > 0 &&
          member.membersEngineered == member.membersReleased) ||
        member.membersReleased > member.membersEngineered ||
        (shopDwg.memberParts?.filter((x) => x.parentMemberPartId === member.id)
          .length > 0 &&
          shopDwg.memberParts
            ?.filter((x) => x.parentMemberPartId === member.id)
            .every((part) => part.noOfMem === part.membersReleased))
      ? "Released"
      : member.membersReleased > 0 && member.membersReleased != member.noOfMem
      ? "Partially Released"
      : "Engineered";
  };

  const mapProjectsToTreeView = (projects: IProject[]): TreeViewDataItem[] => {
    return projects.map((project, projectIdx) => ({
      text: project.name + "-Code-" + project.code,
      projectAbbreviation: project.abbreviation,
      callOffPrefix: project.callOffPrefix,
      level: 0,
      projectId: project.id,
      projectName: project.name,
      standardId: project.standardId,
      detailingOffices: project.detailingOffices,
      subcontractors: project.subcontractors,
      barLength: project.barLength,
      barType: project.barTypeCode,
      index: `${projectIdx}`,
      items: project.levels.map((level, levelIdx) => ({
        text: level.name + "-Code-" + level.code,
        level: 1,
        projectId: project.id,
        idx: levelIdx,
        barLength: project.barLength,
        barType: project.barTypeCode,

        standardId: project.standardId,
        levelCode: level.code,
        levelName: level.name,
        createdOn: level.createdOn,
        projectName: project.name,
        index: `${projectIdx}_${levelIdx}`,
        isActive: level.isActive,

        items: level.subLevels.map((subLevel, subLevelIdx) => ({
          text: subLevel.name + "-Code-" + subLevel.code,
          level: 2,
          projectId: project.id,
          parentId: levelIdx,
          idx: subLevelIdx,
          barLength: project.barLength,
          barType: project.barTypeCode,

          standardId: project.standardId,
          subLevelCode: subLevel.code,
          subLevelName: subLevel.name,
          levelCode: level.code,
          levelName: level.name,
          projectName: project.name,
          projectAbbreviation: project.abbreviation,
          callOffPrefix: project.callOffPrefix,
          isActive: subLevel.isActive,

          createdOn: subLevel.createdOn,
          index: `${projectIdx}_${levelIdx}_${subLevelIdx}`,

          items: subLevel.shopDrawings.map((shopDwg, shopDwgIdx) => ({
            text: shopDwg.name + "-Code-" + shopDwg.code,
            level: 3,
            projectId: project.id,
            parentId: subLevelIdx,
            idx: shopDwgIdx,
            levelId: levelIdx,
            subLevelId: subLevelIdx,
            barLength: project.barLength,
            barType: project.barTypeCode,

            standardId: project.standardId,
            subLevelCode: subLevel.code,
            subLevelName: subLevel.name,
            shopDrawingCode: shopDwg.code,
            shopDrawingName: shopDwg.name,
            levelCode: level.code,
            levelName: level.name,
            projectName: project.name,
            projectAbbreviation: project.abbreviation,
            callOffPrefix: project.callOffPrefix,
            index: `${projectIdx}_${levelIdx}_${subLevelIdx}_${shopDwgIdx}`,
            isActive: shopDwg.isActive,

            createdOn: shopDwg.createdOn,
            allMembers: shopDwg.members
              .filter((x) =>
                x != null && project.showDeleted ? true : x.isActive
              )
              .map((member, shopDrawingIdx) => {
                return member;
              }),
            items: shopDwg.members
              .filter((x) =>
                x != null && project.showDeleted ? true : x.isActive
              )
              .map((member, memberIdx) => ({
                id: member.id,
                memberId: member.id,
                memberName: member.name,
                code: member.id,
                text: member.name,
                levelId: levelIdx,
                status: markStatus(member, shopDwg, project),
                level: 4,
                projectName: project.name,
                projectId: project.id,
                projectAbbreviation: project.abbreviation,
                callOffPrefix: project.callOffPrefix,
                parentId: shopDwgIdx,
                shopDwgId: shopDwgIdx,
                subLevelId: subLevelIdx,
                idx: memberIdx,
                barLength: project.barLength,
                barType: project.barTypeCode,

                standardId: project.standardId,
                levelCode: level.code,
                levelName: level.name,
                subLevelCode: subLevel.code,
                subLevelName: subLevel.name,
                shopDrawingCode: shopDwg.code,
                shopDrawingName: shopDwg.name,
                isActive: member.isActive,
                noOfMem: member.noOfMem,
                superseded: member.superseded,
                index: `${projectIdx}_${levelIdx}_${subLevelIdx}_${shopDwgIdx}_${memberIdx}`,

                items: shopDwg.memberParts
                  ?.filter((x) => x.parentMemberPartId == member.id)
                  .map((memberPart, memberPartIdx) => ({
                    id: memberPart.id,
                    memberId: member.id,
                    memberName: member.name,
                    memberPartId: memberPart.id,
                    code: memberPart.id,
                    text: memberPart.name,

                    status:
                      memberPart.membersReleased == 0
                        ? "Engineered"
                        : (memberPart.membersReleased > 0 &&
                            memberPart.noOfMem == memberPart.membersReleased) ||
                          memberPart.membersReleased > memberPart.noOfMem
                        ? "Released"
                        : "Partially Released",

                    levelId: levelIdx,
                    level: 5,
                    projectName: project.name,
                    projectId: project.id,
                    projectAbbreviation: project.abbreviation,
                    callOffPrefix: project.callOffPrefix,
                    index: `${projectIdx}_${levelIdx}_${subLevelIdx}_${shopDwgIdx}_${memberIdx}_${memberPartIdx}`,
                    parentId: levelIdx,
                    subLevelId: subLevelIdx,
                    parentMemberId: memberIdx,
                    idx: memberPartIdx,
                    barLength: project.barLength,
                    barType: project.barTypeCode,

                    standardId: project.standardId,
                    levelCode: level.code,
                    levelName: level.name,
                    subLevelCode: subLevel.code,
                    subLevelName: subLevel.name,
                    shopDrawingCode: shopDwg.code,
                    shopDrawingName: shopDwg.name,
                    isActive: memberPart.isActive,
                    noOfMem: member.noOfMem,
                    isDisabled: false,
                  })),
              })),
          })),
        })),
      })),
    }));
  };

  const addLevel = (data: any, action: string) => {
    if (action === "edit") {
      setInputVal(data.text?.split("-Code-")[0]);
    }
    selectedLevel.current = data;
    selectedAction.current = action;
    if (action !== "delete") {
      toggleDialog();
    } else {
      toggleDeleteDialog();
    }
  };

  const handleDeleteDialog = (action: string) => {
    if (action === "yes") {
      deleteLevel();
    }
    toggleDeleteDialog();
  };

  const toggleDeleteDialog = () => {
    setModalVisible(!modalVisible);
  };
  const handleMemberDeleteDialog = (action: string) => {
    if (action === "yes") {
      deleteLevel();
    }
    toggleMemberDeleteDialog();
  };

  const toggleUnDeleteDialog = () => {
    setUnDeleteModalVisible(!unDeleteModalVisible);
  };

  const toggleMemberDeleteDialog = () => {
    setMemberDeleteModalVisible(!memberDeleteModalVisible);
  };

  const handleUndeleteDialog = (action: string) => {
    if (action === "yes") {
      unDeleteMember();
    }
    toggleUnDeleteDialog();
  };

  const setSelectedItem = (data: any) => {
    const idx = treeView.findIndex((x: any) => x.projectId === data?.projectId);
    if (idx === -1) return;

    var index = "";

    switch (data.level) {
      case 0:
        index = idx.toString();
        break;
      case 1:
        index = idx.toString() + "_" + data.idx;
        break;
      case 2:
        index =
          idx.toString() +
          "_" +
          data.parentId.toString() +
          "_" +
          data.idx.toString();
        break;
      case 3:
        index =
          idx.toString() +
          "_" +
          data.levelId.toString() +
          "_" +
          data.parentId.toString() +
          "_" +
          data.idx.toString();
        break;
    }

    setSelect([index]);
    selectedLevel.current = data;
  };

  const handleTreeSelection = () => {
    const settings: TreeViewCheckChangeSettings = {
      singleMode: false,
      checkChildren: false,
      checkParents: false,
    };
    const eventData = {
      item: selectedLevel.current,
      itemHierarchicalIndex: select.length ? select[0] : 0,
    };

    if (
      eventData.itemHierarchicalIndex ===
      commonState?.selectedProject?.event?.itemHierarchicalIndex
    ) {
      // dispatch(setSelectedProject(null));
    } else {
      dispatch(
        setSelectedProject({
          event: eventData,
          check,
          treeView,
          settings,
          index: eventData.itemHierarchicalIndex,
        })
      );
    }
  };

  //// --------- Project Right Click Functions  ---------- ///

  const handleManageProject = (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setPrevProject(proj);
    setShowEditProjectDrawer(!showEditProjectDrawer);
  };

  const handleSummary = async (data: any, action: string) => {
    const res = await projectSvc.exportSummary(
      data.projectId,
      data.projectName,
      action
    );
  };

  const handleManageDetailingOffices = (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setShowAddDetailingOfficeDrawer(!showAddDetailingOfficeDrawer);
  };
  const handleManageSubcontractors = (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setShowAddSubcontractorDrawer(!showAddSubcontractorDrawer);
  };

  //// --------- Project Right Click Functions  ---------- ///

  //// --------- Common Right Click Functions  ---------- ///

  const handleManageBBS = (data: any, action: string) => {
    setShowManageBBS(true);
    setShowMembersView(false);
  };
  const handleManageCallOff = (data: any, action: string) => {
    // dispatch(setSelectedMenu(commonState?.selected));
    // dispatch(setManageCallOff(commonState.manageCallOff));
    setShowManageCallOff(true);
    setShowMembersView(false);
  };

  //// --------- Common Right Click Functions  ---------- ///

  //// --------- Member Right Click Functions  ---------- ///

  const handleManageMemberProperties = (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setMemberProperties(data);
    if (data.memberId)
      setShowEditMemberPropertiesDrawer(!showEditMemberPropertiesDrawer);
    else setShowMemberPropertiesDrawer(!showMemberPropertiesDrawer);
  };

  const handleManageMemberRevision = (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setMemberProperties(data);
    setShowMemberRevisionDrawer(!showMemberRevisionDrawer);
  };

  const handleManageMemberImport = (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setMemberProperties(data);
    setMemberImportType(action);
    setShowMemberImportDrawer(!showMemberImportDrawer);
  };

  const handleMemberPDFExport = async (data: any, action: string) => {
    const res = await bbsSvc.exportMemberPDF(data.id);
    if (res.data) {
      if (res.data) {
        setFileUrl(res.data);
        setShowPdfPreview(true);
        setFileNameVal(data.name);
      }
    }
  };

  const handleMemberExcelExport = async (data: any, action: string) => {
    const res = await bbsSvc.exportMemberLines(data.id, data.name);
  };

  const handleMemberAssignment = async (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setMemberProperties(data);
    setShowMemberAssignmentsDrawer(!showMemberAssignmentsDrawer);
  };

  const handleMemberParts = async (data: any, action: string) => {
    const proj: any = projects?.find((p) => p.id === data.projectId);
    setProjectToEdit(proj);
    setMemberProperties(data);
    setShowMemberPartsDrawer(!showMemberPartsDrawer);
  };

  const handleCopyUnitsClick = async (data: any, action: string) => {
    const memberDetails: any = await memberSvc.getByMemberId(data.id);
    if (memberDetails?.data) {
      const selectedUnitsToCopy = memberDetails.data.memberLines.filter(
        (line: IMemberLine) => selectedUnits[line.id!]
      );

      const unitsToCopy = selectedUnitsToCopy.map((line: IMemberLine) => ({
        ...line,
        id: `Copy-${line.id}`,
        mark: `Copy-${line.mark}`,
        selected: false,
        pasted: true,
      }));
      dispatch(setCopiedUnits(unitsToCopy));
      dispatch(
        setToast({
          toastMsg: "Units copied successfully",
          toastType: "success",
        })
      );

      memberSvc.getByMemberId(data.id).then((res) => {
        if (res.data) {
          setMemberDetail(res.data);
          prevMemberDetail.current = JSON.stringify(res.data);
        }
      });
    }
  };

  const handlePasteUnitsClick = async (data: any, action: string) => {
    if (copiedUnits.length > 0) {
      const memberDetails: any = await memberSvc.getByMemberId(data.id);

      if (memberDetails?.data) {
        const updatedMemberDetails = {
          ...memberDetails.data,
          memberLines: [...memberDetails.data.memberLines, ...copiedUnits],
        };

        // Update member with new units
        const response = await memberSvc.updateMemberDetail(
          data.id,
          updatedMemberDetails
        );
        if (!response.error) {
          dispatch(
            setToast({
              toastMsg: "Units pasted successfully",
              toastType: "success",
            })
          );
          dispatch(clearCopiedUnits());
          dispatch(triggerFetchProjects());

          memberSvc.getByMemberId(data.id).then((res) => {
            if (res.data) {
              setMemberDetail(res.data);
              prevMemberDetail.current = JSON.stringify(res.data);
            }
          });
        }
      }
    }
  };

  const handleDeleteUnitsClick = async (data: any, action: string) => {
    const memberDetails: any = await memberSvc.getByMemberId(data.id);

    if (memberDetails?.data) {
      const unitsToDelete = memberDetails.data.memberLines.filter(
        (line: IMemberLine) => selectedUnits[line.id!]
      );

      if (unitsToDelete.length > 0) {
        await memberSvc.deleteMemberLines(data.id, unitsToDelete);
        dispatch(
          setToast({
            toastMsg: "Units deleted successfully",
            toastType: "success",
          })
        );
        dispatch(clearSelectedUnits());
        dispatch(triggerFetchProjects());

        memberSvc.getByMemberId(data.id).then((res) => {
          if (res.data) {
            setMemberDetail(res.data);
            prevMemberDetail.current = JSON.stringify(res.data);
          }
        });
      }
    }
  };

  //// --------- Member Right Click Functions  ---------- ///

  const toggleDialog = useCallback(() => {
    setShowDialog((prevShowDialog) => !prevShowDialog);
  }, []);

  const deleteLevel = async () => {
    const proj = projects.find((p) => p.id === selectedLevel.current.projectId);
    if (proj) {
      if (selectedLevel.current.level === 1) {
        proj.isLevelDeleted = true;
        proj.levelCode = selectedLevel.current.levelCode;
      }

      if (selectedLevel.current.level === 2) {
        proj.isSubLevelDeleted = true;
        proj.levelCode = selectedLevel.current.levelCode;
        proj.subLevelCode = selectedLevel.current.subLevelCode;
      }

      if (selectedLevel.current.level === 3) {
        proj.levelCode = selectedLevel.current.levelCode;
        proj.subLevelCode = selectedLevel.current.subLevelCode;
        proj.shopDrawingCode = selectedLevel.current.shopDrawingCode;
        proj.isShopDrawingDeleted = true;
      }

      if (selectedLevel.current.level === 4) {
        proj.isMemberDeleted = true;
        proj.memberDeletedReason = deletionReason;
        proj.memberId = selectedLevel.current.id;
      }

      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Deleted successfully",
            })
          );

          fetchProjects();

          const item = treeView.find(
            (p: any) =>
              p.projectId ===
              commonState?.selectedProject?.event?.item?.projectId
          );
          if (
            item &&
            item.items?.length !==
              commonState?.selectedProject?.event?.item?.items?.length
          ) {
            let newItem = JSON.parse(
              JSON.stringify(commonState.selectedProject)
            );
            newItem.event.item = item;
            dispatch(setSelectedProject(newItem));
            setSelect([commonState?.selectedProject?.index.split("_")[0]]);
            setDeletionReason("");
          }
        }
      });
    }
  };

  const unDeleteMember = async () => {
    if (commonState?.selectedProject?.event?.item) {
      memberSvc.unDelete(selectedLevel.current.id).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Member is Un-Deleted successfully",
            })
          );
          fetchProjects();
        }
      });
    }
  };

  const handleSaveLevel = () => {
    switch (selectedAction.current) {
      case "new":
        createNewLevel();
        break;
      case "newChild":
        createNewChildLevel();
        break;
      case "newSubChild":
        createNewSubChildLevel();
        break;
      case "edit":
        editLevel();
        break;
      default:
        break;
    }
  };

  const editLevel = async () => {
    var proj = projects.find((p) => p.id === selectedLevel.current.projectId);
    if (proj) {
      await projectSvc
        .editLevel(
          proj?.id,
          selectedLevel.current.level,
          selectedLevel.current.levelCode,
          selectedLevel.current.level === 1
            ? inputVal
            : selectedLevel.current.levelName,
          selectedLevel.current.subLevelCode,
          selectedLevel.current.level === 2
            ? inputVal
            : selectedLevel.current.subLevelName,
          selectedLevel.current.shopDrawingCode,
          selectedLevel.current.level === 3
            ? inputVal
            : selectedLevel.current.shopDrawingName,
          selectedLevel.current.id,
          selectedLevel.current.level === 4 || selectedLevel.current.level === 5
            ? inputVal
            : selectedLevel.current.text
        )
        .then((res) => {
          if (!res.error) {
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: "Updated successfully",
              })
            );
            fetchProjects();
            setInputVal("");
            setShowDialog(false);
          }
        });
    }
  };

  const createNewChildLevel = async () => {
    const proj = projects.find((p) => p.id === selectedLevel.current.projectId);
    if (proj && selectedLevel.current.level === 1) {
      const newLevel: any = {
        name: inputVal,
        code: crypto.randomUUID(),
        subLevels: [],
      };
      proj?.levels[selectedLevel.current.idx].subLevels.push(newLevel);

      proj.levelAdded = 1;
    }
    if (proj) {
      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Element added successfully",
            })
          );
          fetchProjects();
          setInputVal("");
          setShowDialog(false);
        } else {
          proj?.levels[selectedLevel.current.idx].subLevels.pop();
        }
      });
    }
  };

  const createNewSubChildLevel = async () => {
    const proj = projects.find((p) => p.id === selectedLevel.current.projectId);
    if (proj && selectedLevel.current.level === 2) {
      const newLevel: any = {
        name: inputVal,
        code: crypto.randomUUID(),
        members: [],
      };
      proj?.levels[selectedLevel.current.parentId].subLevels[
        selectedLevel.current.idx
      ].shopDrawings.push(newLevel);

      proj.levelAdded = 2;
    }
    if (proj) {
      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Shop Drawing added successfully",
            })
          );
          fetchProjects();
          setInputVal("");
          setShowDialog(false);
        } else {
          proj?.levels[selectedLevel.current.parentId].subLevels[
            selectedLevel.current.idx
          ].shopDrawings.pop();
        }
      });
    }
  };

  const createNewLevel = async () => {
    const proj = projects.find((p) => p.id === selectedLevel.current.projectId);

    if (proj && selectedLevel.current.level === 0) {
      const newLevel: Level = {
        name: inputVal,
        code: crypto.randomUUID(),
        subLevels: [],
        isActive: true,
      };
      proj?.levels.push(newLevel);

      proj.levelAdded = 1;
    }

    if (proj) {
      await projectSvc.update(proj?.id, proj).then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Level added successfully",
            })
          );
          fetchProjects();
          setInputVal("");
          setShowDialog(false);
        } else {
          proj?.levels.pop();
        }
      });
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    // if (commonState?.selectedProject) {
    //   setCheck(
    //     handleTreeViewCheckChange(
    //       commonState.selectedProject.event,
    //       commonState.selectedProject.check,
    //       commonState.selectedProject.treeView,
    //       commonState.selectedProject.settings
    //     )
    //   );
    // }

    if (commonState?.selectedProject?.event?.item?.level === 2) {
      setDistinctMembers(
        commonState?.selectedProject?.event?.item?.allMembers || [] //.flat()
      );
    }
  }, [commonState?.selectedProject?.event?.item]);

  useEffect(() => {
    if (showAddProjectDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showAddProjectDrawer]);

  const getModalHeaderText = () => {
    if (selectedAction.current === "new") {
      if (selectedLevel.current.level === 0) {
        return "Add Level";
      }
      if (selectedLevel.current.level === 1) {
        return "Add Element";
      }
      if (selectedLevel.current.level === 2) {
        return "Add Drawing";
      }
    }

    if (selectedAction.current === "newChild") {
      return "Add Element";
    }
    if (selectedAction.current === "newSubChild") {
      return "Add Drawing";
    }

    if (selectedAction.current === "edit") {
      if (selectedLevel.current.level === 1) {
        return "Edit Level";
      }

      if (selectedLevel.current.level === 2) {
        return "Edit Element";
      }
      if (selectedLevel.current.level === 3) {
        return "Edit Drawing";
      }

      if (selectedLevel.current.level === 4) {
        return "Edit Member Name";
      }

      if (selectedLevel.current.level === 5) {
        return "Edit Member Part Name";
      }
    }
  };

  const filteredTreeView = useMemo(() => {
    let filteredProjects = projects;

    if (searchValue.trim()) {
      const lowercasedSearch = searchValue.toLowerCase();
      filteredProjects = projects.filter(
        (project) =>
          project.name.toLowerCase().includes(lowercasedSearch) ||
          project.levels.some(
            (level) =>
              level.name.toLowerCase().includes(lowercasedSearch) ||
              level.subLevels.some(
                (subLevel) =>
                  subLevel.name.toLowerCase().includes(lowercasedSearch) ||
                  subLevel.shopDrawings.some(
                    (shopDwg) =>
                      shopDwg.name.toLowerCase().includes(lowercasedSearch) ||
                      shopDwg.members.some((member) =>
                        member.name.toLowerCase().includes(lowercasedSearch)
                      )
                  )
              )
          )
      );
    }

    if (showManageCallOff || showManageBBS) {
      filteredProjects = projects.filter(
        (project) =>
          commonState?.selectedProject?.event?.item?.projectId == project.id
      );
    }

    var treeViewData = mapProjectsToTreeView(filteredProjects);
    setTreeView(treeViewData);

    return treeViewData;
  }, [
    commonState.selectedProject,
    projects,
    searchValue,
    showManageBBS,
    showManageCallOff,
  ]);

  const handleDownload = (e: any) => {
    handlePdfDownload();
    setShowPdfPreview(false);
    return false;
  };
  const handlePdfDownload = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `${fileNameVal}`);
    document.body.appendChild(link);
    link.click();
    setShowDialog(false);
    // document.removeChild(link);
  };

  return (
    <div className={`${selected ? "" : ""}`}>
      {modalVisible && (
        <CustomDialogBox
          dialogInfo={getDialogInfo("delete")}
          onToggleDeleteDialog={handleDeleteDialog}
        />
      )}
      {memberDeleteModalVisible && (
        <>
          <Dialog
            title={"Delete Member"}
            width={"500px"}
            onClose={() => toggleMemberDeleteDialog()}
          >
            <p className="mb-2">
              Are you sure you want to un delete this member? Please select a
              reason to Delete.
            </p>

            <div>
              <DropDownList
                className="px-2 font-small z-[100010]"
                data={DeletionReasons}
                value={deletionReason}
                onChange={(e) => setDeletionReason(e.value)}
              />
            </div>

            <DialogActionsBar>
              <div className="flex gap-x-2 justify-end">
                <Button
                  themeColor={"base"}
                  onClick={() => handleMemberDeleteDialog("no")}
                  className="iconcta"
                >
                  <span className="inline-flex items-center gap-1">
                    <X className="h-5 w-5" strokeWidth={1} />
                    No
                  </span>
                </Button>
                <Button
                  className="iconcta"
                  themeColor={"primary"}
                  disabled={!deletionReason}
                  onClick={() => handleMemberDeleteDialog("yes")}
                >
                  <span className="inline-flex items-center gap-1">
                    <CheckIcon className="h-5 w-5" strokeWidth={1} />
                    Yes
                  </span>
                </Button>
              </div>
            </DialogActionsBar>
          </Dialog>
        </>
      )}

      {unDeleteModalVisible && (
        <>
          <Dialog
            title={"Un Delete Member"}
            width={"345px"}
            onClose={() => toggleUnDeleteDialog()}
          >
            <p className="mb-2">
              Are you sure you want to un delete this member?
            </p>
            <DialogActionsBar>
              <div className="flex gap-x-2 justify-end">
                <Button
                  themeColor={"base"}
                  onClick={() => handleUndeleteDialog("no")}
                  className="iconcta"
                >
                  <span className="inline-flex items-center gap-1">
                    <X className="h-5 w-5" strokeWidth={1} />
                    No
                  </span>
                </Button>
                <Button
                  className="iconcta"
                  themeColor={"primary"}
                  onClick={() => handleUndeleteDialog("yes")}
                >
                  <span className="inline-flex items-center gap-1">
                    <CheckIcon className="h-5 w-5" strokeWidth={1} />
                    Yes
                  </span>
                </Button>
              </div>
            </DialogActionsBar>
          </Dialog>
        </>
      )}

      {showPdfPreview && (
        <div className={`fixed h-screen w-screen z-[999999] top-0 left-0`}>
          <div
            className="overlay"
            onClick={() => setShowPdfPreview(false)}
          ></div>
          <div className="flex justify-center items-center w-full h-full">
            {
              <PDFViewer
                url={fileUrl}
                style={{ height: "100vh", width: "90vw" }}
                saveFileName={fileNameVal}
                tools={tools}
                defaultZoom={1.5}
                onDownload={(e) => handleDownload(e)}
              />
            }
          </div>
        </div>
      )}
      {!selected && (
        <>
          <div className="p-2 flex justify-between w-full gap-x-2 min-h-[48px] sticky top-0">
            <SearchBox
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search.."
            />
            <Button
              title="Add New"
              className="border border-[#E2E8F0] rounded-md px-2 py-2 max-h-[40px]"
              onClick={() => {
                if (
                  commonState?.selectedProject?.event?.item?.level === 0 ||
                  !commonState?.selectedProject
                ) {
                  setShowAddProjectDrawer(!showAddProjectDrawer);
                } else if (
                  commonState?.selectedProject?.event?.item?.level != 3 &&
                  commonState?.selectedProject?.event?.item?.level != 2
                ) {
                  addLevel(selectedLevel.current, "new");
                }
              }}
            >
              <Plus className="text-[6px] text-[#484F61]" size="20" />
            </Button>
            <div className="flex gap-x-1 max-h-[40px]">
              <Button
                title="Collapse All"
                className="border border-[#E2E8F0] rounded-md px-2 py-2"
                onClick={handleCollapseAll}
              >
                <CopyMinus size="20" />
              </Button>
              <Button
                title="Expand All"
                className="border border-[#E2E8F0] rounded-md px-2 py-2"
                onClick={handleExpandAll}
              >
                <CopyPlus size="20" />
              </Button>
            </div>
          </div>
        </>
      )}
      {/* {selected && (
          <>
            <Button
              className="border border-[#E2E8F0] rounded-md px-2 py-0 max-h-[40px]"
              onClick={() => {
                if (
                  commonState?.selectedProject?.event?.item?.level === 0 ||
                  !commonState?.selectedProject
                ) {
                  setShowAddProjectDrawer(!showAddProjectDrawer);
                } else {
                  addLevel(selectedLevel.current, "new");
                }
              }}
            >
              <Plus className="text-[6px] text-[#484F61]" size={20} />
            </Button>
          </>
        )} */}

      {!selected && (
        <>
          <hr className="ml-[10px]" />
          <div
            className={`w-full mt-1  ${
              showManageBBS || showManageCallOff
                ? "h-[45vh!important] overflow-y-scroll"
                : "h-full overflow-y-auto"
            } ${commonState?.selected ? "" : ""}`}
          >
            <AddLevelContext.Provider
              value={{
                // Project Right Click Functions //

                addLevel: (data: any, action: string) => addLevel(data, action),
                handleManageProject: (data: any, action: string) =>
                  handleManageProject(data, action),
                handleSummary: (data: any, action: string) =>
                  handleSummary(data, action),
                handleManageDetailingOffices: (data: any, action: string) =>
                  handleManageDetailingOffices(data, action),
                handleManageSubcontractors: (data: any, action: string) =>
                  handleManageSubcontractors(data, action),
                // Project Right Click Functions //

                // Member Right Click Functions //
                handleManageMemberRevision: (data: any, action: string) =>
                  handleManageMemberRevision(data, action),
                handleManageMemberProperties: (data: any, action: string) =>
                  handleManageMemberProperties(data, action),
                handleManageMemberImport: (data: any, action: string) =>
                  handleManageMemberImport(data, action),
                handleMemberPDFExport: (data: any, action: string) =>
                  handleMemberPDFExport(data, action),
                handleMemberExcelExport: (data: any, action: string) =>
                  handleMemberExcelExport(data, action),
                handleMemberAssignment: (data: any, action: string) =>
                  handleMemberAssignment(data, action),
                handleMemberParts: (data: any, action: string) =>
                  handleMemberParts(data, action),
                // Member Right Click Functions //

                // Common Right Click Functions //
                handleManageBBS: (data: any, action: string) =>
                  handleManageBBS(data, action),
                handleManageCallOff: (data: any, action: string) =>
                  handleManageCallOff(data, action),

                // Common Right Click Functions //

                // Level Right Click Functions //

                handleCopyUnitsClick: (data: any, action: string) =>
                  handleCopyUnitsClick(data, action),

                handlePasteUnitsClick: (data: any, action: string) =>
                  handlePasteUnitsClick(data, action),

                handleDeleteUnitsClick: (data: any, action: string) =>
                  handleDeleteUnitsClick(data, action),
                // Level Right Click Functions //
                toggleMemberDeleteDialog: () => toggleMemberDeleteDialog(),
                toggleUnDeleteDialog: () => toggleUnDeleteDialog(),
                setSelectedItem: (data: any) => setSelectedItem(data),
                handleTreeSelection: handleTreeSelection,
                showManageBBS: showManageBBS,
                showManageCallOff: showManageCallOff,
              }}
            >
              <TreeView
                data={processTreeViewItems(filteredTreeView, {
                  select: select,
                  check: check,
                  expand: expand,
                })}
                className={`${
                  showManageBBS || showManageCallOff
                    ? "overflow-y-hidden"
                    : "overflow-y-auto"
                }`}
                size={"small"}
                expandIcons={true}
                onExpandChange={onExpandChange}
                // aria-multiselectable={true}
                onItemClick={onItemClick}
                checkboxes={showCheckboxes()}
                disableField="isDisabled"
                onCheckChange={onCheckChange}
                item={TreeViewItemCell}
              />
            </AddLevelContext.Provider>
            {showDialog && (
              <Dialog
                width={500}
                title={getModalHeaderText()}
                onClose={() => setShowDialog(false)}
                className="custom-dialog"
              >
                <div className="my-4">
                  <CustomLabel
                    label={getModalHeaderText()?.split(" ")[1] || ""}
                    required={true}
                  />
                  <Input
                    type="text"
                    placeholder={"Enter Name"}
                    value={inputVal}
                    onChange={(e: any) => {
                      setInputVal(e.target.value);
                    }}
                    autoComplete="new-password"
                    list="autoCompleteOff"
                  />
                </div>
                <DialogActionsBar>
                  <div className="flex justify-end gap-3">
                    <Button
                      className="border border-[#E2E8F0] xpx-2 py-2 font-small"
                      themeColor={"primary"}
                      disabled={!inputVal}
                      onClick={handleSaveLevel}
                    >
                      Save
                    </Button>
                    <Button
                      className="border border-[#E2E8F0]"
                      onClick={() => {
                        setInputVal("");
                        setShowDialog(false);
                      }}
                      themeColor={"base"}
                      fillMode="outline"
                    >
                      Cancel
                    </Button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TreeViewMain;

const TreeViewItemCell: React.FC<ItemRenderProps> = (props) => {
  const {
    addLevel,
    handleManageProject,
    handleSummary,
    handleManageDetailingOffices,
    handleManageSubcontractors,
    handleManageMemberRevision,
    handleManageMemberProperties,
    handleManageMemberImport,
    handleMemberPDFExport,
    handleMemberExcelExport,
    handleMemberAssignment,
    handleMemberParts,
    handleManageBBS,
    handleManageCallOff,
    handleCopyUnitsClick,
    handleDeleteUnitsClick,
    handlePasteUnitsClick,
    toggleUnDeleteDialog,
    setSelectedItem,
    showManageBBS,
    showManageCallOff,
    toggleMemberDeleteDialog,
    handleTreeSelection,
  } = useContext(AddLevelContext);
  const anchor = useRef<HTMLButtonElement>(null);

  const [show, setShow] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        anchor.current &&
        !anchor.current.contains(event.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const onClick = useCallback((data: any) => {
    setShow((prevShow) => !prevShow);
    setSelectedItem(data);
  }, []);

  const openDialog = (action: string) => {
    addLevel(props.item, action);
  };
  const unDeleteClick = () => {
    toggleUnDeleteDialog();
  };

  const openMemberDeleteDialog = () => {
    toggleMemberDeleteDialog();
  };

  const canDelete = () => {
    if (props.item.level === 0) return false;
    if (props.item.level !== 4) {
      return true;
    }

    if (props.item.level === 4 || props.item.level === 5) {
      if (props.item.status === "Released") return false;
    }
    // if (props.item.level === 2) {
    //   if (props.item.items && props.item.items.length > 0) {
    //     return false;
    //   }
    // }

    return true;
  };

  const canPerformMemberActions = () => {
    if (props.item.level === 4 || props.item.level === 5) {
      if (props.item.status === "Released") return false;
    }
    return true;
  };

  return (
    <>
      <div
        className={`text-left w-full ${
          props.item.level !== 0 && !props.item.isActive ? "inactive" : ""
        } ${
          (props.item.level === 4 || props.item.level === 5) &&
          props.item.status === "Released"
            ? "released"
            : props.item.status === "Partially Released"
            ? "partially-released"
            : ""
        }`}
      >
        <div className="flex items-center gap-x-2 group flex-1 w-full">
          <div
            className={`self-center ${
              showManageBBS && props.item.level === 4 ? "" : "cursor-pointer"
            } hover:text-primaryMain whitespace-pre-wrap line-clamp-1 flex-1 project-text`}
            // style={props.item.level === 5 ? { marginLeft: "-1.5rem" } : {}}
          >
            {props.item.level === 0 && (
              <Folder height={14} className="text-primaryMain" />
            )}
            {props.item.level === 1 && (
              <FolderGit height={14} className="text-primaryMain" />
            )}
            {props.item.level === 2 && (
              <FolderGit2 height={14} className="text-primaryMain" />
            )}
            {props.item.level === 3 && (
              <FolderGit2 height={14} className="text-primaryMain" />
            )}
            {props.item.level === 4 && (
              <List height={14} className="text-primaryMain" />
            )}
            {props.item.level === 5 && (
              <Component height={12} className="text-primaryMain" />
            )}

            {props.item.level === 4 || props.item.level === 5
              ? props.item.text
              : props.item.text?.split("-Code-")[0]}
          </div>
          {!showManageBBS && !showManageCallOff && (
            <div className="text-left h-5">
              <>
                <button
                  className={`${"opacity-100 visible"}  cta_dropdown  transition-all duration-300 ${
                    show ? "active" : ""
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    onClick(props.item);
                  }}
                  ref={anchor}
                >
                  <MoreHorizontal strokeWidth={1} size={22} />
                </button>
                <Popup
                  anchor={anchor.current}
                  show={show}
                  popupClass={"max-w-[300px]"}
                  anchorAlign={{ horizontal: "right", vertical: "bottom" }}
                  popupAlign={{ horizontal: "right", vertical: "top" }}
                >
                  <div ref={popupRef} onClick={handleTreeSelection}>
                    {props.item.level === 0 && (
                      <React.Fragment>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={() => {
                            setShow(!show);
                            handleManageProject(props.item, "save");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <FolderKanban className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage Project
                            </span>
                          </span>
                        </Button>
                        {/* <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleManageBBS(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Layers className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage BBS
                            </span>
                          </span>
                        </Button> */}
                        <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            // event.stopPropagation();
                            handleManageCallOff(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <BringToFront className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage Call-Off
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={() => {
                            handleManageDetailingOffices(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <BriefcaseIcon className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Detailing Offices
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={() => {
                            handleManageSubcontractors(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <User className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Subcontractors
                            </span>
                          </span>
                        </Button>

                        <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            setShow(!show);
                            openDialog("new");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <CopyPlus className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              New Level
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShow(!show);
                            handleSummary(props.item, "BBS");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Download className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              BBS Summary
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShow(!show);
                            handleSummary(props.item, "Revised Summary");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Download className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Revised Bar Marks Summary
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShow(!show);
                            handleSummary(props.item, "CallOff");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Download className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Call-Off Summary
                            </span>
                          </span>
                        </Button>
                      </React.Fragment>
                    )}

                    {props.item.level === 1 && (
                      <>
                        {/*<Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleManageBBS(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Layers className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage BBS
                            </span>
                          </span>
                        </Button>
                         <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleManageCallOff(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <BringToFront className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage Call-Off
                            </span>
                          </span>
                        </Button> */}
                        <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            setShow(!show);
                            openDialog("newChild");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <CopyPlus className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              New Element
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            setShow(!show);
                            openDialog("edit");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <SquarePen className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Rename
                            </span>
                          </span>
                        </Button>
                        {canDelete() && (
                          <Button
                            className="w-full iconBtn px-1 border-b border-neutral-20"
                            fillMode="solid"
                            onClick={(event) => {
                              event.stopPropagation();
                              setShow(!show);
                              openDialog("delete");
                            }}
                          >
                            <span className="inline-flex items-center gap-2 w-full">
                              <Trash2Icon className="w-4 h-4" />
                              <span className="flex-1 text-xs font-normal">
                                Delete
                              </span>
                            </span>
                          </Button>
                        )}
                      </>
                    )}
                    {props.item.level === 2 && (
                      <>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            // event.stopPropagation();
                            handleManageBBS(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Layers className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage BBS
                            </span>
                          </span>
                        </Button>
                        {/*<Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleManageCallOff(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <BringToFront className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage Call-Off
                            </span>
                          </span>
                        </Button> */}
                        {/* <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            setShow(!show);
                            openDialog("newSubChild");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <CopyPlus className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              New Drawing
                            </span>
                          </span>
                        </Button> */}
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            setShow(!show);
                            openDialog("edit");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <SquarePen className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Rename
                            </span>
                          </span>
                        </Button>
                        {canDelete() && (
                          <Button
                            className="w-full iconBtn px-1 border-b border-neutral-20"
                            fillMode="solid"
                            onClick={(event) => {
                              event.stopPropagation();
                              setShow(!show);
                              openDialog("delete");
                            }}
                          >
                            <span className="inline-flex items-center gap-2 w-full">
                              <Trash2Icon className="w-4 h-4" />
                              <span className="flex-1 text-xs font-normal">
                                Delete
                              </span>
                            </span>
                          </Button>
                        )}
                      </>
                    )}

                    {props.item.level === 3 && (
                      <>
                        <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={() => {
                            handleManageMemberProperties(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <CopyPlus className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              New Member
                            </span>
                          </span>
                        </Button>
                        {/*<Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleManageBBS(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Layers className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage BBS
                            </span>
                          </span>
                        </Button>
                         <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleManageCallOff(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <BringToFront className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage Call-Off
                            </span>
                          </span>
                        </Button> */}
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            setShow(!show);
                            openDialog("edit");
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <SquarePen className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Rename
                            </span>
                          </span>
                        </Button>
                        {canDelete() && (
                          <Button
                            className="w-full iconBtn px-1 border-b border-neutral-20"
                            fillMode="solid"
                            onClick={(event) => {
                              event.stopPropagation();
                              setShow(!show);
                              openDialog("delete");
                            }}
                          >
                            <span className="inline-flex items-center gap-2 w-full">
                              <Trash2Icon className="w-4 h-4" />
                              <span className="flex-1 text-xs font-normal">
                                Delete
                              </span>
                            </span>
                          </Button>
                        )}
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            // event.stopPropagation();
                            handleManageMemberImport(props.item, "member");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Import className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Import Member from Excel
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            // event.stopPropagation();
                            handleManageMemberImport(props.item, "multiple");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Import className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Import multiple Members from Excel
                            </span>
                          </span>
                        </Button>
                        {/* <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleManageMemberImport(props.item, "revision");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Import className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Import multiple new Members revisions from Excel
                            </span>
                          </span>
                        </Button> */}
                      </>
                    )}

                    {props.item.level === 4 && (
                      <>
                        {canPerformMemberActions() && (
                          <>
                            <Button
                              className="w-full iconBtn px-1"
                              fillMode="flat"
                              disabled={!props.item.isActive}
                              onClick={() => {
                                handleManageMemberRevision(props.item, "save");
                                setShow(!show);
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <SquarePlus className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Create New Member Revision
                                </span>
                              </span>
                            </Button>
                            <Button
                              className="w-full iconBtn px-1 border-b border-neutral-20"
                              fillMode="flat"
                              disabled={!props.item.isActive}
                              onClick={(event) => {
                                // event.stopPropagation();
                                handleMemberParts(props.item, "manage");
                                setShow(!show);
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <Ungroup className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Manage Member Parts
                                </span>
                              </span>
                            </Button>
                            <Button
                              className="w-full iconBtn px-1 border-b border-neutral-20"
                              fillMode="flat"
                              onClick={(event) => {
                                event.stopPropagation();
                                setShow(!show);
                                openDialog("edit");
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <SquarePen className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Rename
                                </span>
                              </span>
                            </Button>
                          </>
                        )}

                        {/* <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleManageBBS(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Layers className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage BBS
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleManageCallOff(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <BringToFront className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Manage Call-Off
                            </span>
                          </span>
                        </Button> */}
                        <Button
                          className="w-full iconBtn px-1  border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleMemberExcelExport(
                              {
                                id: props.item.id,
                                name: props.item.text,
                              },
                              "save"
                            );
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Download className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Export to Excel
                            </span>
                          </span>
                        </Button>
                        {canPerformMemberActions() && (
                          <>
                            <Button
                              className="w-full iconBtn px-1"
                              fillMode="flat"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleManageMemberImport(props.item, "units");
                                setShow(!show);
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <Import className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Import Member Units from Excel
                                </span>
                              </span>
                            </Button>
                            {/* <Button
                              className="w-full iconBtn px-1 border-b border-neutral-20"
                              fillMode="flat"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleManageMemberImport(
                                  props.item,
                                  "revision"
                                );
                                setShow(!show);
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <Import className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Import new Member revision from Excel
                                </span>
                              </span>
                            </Button> */}
                          </>
                        )}
                        <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleMemberAssignment(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Paperclip className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Show BBS\Call-Offs member used in
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleMemberPDFExport(
                              {
                                id: props.item.id,
                                name: props.item.text,
                              },
                              "save"
                            );
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <FileText className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Generate Report
                            </span>
                          </span>
                        </Button>

                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          disabled={!props.item.isActive}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleCopyUnitsClick(props.item, "copy");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Copy className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Copy Units
                            </span>
                          </span>
                        </Button>
                        {canPerformMemberActions() && (
                          <>
                            <Button
                              className="w-full iconBtn px-1"
                              fillMode="flat"
                              disabled={!props.item.isActive}
                              onClick={(event) => {
                                event.stopPropagation();
                                handlePasteUnitsClick(props.item, "paste");
                                setShow(!show);
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <ClipboardPaste className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Paste Units
                                </span>
                              </span>
                            </Button>
                            <Button
                              className="w-full iconBtn px-1 border-b border-neutral-20"
                              fillMode="flat"
                              disabled={!props.item.isActive}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteUnitsClick(props.item, "delete");
                                setShow(!show);
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <Trash2Icon className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Delete Units
                                </span>
                              </span>
                            </Button>
                          </>
                        )}
                        {canDelete() && (
                          <>
                            <Button
                              className="w-full iconBtn px-1"
                              fillMode="flat"
                              disabled={!props.item.isActive}
                              onClick={(event) => {
                                event.stopPropagation();
                                setShow(!show);
                                openMemberDeleteDialog();
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <Trash2Icon className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Delete
                                </span>
                              </span>
                            </Button>

                            <Button
                              className="w-full iconBtn px-1"
                              fillMode="flat"
                              disabled={props.item.isActive}
                              onClick={(event) => {
                                event.stopPropagation();
                                setShow(!show);
                                unDeleteClick();
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <Trash2Icon className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Un-Delete
                                </span>
                              </span>
                            </Button>
                          </>
                        )}

                        <Button
                          className="w-full iconBtn px-1"
                          fillMode="flat"
                          disabled={!props.item.isActive}
                          onClick={(e) => {
                            // e.stopPropagation();
                            handleManageMemberProperties(props.item, "save");
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <TableProperties className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Properties
                            </span>
                          </span>
                        </Button>
                      </>
                    )}
                    {props.item.level === 5 && (
                      <>
                        {canPerformMemberActions() && (
                          <>
                            <Button
                              className="w-full iconBtn px-1 border-b border-neutral-20"
                              fillMode="flat"
                              onClick={(event) => {
                                event.stopPropagation();
                                setShow(!show);
                                openDialog("edit");
                              }}
                            >
                              <span className="inline-flex items-center gap-2 w-full">
                                <SquarePen className="w-4 h-4" />
                                <span className="flex-1 text-xs font-normal">
                                  Rename
                                </span>
                              </span>
                            </Button>
                          </>
                        )}
                        <Button
                          className="w-full iconBtn px-1 border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            // event.stopPropagation();
                            handleMemberPDFExport(
                              {
                                id: props.item.id,
                                name: props.item.text,
                              },
                              "save"
                            );
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <FileText className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Generate Report
                            </span>
                          </span>
                        </Button>
                        <Button
                          className="w-full iconBtn px-1  border-b border-neutral-20"
                          fillMode="flat"
                          onClick={(event) => {
                            // event.stopPropagation();
                            handleMemberExcelExport(
                              {
                                id: props.item.id,
                                name: props.item.text,
                              },
                              "save"
                            );
                            setShow(!show);
                          }}
                        >
                          <span className="inline-flex items-center gap-2 w-full">
                            <Download className="w-4 h-4" />
                            <span className="flex-1 text-xs font-normal">
                              Export to Excel
                            </span>
                          </span>
                        </Button>
                      </>
                    )}
                  </div>
                </Popup>
              </>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
