import { Upload } from "@progress/kendo-react-upload";
import React from "react";
import templateMappingSvc from "../../../services/templateMapping.service";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";

interface IProps {
  setCallOffMembers?: React.Dispatch<React.SetStateAction<string[]>>;
  setCellsData: React.Dispatch<any>;
  setUploadedFile: any;
  setUploadedFilePath: React.Dispatch<string>;
  setInvalidFileErrMessage: React.Dispatch<string>;
  isSDI: boolean;
}

const UploadFile = ({
  setCallOffMembers,
  setCellsData,
  setUploadedFile,
  setUploadedFilePath,
  setInvalidFileErrMessage,
  isSDI,
}: IProps) => {
  const commonState = useAppSelector((state: RootState) => state.common);
  const dispatch = useAppDispatch();
  const handleUpload = async (event: any) => {
    setInvalidFileErrMessage("");
    const selectedItem = commonState?.selectedProject?.event?.item;
    const files = event.affectedFiles;
    if (files?.length > 0) {
      if (isSDI) {
        if (files[0].extension !== ".sdi") {
          setInvalidFileErrMessage("Only .sdi files are allowed");
          return;
        }

        setUploadedFile(files[0].getRawFile());

        const res: any = await templateMappingSvc.uploadSDIFile(
          files[0].getRawFile(),
          selectedItem?.projectId,
          selectedItem?.levelCode,
          selectedItem?.subLevelCode
        );

        if (res?.data) {
          if(setCallOffMembers) {
            setCallOffMembers(res.data.calledOffMembers);
          }
          setCellsData(res.data.data);
          setUploadedFilePath(res.data.filePath);
        } else {
          setUploadedFile(null);
          setCellsData(null);

          dispatch(
            setToast({
              toastType: "error",
              toastMsg: `Invalid file, Please check the file and upload again.`,
            })
          );
        }
      } else {
        if (files[0].extension !== ".xlsx" && files[0].extension !== ".xls") {
          setInvalidFileErrMessage("Only .xlsx and .xls files are allowed");
          return;
        }
        setUploadedFile(files[0].getRawFile());
        const res: any = await templateMappingSvc.uploadExcelFile(
          files[0].getRawFile(),
          selectedItem?.projectId,
          selectedItem?.levelCode,
          selectedItem?.subLevelCode
        );

        if (res?.data) {
          if(setCallOffMembers) {
            setCallOffMembers(res.data.calledOffMembers);
          }
          setCellsData(res.data.data);
          setUploadedFilePath(res.data.filePath);
        } else {
          setUploadedFile(null);
          setCellsData(null);

          dispatch(
            setToast({
              toastType: "error",
              toastMsg: `Invalid file, Please check the file and upload again.`,
            })
          );
        }
      }
    }
  };

  return (
    <Upload
      batch={false}
      multiple={false}
      defaultFiles={[]}
      restrictions={{
        allowedExtensions: [".xlsx", ".xls"],
      }}
      withCredentials={false}
      saveUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/save"}
      removeUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/remove"}
      onAdd={handleUpload}
      onRemove={() => {
        setUploadedFile(null);
        setCellsData(null);
      }}
    />
  );
};

export default UploadFile;
