import {
  InputClearValue,
  TextBox,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Search } from "lucide-react";
import React from "react";

interface SearchBoxProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const EMPTY_VALUE = "";

const SearchBox: React.FC<SearchBoxProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const handleChange = React.useCallback(
    (event: TextBoxChangeEvent) => {
      const inputValue = event.target.value as string;
      onChange(inputValue);
    },
    [onChange]
  );

  const handleClear = React.useCallback(() => {
    onChange(EMPTY_VALUE);
  }, [onChange]);

  return (
    <form className="w-full" onSubmit={(e) => e.preventDefault()}>
      <TextBox
        className="w-[100%!important] flex px-1 py-1 font-small border border-[#E2E8F0] rounded-md max-h-[40px]"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        prefix={() => (
          <React.Fragment>
            <InputClearValue onClick={handleClear}>
              <Search className="w-5 h-5 font-bold text-black" />
            </InputClearValue>
          </React.Fragment>
        )}
      />
    </form>
  );
};

export default SearchBox;
