import { State, process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridDataStateChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { Plus, X } from "lucide-react";
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ShowColumnsFilter } from "../../../component/global/ColumnMenu";
import Export from "../../../component/global/Export";
import CustomLabel from "../../../component/global/RequiredMark";
import BBSNumberField from "../../../component/inputs/BBSNumberField";
import BBSTextField from "../../../component/inputs/BBSTextField";
import PageTitle from "../../../component/ui/PageTitle";
import SearchBox from "../../../component/ui/SearchBox";
import Filters from "../../../constants/filters";
import IPageState from "../../../interfaces/page.interface";
import { IBarSize, IStandard } from "../../../interfaces/standard.interface";
import { ActionCellPopupSettings } from "../../../shared-components/custom-cells";
import { setToast } from "../../../store/features/toastSlice";
import {
  MIN_RESIZABLE_WIDTH_KENDO_TABLE,
  getPaginationData,
} from "../../../utils/utils";

const Pagination = getPaginationData();
let mappingChanged = false;

interface IBarSizeWithIdx extends IBarSize {
  idx: number;
}

interface IProps {
  standards: IStandard[];
  barSizes: IBarSizeWithIdx[];
  total: number;
  handleValidation: (name: string, isValid: boolean) => void;
  handleCreate: any;
  handleEdit: any;
  handleDelete: any;
  setBarSizes: any;
  action: string;
}

function BarSizes({
  standards,
  barSizes,
  setBarSizes,
  total,
  handleValidation,
  handleCreate,
  handleDelete,
  handleEdit,
  action,
}: IProps) {
  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current?.save();
    }
  };
  const initialRender = useRef(false);
  const dispatch = useDispatch();

  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [oldBarSizes, setOldBarSizes] = useState<IBarSize[]>([]);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);
  const [searchValue, setSearchValue] = useState("");
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      action,
      handleEditClick,
      deleteBarSize,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    )
  );
  const [addNewBarSize, setAddNewBarSize] = useState<{
    size: number;
    radius: number;
    unitWeight: number;
    area: number;
    projection: number;
    standardId: string;
  }>();
  const [editBarSizeValue, setEditBarSizeValue] = useState<
    IBarSize & { standardId: string; idx: number; standardName: string }
  >();

  const createDataState = (dataState: State, data: any) => {
    let createdState: any = {
      result: [],
      dataState: dataState,
    };
    if (data?.data) {
      const temp = data.data;
      createdState = {
        result: process(temp.slice(0), dataState),
        dataState: dataState,
      };
    } else if (data) {
      createdState = {
        result: process(data?.slice(0), dataState),
        dataState: dataState,
      };
    }
    return createdState;
  };

  let initialState = createDataState(
    {
      take: 20,
      skip: 0,
    },
    barSizes
  );

  const [dataState, setDataState] = useState<State>(initialState.dataState);

  function handleEditClick(e: any) {
    setShowEditDrawer(true);
    setEditBarSizeValue(e);
  }

  const handleValueChange = (e: any) => {
    setAddNewBarSize((prev: any) => ({
      ...prev,
      [e.target.name]: e.value,
    }));
  };
  const handleEditChange = (e: any) => {
    setEditBarSizeValue((prev: any) => ({
      ...prev,
      [e.target.name]: e.value,
    }));
  };

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      action,
      handleEditClick,
      deleteBarSize,
      onColumnsSubmit,
      columnsState,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    if (event.dataState.filter === undefined) {
      createDataState(
        {
          take: 20,
          skip: 0,
        },
        barSizes
      );
      setBarSizes(oldBarSizes);
      setDataState(initialState.dataState);
      return;
    }
    let updatedState = createDataState(event.dataState, barSizes);
    setBarSizes(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      const filteredData = oldBarSizes.filter(
        (barSize) => barSize.size === Number(value)
      );
      setBarSizes(filteredData);
    }
    if (!value) {
      setBarSizes(oldBarSizes);
    }
  };

  const createBarSize = () => {
    if (addNewBarSize) {
      for (let barSize of barSizes) {
        if (barSize.size === addNewBarSize?.size) {
          dispatch(
            setToast({
              toastType: "error",
              toastMsg: "Size must be unique",
            })
          );
          return;
        }
      }
      handleCreate(addNewBarSize, "barSizes");
      setShowCreateDrawer(false);
    }
  };

  const editBarSize = () => {
    for (let barSize of barSizes) {
      if (barSize.size === editBarSizeValue?.size) {
        if (barSize.idx !== editBarSizeValue?.idx) {
          dispatch(
            setToast({
              toastType: "error",
              toastMsg: "Size must be unique",
            })
          );
          return;
        }
      }
    }
    handleEdit(editBarSizeValue, "barSizes");
    setShowEditDrawer(false);
  };

  function deleteBarSize(e: any) {
    handleDelete(e, "barSizes");
  }

  const getDropdownValue = () => {
    const standard = standards?.find(
      (std) => std.id === editBarSizeValue?.standardId
    );
    if (!standard) return;
    return standard.name;
  };

  useEffect(() => {
    if (!barSizes?.length || !columnsMapping) return;
    const cols = generateColumns(
      action,
      handleEditClick,
      deleteBarSize,
      onColumnsSubmit,
      null,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }, [barSizes, columnsMapping]);

  useEffect(() => {
    if (barSizes?.length && initialRender.current === false) {
      setOldBarSizes(barSizes);
      initialRender.current = true;
    }
  }, [barSizes]);

  return (
    <>
      <div className="setting-page">
        <Grid
          style={{ maxHeight: "" }}
          data={barSizes}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          pageable={true}
          pageSize={20}
          skip={page.skip}
          take={page.take}
          total={total}
          className={`customHeight employee`}
          resizable={Filters.resizeable}
        >
          <GridToolbar>
            <div className="flex gap-3 w-full items-center">
              <div className="w-[250px]">
                <SearchBox
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search"
                />
              </div>
              {action !== "read" && (
                <Button
                  themeColor={"primary"}
                  className="border border-[#E2E8F0]"
                  onClick={() => setShowCreateDrawer(true)}
                >
                  <span className="inline-flex items-center gap-2">
                    <Plus className="w-4 h-4" /> Add New
                  </span>
                </Button>
              )}
              <div
                className="cursor-pointer  sm:ml-0 ml-auto sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
                <ExcelExport
                  data={oldBarSizes}
                  collapsible={true}
                  fileName="Bar Sizes.xlsx"
                  ref={_exporter}
                >
                  {stateColumns &&
                    stateColumns.length > 0 &&
                    stateColumns.map((column: any, idx: number) => {
                      if (column.show) {
                        return (
                          <ExcelExportColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                          />
                        );
                      }
                    })}
                </ExcelExport>
              </div>
            </div>
          </GridToolbar>
          {stateColumns &&
            stateColumns.length > 0 &&
            stateColumns.map((column: any, idx: number) => {
              if (column.show) {
                if (column.cells) {
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      columnMenu={column.columnMenu}
                      minResizableWidth={200}
                      // width={200}
                      cells={column.cells}
                      filter={column.filter ? column.filter : "text"}
                    />
                  );
                }
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    // width={200}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
            })}
        </Grid>
      </div>
      {/*Create Drawer*/}
      <Drawer
        expanded={showCreateDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500"
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowCreateDrawer(false)}
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showCreateDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Add New Bar Size</h2>
              <Button
                fillMode="flat"
                className="border border-[#E2E8F0]"
                onClick={() => setShowCreateDrawer(false)}
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <BBSNumberField
                    name="size"
                    value={addNewBarSize?.size}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="Size"
                    required={false}
                    placeholder="Enter Size"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="radius"
                    value={addNewBarSize?.radius}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="Radius"
                    required={false}
                    placeholder="Enter Radius"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="unitWeight"
                    value={addNewBarSize?.unitWeight}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="Unit Weight"
                    required={false}
                    placeholder="Enter Unit Weight"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="area"
                    value={addNewBarSize?.area}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="Area"
                    required={false}
                    placeholder="Enter Area"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="projection"
                    value={addNewBarSize?.projection}
                    onChange={handleValueChange}
                    onValidate={handleValidation}
                    label="Projection"
                    required={false}
                    placeholder="Enter Projection"
                    disabled={false}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                onClick={() => setShowCreateDrawer(false)}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                themeColor={"primary"}
                onClick={createBarSize}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
      {/*Edit Drawer*/}
      <Drawer
        expanded={showEditDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500"
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowEditDrawer(false)}
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showEditDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Edit Bar Size</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() => setShowEditDrawer(false)}
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <BBSNumberField
                    name="size"
                    value={editBarSizeValue?.size}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="Size"
                    required={false}
                    placeholder="Enter Size"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="radius"
                    value={editBarSizeValue?.radius}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="Radius"
                    required={false}
                    placeholder="Enter Radius"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="unitWeight"
                    value={editBarSizeValue?.unitWeight}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="Unit Weight"
                    required={false}
                    placeholder="Enter Abbreviation"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSNumberField
                    name="area"
                    value={editBarSizeValue?.area}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="Area"
                    required={false}
                    placeholder="Enter Area"
                    disabled={false}
                  />
                </div>
                <div>
                  <BBSTextField
                    name="projection"
                    value={editBarSizeValue?.projection}
                    onChange={handleEditChange}
                    onValidate={handleValidation}
                    label="Projection"
                    required={false}
                    placeholder="Enter Projection"
                    disabled={false}
                    error="Invalid Code"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                onClick={() => setShowEditDrawer(false)}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                themeColor={"primary"}
                onClick={editBarSize}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default BarSizes;

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  action: string,
  handleEditClick: any,
  handleDeleteClick: any,
  onColumnsSubmit: any,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  const isVisible = (field: string) => {
    if (!columnsMapping?.length) return false;
    if (columnsMapping.includes(field)) {
      return true;
    }

    return false;
  };
  const columns = [
    {
      title: "Size",
      field: "size",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["size"]}
        />
      ),
      cells: {
        data: (props: any) => (
          <ActionCellPopupSettings
            rowData={props.dataItem}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            action={action}
            dataKey="size"
          />
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Radius",
      field: "radius",
      show: changed ? isVisible("radius") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["size"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Unit Weight",
      field: "unitWeight",
      show: changed ? isVisible("unitWeight") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["size"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Area",
      field: "area",
      show: changed ? isVisible("area") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["size"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Projection",
      field: "projection",
      show: changed ? isVisible("projection") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["size"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
  ];

  return columns;
};
