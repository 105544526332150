import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import React, { Suspense, lazy } from "react";

const Imports = lazy(() => import("../BBSComponents/ImportBBS"));

function ImportDrawer({
  show,
  setShow,
  isSDI,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isSDI: boolean;
}) {
  return (
    <div className="">
      <Drawer
        expanded={show}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500"
      >
        <DrawerContent>
          <div className="overlay" onClick={() => setShow(false)}></div>
          <div
            className={`max-w-[1330px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 overflow-y-auto  min-h-screen  ${
              show ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <Suspense fallback={<div>Loading...</div>}>
              {<Imports show={show} setShowDrawer={setShow} isSDI={isSDI} />}
            </Suspense>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default ImportDrawer;
