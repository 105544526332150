import { ShowColumnsFilter } from "../../component/global/ColumnMenu";
import { SubContractorActionCellPopup } from "../../shared-components/custom-cells";
import { MIN_RESIZABLE_WIDTH_KENDO_TABLE } from "../../utils/utils";

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
  columnMenu?: any;
  minResizableWidth?: number;
  cells?: any;
}

export const generateColumns = (
  handleEditClick: any,
  handleDeleteClick: any,
  onColumnsSubmit: any,
  columnsExternal?: any,
  columnsMapping?: any,
  changed?: boolean
): any => {
  const isVisible = (field: string) => {
    if (!columnsMapping?.length) return false;
    if (columnsMapping.includes(field)) {
      return true;
    }

    return false;
  };
  const columns = [
    {
      title: "Name",
      field: "name",
      show: true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      cells: {
        data: (props: any) => (
          <SubContractorActionCellPopup
            {...props}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      },
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Abbreviation",
      field: "abbreviation",
      show: changed ? isVisible("abbreviation") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["abbreviation"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Address",
      field: "address",
      show: changed ? isVisible("address") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
    {
      title: "Contact Person",
      field: "contactPerson",
      show: changed ? isVisible("contactPerson") : true,
      columnMenu: (props: any) => (
        <ShowColumnsFilter
          {...props}
          columns={columns || columnsExternal}
          onColumnsSubmit={onColumnsSubmit}
          showColumnMenu={true}
          disabledColumns={["name"]}
        />
      ),
      minResizableWidth: MIN_RESIZABLE_WIDTH_KENDO_TABLE,
    },
  ];

  return columns;
};
