import React, { useEffect, useState } from "react";
import { IBBS } from "../../interfaces/bbs.interface";
import bbsSvc from "../../services/bbs.service";
import { X } from "lucide-react";
import memberSvc from "../../services/member.service";
import { IMember } from "../../interfaces/member.interface";
import { Checkbox } from "@progress/kendo-react-inputs";

function Members({
  selectedRow,
  setSelectedMembers,
  setMembers,
  members,
}: {
  selectedRow: IBBS;
  setSelectedMembers: any;
  members: any;
  setMembers: any;
}) {
  useEffect(() => {
    if (selectedRow?.id) {
      memberSvc.getByBbs(selectedRow!.id).then((x: any) => {
        if (x.data) setMembers(x.data);
        else {
          setMembers([]);
          setSelectedMembers([]);
        }
      });
    }
  }, [selectedRow]);

  const handleCheckboxSelectionChange = (event: any) => {
    if (event.target.value) {
      setSelectedMembers((prev: any) => {
        var member = members.find(
          (x: IMember) => x.id === event.target.element.value
        );

        return [...prev, member];
      });
    } else {
      setSelectedMembers((prev: any) => {
        return prev.filter(
          (member: any) => member.id !== event.target.element.value
        );
      });
    }
  };

  return (
    <>
      <div className="overflow-y-auto">
        <div className="card shadow-elevation8 py-1 relative z-10 w-full">
          <div className="p-2">
            <h3 className="text-[16px] text-txtPrimary tracking-[-0.08px] font-kanit font-medium">
              BBS Members
            </h3>
          </div>
          <div className="pl-2">
            {members &&
              members.length > 0 &&
              members.map((member: any, index: number) => {
                return (
                  <>
                    {!selectedRow?.isActive && (
                      <h4
                        key={index}
                        className="text-[11px] py-1 border-t border-neutral-30 text-txtPrimary tracking-[-0.10px] font-light"
                      >
                        {member.name}
                      </h4>
                    )}
                    {selectedRow?.isActive && (
                      <h4
                        key={index}
                        className="text-[11px] py-1 border-t border-neutral-30 text-txtPrimary tracking-[-0.10px] font-light"
                      >
                        <Checkbox
                          value={member.id}
                          label={member.name}
                          onChange={handleCheckboxSelectionChange}
                        />
                      </h4>
                    )}
                  </>
                );
              })}
            {members && members.length == 0 && (
              <h4 className="text-[14px] py-2 text-txtPrimary tracking-[-0.10px] font-light">
                No Member Exists
              </h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Members;
