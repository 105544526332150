import { State } from "@progress/kendo-data-query";
import {
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
  GridColumnMenuItemGroup,
  GridColumnMenuProps,
  GridColumnMenuSort,
} from "@progress/kendo-react-grid";
import { useState } from "react";
import Filters from "../../constants/filters";
import { columnInterface } from "../../pages/reports/columns";

export const ColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <div>
      <GridColumnMenuFilter
        {...props}
        expanded={true}
        filterOperators={Filters.filterOptions}
      />
    </div>
  );
};

export const ShowColumnsFilter = (props: any) => {
  const [columns, setColumns] = useState<Array<columnInterface>>(props.columns);
  const [columnsExpanded, setColumnsExpanded] = useState<boolean>(false);
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  const [checkboxFilterExpanded, setCheckboxFilterExpanded] =
    useState<boolean>(false);

  const onToggleColumn = (id: number) => {
    const newColumns = columns.map((column, idx) => {
      return idx === id ? { ...column, show: !column.show } : column;
    });
    setColumns(newColumns);
  };

  const onReset = (event: any) => {
    event.preventDefault();
    const newColumns: Array<columnInterface> = props.columns.map((col: any) => {
      return {
        ...col,
        show: true,
      };
    });
    setColumns(newColumns);
    props.onColumnsSubmit(newColumns);
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const onSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    props.onColumnsSubmit(columns);
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const onMenuItemClick = () => {
    const value = !columnsExpanded;
    setColumnsExpanded(value);
    setFilterExpanded(value ? false : filterExpanded);
  };

  const onFilterExpandChange = (value: boolean) => {
    setFilterExpanded(value);
    setColumnsExpanded(value ? false : columnsExpanded);
  };

  const onCheckboxFilterExpandChange = (value: boolean) => {
    setCheckboxFilterExpanded(value);
    setColumnsExpanded(value ? false : columnsExpanded);
  };

  const oneVisibleColumn = columns.filter((c) => c.show).length === 1;

  const columnField = props.column.field;
  const data = props?.rowData?.map((item: any) => {
    return {
      [columnField]: item,
    };
  });

  return (
    <div>
      <GridColumnMenuSort {...props} />

      {props?.showColumnMenu && (
        <GridColumnMenuFilter
          {...props}
          onExpandChange={onFilterExpandChange}
          expanded={filterExpanded}
          filterOperators={Filters.filterOptions}
        />
      )}

      {props?.showColumnMenuCheckbox && (
        <div className="column-menu-checkbox">
          <GridColumnMenuCheckboxFilter
            {...props}
            data={data || []}
            onExpandChange={onCheckboxFilterExpandChange}
            expanded={checkboxFilterExpanded}
            uniqueData={true}
          />
        </div>
      )}

      <GridColumnMenuItemGroup>
        <GridColumnMenuItem
          title={"Columns"}
          iconClass={"k-i-columns"}
          onClick={onMenuItemClick}
        />
        <GridColumnMenuItemContent show={columnsExpanded}>
          <div className={"k-column-list-wrapper"}>
            <form onSubmit={onSubmit} onReset={onReset}>
              <div className={"k-column-list"}>
                {columns.map((column, idx) => (
                  <div key={idx} className={"k-column-list-item"}>
                    <span>
                      <input
                        id={`column-visiblity-show-${idx}`}
                        className="k-checkbox k-checkbox-md k-rounded-md mr-2"
                        type="checkbox"
                        readOnly={true}
                        disabled={column.show && oneVisibleColumn}
                        checked={column.show}
                        onClick={() => {
                          onToggleColumn(idx);
                        }}
                      />
                      <label
                        htmlFor={`column-visiblity-show-${idx}`}
                        className="k-checkbox-label ms-2"
                        style={{ userSelect: "none" }}
                      >
                        {column.title}
                      </label>
                    </span>
                  </div>
                ))}
              </div>
              <div className={"k-actions k-hstack k-justify-content-stretch"}>
                <button
                  type={"reset"}
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  }
                >
                  Reset
                </button>
                <button
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  }
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  );
};

export const ColumnMenuWithShowColsFilter = (props: any) => {
  return (
    <div>
      <ShowColumnsFilter {...props} />
      {/* <GridColumnMenuFilter {...props} expanded={true} filterOperators={Filters.filterOptions} /> */}
    </div>
  );
};

export const ColumnMenuCheckboxFilter = (props: any) => {
  const data = props.rowData;

  const columnField = props.column.field;

  const filteredData = data?.filter(
    (item: any) => item[columnField] !== null && item[columnField] !== ""
  );
  return (
    <div className="column-menu-checkbox">
      <GridColumnMenuCheckboxFilter
        {...props}
        data={filteredData}
        expanded={true}
        uniqueData={true}
      />
    </div>
  );
};

export const ColumnMenuArrayCheckboxFilter = (props: any) => {
  const columnField = props.column.field;
  const data = props.rowData.map((item: any) => {
    return {
      [columnField]: item,
    };
  });

  return (
    <div className="column-menu-checkbox">
      <GridColumnMenuCheckboxFilter
        {...props}
        data={data}
        expanded={true}
        uniqueData={true}
      />
    </div>
  );
};

export const columnProps = (field: string, dataState: State) => {
  return {
    headerClassName: isColumnActive(field, dataState) ? "active" : "",
  };
};

const isColumnActive = (field: string, dataState: State) => {
  return (
    GridColumnMenuFilter.active(field, dataState.filter) ||
    GridColumnMenuSort.active(field, dataState.sort)
  );
};
