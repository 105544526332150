import { ArrowUpRight } from "lucide-react";
import React from "react";
interface StatsHomeCardProps {
  title: string;
  count: number;
  Icon: any;
  iconColor?: string;
}

const StatsHomeCard: React.FC<StatsHomeCardProps> = ({
  title,
  count,
  Icon,
  iconColor,
}) => {
  return (
    <div className="">
      <h2
        className={`xl:text-base text-sm flex items-center gap-1 text-neutral-130 group-hover:text-primaryMain`}
      >
        {title}
        {/* <ArrowUpRight className="w-4 h-4 text-primaryMain opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all" /> */}
      </h2>
      <div className="flex items-center gap-3 justify-between">
        <div className="xl:text-[35px] text-[22px] font-light font-kanit leading-[40px]">
          {count
            ? count?.toLocaleString("en-IN", { maximumFractionDigits: 0 })
            : 0}
        </div>
        <div>
          <Icon
            className={`xl:h-10 h-8 w-8 xl:w-10 `}
            strokeWidth={1}
            style={{ color: iconColor }}
          />
        </div>
      </div>
    </div>
  );
};

export default StatsHomeCard;
