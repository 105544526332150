import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import { IBarType, IStandard } from "../../../interfaces/standard.interface";
import projectSvc from "../../../services/project.service";
import standardSvc from "../../../services/standard.service";
import companySvc from "../../../services/company.service";
import { ICompany } from "../../../interfaces/company.interface";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { IBBS } from "../../../interfaces/bbs.interface";
import bbsSvc from "../../../services/bbs.service";
import {
  Grid,
  GridColumn as Column,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import callOffSvc from "../../../services/callOff.service";

const initialStateProject = {
  standardId: null,
  code: "",
  name: "",
  clientName: "",
  location: "",
  address: "",
  contactPerson: "",
  barLength: null,
  barTypeCode: "",
  detailingOffices: [],
  projectStandardLength: [],
  levels: [],
  subcontractors: [],
  companyId: "",
};

export const MemberAssignments = ({
  memberProperties,
  showMemberAssignmentsDrawer,
  setShowMemberAssignmentsDrawer,
  fetchProjects,
}: any) => {
  const dispatch = useAppDispatch();

  const [callOffs, setCallOffs] = useState<any[]>([]);
  const [bbs, setBBS] = useState<IBBS[]>([]);

  useEffect(() => {
    if (showMemberAssignmentsDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showMemberAssignmentsDrawer]);

  const fetchCallOffs = () => {
    if (
      memberProperties &&
      memberProperties.projectId &&
      memberProperties.memberId
    ) {
      callOffSvc.getByMember(memberProperties.memberId).then((res: any) => {
        if (res.data) {
          setCallOffs(res.data);
        }
      });
    }
  };

  const fetchBBS = async () => {
    if (
      memberProperties &&
      memberProperties.projectId &&
      memberProperties.memberId
    ) {
      bbsSvc
        .getMemberAssignments(
          memberProperties.projectId,
          memberProperties.levelCode,
          memberProperties.subLevelCode,
          memberProperties.memberId
        )
        .then((res: any) => {
          if (res.data) {
            setBBS(res.data);
          }
        });
    }
  };

  useEffect(() => {
    fetchBBS();
    fetchCallOffs();
  }, [memberProperties]);

  return (
    <>
      <Drawer
        expanded={showMemberAssignmentsDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500 "
        // onOverlayClick={() => handleEditClick}
        // onSelect={() => handleEditClick}
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() =>
              setShowMemberAssignmentsDrawer(!showMemberAssignmentsDrawer)
            }
          ></div>
          <div
            className={`max-w-[700px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showMemberAssignmentsDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Member Call-Offs and BBS</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() =>
                  setShowMemberAssignmentsDrawer(!showMemberAssignmentsDrawer)
                }
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <CustomLabel label="Member" required={true} />
                  <TextBox
                    value={memberProperties?.text}
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    autoComplete="new-password"
                    readOnly={true}
                    disabled={true}
                  />
                </div>
                <div className="py-2 flex items-center justify-between gap-2 border-b border-neutral-30">
                  <h3 className="font-medium text-lg">
                    Call-Offs member used in:
                  </h3>
                </div>
                <div>
                  <Grid
                    pageable={false}
                    sortable={false}
                    filterable={false}
                    size={"small"}
                    groupable={false}
                    scrollable="none"
                    data={callOffs}
                  >
                    <GridToolbar>
                      <Button title="Manage BBS" themeColor={"primary"}>
                        Manage Call-Off
                      </Button>
                    </GridToolbar>
                    <Column field="callOffNumber" title="No" />
                    <Column field="name" title="Name" />
                    <Column field="revisionNumber" title="Revision" />
                  </Grid>
                </div>
                <div className="py-2 flex items-center justify-between gap-2 border-b border-neutral-30">
                  <h3 className="font-medium text-lg">BBS member used in:</h3>
                </div>
                <div>
                  <Grid
                    pageable={false}
                    sortable={false}
                    filterable={false}
                    size={"small"}
                    groupable={false}
                    scrollable="none"
                    data={bbs}
                  >
                    <GridToolbar>
                      <Button title="Manage BBS" themeColor={"primary"}>
                        Manage BBS
                      </Button>
                    </GridToolbar>
                    <Column field="no" title="No" />
                    <Column field="name" title="Name" />
                    <Column field="revNo" title="Revision" width={100} />
                  </Grid>
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() =>
                  setShowMemberAssignmentsDrawer(!showMemberAssignmentsDrawer)
                }
              >
                Close
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
