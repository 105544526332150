import { useEffect, useState } from "react";
import LoginEase from "../../component/onboarding/LoginEase";
import OnBoardingContainer from "../../component/ui/container/OnBoardingContainer";
import { useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const settingsState: any = useAppSelector(
    (state: RootState) => state.settings
  );

  const [showCustomLogin, setShowCustomLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!settingsState) return;
    const authData = settingsState.settings.auth;
    const accessToken = localStorage.getItem("atToken") || "";

    const hasCustom = authData.find(
      (x: any) => x.type === "Custom" && x.enabled
    );

    if (hasCustom) {
      setShowCustomLogin(true);
    } else {
      setShowCustomLogin(false);

      if (accessToken !== "") {
        navigate("/home");
      }
    }
  }, [settingsState]);

  if (showCustomLogin) {
    return (
      <OnBoardingContainer>
        <LoginEase />
      </OnBoardingContainer>
    );
  }
  return <></>;
};

export default Login;
