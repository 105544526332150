import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ISearchFilter } from '../interfaces/searchFilter.interface';
import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class ShapesService extends BaseService {
  constructor() {
    super('Shapes');
  }

  async getByStandardId(standardId: string) {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/Shapes/by-standard/${standardId}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getImgByStandardId(shapeCode: string) {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/Shapes/img-by-code/${shapeCode}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportShapes(searchFilters: ISearchFilter[] = [], filter?: CompositeFilterDescriptor | null, sort?: any) {
    try {
      LoaderService.showLoader();

      let paramsObj: any = {};

      if (!filter) {
        filter = {
          filters: [],
          logic: "and"
        };
      }

      if (searchFilters?.length) {
        filter.filters.push({
          logic: "or",
          filters: searchFilters
        });
      }

      paramsObj = {
        ...paramsObj,
        filter: JSON.stringify(filter)
      };

      if (sort) {
        paramsObj = {
          ...paramsObj,
          sort: JSON.stringify(sort)
        };
      }

      const params = new URLSearchParams(paramsObj);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Shapes/excel-export?${params.toString()}`, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'shapes.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      return { data: true, error: null };

    } catch (error) {
      return { data: null, error };
    } finally {
      LoaderService.hideLoader();
    }
   }

}

const shapesSvc = new ShapesService();

export default shapesSvc;
