import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class BBSService extends BaseService {
  constructor() {
    super('BBS');
  }

  async getByMemberIds(memberIds: string) {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/BBS/by-memberIds/${memberIds}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getMemberNames(memberIds: string) {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/BBS/member-names/${memberIds}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getMemberAssignments(
    projectId: string,
    levelCode: string,
    subLevelCode: string,
    memberId: string
  ) {
    try {
      // LoaderService.showLoader();
      const res = await api.get(
        `/BBS/member-assignments/${projectId}/${levelCode}/${subLevelCode}/${memberId}`
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      // LoaderService.hideLoader();
    }
  }

  async getByCodeMemberId(
    projectId: string,
    levelCode: string,
    subLevelCode: string,
    memberId: string
  ) {
    try {
      // LoaderService.showLoader();
      const res = await api.get(
        `/BBS/by-member/${projectId}/${levelCode}/${subLevelCode}/${memberId}`
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      // LoaderService.hideLoader();
    }
  }

  async getByCode(
    projectId: string,
    levelCode: string,
    subLevelCode: string,
    shopDrawingCode: string
  ) {
    try {
      // LoaderService.showLoader();
      const res = await api.get(
        `/BBS/by-code/${projectId}/${levelCode}/${subLevelCode}/${shopDrawingCode}`
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      // LoaderService.hideLoader();
    }
  }

  async getByIds(bbsIds?: (string | undefined)[]) {
    try {
      const res = await api.get(`/BBS/by-ids/${bbsIds?.join(',')}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      // LoaderService.hideLoader();
    }
  }

  async exportBBS(bbsId: string, bbsNo: string = '') {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/export/${bbsId}`,
        {},
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', bbsNo);
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportBBSSDI(bbsId: string, bbsNo: string = '') {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/export-sdi/${bbsId}`,
        {},
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/octet-stream',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', bbsNo + '.sdi');
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportInterimBBS(
    filePath: string,
    mappingId: string,
    standardId: string,
    projectId: string,
    shopDrawingNo?: string,
    bbsNo?: string
  ) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/export-interim`,
        {
          FilePath: filePath,
          mappingId: mappingId,
          standardId: standardId,
          projectId: projectId,
          BbsName: shopDrawingNo,
        },
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${bbsNo}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportMemberPDF(memberId: string) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/export-member-pdf/${memberId}`,
        {},
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', `${fileName} - BBS ${type}`);
        // document.body.appendChild(link);
        // link.click();
        // document.removeChild(link);
        return { data: url, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportBBSPDF(bbsId: string, bbsNo: string, type: string) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/export-pdf/${bbsId}/${type}`,
        {},
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', `${fileName} - BBS ${type}`);
        // document.body.appendChild(link);
        // link.click();
        // document.removeChild(link);
        return { data: url, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportMemberLines(memberId: string, memberName: string) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/export-member-lines/${memberId}`,
        {},
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', memberName);
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async saveBBSExcel(file: any, prevFilePath: string, newFilePath: string) {
    try {
      LoaderService.showLoader();
      const formData = new FormData();
      formData.append('file', file);
      formData.append('PrevFilePath', prevFilePath);
      formData.append('NewFilePath', newFilePath);
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/save-bbs`,
        formData
      );
      if (res.ok) {
        if (res.data) {
          return { data: res.data, error: null };
        }
        return { data: null, error: res.data };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportErrorReport(
    filePath: string,
    mappingId: string,
    standardId: string,
    projectId: string,
    bbsName?: string,
    bbsNo?: string
  ) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/export-errors`,
        {
          FilePath: filePath,
          mappingId: mappingId,
          standardId: standardId,
          projectId: projectId,
          BbsName: bbsName,
        },
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'plain/text',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${bbsNo} - Error Report.txt`);
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportImportReport(filePath: string, bbsId: string, bbsNo: string) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/export-import-report`,
        {
          FilePath: filePath,
          BBSId: bbsId,
        },
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${bbsNo}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const bbsSvc = new BBSService();

export default bbsSvc;
