import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class MemberLineService extends BaseService {
  constructor() {
    super('MemberLine');
  }

  async getMemberLines(memberIds: string) {
    try {
      LoaderService.showLoader();
      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/api/MemberLine/member-part-lines/${memberIds}`
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const memberLineSvc = new MemberLineService();

export default memberLineSvc;
