import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import { IBarType, IStandard } from "../../../interfaces/standard.interface";
import projectSvc from "../../../services/project.service";
import standardSvc from "../../../services/standard.service";
import companySvc from "../../../services/company.service";
import { ICompany } from "../../../interfaces/company.interface";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { IBBS } from "../../../interfaces/bbs.interface";
import bbsSvc from "../../../services/bbs.service";
import memberSvc from "../../../services/member.service";
import { IMember } from "../../../interfaces/member.interface";
import BBSTextField from "../../../component/inputs/BBSTextField";
import {
  setMemberGridData,
  triggerFetchProjects,
} from "../../../store/features/commonSlice";
import { RootState } from "../../../store/store/store";
import { ISearchFilter } from "../../../interfaces/searchFilter.interface";

const initialStateMember = {
  id: "",
  name: "",
  bbsId: "",
  revisionNumber: "",
  noOfMem: null,
  memberType: "",
  extra1: "",
  extra2: "",
};
interface IProps {
  memberProperties: any;
  showMemberPropertiesDrawer: boolean;
  setShowMemberPropertiesDrawer: any;

  showEditMemberPropertiesDrawer: boolean;
  setShowEditMemberPropertiesDrawer: any;
  fetchProjects: any;
}

export const MemberProperties = ({
  memberProperties,
  showMemberPropertiesDrawer,
  setShowMemberPropertiesDrawer,
  showEditMemberPropertiesDrawer,
  setShowEditMemberPropertiesDrawer,
  fetchProjects,
}: IProps) => {
  const [newMember, setNewMember] = useState(initialStateMember);
  const [memberToEdit, setMemberToEdit] = useState<IMember | null>(null);
  const dispatch = useAppDispatch();
  const [bbsList, setBbsList] = useState<IBBS[] | null>(null);
  const commonState = useAppSelector((state: RootState) => state.common);

  useEffect(() => {
    if (showMemberPropertiesDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showMemberPropertiesDrawer]);

  const [validFields, setValidFields] = useState<any>({
    name: null,
    revisionNumber: null,
    noOfMem: null,
  });

  const fetchBBs = () => {
    let filters: any;
    let searchFilters: ISearchFilter[] = [];

    const { item } = commonState?.selectedProject?.event || {};
    if (item) {
      const { projectId } = item;

      filters = {
        filters: [
          {
            logic: "and",
            filters: [
              {
                field: "projectId",
                operator: "eq",
                value: projectId,
              },
              {
                field: "isActive",
                operator: "eq",
                value: true,
              },
            ],
          },
        ],
      };
    }

    bbsSvc.get(searchFilters, filters).then((res) => {
      setBbsList(res.data);
    });
  };

  const getNewBBBSValue = () => {
    const bbs = bbsList?.find((bbs) => bbs.id === newMember?.bbsId);
    if (!bbs) return "";
    return bbs;
  };

  const getEditBBSValue = () => {
    const bbs = bbsList?.find((bbs) => bbs.id === memberToEdit?.bbsId); // get bbsId
    if (!bbs) return "";
    return bbs;
  };

  useEffect(() => {
    if (showMemberPropertiesDrawer || showEditMemberPropertiesDrawer)
      fetchBBs();
  }, [showMemberPropertiesDrawer, showEditMemberPropertiesDrawer]);

  useEffect(() => {
    if (memberProperties.memberId) {
      memberSvc.getById(memberProperties.memberId).then((x) => {
        if (x.data) setMemberToEdit(x.data as IMember);
      });
    }
  }, [memberProperties]);

  const saveMember = async () => {
    try {
      var payload = {
        ...newMember,
        projectId: memberProperties?.projectId,
        projectName: memberProperties?.projectName,
        levelCode: memberProperties?.levelCode,
        levelName: memberProperties?.levelName,
        subLevelCode: memberProperties?.subLevelCode,
        subLevelName: memberProperties?.subLevelName,
        shopDrawingCode: memberProperties.shopDrawingCode,
        shopDrawingName: memberProperties?.shopDrawingName,
      };

      const res: any = await memberSvc.create(payload);
      if (res.data) {
        dispatch(triggerFetchProjects());
        setShowMemberPropertiesDrawer(false);

        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Member added successfully",
          })
        );
        dispatch(setMemberGridData());
      }
    } catch (error) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `Unable to save member, Please try again.`,
        })
      );
    }
  };

  const updateMember = async () => {
    try {
      const res: any = await memberSvc.update(memberToEdit!.id, memberToEdit);

      if (!res.error) {
        dispatch(triggerFetchProjects());
        setShowEditMemberPropertiesDrawer(false);
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Member updated successfully",
          })
        );
        dispatch(setMemberGridData());
      } else {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: res.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `Unable to updated member, Please try again.`,
        })
      );
    }
  };

  const validateMember = () => {
    if (!newMember) return false;
    if (!newMember.name || !newMember.revisionNumber || !newMember.bbsId)
      return false;

    return Object.values(invalidFields).every((field) => !field);
  };

  const validateMemberEdit = () => {
    if (!memberToEdit) return false;
    if (!memberToEdit.name || !memberToEdit.revisionNumber) return false;
    if (memberToEdit?.membersReleased > 0) return false;
    return Object.values(invalidFields).every((field) => !field);
  };

  const isReadOnlyForm = () => {
    if (memberToEdit && memberToEdit.membersReleased > 0) return true;
    return false;
  };

  const onMemberChange = (e: ComboBoxChangeEvent | TextBoxChangeEvent) => {
    setNewMember((prev: any) => {
      return {
        ...prev,
        [e.target.name as string]:
          e.target.name === "bbsId" ? e.value.id : e.value,
      };
    });
  };

  const onMemberEditChange = (e: ComboBoxChangeEvent | TextBoxChangeEvent) => {
    setMemberToEdit((prev: any) => {
      return {
        ...prev,
        [e.target.name as string]:
          e.target.name === "bbsId" ? e.value.id : e.value,
      };
    });
  };

  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev: any) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const validateName = (username: string) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    return nameRegex.test(username);
  };

  useEffect(() => {
    if (!showMemberPropertiesDrawer) setNewMember(initialStateMember);
  }, [showMemberPropertiesDrawer]);

  useEffect(() => {
    if (showMemberPropertiesDrawer && commonState?.selectedBBS) {
      setNewMember((prev: any) => {
        return {
          ...prev,
          bbsId: commonState?.selectedBBS?.id,
        };
      });
    }
  }, [showMemberPropertiesDrawer, commonState?.selectedBBS]);

  return (
    <>
      <Drawer
        expanded={showMemberPropertiesDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500 "
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() =>
              setShowMemberPropertiesDrawer(!showMemberPropertiesDrawer)
            }
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showMemberPropertiesDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Add New Member</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() =>
                  setShowMemberPropertiesDrawer(!showMemberPropertiesDrawer)
                }
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <CustomLabel label="Sub Title" required={false} />
                  <TextBox
                    value={memberProperties?.subLevelName}
                    type="text"
                    autoComplete="new-password"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
                <div>
                  <CustomLabel label="Name" required={true} />

                  <TextBox
                    value={newMember.name}
                    onChange={onMemberChange}
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    valid={!validFields.name}
                    autoComplete="new-password"
                  />
                  {validFields?.name && (
                    <p className="text-red-600 text-[12px]">
                      {validFields?.name}
                    </p>
                  )}
                </div>
                <div>
                  <BBSTextField
                    name="noOfMem"
                    value={newMember?.noOfMem || ""}
                    onChange={onMemberChange}
                    onValidate={handleValidation}
                    label="No Of Members"
                    required={true}
                    placeholder="Enter No Of Members"
                    disabled={false}
                    regex={/^[0-9]+$/}
                    error="Invalid Code"
                  />
                </div>
                <div>
                  <CustomLabel label="Revision No" required={true} />

                  <TextBox
                    onChange={onMemberChange}
                    value={newMember?.revisionNumber || ""}
                    placeholder="Revision Number"
                    name="revisionNumber"
                    valid={!validFields.revisionNumber}
                    autoComplete="new-password"
                  />
                  {validFields?.revisionNumber && (
                    <p className="text-red-600 text-[12px]">
                      {validFields?.revisionNumber}
                    </p>
                  )}
                </div>

                <div>
                  <CustomLabel label="BBS" required={true} />
                  <ComboBox
                    data={bbsList || []}
                    onChange={onMemberChange}
                    value={getNewBBBSValue()}
                    name="bbsId"
                    clearButton={false}
                    placeholder="Select BBS"
                    textField="no"
                    dataItemKey="id"
                  />
                </div>
                <div>
                  <CustomLabel label="Member Type" required={false} />

                  <TextBox
                    value={newMember.memberType || ""}
                    onChange={onMemberChange}
                    type="text"
                    placeholder="Enter Member Type"
                    name="memberType"
                    autoComplete="new-password"
                  />
                </div>
                <div>
                  <CustomLabel label="Extra 1" required={false} />

                  <TextBox
                    value={newMember.extra1 || ""}
                    onChange={onMemberChange}
                    type="text"
                    placeholder="Enter Extra 1"
                    name="extra1"
                    autoComplete="new-password"
                  />
                </div>
                <div>
                  <CustomLabel label="Extra 2" required={false} />

                  <TextBox
                    value={newMember.extra2 || ""}
                    onChange={onMemberChange}
                    type="text"
                    placeholder="Enter Extra 2"
                    name="extra2"
                    autoComplete="new-password"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => {
                  setNewMember(initialStateMember);
                  setShowMemberPropertiesDrawer(!showMemberPropertiesDrawer);
                }}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                onClick={saveMember}
                disabled={!validateMember()}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
      <Drawer
        expanded={showEditMemberPropertiesDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500 "
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() =>
              setShowEditMemberPropertiesDrawer(!showEditMemberPropertiesDrawer)
            }
          ></div>
          <div
            className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showEditMemberPropertiesDrawer
                ? "translate-x-0"
                : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg"> Edit Member Properties</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() =>
                  setShowEditMemberPropertiesDrawer(
                    !showEditMemberPropertiesDrawer
                  )
                }
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4">
                <div>
                  <CustomLabel label="Sub Title" required={false} />
                  <TextBox
                    value={memberProperties?.subLevelName}
                    type="text"
                    autoComplete="new-password"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
                <div>
                  <CustomLabel label="Name" required={true} />

                  <TextBox
                    value={memberToEdit?.name}
                    onChange={onMemberEditChange}
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    disabled={isReadOnlyForm()}
                    valid={!validFields.name}
                    autoComplete="new-password"
                  />
                  {validFields?.name && (
                    <p className="text-red-600 text-[12px]">
                      {validFields?.name}
                    </p>
                  )}
                </div>
                <div>
                  <CustomLabel label="No. Of Member" required={true} />

                  <TextBox
                    value={memberToEdit?.noOfMem || ""}
                    onChange={onMemberEditChange}
                    type="text"
                    disabled={isReadOnlyForm()}
                    placeholder="Enter No. of Member"
                    name="noOfMem"
                    valid={!validFields.noOfMem}
                    autoComplete="new-password"
                  />
                  {validFields?.name && (
                    <p className="text-red-600 text-[12px]">
                      {validFields?.name}
                    </p>
                  )}
                </div>
                <div>
                  <CustomLabel label="Revision No" required={true} />

                  <TextBox
                    onChange={onMemberEditChange}
                    value={memberToEdit?.revisionNumber || ""}
                    disabled={isReadOnlyForm()}
                    placeholder="Revision Number"
                    name="revisionNumber"
                    valid={!validFields.revisionNumber}
                    autoComplete="new-password"
                  />
                  {validFields?.revisionNumber && (
                    <p className="text-red-600 text-[12px]">
                      {validFields?.revisionNumber}
                    </p>
                  )}
                </div>

                <div>
                  <CustomLabel label="BBS" required={false} />
                  <ComboBox
                    data={bbsList || []}
                    onChange={onMemberEditChange}
                    value={getEditBBSValue()}
                    disabled={isReadOnlyForm()}
                    name="bbsId"
                    clearButton={false}
                    placeholder="Select BBS"
                    textField="no"
                    dataItemKey="id"
                  />
                </div>
                <div>
                  <CustomLabel label="Member Type" required={false} />

                  <TextBox
                    value={memberToEdit?.memberType || ""}
                    onChange={onMemberEditChange}
                    type="text"
                    placeholder="Enter Member Type"
                    disabled={isReadOnlyForm()}
                    name="memberType"
                    autoComplete="new-password"
                  />
                </div>
                <div>
                  <CustomLabel label="Extra 1" required={false} />

                  <TextBox
                    value={memberToEdit?.extra1 || ""}
                    onChange={onMemberEditChange}
                    type="text"
                    disabled={isReadOnlyForm()}
                    placeholder="Enter Extra 1"
                    name="extra1"
                    autoComplete="new-password"
                  />
                </div>
                <div>
                  <CustomLabel label="Extra 2" required={false} />

                  <TextBox
                    value={memberToEdit?.extra2 || ""}
                    onChange={onMemberEditChange}
                    disabled={isReadOnlyForm()}
                    type="text"
                    placeholder="Enter Extra 2"
                    name="extra2"
                    autoComplete="new-password"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => {
                  setMemberToEdit(null);
                  setShowEditMemberPropertiesDrawer(
                    !showEditMemberPropertiesDrawer
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                onClick={updateMember}
                disabled={!validateMemberEdit()}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
