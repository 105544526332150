import { Button } from "@progress/kendo-react-buttons";
import {
  DatePickerChangeEvent,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import BBSDatePicker from "../../../component/inputs/BBSDatePicker";
import BBSTextField from "../../../component/inputs/BBSTextField";
import { IBBS } from "../../../interfaces/bbs.interface";
import { IObjectType } from "../../../interfaces/objectType.interface";
import { IDetailingOffice } from "../../../interfaces/project.interface";
import objectTypeSvc from "../../../services/objectType.service";
import projectSvc from "../../../services/project.service";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { ICallOff } from "../../../interfaces/callOff.interface";

interface IProps {
  showCreateDrawer: boolean;
  setShowCreateDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  editCallOff: (ICallOff & { id: string }) | null;
  setEditCallOff: any;
  handleChange: (
    e:
      | TextBoxChangeEvent
      | ComboBoxChangeEvent
      | DatePickerChangeEvent
      | DateTimePickerChangeEvent,
    isDropdown?: boolean
  ) => void;
  onSave: (action?: string) => void;
}

function WriteDrawer({
  showCreateDrawer,
  setShowCreateDrawer,
  editCallOff,
  setEditCallOff,
  handleChange,
  onSave,
}: IProps) {
  const commonState = useAppSelector((state: RootState) => state.common);

  const [validFields, setValidFields] = useState<any>({
    name: null,
    revisionNumber: null,
  });

  const handleValidation = (name: string, isValid: boolean) => {
    setValidFields((prev: any) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const validateSave = () => {
    if (!editCallOff) return false;
    if (!editCallOff.name || !editCallOff.revisionNumber) return false;
    return true;
  };

  useEffect(() => {
    if (showCreateDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showCreateDrawer]);

  return (
    <Drawer
      expanded={showCreateDrawer}
      position={"start"}
      mode={"overlay"}
      animation={{ duration: 400 }}
      className="customDrawer2 transition-all duration-500"
    >
      <DrawerContent>
        <div
          className="overlay"
          onClick={() => setShowCreateDrawer(false)}
        ></div>
        <div
          className={`max-w-[400px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-screen w-full transform transition-all duration-500 overflow-y-scroll ${
            showCreateDrawer ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
            <h2 className="font-medium text-lg">
              {editCallOff && editCallOff.id ? "Edit" : "Add"} Call-Off
            </h2>
            <Button
              fillMode="flat"
              className="p-2 bg-none outline-none"
              onClick={() => setShowCreateDrawer(false)}
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
          <div className="flex-1 py-4">
            <div className="grid gap-4">
              <div>
                <CustomLabel label="Name" required={true} />

                <TextBox
                  onChange={handleChange}
                  value={editCallOff?.name || ""}
                  placeholder="Enter Call-Off Name"
                  name="name"
                  valid={!validFields.name}
                  autoComplete="new-password"
                  list="autocompleteOff"
                />
                {validFields?.name && (
                  <p className="text-red-600 text-[12px]">
                    {validFields?.name}
                  </p>
                )}
              </div>

              <div>
                <CustomLabel label="Revision Number" required={true} />

                <TextBox
                  onChange={handleChange}
                  value={editCallOff?.revisionNumber || ""}
                  placeholder="Enter Revision Number"
                  name="revisionNumber"
                  valid={!validFields.revisionNumber}
                  autoComplete="new-password"
                  list="autocompleteOff"
                />
                {validFields?.revisionNumber && (
                  <p className="text-red-600 text-[12px]">
                    {validFields?.revisionNumber}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 pb-4 sticky-buttons">
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              onClick={() => {
                setEditCallOff({});
                setShowCreateDrawer(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              themeColor={"primary"}
              onClick={() => {
                onSave(editCallOff?.id ? "Update" : "Add");
              }}
              disabled={!validateSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default WriteDrawer;
