import React, { useEffect, useState } from "react";
import BBSNumberField from "../../../component/inputs/BBSNumberField";
import BBSTextField from "../../../component/inputs/BBSTextField";
import {
  IMemberDetail,
  IMemberLine,
} from "../../../interfaces/member.interface";
import { IShapeCodes } from "../../../interfaces/shapes.interface";
import {
  IBar,
  IBarSize,
  IStandard,
} from "../../../interfaces/standard.interface";
import memberSvc from "../../../services/member.service";
import {
  setMemberLines,
  triggerFetchProjects,
} from "../../../store/features/commonSlice";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import MembersListing from "../MemberComponents/MembersListing";
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
const dimensions = [
  "dimA",
  "dimB",
  "dimC",
  "dimD",
  "dimE",
  "dimF",
  "dimG",
  "dimH",
  "dimK",
  "dimJ",
  "dimR",
  "dimI",
  "dimH2",
  "dimO",
];

interface IProps {
  shapeCodes: IShapeCodes[];
  standard: IStandard | null;
  copiedItems: any;
  setCopiedItems: any;
  pastedItems: any;
  setPastedItems: any;
  memberDetail: IMemberDetail | undefined;
  setMemberDetail: React.Dispatch<
    React.SetStateAction<IMemberDetail | undefined>
  >;
  prevMemberDetail: any;
  memberProperties: any;
  setMemberProperties: any;
  showMemberPartsDrawer: boolean;
  setShowMemberPartsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

function MemberDetails({
  shapeCodes,
  standard,
  setCopiedItems,
  pastedItems,
  setPastedItems,
  copiedItems,
  memberDetail,
  setMemberDetail,
  prevMemberDetail,
  memberProperties,
  setMemberProperties,
  showMemberPartsDrawer,
  setShowMemberPartsDrawer,
}: IProps) {
  const commonState = useAppSelector((state: RootState) => state.common);
  const [editID, setEditID] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState("");

  const dispatch = useAppDispatch();

  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedShape, setSelectedShape] = useState<IShapeCodes | undefined>();

  const [memberIsPart, setmemberIsPart] = useState<boolean>(false);
  const [memberIsActive, setmemberIsActive] = useState<boolean>(true);
  const [memberIsReleased, setmemberIsReleased] = useState<boolean>(false);

  const [selectedState, setSelectedState] = React.useState<{
    [id: string]: number | boolean;
  }>({});

  const handleChange = (e: any) => {
    setMemberDetail((prev: any) => {
      var updatedLines = prev?.memberLines;

      updatedLines?.forEach((item: any) => {
        item.noOfMem = e.target.value;
      });

      return {
        ...prev,
        memberLines: updatedLines,
      };
    });
  };
  const [showDialog, setShowDialog] = useState(false);

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  useEffect(() => {
    if (commonState?.selectedProject?.event?.item) {
      setEditID(null);

      if (!commonState?.selectedProject?.event?.item.isActive) {
        setmemberIsActive(false);
      } else {
        setmemberIsActive(true);
      }

      if (commonState?.selectedProject?.event?.item.memberPartId) {
        setmemberIsPart(true);
      } else {
        setmemberIsPart(false);
      }

      if (
        commonState?.selectedProject?.event?.item?.status === "Released" ||
        commonState?.selectedProject?.event?.item?.status ===
          "Partially Released"
      ) {
        setmemberIsReleased(true);
      } else {
        setmemberIsReleased(false);
      }
    }
  }, [commonState?.selectedProject?.event?.item]);

  const getMembers = () => {
    if (commonState?.selectedProject?.event?.item) {
      const mems = memberDetail?.memberLines?.filter((line) => {
        const targetMemberId = commonState?.selectedProject?.event?.item
          ?.memberPartId
          ? commonState?.selectedProject?.event?.item?.memberId
          : commonState?.selectedProject?.event?.item?.id;

        if (commonState?.selectedProject?.event?.item.isActive) {
          return line.isActive && line.memberId === targetMemberId;
        }

        return line.memberId === targetMemberId;
      });
      return mems;
    }
    return [];
  };

  const handleGridChange = (gridLines: IMemberLine[]) => {
    if (gridLines.length === 0) return;
    const updatedLines = gridLines; // replaceItemsByIdx(gridLines, selectedBBS?.lines!);
    setMemberDetail((prev: any) => {
      return {
        ...prev,
        memberLines: updatedLines,
      };
    });
  };

  function validMemberLines() {
    // Assuming memberDetail is accessible in this scope
    if (!memberDetail || !memberDetail.memberLines) {
      return false;
    }

    // Check if all member lines have valid data
    for (const line of memberDetail.memberLines) {
      const shape = shapeCodes.find((item) => item.code === line.shapeCode);

      if (!shape) {
        setErrMsg("Invalid Shape Code for Bar Mark: " + line.mark);
        return false;
      }

      if (shape?.dimA) {
        if (!line.dimA || line.dimA <= 0) {
          setErrMsg("Invalid Dimension A for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimB) {
        if (!line.dimB || line.dimB <= 0) {
          setErrMsg("Invalid Dimension B for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimC) {
        if (!line.dimC || line.dimC <= 0) {
          setErrMsg("Invalid Dimension C for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimD) {
        if (!line.dimD || line.dimD <= 0) {
          setErrMsg("Invalid Dimension D for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimE) {
        if (!line.dimE || line.dimE <= 0) {
          setErrMsg("Invalid Dimension E for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimF) {
        if (!line.dimF || line.dimF <= 0) {
          setErrMsg("Invalid Dimension F for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimG) {
        if (!line.dimG || line.dimG <= 0) {
          setErrMsg("Invalid Dimension G for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimH) {
        if (!line.dimH || line.dimH <= 0) {
          setErrMsg("Invalid Dimension H for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimK) {
        if (!line.dimK || line.dimK <= 0) {
          setErrMsg("Invalid Dimension K for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimJ) {
        if (!line.dimJ || line.dimJ <= 0) {
          setErrMsg("Invalid Dimension J for Bar Mark: " + line.mark);
          return false;
        }
      }
      if (shape?.dimR) {
        if (!line.dimR || line.dimR <= 0) {
          setErrMsg("Invalid Dimension R for Bar Mark: " + line.mark);
          return false;
        }
      }

      if (shape?.dimO) {
        if (!line.dimO || line.dimO <= 0) {
          setErrMsg("Invalid Dimension 0 for Bar Mark: " + line.mark);
          return false;
        }
      }

      if (
        !line.memberId ||
        !line.type ||
        !line.size ||
        !line.weight ||
        line.weight <= 0
      ) {
        return false;
      }
    }
    return true;
  }

  const updateMember = () => {
    setErrMsg("");

    if (validMemberLines()) {
      memberSvc
        .updateMemberDetail(memberDetail?.member?.id!, memberDetail!)
        .then((res) => {
          if (!res.error) {
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: "Member updated successfully",
              })
            );
            dispatch(triggerFetchProjects());
            dispatch(setMemberLines());
            setEditID(null);
            setSelectedState({});

            if (
              commonState?.selectedProject?.event?.item &&
              commonState?.selectedProject?.event?.item?.items?.length > 0
            ) {
              setShowDialog(!showDialog);
            }
          }
        });
    } else {
      setToast({
        toastType: "error",
        toastMsg: "Invalid Member Lines",
      });
    }
  };

  const deleteMemberLines = (gridLines: IMemberLine[]) => {
    setErrMsg("");

    memberSvc
      .deleteMemberLines(memberDetail?.member?.id!, gridLines)
      .then((res) => {
        if (!res.error) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Units deleted successfully",
            })
          );
          dispatch(triggerFetchProjects());
          setEditID(null);
          setSelectedState({});
          setMemberDetail((prev: any) => {
            return {
              ...prev,
              memberLines: prev.memberLines.filter((line: any) =>
                gridLines.some((x) => x.id !== line.id)
              ),
            };
          });
        }
      });
  };

  const cancelUpdate = () => {
    setErrMsg("");
    setEditID(null);
    setMemberDetail(JSON.parse(prevMemberDetail.current));
    setSelectedShape(undefined);
  };

  const handleBtnDisable = () => {
    if (prevMemberDetail.current === JSON.stringify(memberDetail)) {
      return true;
    }
    return false;
  };

  const handleCancelBtnDisable = () => {
    if (editID) {
      return false;
    }
    return true;
  };

  const calculateValue = (line: IMemberLine) => {
    const barsSizes: IBarSize[] = standard?.barSizes!;
    const bars: IBar[] = standard?.bars!;
    const shapeCodeObj = shapeCodes?.find(
      (shape: IShapeCodes) => shape.code === line.shapeCode
    );

    if (line.id?.includes("new") && !shapeCodeObj) {
      return;
    }

    if (!shapeCodeObj) {
      setErrMsg(`ShapeCode ${line.shapeCode} not found`);
    }

    const dimensions = {
      A: line.dimA,
      B: line.dimB,
      C: line.dimC,
      D: line.dimD,
      E: line.dimE,
      F: line.dimF,
      G: line.dimG,
      H: line.dimH,
      J: line.dimJ,
      K: line.dimK,
      O: line.dimO,
      R: line.dimR,
      I: line.dimI,
      H2: line.dimH2,
    };

    const barSizeObj = barsSizes.find((x) => x.size === Number(line.size));
    const bar = bars.find((x) => x.typeCode === line.type);

    if (barSizeObj && bar && shapeCodeObj) {
      const { radius, size, unitWeight } = barSizeObj;
      const { n, h } = bar;
      const { formula, condition } = shapeCodeObj;

      if (condition) {
        let conditionToEvaluate = condition;

        for (const [key, value] of Object.entries(dimensions)) {
          const regex = new RegExp(`\\b${key}\\b`, "g");
          conditionToEvaluate = conditionToEvaluate.replace(
            regex,
            String(value)
          );
        }

        conditionToEvaluate = conditionToEvaluate
          .replace(/r/g, String(radius))
          .replace(/d/g, String(size))
          .replace(/n/g, String(n))
          .replace(/h/g, String(h));

        try {
          if (conditionToEvaluate && !eval(conditionToEvaluate)) {
            return { ...line, isInvalidBarLength: true };
          }
        } catch (error) {
          setErrMsg(
            `Error evaluating condition for line with shape code ${line.shapeCode}: ${error}`
          );
        }
      }

      let length: string | number = formula;
      for (const [key, value] of Object.entries(dimensions)) {
        const regex = new RegExp(`\\b${key}\\b`, "g");
        length = length.replace(regex, String(value));
      }

      length = length
        .replace(/r/g, String(radius))
        .replace(/d/g, String(size))
        .replace(/n/g, String(n))
        .replace(/h/g, String(h));

      try {
        length = eval(length);
      } catch (error) {
        setErrMsg(
          `Error evaluating condition for line with shape code ${line.shapeCode}: ${error}`
        );
      }

      let totalNumbers = 0;

      if (!unitWeight) {
        setErrMsg(`Unit weight for bar size ${line.size} not found`);
      }

      if (totalNumbers == 0) {
        totalNumbers = line.noInEach;
      }

      var roundedLength = Math.ceil(Number(length) / 25) * 25;

      const weight = (unitWeight * totalNumbers * roundedLength) / 1000;
      if (length > commonState.selectedProject.event.item.barLength) {
        setErrMsg(
          `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`
        );
        // dispatch(
        //   setToast({
        //     toastType: "error",
        //     toastMsg: `Bar length should not be greater than ${commonState.selectedProject.event.item.barLength}`,
        //   })
        // );
        //setEditID(null);
        //return;
      }
      return {
        ...line,
        length: roundedLength,
        weight: weight,
        totalNo: line.noInEach * Number(line.noOfMem),
      };
    }
  };

  const getTotalWeights = (noOfMem?: number) => {
    let totalWeight = 0;
    const members = getMembers();
    members?.forEach((line: any) => {
      var value = calculateValue(line);
      totalWeight += value?.weight!;
    });

    if (!noOfMem) return "";

    if (totalWeight) {
      return ((totalWeight * noOfMem) / 1000).toFixed(3);
    }
    return "";
  };

  const [expanded, setExpanded] = React.useState("properties");
  return (
    <>
      <div className="grid grid-cols-[25%_48%_25%] gap-3 pb-4 panels">
        <div>
          <ExpansionPanel
            title={"Member Properties"}
            expanded={expanded === "properties"}
            tabIndex={0}
            key={"properties"}
            onAction={(event: ExpansionPanelActionEvent) => {
              setExpanded(event.expanded ? "" : "properties");
            }}
          >
            <Reveal>
              {expanded === "properties" && (
                <ExpansionPanelContent>
                  <div className="content">
                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <BBSTextField
                          name="mappingName"
                          value={memberDetail?.member?.revisionNumber}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Revision Number"
                          required={false}
                          placeholder=""
                          disabled={true}
                          regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                          error="Invalid Name"
                        />
                      </div>
                      <div>
                        <BBSNumberField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.member?.noOfMem}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="No. of members"
                          required={false}
                          placeholder=""
                          disabled={true}
                        />
                      </div>
                      <div>
                        <BBSNumberField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.member?.membersEngineered}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Engineered Members"
                          required={false}
                          placeholder=""
                          disabled={true}
                        />
                      </div>
                      <div>
                        <BBSNumberField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.member?.membersReleased}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Released Members"
                          required={false}
                          placeholder=""
                          disabled={true}
                        />
                      </div>
                      {/* <div>
                        <BBSNumberField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.member?.membersFabricated}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Fabricated Members"
                          required={false}
                          placeholder=""
                          disabled={true}
                        />
                      </div>
                      <div>
                        <BBSNumberField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.member?.membersErected}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Erected Members"
                          required={false}
                          placeholder=""
                          disabled={true}
                        />
                      </div> */}
                      <div>
                        <BBSTextField
                          name="templateHeaderMapping.sheetNumber"
                          value={getTotalWeights(memberDetail?.member?.noOfMem)}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Member Weight (Ton)"
                          required={false}
                          placeholder=""
                          disabled={true}
                          error="Invalid Sheet Number"
                        />
                      </div>
                    </div>
                  </div>
                </ExpansionPanelContent>
              )}
            </Reveal>
          </ExpansionPanel>
          {selectedShape && (
            <div className="card mt-5 p-3 w-full">
              <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
                Shape Preview
              </h3>
              <img src={selectedShape?.imagePath} width={250} />
            </div>
          )}
        </div>
        <div>
          <ExpansionPanel
            title={"BBS Properties"}
            expanded={expanded === "properties"}
            tabIndex={0}
            key={"properties"}
            onAction={(event: ExpansionPanelActionEvent) => {
              setExpanded(event.expanded ? "" : "properties");
            }}
          >
            <Reveal>
              {expanded === "properties" && (
                <ExpansionPanelContent>
                  <div className="content">
                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <BBSTextField
                          name="mappingName"
                          value={memberDetail?.bbs?.name}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="BBS Name"
                          required={false}
                          placeholder=""
                          disabled={true}
                          regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                          error="Invalid Name"
                        />
                      </div>
                      <div>
                        <BBSTextField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.bbs?.shopDrawNo}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Shop Drawing No."
                          required={false}
                          placeholder=""
                          disabled={true}
                          error="Invalid Sheet Number"
                        />
                      </div>
                      <div>
                        <BBSTextField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.bbs?.no}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="BBS No."
                          required={false}
                          placeholder=""
                          disabled={true}
                          error="Invalid BBS No."
                        />
                      </div>
                      <div>
                        <BBSTextField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.bbs?.shopDrawRevNo}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Shop Drawing Revision No."
                          required={false}
                          placeholder=""
                          disabled={true}
                          error="Invalid Shop Drawing Revision No."
                        />
                      </div>
                      <div>
                        <BBSTextField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.bbs?.revNo}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="BBS Revision No."
                          required={false}
                          placeholder=""
                          disabled={true}
                          error="Invalid BBS Revision No."
                        />
                      </div>
                      <div>
                        <BBSTextField
                          name="templateHeaderMapping.sheetNumber"
                          value={memberDetail?.bbs?.detailingOff}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Detailing Office"
                          required={false}
                          placeholder=""
                          disabled={true}
                          error="Invalid Detailing Office"
                        />
                      </div>
                    </div>
                  </div>
                </ExpansionPanelContent>
              )}
            </Reveal>
          </ExpansionPanel>

          {!memberIsActive && (
            <>
              <div className="mt-5 text-center">
                <h4 className="text-error text-md font-medium font-kanit tracking-[-0.04px] mb-4">
                  This member has been deleted, it cannot be edited!
                </h4>
              </div>
            </>
          )}
          {memberIsReleased && (
            <>
              <div className="mt-5 text-center">
                <h4 className="text-error text-md font-medium font-kanit tracking-[-0.04px] mb-4">
                  This member has been Released, it cannot be edited!
                </h4>
              </div>
            </>
          )}
          {memberIsPart && (
            <>
              <div className="mt-5 text-center">
                <h4 className="text-error text-md font-medium font-kanit tracking-[-0.04px] mb-4">
                  This is a member part, it cannot be edited! To modify this
                  member kindly modify parent member and use 'Manage Member
                  Parts' window
                </h4>
              </div>
            </>
          )}
        </div>
        <div>
          <ExpansionPanel
            title={"Unit Properties"}
            expanded={expanded === "properties"}
            tabIndex={0}
            key={"properties"}
            onAction={(event: ExpansionPanelActionEvent) => {
              setExpanded(event.expanded ? "" : "properties");
            }}
          >
            <Reveal>
              {expanded === "properties" && (
                <ExpansionPanelContent>
                  <div className="content">
                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <BBSTextField
                          name="dimensionsConstraint"
                          value={selectedShape?.dimensionsConstraint}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Shape Constraints"
                          required={false}
                          placeholder=""
                          disabled={true}
                          regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                          error="Invalid Name"
                        />
                      </div>
                      <div>
                        <BBSTextField
                          name="formula"
                          value={selectedShape?.formula}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Standard Constraints"
                          required={false}
                          placeholder=""
                          disabled={true}
                          regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                          error="Invalid Name"
                        />
                      </div>
                      <div>
                        <BBSTextField
                          name="revision"
                          value={""}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Revision"
                          required={false}
                          placeholder=""
                          disabled={true}
                          regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                          error="Invalid Name"
                        />
                      </div>
                      <div>
                        <BBSTextField
                          name="code"
                          value={selectedShape?.code}
                          onChange={handleChange}
                          onValidate={handleValidation}
                          label="Obj Code"
                          required={false}
                          placeholder=""
                          disabled={true}
                          regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                          error="Invalid Name"
                        />
                      </div>
                    </div>
                  </div>
                </ExpansionPanelContent>
              )}
            </Reveal>
          </ExpansionPanel>
          {memberIsReleased && (
            <ExpansionPanel
              title={"Call Off Details"}
              expanded={expanded === "properties"}
              className="mt-5"
              tabIndex={0}
              key={"properties"}
              onAction={(event: ExpansionPanelActionEvent) => {
                setExpanded(event.expanded ? "" : "properties");
              }}
            >
              <Reveal>
                {expanded === "properties" && (
                  <ExpansionPanelContent>
                    <div className="content">
                      <div className="grid grid-cols-2 gap-3">
                        <div>
                          <BBSTextField
                            name="callOffName"
                            value={memberDetail?.callOff?.name}
                            onChange={handleChange}
                            onValidate={handleValidation}
                            label="Title"
                            required={false}
                            placeholder=""
                            disabled={true}
                            regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                            error="Invalid Name"
                          />
                        </div>
                        <div>
                          <BBSTextField
                            name="callOffNo"
                            value={memberDetail?.callOff?.callOffNumber}
                            onChange={handleChange}
                            onValidate={handleValidation}
                            label="Number"
                            required={false}
                            placeholder=""
                            disabled={true}
                            regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                            error="Invalid Name"
                          />
                        </div>
                        <div>
                          <BBSTextField
                            name="callOffRevision"
                            value={memberDetail?.callOff?.revisionNumber}
                            onChange={handleChange}
                            onValidate={handleValidation}
                            label="Revision"
                            required={false}
                            placeholder=""
                            disabled={true}
                            regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                            error="Invalid Name"
                          />
                        </div>
                        <div>
                          <BBSTextField
                            name="callOffStatus"
                            value={memberDetail?.callOff?.status}
                            onChange={handleChange}
                            onValidate={handleValidation}
                            label="Status"
                            required={false}
                            placeholder=""
                            disabled={true}
                            regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                            error="Invalid Name"
                          />
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelContent>
                )}
              </Reveal>
            </ExpansionPanel>
          )}
        </div>
      </div>

      <div className="w-full max-w-[100vw]">
        <div className="grid grid-cols-2 gap-3">
          {shapeCodes && (
            <MembersListing
              gridData={getMembers() || []}
              onChange={handleGridChange}
              shapeCodes={shapeCodes}
              selectedShape={selectedShape}
              setSelectedShape={setSelectedShape}
              standard={standard}
              editID={editID}
              setEditID={setEditID}
              handleBtnDisable={handleBtnDisable}
              handleCancelBtnDisable={handleCancelBtnDisable}
              updateMember={updateMember}
              deleteMemberLines={deleteMemberLines}
              cancelUpdate={cancelUpdate}
              errMsg={errMsg}
              setErrMsg={setErrMsg}
              memberIsActive={memberIsActive}
              memberIsPart={memberIsPart}
              memberIsReleased={memberIsReleased}
              setCopiedItems={setCopiedItems}
              copiedItems={copiedItems}
              setPastedItems={setPastedItems}
              pastedItems={pastedItems}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              memberDetail={memberDetail}
            />
          )}
        </div>
      </div>

      {showDialog && (
        <Dialog
          width={500}
          title={""}
          onClose={() => setShowDialog(false)}
          className="custom-dialog"
        >
          <div className="my-4">
            Changes you have done to this member affects its member parts. For
            data to remain consistent between main member and its parts, you are
            required to edit parts accordingly. For this reason the 'Manage
            Member parts' window will open automatically now
          </div>
          <DialogActionsBar>
            <div className="flex justify-end gap-3">
              <Button
                className="border border-[#E2E8F0] xpx-2 py-2 font-small"
                themeColor={"primary"}
                onClick={() => {
                  setMemberProperties(
                    commonState?.selectedProject?.event?.item
                  );
                  setShowMemberPartsDrawer(!showMemberPartsDrawer);
                  setShowDialog(false);
                }}
              >
                Ok
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => {
                  setShowDialog(false);
                }}
                themeColor={"base"}
                fillMode="outline"
              >
                Cancel
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}

export default MemberDetails;
