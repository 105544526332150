import { Button } from "@progress/kendo-react-buttons";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { CalendarDays, X } from "lucide-react";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomLabel from "../../../component/global/RequiredMark";
import BBSTextField from "../../../component/inputs/BBSTextField";
import { ICallOff } from "../../../interfaces/callOff.interface";
import { ISubContractor } from "../../../interfaces/subcontractor.interface";
import subContractorSvc from "../../../services/subContractor.service";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";

interface IProps {
  showSendToFabDrawer: boolean;
  setShowSendToFabDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  editCallOff: (ICallOff & { id: string }) | null;
  setEditCallOff: any;
  handleChange: (e: any, isDropdown?: boolean) => void;
  onSave: (action?: string) => void;
  handleDateChange: any;
}

function SendToFabricator({
  showSendToFabDrawer,
  setShowSendToFabDrawer,
  editCallOff,
  setEditCallOff,
  handleChange,
  onSave,
  handleDateChange,
}: IProps) {
  const commonState = useAppSelector((state: RootState) => state.common);
  const [subcontractors, setSubcontractors] = useState<ISubContractor[]>([]);

  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (showSendToFabDrawer) fetchSubcontractors();
  }, [commonState.selectedCallOff, showSendToFabDrawer]);

  const fetchSubcontractors = () => {
    subContractorSvc.getAll().then((res) => {
      if (commonState?.selectedProject?.event?.item) {
        var subConIds =
          commonState?.selectedProject?.event?.item.subcontractors.map(
            (x: any) => x.id
          );
        var subcontractors = res.data.filter((x: any) =>
          subConIds.includes(x.id)
        );
        setSubcontractors(subcontractors);
      }
    });
  };

  const getSubcontractorValue = () => {
    const subcontractor = subcontractors?.find(
      (std) => std.id === editCallOff?.subcontractorId
    );
    if (!subcontractor) return "";
    return subcontractor;
  };

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev: any) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const validateSave = () => {
    return (
      Object.values(invalidFields).every((field) => !field) &&
      editCallOff?.subcontractorId
    );
  };

  // const getDetailingOfficeValue = () => {
  //   return detailingOffices?.find(
  //     (item: any) => item.name === editCallOff?.detailingOff
  //   );
  // };
  function detectDateFormat(dateString: string) {
    const formats = [
      { regex: /^\d{2}-\d{2}-\d{2}$/, format: "dd-mm-yy" },
      { regex: /^\d{4}-\d{2}-\d{2}$/, format: "yyyy-mm-dd" },
      { regex: /^\d{2}\/\d{2}\/\d{4}$/, format: "mm/dd/yyyy" },
    ];

    for (let format of formats) {
      if (format.regex.test(dateString)) {
        return format.format;
      }
    }

    return null;
  }

  useEffect(() => {
    if (showSendToFabDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showSendToFabDrawer]);

  function getCallOffIdValue():
    | string
    | number
    | readonly string[]
    | undefined {
    return editCallOff?.callOffId?.toString();
  }

  return (
    <Drawer
      expanded={showSendToFabDrawer}
      position={"start"}
      mode={"overlay"}
      animation={{ duration: 400 }}
      className="customDrawer2 transition-all duration-500"
    >
      <DrawerContent>
        <div
          className="overlay"
          onClick={() => setShowSendToFabDrawer(false)}
        ></div>
        <div
          className={`max-w-[450px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-screen w-full transform transition-all duration-500 overflow-y-scroll ${
            showSendToFabDrawer ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
            <h2 className="font-medium text-lg">Send To Fabricator</h2>
            <Button
              fillMode="flat"
              className="p-2 bg-none outline-none"
              onClick={() => setShowSendToFabDrawer(false)}
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
          <div className="flex-1 py-4">
            <div className="grid gap-4">
              <div>
                <BBSTextField
                  name="workOrderNumber"
                  value={editCallOff?.workOrderNumber}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Work Order Number"
                  required={true}
                  placeholder="Enter Work Order Number"
                  disabled={true}
                  regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                  error="Invalid Work Order Number"
                />
              </div>
              <div>
                <CustomLabel label="Subcontractor" required={true} />
                <ComboBox
                  data={subcontractors || []}
                  placeholder="Select Subcontractor"
                  name="subcontractorId"
                  value={getSubcontractorValue()}
                  onChange={(e) => handleChange(e, true)}
                  disabled={false}
                  clearButton={false}
                  dataItemKey="id"
                  textField="name"
                />
              </div>

              <div>
                <CustomLabel label="Call Off Number" required={true} />
                <div className="grid grid-cols-3 gap-5">
                  <div>
                    <TextBox
                      value={editCallOff?.prefix}
                      onChange={handleChange}
                      name="prefix"
                      disabled={true}
                      width={"100px"}
                      autoComplete="new-password"
                      list="autocompleteOff"
                    />
                  </div>
                  <div>
                    <TextBox
                      value={getCallOffIdValue()}
                      onChange={handleChange}
                      name="callOffId"
                      width={100}
                      autoComplete="new-password"
                      list="autocompleteOff"
                    />
                  </div>
                  <TextBox
                    value={editCallOff?.suffix}
                    onChange={handleChange}
                    name="suffix"
                    width={100}
                    autoComplete="new-password"
                    list="autocompleteOff"
                  />
                </div>
              </div>
              <div>
                <BBSTextField
                  name="deliveryAddress"
                  value={editCallOff?.deliveryAddress || ""}
                  onChange={handleChange}
                  onValidate={handleValidation}
                  label="Delivery Address"
                  required={false}
                  placeholder="Enter Delivery Address"
                  disabled={false}
                />
              </div>
              <div className="w-full">
                <CustomLabel label={"Delivery On"} required={false} />
                <DatePicker
                  className="border border-gray-900 py-1 px-2.5 w-full text-sm"
                  name="deliveryDate"
                  placeholderText="Enter date time"
                  selected={
                    editCallOff?.deliveryDate
                      ? new Date(editCallOff?.deliveryDate)
                      : null
                  }
                  dateFormat="MM/dd/yyyy h:mm aa"
                  onChange={handleDateChange}
                  required={false}
                  disabled={false}
                  showTimeInput
                  showIcon
                  icon={<CalendarDays />}
                  toggleCalendarOnIconClick
                  calendarIconClassName="mr-2"
                  autoComplete="new-password"
                />
              </div>
              <div className="grid grid-cols-2">
                <div>
                  <Checkbox
                    name="attachDefaultReports"
                    // value={editCallOff?.attachDefaultReports || true}
                    checked={editCallOff?.attachDefaultReports || true}
                    onChange={handleChange}
                    label={"Attach Default Reports"}
                  />
                </div>
                <div>
                  <Checkbox
                    name="attachExcelReports"
                    checked={editCallOff?.attachExcelReports || true}
                    onChange={handleChange}
                    label={"Attach Excel Reports"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 pb-4 sticky-buttons">
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              onClick={() => {
                setEditCallOff(null);
                setInvalidFields({});
                setShowSendToFabDrawer(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              themeColor={"primary"}
              onClick={() => onSave("SendToFab")}
              disabled={!validateSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default SendToFabricator;
