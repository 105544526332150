import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  Editor,
  EditorChangeEvent,
  EditorTools,
  EditorUtils,
} from "@progress/kendo-react-editor";

import {
  ComboBoxChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { PDFViewer, PDFViewerTool } from "@progress/kendo-react-pdf-viewer";
import { FileText, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomLabel from "../../../component/global/RequiredMark";
import BBSTextField from "../../../component/inputs/BBSTextField";
import Reports from "../../../constants/reports";
import {
  ICallOff,
  IFabricatorEmail,
} from "../../../interfaces/callOff.interface";
import callOffSvc from "../../../services/callOff.service";
import { setToast } from "../../../store/features/toastSlice";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import LoaderService from "../../../services/loader.service";
import { size } from "lodash";

interface IProps {
  showFabEmailDrawer: boolean;
  setShowFabEmailDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  editCallOff: (ICallOff & { id: string }) | null;
  setEditCallOff: any;
}

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  ForeColor,
  BackColor,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

const initialStateEmail = {
  to: "",
  cc: "",
  subject: "",
  body: "",
  bcc: "",
  reports: [],
};

const paletteSettings = {
  palette: [
    // Reds & Pinks
    "#ff0000", // Red
    "#ff6b6b", // Bright Red
    "#dc143c", // Crimson
    "#ff1493", // Deep Pink
    "#ff69b4", // Hot Pink
    "#ff007f", // Neon Pink
    "#ff66b2", // Bubblegum Pink
    "#ff5050", // Strawberry Red
    "#ff3366", // Watermelon Red

    // Oranges & Yellows
    "#ff7f00", // Orange
    "#ff4500", // Bright Orange-Red
    "#ff8c00", // Dark Orange
    "#ff9933", // Peach Orange
    "#ffae42", // Mango Yellow
    "#ffb400", // Vivid Yellow-Orange
    "#ffd700", // Gold
    "#ffee00", // Neon Yellow
    "#ffff00", // Yellow
    "#ffcc00", // Candy Yellow
    "#ffea00", // Electric Yellow
    "#ffcc66", // Caramel Gold

    // Greens
    "#00ff00", // Green
    "#32cd32", // Lime Green
    "#7cfc00", // Lawn Green
    "#00fa9a", // Medium Spring Green
    "#00ff7f", // Bright Green
    "#33cc33", // Apple Green
    "#00cc99", // Mint Green
    "#20b2aa", // Sea Green

    // Blues & Cyans
    "#0000ff", // Blue
    "#1e90ff", // Dodger Blue
    "#00c3ff", // Electric Blue
    "#00bfff", // Ocean Blue
    "#66ccff", // Sky Blue
    "#00ced1", // Dark Turquoise
    "#00ffff", // Cyan
    "#00e5ff", // Neon Cyan
    "#00ffcc", // Aqua Green
    "#40e0d0", // Turquoise

    // Purples & Violets
    "#800080", // Purple
    "#8a2be2", // Vivid Purple
    "#cc33ff", // Lavender Purple

    // Neutrals
    "#ffffff", // White
    "#000000", // Black
    "#808080", // Gray
  ],
  columns: 10,
  tileSize: 20,
  size: "medium",
};

function FabricatorEmail({
  showFabEmailDrawer,
  setShowFabEmailDrawer,
  editCallOff,
  setEditCallOff,
}: IProps) {
  const commonState = useAppSelector((state: RootState) => state.common);
  const [emailContent, setEmailContent] = useState<any>(initialStateEmail);
  const editor = React.createRef<Editor>();
  const [fileUrl, setFileUrl] = useState<string>("");
  const [showPdfPreview, setShowPdfPreview] = useState<boolean>(false);

  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});

  const dispatch = useDispatch();

  const CustomBackColor = (props: any) => (
    <BackColor
      {...props}
      colorPickerProps={{ view: "palette", paletteSettings: paletteSettings }}
    />
  );

  useEffect(() => {
    if (editCallOff && showFabEmailDrawer) {
      callOffSvc.getFabEmailContent(editCallOff?.id).then((x) => {
        setEmailContent(x.data);
      });
    }
  }, [editCallOff, showFabEmailDrawer]);

  useEffect(() => {
    if (showFabEmailDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showFabEmailDrawer]);

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev: any) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  const handleChange = (
    e:
      | TextBoxChangeEvent
      | ComboBoxChangeEvent
      | DatePickerChangeEvent
      | MultiSelectChangeEvent,

    isDropdown?: boolean
  ) => {
    if (e.target.name === "id") {
      setEmailContent((prev: any) => {
        return {
          ...prev,
          [e.target.name as any]: e.target.value.id,
        };
      });
      return;
    }

    setEmailContent((prev: any) => {
      return {
        ...prev,
        [e.target.name as any]: isDropdown ? e.target.value.id : e.target.value,
      };
    });
  };

  const sendEmail = () => {
    const payload: IFabricatorEmail = {
      ...emailContent,
      callOffId: editCallOff?.id,
    };
    callOffSvc.sendFabEmail(payload).then((x) => {
      if (!x.error) {
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Email has been sent to Fabricator.",
          })
        );
        setEditCallOff(null);
        setShowFabEmailDrawer(false);
      }
    });
  };

  const handleEditorChange = (e: EditorChangeEvent) => {
    setEmailContent((prev: any) => {
      return {
        ...prev,
        body: e?.html || prev.body,
      };
    });
    return;
  };

  const handlePreview = (type: string, url: string, name: string) => {
    switch (type) {
      case "Call Off Excel":
      case "BBS SDI":
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        break;

      default:
        setFileUrl(url);
        setShowPdfPreview(true);
    }
  };

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === "Escape") {
        setShowPdfPreview(false);
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [showPdfPreview]);

  const tools: PDFViewerTool[] = [
    "pager",
    "spacer",
    "zoomInOut",
    //"zoom",
    "selection",
    "spacer",
    "search",
    "print",
    "download",
  ];

  return (
    <>
      {showPdfPreview && (
        <div className={`fixed h-screen w-screen z-[999999] top-0 left-0`}>
          <div
            className="overlay"
            onClick={() => setShowPdfPreview(false)}
          ></div>
          <div className="flex justify-center items-center w-full h-full">
            {
              <PDFViewer
                url={fileUrl}
                style={{ height: "100vh", width: "90vw" }}
                tools={tools}
                defaultZoom={1.5}
              />
            }
          </div>
        </div>
      )}
      <Drawer
        expanded={showFabEmailDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer transition-all duration-500"
        style={{ zIndex: 9999 }}
      >
        <DrawerContent>
          <div
            className="overlay"
            // onClick={() => setShowFabEmailDrawer(false)}
          ></div>
          <div
            className={`max-w-[1024px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-screen w-full transform transition-all duration-500 overflow-y-scroll ${
              showFabEmailDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Send Email to Fabricator</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() => setShowFabEmailDrawer(false)}
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <BBSTextField
                    name="to"
                    value={emailContent?.to}
                    onChange={handleChange}
                    onValidate={handleValidation}
                    label="To"
                    required={false}
                    placeholder="Enter To"
                    disabled={false}
                    error="Invalid To"
                  />
                </div>
                <div>
                  <BBSTextField
                    name="cc"
                    value={emailContent?.cc}
                    onChange={handleChange}
                    onValidate={handleValidation}
                    label="Cc"
                    required={false}
                    placeholder="Enter Cc"
                    disabled={false}
                    error="Invalid Cc"
                  />
                </div>
                <div>
                  <BBSTextField
                    name="bcc"
                    value={emailContent?.bcc}
                    onChange={handleChange}
                    onValidate={handleValidation}
                    label="Bcc"
                    required={false}
                    placeholder="Enter Bcc"
                    disabled={false}
                    error="Invalid Bcc"
                  />
                </div>
                <div>
                  <CustomLabel label="Reports" required={false} />
                  <MultiSelect
                    autoClose={false}
                    data={Reports}
                    name="reports"
                    onChange={handleChange}
                    value={emailContent?.reports}
                    placeholder="Please select ..."
                  />
                </div>
                <div>
                  <BBSTextField
                    name="subject"
                    value={emailContent?.subject}
                    onChange={handleChange}
                    onValidate={handleValidation}
                    label="Subject"
                    required={false}
                    placeholder="Enter Subject"
                    disabled={false}
                    error="Invalid Subject"
                  />
                </div>
              </div>
              {emailContent?.attachments && (
                <div className="grid mt-3">
                  <div className="card mb-2 p-3">
                    <div className="justify-between w-full">
                      <div className="flex flex-wrap rounded  border-neutral-30">
                        <div className="self-center text-base py-1 rounded-sm">
                          Attachments
                        </div>
                      </div>
                    </div>
                    <div>
                      {emailContent?.attachments?.map(
                        (
                          x: { type: string; url: string; name: string },
                          idx: number
                        ) => {
                          return (
                            <h4
                              className="text-[11px] py-1 border-t border-neutral-30 text-txtPrimary tracking-[-0.10px] font-light cursor-pointer"
                              key={idx}
                              onClick={() =>
                                handlePreview(x.type, x.url, x.name)
                              }
                            >
                              {x.name}
                            </h4>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="grid mt-3">
                <div>
                  <Editor
                    tools={[
                      [Bold, Italic, Underline, Strikethrough],
                      ForeColor,
                      CustomBackColor,
                      [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                      [Indent, Outdent],
                      [OrderedList, UnorderedList],
                      [Undo, Redo],
                    ]}
                    contentStyle={{
                      height: 450,
                    }}
                    onChange={handleEditorChange}
                    value={emailContent?.body}
                    ref={editor}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4 sticky-buttons">
              <Button
                className="border border-[#E2E8F0] px-4 py-2 font-medium"
                themeColor={"primary"}
                onClick={() => sendEmail()}
                // disabled={!validateSave()}
              >
                Send
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default FabricatorEmail;
