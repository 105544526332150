import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class MemberPartService extends BaseService {
  constructor() {
    super('MemberPart');
  }

  async createMemberParts(data: any) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/MemberPart/create-member-parts`,
        data
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async updateMemberParts(data: any) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/MemberPart/update-member-parts`,
        data
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getMemberParts(memberPartIds: string) {
    try {
      LoaderService.showLoader();
      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/api/MemberPart/get-member-parts/${memberPartIds}`
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const memberPartSvc = new MemberPartService();

export default memberPartSvc;
