import * as React from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { ISubContractor } from "../../../interfaces/subcontractor.interface";
import { IBarSize } from "../../../interfaces/standard.interface";
import { IShapeCodes } from "../../../interfaces/shapes.interface";

export const ShapeCodeDropdownCell = (
  props: GridCellProps & {
    shapeCodes: IShapeCodes[] | undefined;
  }
) => {
  const [filter, setFilter] = React.useState<string>("");

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: props.dataItem.idx,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.code,
      });
    }
  };

  const handleFilterChange = (event: any) => {
    setFilter(event.filter.value);
  };

  const { dataItem } = props;
  const field = "shapeCode";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  const filteredShapeCodes: any = props.shapeCodes?.filter((shapeCode) => shapeCode.code.toLowerCase() === filter.toLowerCase());

  return (
    <td className={`${dataItem.pasted ? "pasted-row" : ""} `}>
      {dataItem.inEdit ? (
        <DropDownList
          style={{ width: 100, height: 30 }}
          onChange={handleChange}
          filterable={true}
          onFilterChange={handleFilterChange}
          filter={filter}
          value={props.shapeCodes?.find((c) => c.code === dataValue)}
          data={filteredShapeCodes?.length > 0 ? filteredShapeCodes : props.shapeCodes} // Use the filtered shape codes
          dataItemKey="code"
          textField="code"
        />
      ) : dataValue == null ? (
        ""
      ) : (
        dataValue
      )}
    </td>
  );
};
