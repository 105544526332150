import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import {
  IBar,
  IBarSize,
  IBarType,
  IStandard,
} from "../../../interfaces/standard.interface";
import projectSvc from "../../../services/project.service";
import standardSvc from "../../../services/standard.service";
import companySvc from "../../../services/company.service";
import { ICompany } from "../../../interfaces/company.interface";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { IBBS } from "../../../interfaces/bbs.interface";
import bbsSvc from "../../../services/bbs.service";
import memberSvc from "../../../services/member.service";
import UploadFile from "../BBSComponents/UploadFile";
import { ITemplateMapping } from "../../../interfaces/mapping.interface";
import templateMappingSvc from "../../../services/templateMapping.service";
import { IShapeCodes } from "../../../interfaces/shapes.interface";
import shapesSvc from "../../../services/shapes.service";
import { RootState } from "../../../store/store/store";
import { IProject } from "../../../interfaces/project.interface";
import { triggerFetchProjects } from "../../../store/features/commonSlice";
import { IMemberLine } from "../../../interfaces/member.interface";
import { useDispatch } from "react-redux";

const initialStateImport = {
  mappingId: "",
  memberName: "",
  drawingNo: "",
  revision: "",
  lines: [],
  importType: "",
};

export const MemberImport = ({
  memberProperties,
  memberImportType,
  showMemberImportDrawer,
  setShowMemberImportDrawer,
}: any) => {
  const [memberImport, setMemberImport] = useState(initialStateImport);
  const dispatch = useDispatch();
  const [cellsData, setCellsData] = useState<any>(null);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [uploadedFilePath, setUploadedFilePath] = useState<string>("");
  const [templateMappings, setTemplateMappings] = useState<ITemplateMapping[]>(
    []
  );

  const mappingsFetched = useRef<boolean>(false);
  const selectedMappingId = useRef<string | null>(null);

  const [invalidFileErrMessage, setInvalidFileErrMessage] =
    useState<string>("");

  useEffect(() => {
    if (showMemberImportDrawer) fetchMappings();
  }, [showMemberImportDrawer]);

  const fetchMappings = () => {
    if (mappingsFetched.current) return;
    mappingsFetched.current = true;
    const filters: any = {
      filters: [
        {
          logic: "and",
          filters: [
            {
              field: "isEnabled",
              operator: "eq",
              value: true,
            },
          ],
        },
      ],
    };
    templateMappingSvc.getAll([], filters).then((res) => {
      setTemplateMappings(res.data);
    });
  };

  const onDataChange = (e: ComboBoxChangeEvent | TextBoxChangeEvent) => {
    setMemberImport((prev: any) => {
      return {
        ...prev,
        [e.target.name as string]: e.value,
      };
    });
  };

  const importMember = async () => {
    try {
      if (selectedMappingId.current && cellsData) {
        if (!shapeCodes?.length) {
          setInvalidFileErrMessage(
            "Shape Codes are not configured with this project"
          );
          return;
        }
        const filteredObject = Object.fromEntries(
          Object.entries(cellsData).filter(([key, value]) => value)
        );
        const mapping = templateMappings.find(
          (x) => x.id === selectedMappingId.current
        );
        const mappedHeaderProperties = mapHeaderProperties(
          filteredObject,
          mapping
        );
        let mappedLineMapping = mapTemplateLineMapping(filteredObject, mapping);
        const headerProperties =
          mappedHeaderProperties.templateHeaderMapping.templatePropertyMapping;
        mappedLineMapping = mapLines(mappedLineMapping);
        mappedLineMapping = calculateValues(mappedLineMapping);
        mappedLineMapping = mappedLineMapping.filter(
          (x: IMemberLine) =>
            x.name && x.mark && x.type && x.size && x.shapeCode
        );
        headerProperties.templateMappingId = selectedMappingId.current;

        setMemberImport((prev: any) => {
          return { ...prev, lines: mappedLineMapping };
        });

        let payload = {
          ...memberImport,
          lines: mappedLineMapping,
          projectId: memberProperties?.projectId,
          bbsId: "",
          projectName: memberProperties?.projectName,
          levelCode: memberProperties?.levelCode,
          levelName: memberProperties?.levelName,
          subLevelCode: memberProperties?.subLevelCode,
          subLevelName: memberProperties?.subLevelName,
          shopDrawingCode: memberProperties.shopDrawingCode,
          shopDrawingName: memberProperties?.shopDrawingName,
          importType: memberImportType,
          level: memberProperties?.level,
          memberId: memberProperties?.memberId,
        };
        memberSvc.importMembers(payload).then((x) => {
          if (x.data) {
            dispatch(triggerFetchProjects());
            dispatch(
              setToast({
                toastType: "success",
                toastMsg: `Member Imported successfully.`,
              })
            );
            setShowMemberImportDrawer(false);
            resetImport();
          } else {
            dispatch(
              setToast({
                toastType: "error",
                toastMsg: x.error,
              })
            );
          }
        });
      }
    } catch (error) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `Unable to save member, Please try again.`,
        })
      );
    }
  };

  const validateImportBtn = () => {
    if (!selectedMappingId.current) {
      return false;
    }

    if (!cellsData) return false;
    return true;
  };
  const commonState = useAppSelector((state: RootState) => state.common);

  const [shapeCodes, setShapeCodes] = useState<IShapeCodes[]>([]);
  const selectedStandardId = useRef<string>("");
  const [standard, setStandard] = useState<IStandard | null>(null);

  const fetchShapeCodes = (standardId: string) => {
    if (standardId === selectedStandardId.current) return;
    shapesSvc.getByStandardId(standardId).then((res) => {
      setShapeCodes(res.data as IShapeCodes[]);
      selectedStandardId.current = standardId;
    });
  };

  const [projects, setProjects] = useState<IProject[]>([]);

  const fetchProjects = () => {
    projectSvc.get().then((res) => {
      if (res.data) {
        const projects = res.data;
        setProjects(projects);
      }
    });
  };

  useEffect(() => {
    if (!showMemberImportDrawer) return;
    fetchProjects();
  }, [showMemberImportDrawer]);

  const fetchStandard = (standardId: string) => {
    if (standardId === selectedStandardId.current) return;
    standardSvc.getById(standardId).then((res) => {
      setStandard(res.data as IStandard);
      selectedStandardId.current = standardId;
    });
  };

  const getMappingValue = () => {
    if (!templateMappings?.length) return { id: "", mappingValue: "" };
    return templateMappings.find((x) => x.id === selectedMappingId.current);
  };

  useEffect(() => {
    if (commonState?.selectedProject?.event) {
      const project = projects.find(
        (x) => x.id === commonState.selectedProject.event.item.projectId
      );
      if (project) {
        fetchShapeCodes(project.standardId);
        fetchStandard(project.standardId);
      }
    }
    // if (!showMemberImportDrawer) setMember(initialStateMember);
  }, [showMemberImportDrawer, commonState?.selectedProject?.event, projects]);

  const calculateValues = (lines: IMemberLine[]) => {
    const barsSizes: IBarSize[] = standard?.barSizes!;
    const bars: IBar[] = standard?.bars!;

    return lines.map((line: IMemberLine) => {
      const shapeCodeObj = shapeCodes?.find(
        (shape: IShapeCodes) => shape.code === line.shapeCode
      );
      if (!shapeCodeObj) {
        return { ...line };
      }
      const { formula, condition } = shapeCodeObj;

      const barSizeObj = barsSizes.find((x) => x.size === Number(line.size));
      const bar = bars.find((x) => x.typeCode === line.type);
      if (!barSizeObj) {
        return { ...line };
      }
      if (!bar) {
        return { ...line };
      }
      const { radius, size, unitWeight } = barSizeObj;
      const { n, h } = bar;

      const dimensions = {
        A: line.dimA,
        B: line.dimB,
        C: line.dimC,
        D: line.dimD,
        E: line.dimE,
        F: line.dimF,
        G: line.dimG,
        H: line.dimH,
        J: line.dimJ,
        K: line.dimK,
        O: line.dimO,
        R: line.dimR,
        I: line.dimI,
        H2: line.dimH2,
      };

      if (condition) {
        let conditionToEvaluate = condition;

        for (const [key, value] of Object.entries(dimensions)) {
          const regex = new RegExp(`\\b${key}\\b`, "g");
          conditionToEvaluate = conditionToEvaluate.replace(
            regex,
            String(value)
          );
        }

        conditionToEvaluate = conditionToEvaluate
          .replace(/r/g, String(radius))
          .replace(/d/g, String(size))
          .replace(/n/g, String(n))
          .replace(/h/g, String(h));

        try {
          if (conditionToEvaluate && !eval(conditionToEvaluate)) {
            return { ...line, isInvalidBarLength: true };
          }
        } catch (error) {
          dispatch(
            setToast({
              toastType: "error",
              toastMsg: `Error evaluating condition for line with shape code ${line.shapeCode}: ${error}`,
            })
          );

          return { ...line, isInvalidBarLength: true };
        }
      }

      let length: string | number = formula;

      for (const [key, value] of Object.entries(dimensions)) {
        const regex = new RegExp(`\\b${key}\\b`, "g");
        length = length.replace(regex, String(value));
      }

      length = length
        .replace(/r/g, String(radius))
        .replace(/d/g, String(size))
        .replace(/n/g, String(n))
        .replace(/h/g, String(h));

      try {
        length = eval(length);
      } catch (error) {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: `Error evaluating formula for line with shape code ${line.shapeCode}: ${error}`,
          })
        );

        return { ...line, IsInvalidBarLength: true };
      }

      var totalNumbers = line.noInEach;
      if (!unitWeight) {
        dispatch(
          setToast({
            toastType: "error",
            toastMsg: `Unit weight for bar size ${line.size} not found`,
          })
        );
        return { ...line };
      }

      const weight = (unitWeight * totalNumbers * Number(length)) / 1000;

      return {
        ...line,
        length: Math.ceil(Number(length) / 25) * 25,
        weight: weight,
      };
    });
  };

  function mapHeaderProperties(cellsMapping: any, templateMapping: any) {
    const mappedObject = JSON.parse(JSON.stringify(templateMapping));
    const propertyMapping =
      templateMapping.templateHeaderMapping.templatePropertyMapping;
    for (const key in propertyMapping) {
      if (propertyMapping.hasOwnProperty(key)) {
        const cellKey = propertyMapping[key];
        mappedObject.templateHeaderMapping.templatePropertyMapping[key] =
          cellsMapping[cellKey] || "";
      }
    }
    return mappedObject;
  }

  function mapTemplateLineMapping(cellsMapping: any, templateMapping: any) {
    const lineStartRow = parseInt(
      templateMapping.templateHeaderMapping.templateLineMapping.lineStartRow
    );
    const templateLineMapping =
      templateMapping.templateHeaderMapping.templateLineMapping;
    const lineMappingArray = [];

    let highestRow = 0;
    for (const cellKey in cellsMapping) {
      const match = cellKey.match(/\d+$/);
      if (match) {
        const rowIndex = parseInt(match[0], 10);
        if (rowIndex > highestRow) {
          highestRow = rowIndex;
        }
      }
    }

    for (let i = lineStartRow; i <= highestRow; i++) {
      const lineObject: any = {};
      let hasNonEmptyValue = false;

      for (const key in templateLineMapping) {
        if (
          templateLineMapping.hasOwnProperty(key) &&
          templateLineMapping[key]
        ) {
          const cellProperties = templateLineMapping[key];
          const columnName = cellProperties.col;
          const cellKey = columnName + i;
          const value = cellsMapping[cellKey] || "";

          if (cellProperties.offset) {
            continue;
          }
          const similarCols = [];
          for (let x in templateLineMapping) {
            if (
              templateLineMapping[x].col === columnName &&
              templateLineMapping[x].offset > 0
            ) {
              similarCols.push({ [x]: templateLineMapping[x] });
            }
          }
          if (similarCols.length) {
            for (let y in similarCols) {
              const cell = similarCols[y];
              const colKey = Object.keys(cell)[0];
              const colName =
                columnName +
                (Number(i) + Number(cell[Object.keys(cell)[0]].offset));
              const value = cellsMapping[colName] || "";
              lineObject[colKey] = value;
            }
          }
          if (cellProperties.separator) {
            const splitValue = value.split(cellProperties.separator);
            if (
              cellProperties.arrIndex !== null &&
              cellProperties.arrIndex !== undefined &&
              cellProperties.arrIndex <= splitValue.length
            ) {
              lineObject[key] = splitValue[cellProperties.arrIndex - 1];
            }
          }
          if (cellProperties.textLength > 0 && value) {
            lineObject[key] = value.substring(
              cellProperties.startIndex - 1,
              cellProperties.startIndex - 1 + cellProperties.textLength
            );
          } else {
            lineObject[key] = value;
          }
          if (value !== "") {
            hasNonEmptyValue = true;
          }
        }
      }
      if (hasNonEmptyValue) {
        lineMappingArray.push(lineObject);
      }
    }

    return lineMappingArray;
  }

  const mapLines = (lines: IMemberLine[]) => {
    const strValues = [
      "name",
      "detail",
      "mark",
      "type",
      "size",
      "barRev",
      "shapeCode",
      "remarks",
      "callOffId",
      "status",
    ];
    for (const line of lines) {
      for (const key in line) {
        if (!strValues.includes(key)) {
          const val = Number(line[key as keyof IMemberLine]);
          if (!isNaN(val)) {
            (line[key as keyof IMemberLine] as any) = Number(
              line[key as keyof IMemberLine]
            );
          } else {
            (line[key as keyof IMemberLine] as any) = null;
          }
        }
      }

      line["isActive"] = true;
    }
    return lines;
  };

  const convertToDateTime = (dateString: string | null | undefined) => {
    if (!dateString) return null;
    const parts = dateString.split("-");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const date = new Date(year + 2000, month, day + 1);
    return date;
  };

  const resetImport = () => {
    setMemberImport(initialStateImport);
    selectedStandardId.current = "";
    selectedMappingId.current = null;
    mappingsFetched.current = false;
    setCellsData(null);
    setProjects([]);
    setUploadedFile(null);
    setUploadedFilePath("");
    setInvalidFileErrMessage("");
  };

  return (
    <>
      <Drawer
        expanded={showMemberImportDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500 "
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowMemberImportDrawer(!showMemberImportDrawer)}
          ></div>
          <div
            className={`max-w-[500px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showMemberImportDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">
                {memberImportType &&
                (memberImportType == "member" || memberImportType == "multiple")
                  ? `Import ${
                      memberImportType == "member"
                        ? "Member"
                        : "Multiple Members"
                    } from Excel`
                  : "Import Multiple Revision"}
              </h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() =>
                  setShowMemberImportDrawer(!showMemberImportDrawer)
                }
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="card mb-5 p-3">
                <div className="flex items-center mb-3">
                  <div className="flex-1">
                    <h3 className="text-txtPrimary text-md font-small font-kanit tracking-[-0.04px]">
                      Select File
                    </h3>
                  </div>
                </div>
                <div className="grid gap-4 upload-import">
                  <div>
                    <div className="flex items-center h-full w-full gap-3">
                      <UploadFile
                        setCellsData={setCellsData}
                        setUploadedFile={setUploadedFile}
                        setUploadedFilePath={setUploadedFilePath}
                        setInvalidFileErrMessage={setInvalidFileErrMessage}
                        isSDI={false}
                      />
                      {invalidFileErrMessage === "" && (
                        <div className="flex-1 text-xs">
                          {uploadedFile?.name}

                          {uploadedFile?.name && (
                            <X
                              className="ml-5 cursor-pointer inline-flex"
                              size={14}
                              onClick={() => {
                                setUploadedFile(null);
                                setUploadedFilePath("");
                              }}
                            />
                          )}
                        </div>
                      )}
                      {invalidFileErrMessage !== "" && (
                        <div className="flex-1 text-red-600">
                          {invalidFileErrMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <CustomLabel label="Mapping Name" required={false} />
                    <ComboBox
                      placeholder="Select Mapping Name"
                      data={templateMappings}
                      value={getMappingValue() || ""}
                      dataItemKey="id"
                      textField="mappingName"
                      filterable={true}
                      disabled={!cellsData}
                      allowCustom={true}
                      clearButton={false}
                      onChange={(e) => {
                        selectedMappingId.current = e.value?.id;
                        setMemberImport({
                          ...memberImport,
                          mappingId: e.value?.id,
                        });
                      }}
                    />
                  </div>

                  {memberProperties?.level === 4 &&
                    memberImportType === "revision" && (
                      <div>
                        <CustomLabel label="Revision" required={false} />
                        <TextBox
                          onChange={onDataChange}
                          value={memberImport?.revision}
                          type="text"
                          placeholder="Enter Revision"
                          name="revision"
                          autoComplete="new-password"
                        />
                      </div>
                    )}
                </div>
              </div>

              {memberImportType == "member" && (
                <div className="card mb-5 p-3">
                  <div className="flex items-center mb-3">
                    <div className="flex-1">
                      <h3 className="text-txtPrimary text-md font-small font-kanit tracking-[-0.04px]">
                        Enter the Member name which should be unique per
                        Subtitle
                      </h3>
                    </div>
                  </div>
                  <div className="grid gap-4">
                    <div>
                      <CustomLabel label="Member" required={false} />
                      <TextBox
                        onChange={onDataChange}
                        value={memberImport?.memberName}
                        type="text"
                        placeholder="Enter Name"
                        name="memberName"
                        autoComplete="new-password"
                      />
                    </div>
                    <div>
                      <CustomLabel label="Drawing No." required={false} />
                      <TextBox
                        onChange={onDataChange}
                        value={memberImport?.drawingNo}
                        type="text"
                        placeholder="Enter Drawing No."
                        name="drawingNo"
                        autoComplete="new-password"
                      />
                    </div>
                    <div>
                      <CustomLabel label="Revision" required={false} />
                      <TextBox
                        onChange={onDataChange}
                        value={memberImport?.revision}
                        type="text"
                        placeholder="Enter Revision"
                        name="revision"
                        autoComplete="new-password"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => {
                  resetImport();
                  setShowMemberImportDrawer(!showMemberImportDrawer);
                }}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                onClick={importMember}
                disabled={!validateImportBtn()}
              >
                Import
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
