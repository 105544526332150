import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { Plus, X } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import BBSDatePicker from "../../../component/inputs/BBSDatePicker";
import BBSTextField from "../../../component/inputs/BBSTextField";
import { IBBS } from "../../../interfaces/bbs.interface";
import { IObjectType } from "../../../interfaces/objectType.interface";
import { IDetailingOffice } from "../../../interfaces/project.interface";
import objectTypeSvc from "../../../services/objectType.service";
import projectSvc from "../../../services/project.service";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import {
  ICallOff,
  ICallOffMembers,
} from "../../../interfaces/callOff.interface";
import {
  getSelectedState,
  Grid,
  GridColumn,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import SearchBox from "../../../component/ui/SearchBox";
import { getter } from "@progress/kendo-react-common";
import { State } from "@progress/kendo-data-query";
import { debounce } from "lodash";
import bbsSvc from "../../../services/bbs.service";
import { ISearchFilter } from "../../../interfaces/searchFilter.interface";
import { IMember } from "../../../interfaces/member.interface";
import memberSvc from "../../../services/member.service";
import { elementOffset } from "@progress/kendo-drawing/dist/npm/util";
import callOffSvc from "../../../services/callOff.service";
import { useDispatch } from "react-redux";
import { setToast } from "../../../store/features/toastSlice";
import { prevView } from "@progress/kendo-react-dateinputs/messages";
import { DateCell } from "../../../shared-components/custom-cells";

const DATA_ITEM_KEY: string = "id";

interface IProps {
  showLogsDrawer: boolean;
  setShowLogsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  callOff: (ICallOff & { id: string }) | null;
  fetchCallOff: any;
}

function CallOffLogsDrawer({
  showLogsDrawer,
  setShowLogsDrawer,
  callOff,
  fetchCallOff,
}: //handleChange,
//onSave,
IProps) {
  const commonState = useAppSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const [logs, setLogs] = useState<any[]>();

  useEffect(() => {
    if (callOff && showLogsDrawer) {
      callOffSvc.getCallOffLogs(callOff?.id).then((res: any) => {
        if (res.data) {
          setLogs(res.data);
        }
      });
    }
  }, [callOff, showLogsDrawer]);

  useEffect(() => {
    if (commonState?.selectedProject?.event?.item) {
    }
  }, [commonState?.selectedProject]);

  useEffect(() => {
    if (showLogsDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showLogsDrawer]);

  return (
    <Drawer
      expanded={showLogsDrawer}
      position={"start"}
      mode={"overlay"}
      animation={{ duration: 400 }}
      className="customDrawer2 transition-all duration-500"
    >
      <DrawerContent>
        <div className="overlay" onClick={() => setShowLogsDrawer(false)}></div>
        <div
          className={`max-w-[750px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-screen w-full transform transition-all duration-500 overflow-y-scroll ${
            showLogsDrawer ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
            <h2 className="font-medium text-lg">
              Call-Off: {callOff?.name} Logs
            </h2>
            <Button
              fillMode="flat"
              className="p-2 bg-none outline-none"
              onClick={() => setShowLogsDrawer(false)}
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
          <div className="flex-1 py-4">
            <div className="grid gap-4">
              <div className="callOffMembers-Grid">
                <Grid
                  style={{ width: "100%", maxWidth: "100vw", height: "500px" }}
                  size="small"
                  className={`customHeight employee`}
                  scrollable="scrollable"
                  data={logs}
                >
                  {/* <GridColumn field="id" title="Id" editable={false} /> */}
                  <GridColumn field="action" title="Action" width={100} />
                  <GridColumn field="createdBy" title="Created By" />
                  <GridColumn
                    field="createdOn"
                    title="Created On"
                    width={175}
                    cells={{
                      data: (props: any) => <DateCell {...props} />,
                    }}
                  />

                  <GridColumn field="comments" title="Comments" />
                </Grid>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 pb-4 sticky-buttons">
            <Button
              className="border border-[#E2E8F0] px-4 py-2 font-medium"
              onClick={() => {
                setShowLogsDrawer(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default CallOffLogsDrawer;
