import { IFabricatorEmail } from '../interfaces/callOff.interface';
import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';

class CallOffService extends BaseService {
  constructor() {
    super('CallOff');
  }

  async getByMember(memberId: string) {
    try {
      // LoaderService.showLoader();
      const res = await api.get(`/CallOff/by-member/${memberId}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      // LoaderService.hideLoader();
    }
  }

  async sendFabEmail(payload: IFabricatorEmail) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/CallOff/send-email/`,
        {
          callOffId: payload.callOffId,
          to: payload.to,
          cc: payload.cc,
          bcc: payload.bcc,
          body: payload.body,
          subject: payload.subject,
          reports: payload.reports,
        }
      );
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getFabEmailContent(callOffId: string) {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/CallOff/fab-email/${callOffId}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getCallOffLogs(callOffId: string) {
    try {
      LoaderService.showLoader();
      const res = await api.get(`/CallOff/logs/${callOffId}`);
      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportExcel(callOffId: string, callOffName: string) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/CallOff/export-excel/${callOffId}`,
        {},
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${callOffName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async exportPDF(callOffId: string, callOffName: string, type: string) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/CallOff/export-pdf/${callOffId}/${type}`,
        {},
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(blob);
        return { data: url, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const callOffSvc = new CallOffService();

export default callOffSvc;
