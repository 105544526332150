import { api } from '../lib';
import { BaseService } from './base.service';

class SubContractorService extends BaseService {
  constructor() {
    super('Subcontractor');
  }

  async existsInProject(subContractorId: string) {
    const res: any = await api.get(`${process.env.REACT_APP_BASE_URL}/api/Subcontractor/exist-in-project/${subContractorId}`);

    if (res.ok) {
      return { data: res.data, error: null };
    }

    return { data: null, error: res?.data || true };
  }
}

const subContractorSvc = new SubContractorService();

export default subContractorSvc;
