const DeletionReasons = [
  'Deleted from BBS Import Window',
  'Member deleted from bbs',
  'Software Issue',
  'Tested Data',
  'Wrong Entry',
  'Wrong file name / BBS Repeatation',
  'Wrong Import',
  'Wrong Revison',
  'Delete',
];

export default DeletionReasons;
