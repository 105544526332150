import { configureStore } from '@reduxjs/toolkit';
import commonReducer from '../features/commonSlice';
import leaseStatusReducer from '../features/leaseStatusSlice';
import toastReducer from '../features/toastSlice';
import userReducer from '../features/userSlice';
import settingsReducer from '../features/settingsSlice';
import unitsReducer from '../features/unitsSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    common: commonReducer,
    toast: toastReducer,
    leaseFilteredStatus: leaseStatusReducer,
    settings: settingsReducer,
    units: unitsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
