import { date } from '@progress/kendo-react-dateinputs/messages';
import { api } from '../lib';
import { BaseService } from './base.service';
import LoaderService from './loader.service';
import moment from 'moment';

class ProjectService extends BaseService {
  constructor() {
    super('Project');
  }

  async exportSummary(id: any, name: string, type: any) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/BBSFileUpload/export-summary/${id}/${type}`,
        {},
        { responseType: 'blob' }
      );
      if (res.data) {
        // Read file and download it
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${name}_${type}_Summary_ ${moment(new Date()).format(
            'DD-MM-yyyy HH:mm:ss'
          )}`
        );
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
        return { data: res.data, error: null };
      }
      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async editLevel(
    projectId: string,
    level: number,
    levelCode: string,
    levelName: string,
    subLevelCode?: string,
    subLevelName?: string,
    shopDrawingCode?: string,
    shopDrawingName?: string,
    memberId?: string,
    memberName?: string
  ) {
    try {
      LoaderService.showLoader();
      const res: any = await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/Project/edit-level`,
        {
          projectId: projectId,
          level: level,
          levelCode: levelCode,
          levelName: levelName,
          subLevelCode: subLevelCode,
          subLevelName: subLevelName,
          shopDrawingCode: shopDrawingCode,
          shopDrawingName: shopDrawingName,
          memberId: memberId,
          memberName: memberName,
        }
      );

      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }

  async getTree() {
    try {
      LoaderService.showLoader();
      const res: any = await api.get(
        `${process.env.REACT_APP_BASE_URL}/api/Project/project-tree`
      );

      if (res.ok && res.data) {
        return { data: res.data, error: null };
      }

      return { data: null, error: res.data };
    } catch (error) {
      return { data: null, error: error };
    } finally {
      LoaderService.hideLoader();
    }
  }
}

const projectSvc = new ProjectService();

export default projectSvc;
