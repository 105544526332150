import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import {
  PagerTargetEvent,
  getSelectedState,
} from "@progress/kendo-react-data-tools";
import {
  DatePickerChangeEvent,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  ComboBoxChangeEvent,
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridDataStateChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Input, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import { debounce } from "lodash";
import {
  ArrowUpSquare,
  ChevronDown,
  FileText,
  FileUp,
  FileX,
  FileX2,
  Plus,
  Sheet,
} from "lucide-react";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import ImportDrawer from "../BBSComponents/ImportDrawer";
import SearchBox from "../../../component/ui/SearchBox";
import Filters from "../../../constants/filters";
import { IBBS } from "../../../interfaces/bbs.interface";
import IPageState from "../../../interfaces/page.interface";
import { ISearchFilter } from "../../../interfaces/searchFilter.interface";
import bbsSvc from "../../../services/bbs.service";
import {
  resetFetchProjects,
  setSelectedBBS,
  resetSelectedBBS,
  triggerFetchProjects,
} from "../../../store/features/commonSlice";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store/store";
import { getPaginationData } from "../../../utils/utils";
import { columnInterface, generateColumns } from "./columns";
import WriteDrawer from "./WriteDrawer";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CustomLabel from "../../../component/global/RequiredMark";
import { PDFViewer, PDFViewerTool } from "@progress/kendo-react-pdf-viewer";
import { setToast } from "../../../store/features/toastSlice";
import BBSLogsDrawer from "../BBSComponents/BBSLogsDrawer";

let mappingChanged = false;
const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const tools: PDFViewerTool[] = [
  "pager",
  "spacer",
  "zoomInOut",
  //"zoom",
  "selection",
  "spacer",
  "search",
  "print",
  "download",
];

const BBSListing = () => {
  const [editBBS, setEditBBS] = useState<(IBBS & { id: string }) | null>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showImportDrawer, setShowImportDrawer] = useState(false);
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [fileNameVal, setFileNameVal] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [actionFor, setActionFor] = useState<string>("edit");

  const [isSDI, setIsSDI] = useState(false);
  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      fetchAllBBS();
    }
  };
  const Pagination = getPaginationData();
  const [showLogsDrawer, setShowLogsDrawer] = useState(false);

  const dispatch = useDispatch();

  const toggleDialog = useCallback(() => {
    setShowDialog((prevShowDialog) => !prevShowDialog);
  }, []);

  const commonState = useAppSelector((state: RootState) => state.common);

  const debouncedSearch = useRef(
    debounce((value: string) => fetchBBS(value), 500)
  ).current;

  const bbsTemp = useRef<IBBS[]>([]);

  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});
  const [columnsMapping, setColumnsMapping] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >();
  const [bbs, setBbs] = useState<IBBS[]>([]);
  const [allBbs, setAllBbs] = useState<IBBS[]>([]);
  const [page, setPage] = useState<IPageState>(Pagination.initialDataState);

  const [isImportDisabled, setIsImportDisabled] = useState(true);

  const handleImportBBS = () => {
    setIsSDI(false);
    setShowImportDrawer(true);
    setShow(false);
  };

  const handleImportSDI = () => {
    setIsSDI(true);
    setShowImportDrawer(true);
    setShow(false);
  };

  useEffect(() => {
    if (commonState?.selectedProject?.event?.item?.level !== 2) {
      setIsImportDisabled(true);
    } else setIsImportDisabled(false);
  }, [commonState?.selectedProject?.event?.item]);

  const handleExport = (e: any) => {
    if (e?.id) {
      dispatch(setSelectedBBS(e));
      bbsSvc.exportBBS(e?.id, e?.no);
      return;
    }

    if (commonState?.selectedBBS?.id || e?.id) {
      bbsSvc.exportBBS(
        commonState?.selectedBBS?.id,
        commonState?.selectedBBS?.no
      );
    } else {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: "Please select a BBS to export",
        })
      );
    }

    setShow(false);
  };

  const handleExportSDI = (e: any) => {
    if (e?.id) {
      dispatch(setSelectedBBS(e));
      bbsSvc.exportBBSSDI(e?.id, e?.no);
      return;
    }

    if (commonState?.selectedBBS?.id || e?.id) {
      bbsSvc.exportBBSSDI(
        commonState?.selectedBBS?.id,
        commonState?.selectedBBS?.no
      );
    } else {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: "Please select a BBS to export",
        })
      );
    }

    setShow(false);
  };

  const fetchAllBBS = () => {
    let filterQueryTemp = { ...filterQuery };
    if (filterQueryTemp?.filters?.length) {
      const index = filterQueryTemp.filters[0].filters.findIndex(
        (filter: any) => filter.field === "isActive"
      );
      if (index === -1 && historyFilterValue === "Latest") {
        filterQueryTemp = filterQueryTemp.filters[0].filters.push({
          field: "isActive",
          operator: "eq",
          value: true,
        });
      }
    }
    bbsSvc.getAll([], filterQueryTemp).then((res) => {
      setAllBbs(res.data);
    });
  };

  const handleDelete = () => {
    editFilterQuery();
    //fetchBBS();
    dispatch(triggerFetchProjects());
  };

  const handleBBSLogsClick = (data: IBBS & { id: string }) => {
    setEditBBS(data);
    setShowLogsDrawer(true);
  };

  const [stateColumns, setStateColumns] = useState<any>(
    generateColumns(
      handleEditClick,
      handleImportBBS,
      handleExport,
      onColumnsSubmit,
      setShowDrawer,
      handleDelete,
      handleBBSLogsClick,
      null,
      columnsMapping,
      mappingChanged
    )
  );
  const [searchValue, setSearchValue] = useState("");
  const [filterQuery, setFilterQuery] = useState<any>({
    filters: [
      {
        logic: "and",
        filters: [
          {
            field: "isActive",
            operator: "eq",
            value: true,
          },
        ],
      },
    ],
  });

  const [historyFilterValue, setHistoryFilterValue] = useState("");

  const [dataState, setDataState] = useState<State>({
    skip: 0,
    take: 10,
  });
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>("");

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === "Escape") {
        setShowPdfPreview(false);
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [showPdfPreview]);

  const editBBSListing = () => {
    if (actionFor === "create") {
      bbsSvc
        .create({
          ...editBBS,
          projectId: commonState?.selectedProject?.event?.item?.projectId,
          levelCode: commonState?.selectedProject?.event?.item?.levelCode,
          levelName: commonState?.selectedProject?.event?.item?.levelName,
          subLevelCode: commonState?.selectedProject?.event?.item?.subLevelCode,
          subLevelName: commonState?.selectedProject?.event?.item?.subLevelName,
          templateMappingId: null,
          subcontractorId: null,
          isActive: true,
          preparedOn: convertToDateTime(editBBS?.preparedOn),
          checkedOn: convertToDateTime(editBBS?.checkedOn),
        })
        .then((res) => {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "BBS created successfully",
            })
          );
          fetchBBS(searchValue, filterQuery);
          dispatch(triggerFetchProjects());
          setEditBBS(null);
          setShowDrawer(false);
        });
      return;
    }
    if (!editBBS?.id) return;
    bbsSvc
      .update(
        editBBS!.id,

        {
          ...editBBS,
          preparedOn: convertToDateTime(editBBS?.preparedOn),
          checkedOn: convertToDateTime(editBBS?.checkedOn),
        }
      )
      .then((res) => {
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "BBS updated successfully",
          })
        );
        fetchBBS(searchValue, filterQuery);
        setEditBBS(null);
        setShowDrawer(false);
      });
  };

  function detectDateFormat(dateString: string) {
    const formats = [
      { regex: /^-?\d+(\.\d+)?$/, format: "oaDate" },
      { regex: /^\d{2}-\d{2}-\d{2}$/, format: "dd-mm-yy" },
      { regex: /^\d{4}-\d{2}-\d{2}$/, format: "yyyy-mm-dd" },
      {
        regex: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/,
        format: "mm/dd/yyyy",
      },
      {
        regex: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
        format: "dd/mm/yyyy",
      },
    ];

    for (let format of formats) {
      if (format.regex.test(dateString)) {
        return format.format;
      }
    }

    return null;
  }

  const convertToDateTime = (dateString: string | null | undefined) => {
    if (!dateString) return null;

    if (!dateString.includes("/")) {
      var parsedDate = parseDate(dateString);
      return parsedDate;
    }

    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month - 1, day + 1);
    return date;
  };

  function parseDate(dateString: string | null | undefined): Date | null {
    if (!dateString) return null;

    const format = detectDateFormat(dateString);
    if (!format) return null; // Invalid date

    let day: number, month: number, year: number;
    switch (format) {
      case "dd-mm-yy":
        [day, month, year] = dateString.split("-").map(Number);
        year = 2000 + year;
        break;
      case "yyyy-mm-dd":
        [year, month, day] = dateString.split("-").map(Number);
        break;
      case "mm/dd/yyyy":
        [month, day, year] = dateString.split("/").map(Number);
        break;
      case "dd/mm/yyyy":
        [day, month, year] = dateString.split("/").map(Number);
        break;
      case "oaDate":
        return new Date((Number(dateString) - 25569) * 86400 * 1000);
      default:
        return null;
    }

    const date = new Date(year, month - 1, day + 1);
    return isNaN(date.getTime()) ? null : date;
  }

  function handleEditClick(data: IBBS & { id: string }) {
    setEditBBS(data);
    setShowDrawer(true);
  }

  const handleChange = (
    e:
      | TextBoxChangeEvent
      | ComboBoxChangeEvent
      | DatePickerChangeEvent
      | DateTimePickerChangeEvent,
    isDropdown?: boolean
  ) => {
    if (e.target.name === "detailingOff" || e.target.name === "objectType") {
      setEditBBS((prev: any) => {
        return {
          ...prev,
          [e.target.name as any]: e.target.value.name,
        };
      });
      return;
    }
    if (e.target.name === "preparedOn" || e.target.name === "checkedOn") {
      const formattedDate = e.value
        ? `${e.value.getDate().toString().padStart(2, "0")}-${(
            e.value.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${e.value.getFullYear().toString().slice(-2)}`
        : null;
      setEditBBS((prev: any) => {
        return {
          ...prev,
          [e.target.name as any]: formattedDate,
        };
      });
      return;
    }
    setEditBBS((prev: any) => {
      return {
        ...prev,
        [e.target.name as any]: isDropdown ? e.target.value.id : e.target.value,
      };
    });
  };

  function onColumnsSubmit(columnsState: Array<columnInterface>) {
    const columnsMapping: any = [];
    for (let col of columnsState) {
      if (col.show) {
        columnsMapping.push(col.field);
      }
    }
    setColumnsMapping(columnsMapping);
    mappingChanged = true;
    const cols = generateColumns(
      handleEditClick,
      handleImportBBS,
      handleExport,
      onColumnsSubmit,
      setShowDrawer,
      handleDelete,
      handleBBSLogsClick,
      columnsState,
      columnsMapping,
      mappingChanged
    );
    setStateColumns(cols);
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (value?.length !== 1) {
      debouncedSearch(value);
    }
  };

  const projectSelected = () => {
    if (commonState?.selectedProject?.event?.item === undefined) {
      return false;
    }
    if (commonState?.selectedProject?.event?.item?.checked) {
      return false;
    }
    return true;
  };

  const fetchBBS = (
    searchQuery?: string,
    filters?: any,
    sort?: any,
    pageNumber = 0,
    pageSize = Pagination.initialDataState.take
  ) => {
    if (!projectSelected) return;
    setSelectedState({});
    let searchFilters: ISearchFilter[] = [];
    if (searchQuery) {
      searchFilters = [
        { field: "Name", operator: "contains", value: searchQuery },
        { field: "No", operator: "contains", value: searchQuery },
      ];
    }

    if (historyFilterValue == "") {
      setHistoryFilterValue("Latest");
    }

    if (filters?.filters?.length) {
      const index = filters.filters[0].filters.findIndex(
        (filter: any) => filter.field === "isActive"
      );
      const projIdx = filters.filters[0].filters.findIndex(
        (filter: any) => filter.field === "projectId"
      );
      if (projIdx === -1) return;
      if (index === -1 && historyFilterValue === "Latest") {
        filters.filters[0].filters.push({
          field: "isActive",
          operator: "eq",
          value: true,
        });
      }
    }

    bbsSvc
      .getWithUrl("get-bbs", searchFilters, filters, sort, pageNumber, pageSize)
      .then((res) => {
        bbsTemp.current = JSON.parse(JSON.stringify(res.data));
        setBbs(res.data);
        setTotal(res.data.total);
      });
  };

  useEffect(() => {
    if (commonState?.fetchProjects) {
      editFilterQuery();
      dispatch(resetFetchProjects());
    }
  }, [commonState?.fetchProjects]);

  const editFilterQuery = (showAll?: boolean) => {
    if (!projectSelected()) return;
    const { item } = commonState?.selectedProject?.event || {};
    const filterQueryTemp = {
      filters: [
        {
          logic: "and",
          filters:
            historyFilterValue === "Latest"
              ? [
                  {
                    field: "isActive",
                    operator: "eq",
                    value: true,
                  },
                ]
              : [],
        },
      ],
    };

    if (item) {
      const { level, projectId, levelCode, subLevelCode, shopDrawingCode } =
        item;

      const filterExists = (field: any) => {
        return filterQueryTemp.filters[0].filters.some(
          (filter: any) => filter.field === field
        );
      };
      const removeFilter = (field: any) => {
        filterQueryTemp.filters[0].filters =
          filterQueryTemp.filters[0].filters.filter(
            (filter: any) => filter.field !== field
          );
      };

      if (level === 0) {
        if (filterExists("projectId")) {
          removeFilter("projectId");
        }
        filterQueryTemp.filters[0].filters.push({
          field: "projectId",
          operator: "eq",
          value: projectId,
        });
      } else if (level === 1) {
        if (filterExists("projectId")) {
          removeFilter("projectId");
        }
        if (filterExists("levelCode")) {
          removeFilter("levelCode");
        }
        filterQueryTemp.filters[0].filters.push({
          field: "projectId",
          operator: "eq",
          value: projectId,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "levelCode",
          operator: "eq",
          value: levelCode,
        });
      } else if (level === 2) {
        if (filterExists("projectId")) {
          removeFilter("projectId");
        }
        if (filterExists("levelCode")) {
          removeFilter("levelCode");
        }
        if (filterExists("subLevelCode")) {
          removeFilter("subLevelCode");
        }
        filterQueryTemp.filters[0].filters.push({
          field: "projectId",
          operator: "eq",
          value: projectId,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "levelCode",
          operator: "eq",
          value: levelCode,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "subLevelCode",
          operator: "eq",
          value: subLevelCode,
        });
      } else if (level === 3 || level == 4) {
        if (filterExists("projectId")) {
          removeFilter("projectId");
        }
        if (filterExists("levelCode")) {
          removeFilter("levelCode");
        }
        if (filterExists("subLevelCode")) {
          removeFilter("subLevelCode");
        }
        if (filterExists("shopDrawingCode")) {
          removeFilter("shopDrawingCode");
        }
        filterQueryTemp.filters[0].filters.push({
          field: "projectId",
          operator: "eq",
          value: projectId,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "levelCode",
          operator: "eq",
          value: levelCode,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "subLevelCode",
          operator: "eq",
          value: subLevelCode,
        });
        filterQueryTemp.filters[0].filters.push({
          field: "shopDrawingCode",
          operator: "eq",
          value: shopDrawingCode,
        });
      }
    }
    setFilterQuery(filterQueryTemp);
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    fetchBBS(
      "",
      event.dataState.filter,
      event.dataState.sort,
      page.skip,
      page.take
    );
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = targetEvent.value === "All" ? bbs.length : event.page.take;

    fetchBBS(searchValue, null, event.page.skip, event.page.take);
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  useEffect(() => {
    if (commonState?.selectedProject?.event?.item) {
      editFilterQuery();
    }
  }, [commonState?.selectedProject]);

  useEffect(() => {
    setSelectedState({});
    fetchBBS(searchValue, filterQuery);
  }, [filterQuery, searchValue]);

  const onHistoryFilterChange = (e: DropDownListChangeEvent) => {
    setHistoryFilterValue(e.value);
    const prevFilter = { ...filterQuery };
    const index = prevFilter.filters[0].filters.findIndex(
      (filter: any) => filter.field === "isActive"
    );
    if (index === -1) {
      if (e.value === "Latest") {
        prevFilter.filters[0].filters.push({
          field: "isActive",
          operator: "eq",
          value: true,
        });
      }
    } else {
      if (e.value === "Latest") {
        prevFilter.filters[0].filters[index].value = true;
      } else {
        prevFilter.filters[0].filters.splice(index, 1);
      }
    }
    setFilterQuery(prevFilter);
  };

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      if (Object.keys(newSelectedState)[0] === Object.keys(selectedState)[0]) {
        dispatch(resetSelectedBBS());
        setSelectedState({});
        return;
      }

      if (newSelectedState) {
        const key = Object.keys(newSelectedState)[0];
        const row = bbsTemp?.current?.find((item) => key === item.id) || null;
        dispatch(setSelectedBBS(row));
      }
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  useEffect(() => {
    if (selectedState && Object.values(selectedState).length == 0) {
      dispatch(resetSelectedBBS());
    }
  }, [selectedState]);

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: HTMLInputElement = event.syntheticEvent
        .target as HTMLInputElement;
      const checked = checkboxElement.checked;
      const newSelectedState: { [id: string]: boolean | number[] } = {};
      bbs?.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  const handleDownload = (e: any) => {
    handlePDFDialog(fileType);
    setShowPdfPreview(false);
    return false;
  };

  const handlePDFDialog = (type: string) => {
    setFileNameVal(
      `${commonState?.selectedBBS?.no} - ${commonState?.selectedBBS?.revNo}`
    );
    setFileType(type);
    setShowDialog(true);
  };

  const handlePdfDownload = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `${fileNameVal} - ${fileType}.pdf`);
    document.body.appendChild(link);
    link.click();
    setShowDialog(false);
    // document.removeChild(link);
  };

  const handlePDFExport = async (type: string) => {
    if (commonState?.selectedBBS?.id) {
      const res = await bbsSvc.exportBBSPDF(
        commonState?.selectedBBS?.id,
        commonState?.selectedBBS?.no,
        type
      );
      if (res.data) {
        if (res.data) {
          setFileUrl(res.data);
          setShowPdfPreview(true);
          setFileType(type);
        }
      }
    }
    setShow(false);
  };

  useEffect(() => {
    if (!allBbs?.length) return;
    _exporter.current?.save();
    setAllBbs([]);
  }, [allBbs]);

  const popupRef = React.useRef<HTMLDivElement>(null);
  const anchor = useRef<HTMLButtonElement | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  const onClick = () => {
    setShowPopup(!showPopup);
  };

  const handleClickOutside = useCallback((event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        anchor.current &&
        !anchor.current.contains(event.target)
      ) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (showImportDrawer) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showImportDrawer]);

  const handleAddClick = () => {
    if (commonState?.selectedProject?.event?.item) {
      setActionFor("create");
      setShowDrawer(true);
      setEditBBS(null);
    }
  };

  useEffect(() => {
    setActionFor("");
  }, [showImportDrawer]);

  return (
    <>
      <div style={{ height: "100%" }}>
        <Grid
          size="small"
          style={{ height: "650px" }} //maxWidth: "100vw",
          data={bbs?.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          {...dataState}
          onDataStateChange={dataStateChange}
          skip={page.skip}
          take={page.take}
          total={total}
          selectedField={SELECTED_FIELD}
          pageable={{
            ...Pagination.pageSizeValue,
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          className={`customHeight bbsGrid`}
          resizable={Filters.resizeable}
          selectable={{
            enabled: true,
            drag: true,
            mode: "single",
          }}
          scrollable="scrollable"
          fixedScroll={true}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
        >
          <GridToolbar>
            <div className="flex gap-3 w-full items-center justify-between">
              <div className="flex gap-x-3 w-full">
                <div className="w-[250px]">
                  <SearchBox
                    value={searchValue}
                    onChange={handleSearchChange}
                    placeholder="Search"
                  />
                </div>
                {/* <div
                className="cursor-pointer sm:ml-0 ml-2 sm:flex-initial flex-1"
                onClick={excelExport}
              >
                <Export />
                <ExcelExport
                  data={allBbs}
                  collapsible={true}
                  fileName="Subcontractors.xlsx"
                  ref={_exporter}
                >
                  {stateColumns &&
                    stateColumns.length > 0 &&
                    stateColumns.map((column: any, idx: number) => {
                      if (column.show) {
                        return (
                          <ExcelExportColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                          />
                        );
                      }
                    })}
                </ExcelExport>
              </div> */}
                <DropDownList
                  className="px-2 font-small"
                  data={["Latest", "All"]}
                  value={historyFilterValue}
                  onChange={onHistoryFilterChange}
                />
                <Button
                  themeColor={"primary"}
                  className="border border-[#E2E8F0]"
                  onClick={handleAddClick}
                >
                  <span className="inline-flex items-center gap-2">
                    <Plus className="w-4 h-4" /> Add New
                  </span>
                </Button>
                <Button
                  onClick={handleImportBBS}
                  disabled={isImportDisabled}
                  className="px-2 font-small"
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <Sheet className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">
                      Import BBS
                    </span>
                  </span>
                </Button>
                <Button onClick={handleExport} className="px-2 font-small">
                  <span className="inline-flex items-center gap-2 w-full">
                    <Sheet className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">
                      Export BBS
                    </span>
                  </span>
                </Button>
                <Button
                  onClick={handleImportSDI}
                  disabled={isImportDisabled}
                  className="px-2 font-small"
                >
                  <span className="inline-flex items-center gap-2 w-full">
                    <FileText className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">
                      Import SDI
                    </span>
                  </span>
                </Button>

                <Button onClick={handleExportSDI} className="px-2 font-small">
                  <span className="inline-flex items-center gap-2 w-full">
                    <FileText className="w-4 h-4" />
                    <span className="flex-1 text-sm font-normal">
                      Export SDI
                    </span>
                  </span>
                </Button>

                <button
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md px-2 font-small"
                  onClick={onClick}
                  ref={anchor}
                >
                  <span className="inline-flex items-center gap-2">
                    <FileUp className="w-4 h-4" />

                    <span className="border-r border-neutral-110 pr-2">
                      BBS Reports
                    </span>

                    <ChevronDown className="w-4 h-4" />
                  </span>
                </button>
                <Popup
                  anchor={anchor.current}
                  show={showPopup}
                  popupClass={"popup-content w-[174px]"}
                  anchorAlign={{ horizontal: "right", vertical: "bottom" }}
                  popupAlign={{ horizontal: "right", vertical: "top" }}
                >
                  <div ref={popupRef}>
                    <Button
                      fillMode="flat"
                      disabled={!commonState?.selectedBBS}
                      onClick={() => {
                        handlePDFExport("Units");
                        setShowPopup(false);
                      }}
                      className="w-full iconBtn px-1"
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <FileX className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Units
                        </span>
                      </span>
                    </Button>
                    <Button
                      fillMode="flat"
                      disabled={!commonState?.selectedBBS}
                      onClick={() => {
                        handlePDFExport("Total Weight");
                        setShowPopup(false);
                      }}
                      className="w-full iconBtn px-1"
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <FileX className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Total Weight
                        </span>
                      </span>
                    </Button>
                    <Button
                      fillMode="flat"
                      disabled={!commonState?.selectedBBS}
                      onClick={() => {
                        handlePDFExport("Members");
                        setShowPopup(false);
                      }}
                      className="w-full iconBtn px-1"
                    >
                      <span className="inline-flex items-center gap-2 w-full">
                        <FileX className="w-4 h-4" />
                        <span className="flex-1 text-sm font-normal">
                          Members
                        </span>
                      </span>
                    </Button>
                  </div>
                </Popup>
              </div>
            </div>
          </GridToolbar>
          {stateColumns &&
            stateColumns.length > 0 &&
            stateColumns.map((column: any, idx: number) => {
              if (column.show) {
                if (column.cells) {
                  return (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      columnMenu={column.columnMenu}
                      minResizableWidth={200}
                      width={column.width}
                      cells={column.cells}
                      filter={column.filter ? column.filter : "text"}
                    />
                  );
                }
                return (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    columnMenu={column.columnMenu}
                    minResizableWidth={200}
                    width={column.width}
                    filter={column.filter ? column.filter : "text"}
                  />
                );
              }
            })}
        </Grid>
      </div>
      <WriteDrawer
        showCreateDrawer={showDrawer}
        setShowCreateDrawer={setShowDrawer}
        editBBS={editBBS}
        handleChange={handleChange}
        onSave={editBBSListing}
        actionFor={actionFor || "edit"}
      />
      <ImportDrawer
        show={showImportDrawer}
        setShow={setShowImportDrawer}
        isSDI={isSDI}
      />

      {showDialog && (
        <Dialog
          width={450}
          title={"Export BBS " + fileType}
          onClose={() => setShowDialog(false)}
          className="custom-dialog"
        >
          <div className="my-4">
            <CustomLabel label={"File Name"} required={true} />
            <Input
              type="text"
              placeholder={"Enter Name"}
              value={fileNameVal}
              onChange={(e: any) => {
                setFileNameVal(e.target.value);
              }}
              autoComplete="new-password"
              list="autoCompleteOff"
            />
          </div>
          <DialogActionsBar>
            <div className="flex justify-end gap-3">
              <Button
                className="border border-[#E2E8F0] xpx-2 py-2 font-small"
                themeColor={"primary"}
                disabled={!fileNameVal}
                onClick={handlePdfDownload}
              >
                Save
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => setShowDialog(false)}
                themeColor={"base"}
                fillMode="outline"
              >
                Cancel
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
      {showPdfPreview && (
        <div className={`fixed h-screen w-screen z-[999999] top-0 left-0`}>
          <div
            className="overlay"
            onClick={() => setShowPdfPreview(false)}
          ></div>
          <div className="flex justify-center items-center w-full h-full">
            {
              <PDFViewer
                url={fileUrl}
                style={{ height: "100vh", width: "90vw" }}
                saveFileName={fileNameVal}
                tools={tools}
                defaultZoom={1.5}
                onDownload={(e) => handleDownload(e)}
              />
            }
          </div>
        </div>
      )}

      <BBSLogsDrawer
        showLogsDrawer={showLogsDrawer}
        setShowLogsDrawer={setShowLogsDrawer}
        bbs={editBBS}
      />
    </>
  );
};

export default BBSListing;
