import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Checkbox,
  CheckboxChangeEvent,
  TextBox,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import CustomLabel from "../../../component/global/RequiredMark";
import { IBarType, IStandard } from "../../../interfaces/standard.interface";
import projectSvc from "../../../services/project.service";
import standardSvc from "../../../services/standard.service";
import companySvc from "../../../services/company.service";
import { ICompany } from "../../../interfaces/company.interface";
import { setToast } from "../../../store/features/toastSlice";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { triggerFetchProjects } from "../../../store/features/commonSlice";

const initialStateProject = {
  standardId: null,
  code: "",
  name: "",
  abbreviation: "",
  callOffPrefix: "",
  clientName: "",
  location: "",
  address: "",
  contactPerson: "",
  barLength: null,
  barTypeCode: "",
  detailingOffices: [],
  projectStandardLength: [],
  levels: [],
  subcontractors: [],
  companyId: "",
  showDeleted: true,
};

export const AddProject = ({
  showAddProjectDrawer,
  setShowAddProjectDrawer,
  showEditProjectDrawer,
  setShowEditProjectDrawer,
  prevProject,
  setPrevProject,
  projectToEdit,
  setProjectToEdit,
  fetchProjects,
}: any) => {
  const [newProject, setNewProject] = useState(initialStateProject);
  const dispatch = useAppDispatch();

  const [validFieldsOnAdd, setValidFieldsOnAdd] = useState<any>({
    name: null,
    code: null,
    clientName: null,
    location: null,
    barLength: null,
  });

  const [validFieldsOnEdit, setValidFieldsOnEdit] = useState<any>({
    name: null,
    code: null,
    clientName: null,
    location: null,
    barLength: null,
  });

  const [standards, setStandards] = useState<IStandard[]>([]);
  const [barTypes, setBarTypes] = useState<IBarType[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);

  const fetchStandards = () => {
    standardSvc.getAll().then((res) => {
      setStandards(res.data);
    });
  };

  useEffect(() => {
    if (projectToEdit?.standardId && standards.length) {
      const standard = standards?.find(
        (std) => std.id === projectToEdit?.standardId
      );
      if (!standard) return;
      setBarTypes(standard.barTypes);
    }
  }, [projectToEdit]);

  const getStandardValue = () => {
    const standard = standards?.find(
      (std) => std.id === newProject?.standardId
    );
    if (!standard) return "";
    return standard;
  };

  const getEditStandardValue = () => {
    const standard = standards?.find(
      (std) => std.id === projectToEdit?.standardId
    );
    if (!standard) return "";
    return standard;
  };

  const getBarTypeValue = () => {
    const barType = barTypes?.find(
      (std) => std.code === newProject?.barTypeCode
    );
    if (!barType) return "";
    return barType;
  };

  const getCompanyValue = () => {
    const company = companies?.find((x) => x.id === newProject?.companyId);
    if (!company && companies.length) {
      setNewProject((prev: any) => {
        return {
          ...prev,
          companyId: companies[0].id,
        };
      });
      return;
    }
    return company;
  };

  const getEditCompanyValue = () => {
    const company = companies?.find((x) => x.id === projectToEdit?.companyId);
    if (!company) return;
    return company;
  };

  const getEditBarTypeValue = () => {
    const barType = barTypes?.find(
      (std) => std.code === projectToEdit?.barTypeCode
    );
    if (!barType) return projectToEdit?.barTypeCode;
    return barType;
  };

  useEffect(() => {
    if (showAddProjectDrawer || showEditProjectDrawer) fetchStandards();
  }, [showAddProjectDrawer, showEditProjectDrawer]);

  const saveProject = async () => {
    try {
      const res: any = await projectSvc.create(newProject).then((res) => {
        if (res) {
          dispatch(triggerFetchProjects());
          setShowAddProjectDrawer(false);
        }
      });
    } catch (error) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `Unable to save project, Please try again.`,
        })
      );
    }
  };

  const handleEditProject = async () => {
    try {
      setProjectToEdit((prev: any) => {
        return { ...prev };
      });

      await projectSvc.update(projectToEdit?.id!, projectToEdit).then((res) => {
        if (res) {
          dispatch(
            setToast({
              toastType: "success",
              toastMsg: "Project updated successfully",
            })
          );
          dispatch(triggerFetchProjects());
          setShowEditProjectDrawer(false);
        }
      });
    } catch (error) {
      dispatch(
        setToast({
          toastType: "error",
          toastMsg: `Unable to update project, Please try again.`,
        })
      );
    }
  };

  const validateNewProject = () => {
    if (!newProject) return false;
    if (
      !newProject.name ||
      !newProject.abbreviation ||
      !newProject.callOffPrefix ||
      !newProject.clientName ||
      !newProject.standardId ||
      !newProject.barLength ||
      newProject.barLength > 12000 ||
      !newProject.barTypeCode ||
      !newProject.companyId
    )
      return false;

    return true;
  };

  const validateEditProject = () => {
    if (!projectToEdit) return false;

    if (prevProject == projectToEdit) return false;
    if (
      !projectToEdit.name ||
      !projectToEdit.abbreviation ||
      !projectToEdit.clientName ||
      !projectToEdit.callOffPrefix ||
      !projectToEdit.companyId ||
      !projectToEdit.standardId ||
      !projectToEdit.barLength ||
      projectToEdit.barLength > 12000 ||
      !projectToEdit.barTypeCode
    )
      return false;

    return true;
  };

  const onProjectEditChange = (
    e: ComboBoxChangeEvent | TextBoxChangeEvent | CheckboxChangeEvent
  ) => {
    if (e.target.name === "name") {
      setValidFieldsOnEdit((prev: any) => {
        return {
          ...prev,
          Name: validateName(e.value)
            ? null
            : "Name can't contain numbers or special characters",
        };
      });
    }

    if (e.target.name === "standardId") {
      if (e.value) setBarTypes(e.value.barTypes);
    }

    setProjectToEdit((prev: any) => {
      return {
        ...prev,
        [e.target.name as string]:
          e.target.name === "standardId" || e.target.name === "companyId"
            ? e.value.id
            : e.target.name === "barTypeCode"
            ? e.value.code
            : e.value,
        //barTypeCode: e.target.name === "standardId" ? "" : prev.barTypeCode,
      };
    });
  };

  const onNewProjectChange = (
    e: ComboBoxChangeEvent | TextBoxChangeEvent | CheckboxChangeEvent
  ) => {
    if (e.target.name === "standardId") {
      if (e.value) setBarTypes(e.value.barTypes);
    }

    setNewProject((prev: any) => {
      return {
        ...prev,
        [e.target.name as string]:
          e.target.name === "standardId" || e.target.name === "companyId"
            ? e.value.id
            : e.target.name === "barTypeCode"
            ? e.value.code
            : e.value,
        //barTypeCode: e.target.name === "standardId" ? "" : prev.barTypeCode,
      };
    });
  };

  const validateName = (username: string) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    return nameRegex.test(username);
  };

  const fetchCompanies = async () => {
    const filters: any = {
      filters: [
        {
          logic: "and",
          filters: [
            {
              field: "isActive",
              operator: "eq",
              value: true,
            },
          ],
        },
      ],
    };
    companySvc.getAll([], filters).then((res) => {
      if (res.data) {
        setCompanies(res.data);
      }
    });
  };

  useEffect(() => {
    if (showAddProjectDrawer || showEditProjectDrawer) fetchCompanies();
  }, [showAddProjectDrawer, showEditProjectDrawer]);

  useEffect(() => {
    if (!showAddProjectDrawer) setNewProject(initialStateProject);
    if (!showEditProjectDrawer) setProjectToEdit(null);
  }, [showAddProjectDrawer, showEditProjectDrawer]);
  return (
    <>
      <Drawer
        expanded={showAddProjectDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500 "
        // onOverlayClick={() => handleEditClick}
        // onSelect={() => handleEditClick}
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowAddProjectDrawer(!showAddProjectDrawer)}
          ></div>
          <div
            className={`max-w-[800px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showAddProjectDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Add New Project</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() => setShowAddProjectDrawer(!showAddProjectDrawer)}
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4 grid-cols-2">
                <div className="grid gap-y-2">
                  <div>
                    <CustomLabel label="Name" required={true} />

                    <TextBox
                      value={newProject.name}
                      onChange={onNewProjectChange}
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      valid={!validFieldsOnAdd.name}
                      autoComplete="new-password"
                    />
                    {validFieldsOnAdd?.name && (
                      <p className="text-red-600 text-[12px]">
                        {validFieldsOnAdd?.name}
                      </p>
                    )}
                  </div>
                  <div>
                    <CustomLabel label="Abbreviation" required={true} />

                    <TextBox
                      value={newProject.abbreviation}
                      onChange={onNewProjectChange}
                      type="text"
                      placeholder="Enter Abbreviation"
                      name="abbreviation"
                      valid={!validFieldsOnAdd.abbreviation}
                      autoComplete="new-password"
                    />
                    {validFieldsOnAdd?.abbreviation && (
                      <p className="text-red-600 text-[12px]">
                        {validFieldsOnAdd?.abbreviation}
                      </p>
                    )}
                  </div>
                  <div>
                    <CustomLabel label="Client Name" required={true} />

                    <TextBox
                      onChange={onNewProjectChange}
                      value={newProject.clientName}
                      placeholder="Client Name"
                      name="clientName"
                      valid={!validFieldsOnAdd.email}
                      autoComplete="new-password"
                    />
                    {validFieldsOnAdd?.email && (
                      <p className="text-red-600 text-[12px]">
                        {validFieldsOnAdd?.email}
                      </p>
                    )}
                  </div>

                  <div>
                    <CustomLabel label="Company" required={true} />
                    <ComboBox
                      data={companies || []}
                      onChange={onNewProjectChange}
                      value={getCompanyValue()}
                      name="companyId"
                      clearButton={false}
                      placeholder="Select Company"
                      textField="name"
                      dataItemKey="id"
                    />
                  </div>
                </div>
                <div className="grid gap-y-2">
                  <div>
                    <CustomLabel label="Standard" required={true} />

                    <ComboBox
                      data={standards || []}
                      onChange={onNewProjectChange}
                      value={getStandardValue()}
                      name="standardId"
                      clearButton={false}
                      placeholder="Select Standard"
                      textField="name"
                      dataItemKey="id"
                    />
                  </div>
                  <div>
                    <CustomLabel label="Bar Length" required={true} />

                    <TextBox
                      value={newProject.barLength || ""}
                      onChange={onNewProjectChange}
                      type="text"
                      placeholder="Enter Bar Length"
                      name="barLength"
                      autoComplete="new-password"
                    />
                  </div>
                  <div>
                    <CustomLabel label="Bar Type" required={true} />

                    <ComboBox
                      data={barTypes || []}
                      onChange={onNewProjectChange}
                      value={getBarTypeValue()}
                      name="barTypeCode"
                      clearButton={false}
                      placeholder="Select Bar Type"
                      textField="name"
                      dataItemKey="code"
                    />
                  </div>
                  <div>
                    <CustomLabel label="Call-Off Prefix" required={true} />
                    <ComboBox
                      data={[
                        "Project Abbreviation",
                        "Subcontractor Abbreviation",
                        "Project-Subcontractor Abbreviation",
                      ]}
                      onChange={onNewProjectChange}
                      value={newProject.callOffPrefix}
                      name="callOffPrefix"
                      clearButton={false}
                      placeholder="Select Call-Off Prefix"
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <Checkbox
                    name="showDeleted"
                    value={newProject?.showDeleted || false}
                    onChange={onNewProjectChange}
                    label={"Show Deleted"}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => setShowAddProjectDrawer(!showAddProjectDrawer)}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                onClick={saveProject}
                disabled={!validateNewProject()}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>

      <Drawer
        expanded={showEditProjectDrawer}
        position={"start"}
        mode={"overlay"}
        animation={{ duration: 400 }}
        className="customDrawer2 transition-all duration-500 "
        // onOverlayClick={() => handleEditClick}
        // onSelect={() => handleEditClick}
      >
        <DrawerContent>
          <div
            className="overlay"
            onClick={() => setShowEditProjectDrawer(!showEditProjectDrawer)}
          ></div>
          <div
            className={`max-w-[800px] flex flex-col bg-neutral-10 px-4 fixed right-0 top-0 bottom-0 h-full w-full transform transition-all duration-500 ${
              showEditProjectDrawer ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="py-4 flex items-center justify-between gap-2 border-b border-neutral-30">
              <h2 className="font-medium text-lg">Manage Project</h2>
              <Button
                fillMode="flat"
                className="p-2 bg-none outline-none"
                onClick={() => setShowEditProjectDrawer(!showEditProjectDrawer)}
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex-1 py-4">
              <div className="grid gap-4 grid-cols-2">
                <div className="grid gap-y-2">
                  <div>
                    <CustomLabel label="Name" required={true} />

                    <TextBox
                      value={projectToEdit?.name}
                      onChange={onProjectEditChange}
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      valid={!validFieldsOnEdit.name}
                    />
                    {validFieldsOnEdit?.name && (
                      <p className="text-red-600 text-[12px]">
                        {validFieldsOnEdit?.name}
                      </p>
                    )}
                  </div>
                  <div>
                    <CustomLabel label="Abbreviation" required={true} />

                    <TextBox
                      value={projectToEdit?.abbreviation}
                      onChange={onProjectEditChange}
                      type="text"
                      placeholder="Enter Abbreviation"
                      name="abbreviation"
                      valid={!validFieldsOnEdit.abbreviation}
                    />
                    {validFieldsOnEdit?.abbreviation && (
                      <p className="text-red-600 text-[12px]">
                        {validFieldsOnEdit?.abbreviation}
                      </p>
                    )}
                  </div>

                  <div>
                    <CustomLabel label="Client Name" required={true} />

                    <TextBox
                      onChange={onProjectEditChange}
                      value={projectToEdit?.clientName}
                      placeholder="Client Name"
                      name="clientName"
                      valid={!validFieldsOnEdit.clientName}
                    />
                    {validFieldsOnEdit?.email && (
                      <p className="text-red-600 text-[12px]">
                        {validFieldsOnEdit?.clientName}
                      </p>
                    )}
                  </div>

                  <div>
                    <CustomLabel label="Company" required={true} />
                    <ComboBox
                      data={companies || []}
                      onChange={onProjectEditChange}
                      value={getEditCompanyValue()}
                      name="companyId"
                      clearButton={false}
                      placeholder="Select Company"
                      textField="name"
                      dataItemKey="id" // valid={!validFieldsOnEdit.standardId}
                    />
                  </div>
                </div>
                <div className="grid gap-y-2">
                  <div>
                    <CustomLabel label="Standard" required={true} />

                    <ComboBox
                      data={standards || []}
                      onChange={onProjectEditChange}
                      value={getEditStandardValue()}
                      name="standardId"
                      clearButton={false}
                      placeholder="Select Standard"
                      textField="name"
                      dataItemKey="id"
                      valid={!validFieldsOnEdit.standardId}
                    />
                    {validFieldsOnEdit?.standardId && (
                      <p className="text-red-600 text-[12px]">
                        {validFieldsOnEdit?.standardId}
                      </p>
                    )}
                  </div>
                  <div>
                    <CustomLabel label="Bar Length" required={true} />

                    <TextBox
                      value={projectToEdit?.barLength}
                      onChange={onProjectEditChange}
                      type="number"
                      placeholder="Enter Bar Length"
                      name="barLength"
                      valid={projectToEdit?.barLength <= 12000}
                    />
                    {validFieldsOnEdit?.barLength && (
                      <p className="text-red-600 text-[12px]">
                        {validFieldsOnEdit?.barLength} cannot be greated than
                        12000
                      </p>
                    )}
                  </div>
                  <div>
                    <CustomLabel label="Bar Type" required={true} />

                    <ComboBox
                      data={barTypes || []}
                      onChange={onProjectEditChange}
                      value={getEditBarTypeValue()}
                      name="barTypeCode"
                      clearButton={false}
                      placeholder="Select Bar Type"
                      textField="name"
                      dataItemKey="code"
                    />
                  </div>
                  <div>
                    <CustomLabel label="Call-Off Prefix" required={true} />
                    <ComboBox
                      data={[
                        "Project Abbreviation",
                        "Subcontractor Abbreviation",
                        "Project-Subcontractor Abbreviation",
                      ]}
                      onChange={onProjectEditChange}
                      value={
                        projectToEdit?.callOffPrefix || "Project Abbreviation"
                      }
                      name="callOffPrefix"
                      clearButton={false}
                      placeholder="Select Call-Off Prefix"
                    />
                  </div>
                </div>
                <div>
                  <Checkbox
                    name="showDeleted"
                    value={projectToEdit?.showDeleted || false}
                    onChange={onProjectEditChange}
                    label={"Show Deleted"}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pb-4">
              <Button
                className="border border-[#E2E8F0]"
                onClick={() => setShowEditProjectDrawer(!showEditProjectDrawer)}
              >
                Cancel
              </Button>
              <Button
                className="border border-[#E2E8F0]"
                themeColor={"primary"}
                onClick={handleEditProject}
                disabled={!validateEditProject()}
              >
                Save
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
